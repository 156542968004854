import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { User } from 'src/app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';
import { HostbiPlan } from 'src/app/modules/hostbi/models/hostbi-plan';
import { HostbiAccount } from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { UserService } from 'src/app/modules/user/services/user.service';

@Injectable()
export class HostbiAccountsService {
    public list = new BehaviorSubject<Array<HostbiAccount>>([]);
    public listIsUpdate = new BehaviorSubject<any>('');
    public listLoader = new BehaviorSubject<boolean>(false);
    public listLoaderStatus = new BehaviorSubject<string>('load');

    public workspace = 'manage/';

    constructor(
        private apiService: ApiService,
        private userService: UserService,
    ) {
        this.listIsUpdate.subscribe(() => {
            this.listLoader.next(true);
            this.listLoaderStatus.next('load');

            this.whitWorkspace().getAll().then((success: HttpResponseData) => {
                this.listLoader.next(false);

                if (success.result.length > 0) {
                    this.list.next(success.result);
                    this.listLoaderStatus.next('success');
                } else {
                    this.listLoaderStatus.next('empty');
                }
            }).catch(() => {
                this.listLoader.next(false);
                this.listLoaderStatus.next('error');
            });
        });

        this.userService.userData.subscribe((user: User) => {
            if(user.isSessionSet()){
                this.list.next([]);
                this.listUpdate();
            }
        });
    }

    /**
     * Execute the process of reloading the list of accounts
     */
    listUpdate(): void {
        this.listIsUpdate.next(Date.now());
    }

    whitWorkspace(){
        this.workspace = 'workspace/';

        return this;
    }

    whitoutWorkspace(){
        this.workspace = 'manage/';

        return this;
    }

    getAll(page = 1, pageSize = 10, filters?: { search?: string; workspace?: number; server?: number; }): Promise<HttpResponseData> {
        let url = 'module/hostbi/v1/' + this.workspace + 'accounts?page=' + page + '&pageSize=' + pageSize;

        if(typeof filters !== 'undefined'){
            const filtered = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v !== null && v !== 0 && v !== ''));

            if(Object.keys(filtered).length > 0){
                url = url + '&' + this.apiService.paramsBuild(filtered);
            }
        }

        return new Promise((resolve, reject) => {
            this.apiService.request('get', url).toPromise().then((success: any) => {
                if (success.status) {
                    const accountsData: Array<HostbiAccount> = [];

                    success.data.results.forEach((value: any) => {
                        accountsData.push(new HostbiAccount(value));
                    });

                    resolve(this.apiService.successDataPrepare(success.data.message, accountsData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    get(id: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/' + this.workspace + 'accounts/' + id).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccount(success.data.account)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    create(data: { workspace: string; domain: string; service: number }): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.request('post', 'module/hostbi/v1/manage/accounts', { ...data, ...{ type: 'hosting' }},
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccount(success.data.account)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    /*create(domain: string, plan: HostbiPlan): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'accounts/hosting',
                { domain, plan: plan.id },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }*/

    delete(id: number): Promise<any> {
       return new Promise((resolve, reject) => {
            this.apiService.request('delete', 'module/hostbi/v1/' + this.workspace + 'accounts/' + id).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
    
    summary(id: number): Promise<any> {
       return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/' + this.workspace + 'accounts/' + id + '/summary').toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    cancellation(id: number, data: { motive: string, recommendation: string, note: string }): Promise<any> {
       return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + id + '/cancellation',
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccount(success.data.account)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    cancellationAbort(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + id + '/cancellation/abort',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccount(success.data.account)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
 
    plans(id: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/' + this.workspace + 'accounts/' + id + '/plans').toPromise().then((success: any) => {             
                if (success.status) {
                    const plansData: Array<HostbiPlan> = [];

                    success.data.plans.forEach((value: any) => {
                        plansData.push(new HostbiPlan(value));
                    });
                    
                    resolve(this.apiService.successDataPrepare(success.data.message, plansData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    planChange(id: number, planId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.request('put', 'module/hostbi/v1/' + this.workspace + 'accounts/' + id + '/plan/', { planId }).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    login(id: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/' + this.workspace + 'accounts/' + id + '/login').toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
    
    emailLogin(id: number, email: string, password: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + id + '/email/login',
                { email, password },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}

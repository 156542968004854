import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

// Models
import { ScreenSizeData } from '../../models/screen-size';

// Services
import { UiService } from '../../services/ui.service';
import { MenuService } from './ui-menu.service';

@Component({
  selector: 'ui-menu',
  templateUrl: './ui-menu.component.html',
  styleUrls: ['./ui-menu.component.scss'],
  animations: [
    trigger('slide', [
      state('true', style({
        transform: 'translate(0, 0)',
        visibility: 'visible'
      })),
      state('false', style({
        transform: 'translate(-100%, 0)',
        visibility: 'hidden'
      })),
      transition('true => false', animate('200ms ease-in-out')),
      transition('false => true', animate('200ms ease-in-out'))
    ]),
  ]
})
export class UiMenuComponent implements OnInit {
  public isVisible = true;
  public screenSize: ScreenSizeData = new ScreenSizeData();
  
  constructor(
    private uiService: UiService,
    private menuService: MenuService
  ) {
    this.uiService.screen.subscribe((screen: ScreenSizeData) => {
      this.screenSize = screen;

      if (screen.width < 961) {
        this.menuService.set(false);
      } else {
        this.menuService.set(true);
      }
    });

    this.menuService.status.subscribe((status: boolean) => {
      // Min width 961 always is visible
      this.isVisible = (this.screenSize.width >= 961) ? true : status;
    });
  }

  ngOnInit(): void { }

  onClose(): void {
    this.menuService.set(false);
  }
}

<ui-card>
    <div class="ui-content-el">
        <div class="ui-data-el">
            <span class="ui-name-el">{{integration.name}}</span>
            <span class="ui-info-el">{{integration.origin.name}} ({{integration.origin.typeName}}) > {{integration.destination.name}} ({{integration.destination.typeName}})</span>
        </div>
    </div>

    <div class="ui-actions-el">
        <ui-button color="transparent" (click)="preview()">
            <ui-icon name="input" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>
    </div>
</ui-card>
<div class="ui-auth-text">
    <p>Registrate para continuar</p>
</div>
            
<div class="ui-auth-form">
    <form [formGroup]="formData" class="ui-form">
        <div class="ui-form-row-double">
            <ui-field class="ui-field" label="Nombre" [validate]="formValidates.firstname">
                <input class="ui-input" type="firstname" formControlName="firstname">
            </ui-field> 

            <ui-field class="ui-field" label="Apellido" [validate]="formValidates.lastname">
                <input class="ui-input" type="lastname" formControlName="lastname">
            </ui-field> 
        </div>

        <ui-field class="ui-field" label="Correo electrónico" [validate]="formValidates.email">
            <input class="ui-input" type="email" formControlName="email">
        </ui-field>

        <div class="ui-form-row-double">
            <ui-field class="ui-field" label="Nueva contraseña">
                <input class="ui-input" type="password" formControlName="passwordNew">
            </ui-field>
                    
            <ui-field class="ui-field" label="Repetir contraseña" [validate]="formValidates.passwordRepeat">
                <input class="ui-input" type="password" formControlName="passwordRepeat">
            </ui-field>
        </div>

        <ui-field class="ui-field ui-field-workspace" label="Nombre de la cuenta" [validate]="formValidates.workspace">
            <input class="ui-input" type="workspace" formControlName="workspace">
        </ui-field> 
    
        <ui-note icon="priority">
            El nombre de la cuenta se utilizará para identificarla ya que podras tener múltiples cuentas asociadas a tu usuario.
        </ui-note>
    </form>
                    
    <div class="ui-auth-form-button">
        <ui-button color="primary" (click)="register()" [disabled]="formLoading">
            <span *ngIf="!formLoading">Registrarme</span>
            <ui-spinner class="ui-spinner" color="white" diameter="20" *ngIf="formLoading"></ui-spinner>
        </ui-button>
    </div>
</div>
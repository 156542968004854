<ui-card>
    <ui-header>
        <div class="ui-status-el" [ngClass]="account.status.status" [matTooltip]="account.status.message" (click)="issues()">
            <ui-icon *ngIf="account.status.status === 'online'" name="check_circle" type="outlined"></ui-icon>
            <ui-icon *ngIf="account.status.status === 'partial'" name="report_problem" type="outlined"></ui-icon>
            <ui-icon *ngIf="account.status.status === 'offline'" name="error_outline" type="outlined"></ui-icon>
        </div>

        <div class="ui-data-el">
            <div class="ui-title-el">{{account.domain}}</div>
            <div class="ui-info-el">{{account.plan.name}}</div>
        </div>
    </ui-header>
    
    <ui-content>
        <div class="ui-stat-el">
            <div class="ui-data-el">
                <div class="ui-name-el">Uso de transferencia <ui-helper text="Se renueva mensualmente"></ui-helper></div>
                <div class="ui-percent-el">{{account.bandwidth.usagePercent}}%</div>
            </div>

            <div class="ui-progress-el" [ngClass]="{'full': account.bandwidth.usagePercent >= 100}">
                <ui-progress-bar mode="determinate" [value]="account.bandwidth.usagePercent"></ui-progress-bar>
            </div>
        </div>

        <div class="ui-stat-el">
            <div class="ui-data-el">
                <div class="ui-name-el">Uso de almacenamiento</div>
                <div class="ui-percent-el">{{account.storage.usagePercent}}%</div>
            </div>
    
            <div class="ui-progress-el" [ngClass]="{'full': account.storage.usagePercent >= 100}">
                <ui-progress-bar mode="determinate" [value]="account.storage.usagePercent"></ui-progress-bar>
            </div>
        </div>
    </ui-content>

    <ui-footer>
        Ver detalles
        <ui-icon name="chevron_right" type="outlined"></ui-icon>
    </ui-footer>
</ui-card>
<ng-container *ngIf="screen == 'login'">
    <app-login (screen)="screenChange($event)" [(user)]="user" (userChange)="userChanged($event)"></app-login>

    <ng-container *ngIf="whitRegister">
        <p style="text-align:center; margin-top: 40px;">o tambien podes</p>
        <ui-button color="secondary" (click)="screenChange('register')">Crear una cuenta</ui-button>
    </ng-container>
</ng-container>

<ng-container *ngIf="screen == 'register'">
    <app-register (screen)="screenChange($event)" [(user)]="user" (userChange)="userChanged($event)" [autologin]="true" [url]="url"></app-register>
    
    <p style="text-align:center; margin-top: 40px;">tambien podes</p>
    <ui-button color="secondary" (click)="screenChange('login')">Ingresar a tu cuenta</ui-button>
</ng-container>

<app-recover *ngIf="screen == 'recover'" (screen)="screenChange($event)" [url]="url"></app-recover>

<app-restore *ngIf="screen == 'restore'" (screen)="screenChange($event)"></app-restore>

<app-workspaces *ngIf="screen == 'workspaces'" (screen)="screenChange($event)" [(user)]="user" (userChange)="userChanged($event)" ></app-workspaces>
            
<app-activation *ngIf="screen == 'activation'" (screen)="screenChange($event)" [(user)]="user" (userChange)="userChanged($event)"></app-activation>

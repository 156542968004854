
// Models
import { ModelDefault } from "@app/library/models/commons";
import { HostbiAccount } from "./hostbi-account";

export class HostbiMonitoring extends ModelDefault {
    id !: number;
    workspace!: string;
    account!: HostbiAccount;
    name!: string;
    domain!: string;
    user!: string;
    url!: string;
    scheme!: string;
    firewall!: HostbiMonitoringReport;
    stats!: any;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override default(){
        (this as any).id ??= 0;
        (this as any).workspace ??= '';
        (this as any).account ??= new HostbiAccount();
        (this as any).name ??= '';
        (this as any).domain ??= '';
        (this as any).user ??= '';
        (this as any).url ??= '';
        (this as any).scheme ??= '';
        (this as any).firewall ??= new HostbiMonitoringReport();
        (this as any).stats ??= {};
    }

    protected override filter(key: string, value: any){
        if(key === 'firewall'){
            return new HostbiMonitoringReport(value);
        }
        
        return super.filter(key, value);
    }

    get hasAccount(){
        return false;
    }
}

export class HostbiMonitoringLiveTraffic extends ModelDefault {
    url!: any;
	referer!: any;
	timeAgo!: any;
	action!: any;
	actionDescription!: any;
	browser!: HostbiMonitoringTrafficBrowser;		
	loc!: HostbiMonitoringTrafficLocation;
    jsRun!: any
    blocked!: any
    statusCode!: any

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override filter(key: string, value: any){
        if(key === 'browser'){
            return new HostbiMonitoringTrafficBrowser(value);
        } else if(key === 'location'){
            return new HostbiMonitoringTrafficLocation(value);
        } else if(key === 'url'){
            const url = new URL(value);

            return url.pathname;
        }

        return super.filter(key, value);
    }

    protected override default(){
        (this as any).url ??= '';
        (this as any).referer ??= '';
        (this as any).timeAgo ??= '';
        (this as any).action ??= '';
        (this as any).actionDescription ??= '';
        (this as any).browser ??= new HostbiMonitoringTrafficBrowser();		
        (this as any).loc ??= new HostbiMonitoringTrafficLocation();
        (this as any).jsRun ??= '';
        (this as any).blocked ??= '';
        (this as any).statusCode ??= '';
    }

    get status(): string{
        if (['403', '503'].includes(this.statusCode)) {
            return 'error';
        } else if (this.statusCode === '404' || ['loginFailValidUsername', 'loginFailInvalidUsername'].includes(this.action)) {
            return 'alert';
        } else if (this.jsRun === '1') {
            return 'success';
        } else {
            return '';
        }
    }

    get statusMessage(): string{
        if (['loginFailValidUsername', 'loginFailInvalidUsername'].includes(this.action)) {
            return 'Inicio de sesión inválido';
        } else if (['403', '503'].includes(this.statusCode)) {
            return 'Bloqueado';
        } else if (this.statusCode === '404') {
            return 'No encontrado';
        } else if (this.statusCode === '302') {
            return 'Redireccionado';
        } else if (this.jsRun === '1') {
            return 'Humano';
        } else {
            return 'Bot';
        }
    }
}

export class HostbiMonitoringTrafficBrowser extends ModelDefault {
    browser!: any;
	version!: any;
	platform!: any;
	isMobile!: any;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override default(){
        (this as any).browser ??= '';
        (this as any).version ??= '';
        (this as any).platform ??= '';
        (this as any).isMobile ??= '';
    }
}

export class HostbiMonitoringTrafficLocation extends ModelDefault {
    IP!: any;
	city!: any;
	region!: any;
	countryName!: any;
	countryCode!: any;
	lat!: any;
	lon!: any;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override default(){
        (this as any).IP ??= '';
        (this as any).city ??= '';
        (this as any).region ??= '';
        (this as any).countryCode ??= '';
        (this as any).countryName ??= '';
        (this as any).lat ??= '';
        (this as any).lon ??= '';
    }
}

export class HostbiMonitoringReport extends ModelDefault {
    status!: boolean;
    userIp!: string;
    updates!: HostbiMonitoringUpdates;
    activity!: Array<HostbiMonitoringActivity>;
    filesModified!: Array<HostbiMonitoringFileModified>;
    loginsFailed!: Array<HostbiMonitoringLoginFailed>;
    countriesBlocked!: Array<HostbiMonitoringCountryBlocked>;
    ipsBlocked!: Array<HostbiMonitoringIpBlocked>;
    scanIssues!: Array<HostbiMonitoringScanIssue>;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override default(){
        (this as any).status ??= false;
        (this as any).userIp ??= '';
        (this as any).updates ??= new HostbiMonitoringUpdates();
        (this as any).activity ??= [];
        (this as any).filesModified ??= [];
        (this as any).loginsFailed ??= [];
        (this as any).countriesBlocked ??= [];
        (this as any).ipsBlocked ??= [];
        (this as any).scanIssues ??= [];
    }

    protected override filter(key: string, value: any){
        const classMap: Record<string, any> = {
            'activity': HostbiMonitoringActivity,
            'filesModified': HostbiMonitoringFileModified,
            'loginsFailed': HostbiMonitoringLoginFailed,
            'countriesBlocked': HostbiMonitoringCountryBlocked,
            'ipsBlocked': HostbiMonitoringIpBlocked,
            'scanIssues': HostbiMonitoringScanIssue
        };
        
        if(key === 'updates'){
            return new HostbiMonitoringUpdates(value);
        } else if(Object.keys(classMap).includes(key)){
            const valueNew: Array<any> = [];
            
            value.forEach((item: any) => {
                valueNew.push(new classMap[key](item));
            });

            return valueNew;
        }
        
        return super.filter(key, value);
    }

    get hasIssues(){
        return this.scanIssues.length > 0;
    }
    
    get scanIssuesCount(){
        return this.scanIssues.length;
    }
}

export class HostbiMonitoringUpdates extends ModelDefault {
    core!: string;
	plugins!: Array<any>;
	themes!: Array<any>;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override default(){
        (this as any).core ??= '';
        (this as any).plugins ??= [];
        (this as any).themes ??= [];
    }

    get isCoreOutdated(){
        return false;
    }
    
    get isPluginsOutdated(){
        return this.plugins.length > 0;
    }

    get isThemesOutdated(){
        return this.themes.length > 0;
    }
}

export class HostbiMonitoringActivity extends ModelDefault {
    actionData!: any;
    actionDescription!: any;
    attackLogTime!: any;
    ip!: any;
    longDescription!: any;
    ua!: any;
    url !: any;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override filter(key: string, value: any){
        if(key === 'url'){
            const url = new URL(value);

            return url.pathname;
        }

        return super.filter(key, value);
    }
}

export class HostbiMonitoringFileModified extends ModelDefault {
    file!: string;
    date!: any;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }
}

export class HostbiMonitoringLoginFailed extends ModelDefault {
    ip!: any;
    action!: any;
    ctime!: any;
    fail!: any;
    failCount!: any;
    hitID!: any;
    id!: any;
    isValidUser!: any;
    userID!: any;
    username!: any;
    ua!: any;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override default(){
        (this as any).ip ??= '';
        (this as any).action ??= '';
        (this as any).ctime ??= '';
        (this as any).fail ??= '';
        (this as any).failCount ??= '';
        (this as any).hitID ??= '';
        (this as any).id ??= '';
        (this as any).isValidUser ??= '';
        (this as any).userID ??= '';
        (this as any).username ??= '';
        (this as any).ua ??= '';
    }
}

export class HostbiMonitoringCountryBlocked extends ModelDefault {
    ip!: any;
    blockCount!: any;
    blockType!: any;
    countryCode!: any;
    countryName!: any;
    ipBlockCount!: any;
    totalBlockCount!: any;
    totalIPs!: any;
    unixday!: any;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override default(){
        (this as any).ip ??= '';
        (this as any).blockCount ??= '';
        (this as any).blockType ??= '';
        (this as any).countryCode ??= '';
        (this as any).countryName ??= '';
        (this as any).ipBlockCount ??= '';
        (this as any).totalBlockCount ??= '';
        (this as any).totalIPs ??= '';
        (this as any).unixday ??= '';
    }
}

export class HostbiMonitoringIpBlocked extends ModelDefault {
    ip!: any;
    blockCount!: any;
    blockType!: any;
    countryCode!: any;
    countryName!: any;
    unixday!: any;

    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override default(){
        (this as any).ip ??= '';
        (this as any).blockCount ??= '';
        (this as any).blockType ??= '';
        (this as any).countryCode ??= '';
        (this as any).countryName ??= '';
        (this as any).unixday ??= '';
    }
}

export class HostbiMonitoringScanIssue extends ModelDefault {
    id!: any
    time!: any
    lastUpdated!: any
    status!: any
    type!: any
    severity!: any
    ignoreP!: any
    ignoreC!: any
    shortMsg!: any
    longMsg!: any
    sortTag!: any
    timeAgo!: any
    displayTime!: any
    issueIDX!: any
    
    constructor(parameters?: Record<string, any>) {
        super(parameters);
    }

    protected override default(){
        (this as any).id ??= '';
        (this as any).time ??= '';
        (this as any).lastUpdated ??= '';
        (this as any).status ??= '';
        (this as any).type ??= '';
        (this as any).severity ??= '';
        (this as any).ignoreP ??= '';
        (this as any).ignoreC ??= '';
        (this as any).shortMsg ??= '';
        (this as any).longMsg ??= '';
        (this as any).sortTag ??= '';
        (this as any).timeAgo ??= '';
        (this as any).displayTime ??= '';
        (this as any).issueIDX ??= '';
    }
}

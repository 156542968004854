<ui-card>
    <div class="ui-el-image">
        <div *ngIf="workspaceUser.image === ''">{{workspaceUser.nameInitials}}</div>                
        <img *ngIf="workspaceUser.image !== ''" [src]="workspaceUser.image">
    </div>

    <div class="ui-el-data">
        <span class="ui-el-name">{{workspaceUser.name}}</span>           
        <span class="ui-el-details">{{workspaceUser.email}} · {{workspaceUser.roleName}}</span>
    </div>
</ui-card>

<div class="ui-account-add">
    <form [formGroup]="formData" class="ui-form" *ngIf="loadData"> 
        <h2>¡Creá una nueva cuenta!</h2>

        <ng-container *ngIf="step == 1">
            <p>¿Ya tenes el dominio para tu nueva cuenta?</p>

            <div class="ui-form-row ui-domain-el">
                <ui-field class="ui-field">
                    <select class="ui-input" formControlName="domainStatus" (change)="domainStatusChange()">
                        <option value=""></option>
                        <option value="exist">Ya tengo un dominio registrado</option>
                        <option value="register">Quiero registrar un nuevo dominio</option>
                    </select>
                </ui-field>

                <ui-field class="ui-domain-el" label="Dominio. Ej: dominio.com" [validate]="formValidates.domain" [loading]="domainSpinner" *ngIf="formData.value.domainStatus !== ''">
                    <input class="ui-input" type="text" formControlName="domain" (keyup)="domainVerify()">
                
                    <ui-icon name="check" type="outlined" slot="right" *ngIf="domainVerified"></ui-icon>
                </ui-field>
            
                <ui-card class="active" *ngIf="formData.value.domainStatus === 'register' && domainService.price > 0">
                    <div class="ui-plan-el" >                
                        <div class="ui-data-el">
                            <span class="ui-name-el">Registro de dominio</span>
                            <span class="ui-price-el">$&nbsp;{{domainService.price | number: '1.2-2'}}/anual</span>
                        </div>
                    </div>

                    <div class="ui-status-el">
                        <ui-icon name="check_box" type="outlined" slot="icon-only"></ui-icon>
                    </div>
                </ui-card>
            </div>
        </ng-container>

        <ng-container *ngIf="step == 2">
            <p><strong>Seleccioná un plan</strong></p>
        
            <div class="ui-form-row">
                <mat-radio-group formControlName="service">
                    <ng-container *ngFor="let service of hostingPlans">
                        <mat-radio-button [value]="service">
                            <ui-card>
                                <div class="ui-plan-el">                
                                    <div class="ui-data-el">
                                        <span class="ui-name-el">{{service.name}} </span>
                                        <span class="ui-price-el">$&nbsp;{{service.price | number: '1.2-2'}}/mes</span>
                                    </div>
                                </div>

                                <div class="ui-status-el">
                                    <ui-icon class="ui-checked-el" name="check_box" type="outlined" slot="icon-only"></ui-icon>
                                    <ui-icon class="ui-unchecked-el" name="check_box_outline_blank" type="outlined" slot="icon-only"></ui-icon>
                                </div>
                            </ui-card>
                        </mat-radio-button>
                    </ng-container>
                </mat-radio-group>
            </div>
        </ng-container>

        <div class="ui-form-actions">
            <ui-button color="secondary" [disabled]="domainSpinner" (click)="step = 1" *ngIf="step == 2">Volver</ui-button>    
            <ui-button color="primary" [disabled]="domainSpinner || !domainVerified" (click)="continue()">Continuar</ui-button>    
        </div> 
    </form>

    <ui-screen-loader *ngIf="!loadData"></ui-screen-loader>
</div>
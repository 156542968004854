import { Component, Input, OnInit } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import 'moment/locale/es';

// Models
import { BillingService } from 'src/app/modules/billing/models/services';

// Services
import { UiService } from 'src/app/library/services/ui.service';

@Component({
  selector: 'billing-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class BillingServiceComponent implements OnInit {
  @Input() service: BillingService = new BillingService();
  @Input() delete: (service: BillingService) => any = (): void => { };
  @Input() removable = false;
  
  constructor(
    private uiService: UiService
  ) { }

  ngOnInit(): void { }

  details(){
    this.uiService.dialog('Detalles del servicio', `
      <p>
        <strong>` + this.service.name + `</strong><br>
        ` + this.service.detail + `
      </p>
      <p>Se renueva ` + this.service.periodLegend + (this.service.period === 'annual' ? ' en ' + moment(this.service.createdAt).locale('es').format('MMMM')  : '') + `</p>
    `);
  }
}

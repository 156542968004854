import { Component, Input, OnInit } from '@angular/core';
import { UiAlertRef } from 'src/app/library/components/ui-alert';
import { HostbiAccountIssues } from '../../models/hostbi-account';

@Component({
  selector: 'app-hostbi-account-issues-status',
  templateUrl: './account-issues.component.html',
  styleUrls: ['./account-issues.component.scss']
})
export class HostbiAccountIssuesComponent implements OnInit {
  @Input() issues: Array<HostbiAccountIssues> = [];

  public issuesList: Array<HostbiAccountIssues> = [];

  constructor(
    private uiAlertRef: UiAlertRef,
  ) { }

  ngOnInit(): void {
    this.issuesList = this.issues.filter((obj, index, self) =>
      index === self.findIndex((item) => item.key === obj.key)
    );
  }
}

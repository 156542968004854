<ui-header>
    <ui-title>Mis servicios</ui-title>
</ui-header>

<ui-content>
    <div class="ui-services">
        <div class="ui-services-list" *ngIf="servicesData.length > 0">
            <billing-service [service]="service" [delete]="servicesDelete" *ngFor="let service of servicesData"></billing-service>

            <ui-spinner-layer *ngIf="servicesLoading"></ui-spinner-layer>
        </div>

        <div class="ui-services-pagination" *ngIf="servicesData.length > 0">
            <ui-pagination [fetch]="servicesFetch" [data]="servicesPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
        
        <ui-screen-loader *ngIf="servicesData.length == 0"></ui-screen-loader>
    </div>
</ui-content>


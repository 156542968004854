<div class="ui-auth-text">
    <h1>Generar nueva contraseña</h1>
    <p>Vas a generar una nueva contraseña de acceso para tu cuenta.</p>
</div>
        
<div class="ui-auth-form">
    <form [formGroup]="formData">
        <ui-field class="ui-field" label="Correo electrónico" [validate]="formValidates.email">
            <input class="ui-input" type="email" formControlName="email">
        </ui-field> 

        <ui-field class="ui-field" label="Nueva contraseña" [validate]="formValidates.passwordNew">
            <input class="ui-input" type="password" formControlName="passwordNew">
        </ui-field>

        <ui-field class="ui-field" label="Repetir contraseña" [validate]="formValidates.passwordRepeat">
            <input class="ui-input" type="password" formControlName="passwordRepeat">
        </ui-field>
    </form>

    <div class="ui-auth-form-actions">
        <a (click)="screenChange('login')">Ingresar</a>
    </div>
            
    <div class="ui-auth-form-button">
        <ui-button color="primary" (click)="restore()" [disabled]="formLoading">
            <span *ngIf="!formLoading">Modificar</span>
            <ui-spinner class="ui-spinner" color="white" diameter="20" *ngIf="formLoading"></ui-spinner>
        </ui-button>
    </div>
</div>
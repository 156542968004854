import { Router } from "@angular/router";
import { getSubdomain } from "src/app/library/utils/functions";
import { environment } from "src/environments/environment";

export const isMobile: boolean = (environment.device === 'android' || environment.device === 'ios');

export const appName: string = isMobile ? environment.appName : (environment.appName != '') ? environment.appName : getSubdomain();

export const apps: {[key:string]: { name: string, module: string }} = {
    'clousis': { name: 'Clousis', module: 'clousis' },
    'hostbi': { name: 'Hostbi', module: 'hostbi' },
    'jobed': { name: 'Jobed', module: 'jobed' },
};

export const appCurrent: { name: string, module: string } = apps[(appName != '') ? appName : 'clousis'];

export function deviceType(): string{    
    return (environment.device != 'web') ? 'mobile' : 'web';
}

export function platformUrl(path = ''): string{    
    const protocol = (environment.platformSsl) ? 'https://' : 'http://';
    
    return protocol + environment.platformDomain + '/' + path.replace(/^\//g, '');
}

export function navigateStrategy(path: string, router: Router): void{    
    if(environment.device === 'web' && environment.production){
        router.navigate([path]);

        /*console.error('%c \u2718 Redirecting to ' + path, 'font-weight:bold;');
        
        window.location.href = platformUrl(path);*/
    } else {
        router.navigate([path]);
    } 
}

<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">{{invoice.title}}</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card class="ui-form">
        <div class="ui-card-content">
            <div class="invoice-data">
                <dl>
                    <dt>Fecha de emisión:</dt>
                    <dd>{{invoice.date | date: 'dd/MM/yyyy'}}</dd>
                </dl>
            </div>
            
            <div class="invoice-details">
                <div class="invoice-details-head">
                    <div class="ui-detail-el">
                        <div class="ui-movement-el">Detalle</div>
                        <div class="ui-quantity-el">Cant</div>
                        <div class="ui-price-el">Valor</div>
                        <div class="ui-tax-el">IVA 21%</div>
                        <div class="ui-total-el">Total</div>
                    </div>
                </div>

                <div class="invoice-details-body">
                    <div class="ui-detail-el" *ngFor="let detail of invoice.details">
                        <div class="ui-movement-el">
                            {{detail.description}}
                            <span class="ui-movement-data-el">{{detail.quantity}} x $&nbsp;{{detail.price | number : '1.2-2'}}</span>
                        </div>
                        <div class="ui-quantity-el">{{detail.quantity}}</div>
                        <div class="ui-price-el">$&nbsp;{{detail.price | number : '1.2-2'}}</div>
                        <div class="ui-tax-el">$&nbsp;{{detail.taxAmount | number : '1.2-2'}}</div>
                        <div class="ui-total-el">$&nbsp;{{detail.amount | number : '1.2-2'}}</div>
                    </div>
                </div>

                <div class="invoice-details-foot">
                    <div class="ui-detail-el">
                        <div class="ui-text-el">Subtotal</div>
                        <div class="ui-total-el">$&nbsp;{{invoice.subtotal | number : '1.2-2'}}</div>
                    </div>
                    
                    <div class="ui-detail-el">
                        <div class="ui-text-el">IVA 21%</div>
                        <div class="ui-total-el">$&nbsp;{{invoice.taxAmount | number : '1.2-2'}}</div>
                    </div>

                    <div class="ui-detail-el">
                        <div class="ui-text-el">Total</div>
                        <div class="ui-total-el">$&nbsp;{{invoice.total | number : '1.2-2'}}</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" (click)="download(invoice)">Descargar</ui-button>    
            <ui-button color="secondary" (click)="close()">Cerrar</ui-button>    
        </div>
    </ui-card>
</ui-content>
import {
  ChangeDetectionStrategy, Component, forwardRef, ElementRef, EventEmitter, Input,
  OnChanges, OnInit, Output, ViewChild, Inject, Injector, ChangeDetectorRef, OnDestroy
} from '@angular/core';
import {
  ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgControl, NgModel, FormControlName,
  FormGroupDirective, FormControlDirective
} from '@angular/forms';
import { BehaviorSubject, Subject, tap, takeUntil } from 'rxjs';

@Component({
  selector: 'ui-switch',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ui-switch.component.html',
  styleUrls: ['./ui-switch.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiSwitchComponent),
    multi: true
  }],
})
export class UiSwitchComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  @ViewChild('content') content: ElementRef|undefined;
  @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();

  public contentValue = '';
  public contentMatch = '';
  public contentStatus: Array<any> = [];
  public control!: FormControl;

  protected readonly destroy = new Subject<void>();

  private statusValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(Injector) private injector: Injector,
    public elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  get hostElement(): HTMLElement {
    this.setContent();
    return this.elementRef.nativeElement;
  }
  get status(): boolean {
    return this.statusValue.value;
  }
  @Input()
  set status(value: boolean) {
    this.statusValue.next(value === true);
  }

  ngOnInit() {
    this.setControl();

    this.control.patchValue((typeof this.control.value == 'boolean') ? this.control.value : this.status);
  }

  ngOnChanges(changes: any) {
    this.setContent();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  statusChanged(): void {
    this.control.patchValue(this.status);
    this.statusChange.emit(this.status);
  }

  writeValue(value: any): void {
    this.status = (value === true) ? true : false;
    this.changeDetectorRef.markForCheck();

    this.onChange(value);
  }

  registerOnChange(fn: (value: any | null) => any): void {
    this.onChange = fn;
  }

  onChange = (value: any | null): any | null => value;

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  onTouch = (): void => {};

  setDisabledState(){ }

  private setContent(): void{
    if(typeof this.content !== 'undefined'){
      if(this.contentValue === ''){
        this.contentValue = this.content.nativeElement.innerHTML;

        const matches = /\[(.*?)\]/.exec(this.contentValue);

        if(matches !== null){
          this.contentMatch = matches[0];
          this.contentStatus = matches[1].split('|');
        }
      }

      if(this.contentMatch !== ''){
        let content = this.contentValue;

        content = content.replace(this.contentMatch, this.contentStatus[(this.status) ? 1 : 0]);

        this.content.nativeElement.innerHTML = content;
      }
    }
  }

  private setControl(): void {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case NgModel: {
          const { control, update } = formControl as NgModel;

          this.control = control;

          this.control.valueChanges.pipe(
            tap((value) => update.emit(value)),
            takeUntil(this.destroy),
          ).subscribe();
          break;
        }

        case FormControlName: {
          this.control = this.injector.get(FormGroupDirective).getControl(formControl as FormControlName);
          break;
        }

        default: {
          this.control = (formControl as FormControlDirective).form as FormControl;
          break;
        }
      }
    } catch (error) {
      this.control = new FormControl();
    }
  }
}

import { Component, Input, OnInit, Type } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { UiModalbox } from 'src/app/library/components/ui-modalbox';
import { formValidateInit } from 'src/app/library/utils/form-validators';
import { HostbiAccount } from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiDomainsService } from 'src/app/modules/hostbi/services/hostbi-domains.service';
import { isDomain } from 'src/app/library/utils/functions';

@Component({
  selector: 'app-hostbi-account-domain-add',
  templateUrl: './account-domain-add.component.html',
  styleUrls: ['./account-domain-add.component.scss']
})
export class HostbiAccountDomainAddComponent implements OnInit {
  @Input() account = new HostbiAccount();
  
  public domainSpinner = false;
  private domainTimer: any;
  private domainValid = true;

  public formData: FormGroup;
  public formValidates: any;
  public formInputsErrors: object = {
    domain: {
      required: 'Ingresá el dominio para la cuenta',
      invalid:  'El dominio es inválido',
      registered:  'Este dominio no puede ser utilizado',
      timeout:  'No podemos validar el dominio, ingresalo nuevamente'
    },
    pathValue: {},
    pathPublic: {}    
  };

  constructor(
    private uiService: UiService,
    private modalbox: UiModalbox,
    private formBuilder: FormBuilder,
    private hostbiDomainsService: HostbiDomainsService
  ) {
    this.formData = this.formBuilder.group({
      domain: new FormControl('', [Validators.required]),
      pathPublic: new FormControl(true, [Validators.required]),
      pathValue: new FormControl('', []),
    });

    this.formValidates = formValidateInit(this.formData);
  }

  ngOnInit(): void { }

  close(): void {
    this.modalbox.close();
  }

  add(): void{
    if (this.formData.valid) {
      this.uiService.loader(true);

      this.hostbiDomainsService.whitWorkspace().create(this.account.id, { 
        domain: this.formData.value.domain,
        pathPublic: this.formData.value.pathPublic,
        pathValue: this.formData.value.pathValue
      }).then((success: HttpResponseData) => {
        this.modalbox.close();
        this.uiService.loader(false);
        this.uiService.dialog('Dominio agregado', 'El dominio se agrego a tu cuenta.', 'success');

        this.account.domainRecords.push(success.result);
      }, () => {
        this.uiService.loader(false);
        this.uiService.dialog('Agregar un dominio', 'En este momento no es posible agregar un dominio. Estamos trabajando para solucionar este inconveniente.');
      });
    } else {
      this.formData.markAllAsTouched();
      this.uiService.snackbar('Algunos datos no están completos');
    }
  }

  pathPublicChange(event: boolean){
    if(event === false) {
      this.formData.controls['pathValue'].setValidators(Validators.required);
    } else {
      this.formData.controls['pathValue'].clearValidators();
    }

    this.formData.controls['pathValue'].updateValueAndValidity();
  }

  domainVerify(): void {
    clearTimeout(this.domainTimer);

    this.domainTimer = setTimeout(() => {
      if (isDomain(this.formData.value.domain)) {
        this.domainSpinner = true;
        this.formData.get('domain')?.setErrors(null);

        this.hostbiDomainsService.whitWorkspace().verify(this.account.id, this.formData.value.domain).then((success: HttpResponseData) => {
          this.domainSpinner = false;

          if (success.result) {
            this.formData.get('domain')?.setErrors({registered: true});
          } else {
            this.domainValid = true;
          }
        }).catch(() => {
          this.domainSpinner = false;
          this.formData.get('domain')?.setErrors({timeout: true});
        });
      }
    }, 1000);
  }
}

import { Component, Injector, OnInit, Type } from '@angular/core';

// Serivices
import { UiAlertClickEvent, UiAlertRef } from 'src/app/library/components/ui-alert/ui-alert.ref';
import { UiAlertButton } from 'src/app/library/components/ui-alert/ui-alert.service';

@Component({
  selector: 'ui-alert',
  templateUrl: './ui-alert.component.html',
  styleUrls: ['./ui-alert.component.scss']
})
export class UiAlertComponent implements OnInit {
  public width = 300;
  public title: string;
  public message: string;
  public buttons: Array<UiAlertButton> = [];
  public type: ''|'info'|'alert'|'error'|'success' = '';
  public content: Type<any>|null = null;
  public data: any;
  public injectData: Injector;

  constructor(
    private injector: Injector,
    private uiAlertRef: UiAlertRef,
  ) {
    this.width = (this.uiAlertRef.config.options.width >= 350) ? this.uiAlertRef.config.options.width : 300 ;
    this.title = this.uiAlertRef.config.options.title;
    this.message = this.uiAlertRef.config.options.message;
    this.type = this.uiAlertRef.config.options.type;
    this.content = this.uiAlertRef.config.options.content;
    this.data = this.uiAlertRef.config.data;

    this.injectData = Injector.create({
      parent: this.injector,
      providers: [
          {
            provide: Type,
            useValue: this.uiAlertRef.data.data
          }
      ]
    });

    this.uiAlertRef.config.options.buttons.forEach((button: UiAlertButton) => {
      button.color = (button.role == 'accept') ? ((button.color !== '') ? button.color : 'primary') : button.color;

      this.buttons.push(button);
    });
  }

  ngOnInit(): void { }

  close(): void {
    this.uiAlertRef.close();
  }

  click(button: UiAlertButton){
    if(typeof button.click !== 'undefined'){
      button.click(this.uiAlertRef);  
    }            
    
    if(['cancel', 'accept'].includes(String(button.role))){
      this.uiAlertRef.close();
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { WorkspacesService } from 'src/app/modules/workspaces/services/workspaces.service';
import { Workspace } from '../../models/workspace';

@Component({
  selector: 'workspace-record',
  templateUrl: './workspace-record.component.html',
  styleUrls: ['./workspace-record.component.scss']
})
export class WorkspaceRecordComponent implements OnInit {
  @Input() workspace = new Workspace();
  @Input() details: (wokspace: Workspace) => any = () => {};

  constructor(
    private uiService: UiService,
    private workspacesService: WorkspacesService
  ) { }

  ngOnInit(): void { }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { formValidate, formValidateInit, validateEmail } from 'src/app/library/utils/form-validators';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {
  @Input() url = '';
  @Output() screen: EventEmitter<string> = new EventEmitter();
  
  public formData: UntypedFormGroup;
  public formLoading = false;
  public formValidates: any;
  public formInputsErrors: object = {
    email: {
      invalid:  'El email es inválido.',
    }
  };

  constructor(
    private uiService: UiService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
  ) {
    this.formData = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required, validateEmail()]),
    });

    this. formValidates = formValidateInit(this.formData);
  }

  ngOnInit() {    
    this.formData.get('email')?.valueChanges.subscribe(event => {
      this.formData.get('email')?.setValue(event.toLowerCase() ?? this.formData.value.email, {emitEvent: false});
    });
  }

  screenChange(screen: string){
    this.screen.emit(screen);
  }

  recover(): void {
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    

    if (this.formData.valid) {
      this.formLoading = true;

      this.userService.passwordRecover(this.formData.get('email')?.value, this.url + '/restore?token=').then((success: HttpResponseData) => {   
        this.formData.reset();
        this.formLoading = false;
        this.uiService.snackbar(success.message);

        this.screen.emit('login');
      }).catch((error: HttpResponseData) => {
        this.formLoading = false;
        this.uiService.snackbar(error.message);
      });
    }    
  }
}

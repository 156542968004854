import { Directive, HostListener } from '@angular/core';

// Services
import { MenuService } from '../components/ui-menu/ui-menu.service';

@Directive({
  selector: '[uiMenuToggle]'
})
export class UiMenuToggleDirective {
  @HostListener('click', ['$event']) onClick($event: any){
    this.menuService.set(!this.menuService.get());
  }
  
  constructor(
    private menuService: MenuService
  ) { }
}
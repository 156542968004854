<ui-content>
    <ng-container *ngIf="loadData">
        <div class="monitoring-header">
            <div class="monitoring-header-container">
                <div class="ui-stats-el">
                    <ui-title>{{monitoring.name}} | {{monitoring.domain}}</ui-title>
                    
                    <div class="ui-stats">
                        <div class="ui-stat-el">
                            <div class="ui-status-el" [ngClass]="{'alert': monitoring.firewall.updates.isCoreOutdated}">
                                <ui-icon name="check_circle" type="outlined" *ngIf="!monitoring.firewall.updates.isCoreOutdated"></ui-icon>
                                <ui-icon name="warning" type="outlined" *ngIf="monitoring.firewall.updates.isCoreOutdated"></ui-icon>
                            </div>
                            <div class="ui-data-el">Versión de Wordpress ({{monitoring.firewall.updates.core}})</div>
                        </div>

                        <div class="ui-stat-el">
                            <div class="ui-status-el" [ngClass]="{'alert': monitoring.firewall.hasIssues}">
                                <ui-icon name="check_circle" type="outlined" *ngIf="!monitoring.firewall.hasIssues"></ui-icon>
                                <span *ngIf="monitoring.firewall.hasIssues">{{monitoring.firewall.scanIssuesCount}}</span>
                            </div>                
                            <div class="ui-data-el">
                                {{(monitoring.firewall.hasIssues ? 'Problemas recientes' : 'Sin problemas recientes')}}
                            </div>
                        </div>

                        <div class="ui-stat-el">
                            <div class="ui-status-el" [ngClass]="{'alert': monitoring.firewall.updates.isPluginsOutdated}">
                                <ui-icon name="check_circle" type="outlined" *ngIf="!monitoring.firewall.updates.isPluginsOutdated"></ui-icon>
                                <span *ngIf="monitoring.firewall.updates.isPluginsOutdated">{{monitoring.firewall.updates.plugins.length}}</span>
                            </div>                
                            <div class="ui-data-el">
                                {{(monitoring.firewall.updates.isPluginsOutdated ? 'Plugins desactualizados' : 'Sin plugins para actualizar')}}
                            </div>
                        </div>

                        <div class="ui-stat-el">
                            <div class="ui-status-el" [ngClass]="{'alert': monitoring.firewall.updates.isThemesOutdated}">
                                <ui-icon name="check_circle" type="outlined" *ngIf="!monitoring.firewall.updates.isThemesOutdated"></ui-icon>
                                <span *ngIf="monitoring.firewall.updates.isThemesOutdated">{{monitoring.firewall.updates.themes.length}}</span>
                            </div>                
                            <div class="ui-data-el">
                                {{(monitoring.firewall.updates.isThemesOutdated ? 'Plantillas desactualizadas' : 'Sin plantillas para actualizar')}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ui-chart-el">
                    <canvas id="myChart" baseChart [data]="chartData" [type]="chartType" [options]="chartOptions"></canvas>
                </div>
            </div>
        </div>

        <div class="monitoring-content">
            <div class="monitoring-stats">
                <ui-card class="ui-logins">
                    <ui-card-header>
                        <ui-title>Ingresos erroneos</ui-title>
                    </ui-card-header>
                    
                    <div class="ui-records" *ngIf="monitoring.firewall.loginsFailed.length > 0">
                        <div class="ui-records-head ui-record">
                            <div class="ui-user-el">Usuario</div>
                            <div class="ui-quantity-el">Bloqueos</div>
                        </div>

                        <div class="ui-records-list">
                            <div class="ui-record" *ngFor="let item of monitoring.firewall.loginsFailed">
                                <div class="ui-user-el">{{item.username}}</div>
                                <div class="ui-quantity-el">{{item.failCount}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="ui-records-no-data" *ngIf="monitoring.firewall.loginsFailed.length <= 0">
                        No hubo accesos erroneos este mes
                    </div>
                </ui-card>
                
                <ui-card class="ui-countries">
                    <ui-card-header>
                        <ui-title>Top de países bloqueados</ui-title>
                    </ui-card-header>

                    <div class="ui-records" *ngIf="monitoring.firewall.countriesBlocked.length > 0">
                        <div class="ui-records-head ui-record">                        
                            <div class="ui-country-el">País</div>
                            <div class="ui-ip-el">Cant. IPs</div>
                            <div class="ui-quantity-el">Bloqueos</div>
                        </div>

                        <div class="ui-records-list">
                            <div class="ui-record" *ngFor="let item of monitoring.firewall.countriesBlocked">
                                <div class="ui-country-el"><ui-flag [code]="item.countryCode"></ui-flag>{{item.countryName}}</div>
                                <div class="ui-ip-el">{{item.ipBlockCount}}</div>
                                <div class="ui-quantity-el">{{item.totalBlockCount}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="ui-records-no-data" *ngIf="monitoring.firewall.countriesBlocked.length <= 0">
                        No se bloquearon direcciones IP este mes
                    </div>
                </ui-card>

                <ui-card class="ui-ip">
                    <ui-card-header>
                        <ui-title>Top de IPs bloqueadas</ui-title>
                    </ui-card-header>
                    
                    <div class="ui-records" *ngIf="monitoring.firewall.ipsBlocked.length > 0">
                        <div class="ui-records-head ui-record">
                            <div class="ui-ip-el">IP</div>
                            <div class="ui-country-el">País</div>
                            <div class="ui-quantity-el">Bloqueos</div>
                        </div>

                        <div class="ui-records-list">
                            <div class="ui-record" *ngFor="let item of monitoring.firewall.ipsBlocked">
                                <div class="ui-ip-el">{{item.ip}}</div>
                                <div class="ui-country-el"><ui-flag [code]="item.countryCode"></ui-flag>{{item.countryName}}</div>
                                <div class="ui-quantity-el">{{item.blockCount}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="ui-records-no-data" *ngIf="monitoring.firewall.ipsBlocked.length <= 0">
                        No se bloquearon direcciones IP este mes
                    </div>
                </ui-card>
            </div>

            <div class="monitoring-reports">
                <ui-card class="ui-live-traffic">
                    <ui-card-header>
                        <ui-title>Tráfico en vivo</ui-title>

                        <ui-button color="secondary" (click)="livetrafficFetch()">Actualizar</ui-button>
                    </ui-card-header>
                        
                    <div class="ui-records" *ngIf="livetrafficData.length > 0">
                        <div class="ui-records-head ui-record">
                            <div class="ui-url-el">URL</div>
                            <div class="ui-location-el">Ubicación</div>
                            <div class="ui-browser-el">Navegador</div>
                            <div class="ui-date-el">Hace</div>
                        </div>

                        <div class="ui-records-list">
                            <div class="ui-record" *ngFor="let item of livetrafficData">
                                <div class="ui-url-el"><span [ngClass]="item.status" [matTooltip]="item.statusMessage" matTooltipPosition="after"></span> {{item.url}}</div>
                                <div class="ui-location-el"><ui-flag [code]="item.loc.countryCode"></ui-flag> {{item.loc.countryName}}, {{item.loc.city}} ({{item.loc.IP}})</div>
                                <div class="ui-browser-el">{{item.browser.browser}}</div>
                                <div class="ui-date-el">{{item.timeAgo}}</div>                            
                            </div>
                        
                            <ui-spinner-layer *ngIf="livetrafficLoading"></ui-spinner-layer>
                        </div>
                    </div>
        
                    <div class="ui-records-no-data" *ngIf="livetrafficData.length <= 0">
                        No hay tráfico en la web en este momento
                    </div>
                </ui-card>

                <ui-card class="ui-activity">
                    <ui-card-header>
                        <ui-title>Actividad reciente</ui-title>
                    </ui-card-header>
                        
                    <div class="ui-records" *ngIf="monitoring.firewall.activity.length > 0">
                        <div class="ui-records-head ui-record">
                            <div class="ui-ip-el">IP</div>
                            <div class="ui-url-el">URL</div>
                            <div class="ui-date-el">Fecha</div>
                        </div>
        
                        <div class="ui-records-list">
                            <div class="ui-record" *ngFor="let item of monitoring.firewall.activity">
                                <div class="ui-ip-el">{{item.ip}}</div>
                                <div class="ui-url-el">{{item.url}}</div>
                                <div class="ui-date-el">{{item.attackLogTime}}</div>
                            </div>
                        
                            <!--<ui-spinner-layer></ui-spinner-layer>-->
                        </div>
                        
                        <div class="ui-records-pagination">
                            <!--<ui-pagination [fetch]="invoicesFetchBind" [data]="invoicesPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>-->
                        </div>
                    </div>

                    <div class="ui-records-no-data" *ngIf="monitoring.firewall.activity.length <= 0">
                        No se bloquearon direcciones IP este mes
                    </div>
                </ui-card>
                
                <ui-card class="ui-issues">
                    <ui-card-header>
                        <ui-title>Problemas detectados</ui-title>
                    </ui-card-header>
                        
                    <div class="ui-records" *ngIf="monitoring.firewall.scanIssues.length > 0">
                        <div class="ui-records-head ui-record">
                            <div class="ui-type-el">Tipo</div>
                            <div class="ui-description-el">Descripción</div>
                            <div class="ui-date-el">Fecha</div>
                        </div>

                        <div class="ui-records-list">
                            <div class="ui-record" *ngFor="let item of monitoring.firewall.scanIssues">
                                <div class="ui-type-el">{{item.type}}</div>
                                <div class="ui-description-el">{{item.shortMsg}}</div>
                                <div class="ui-date-el">{{item.timeAgo}}</div>
                            </div>

                            <!--<ui-spinner-layer></ui-spinner-layer>-->
                        </div>
                        
                        <div class="ui-records-pagination">
                            <!--<ui-pagination [fetch]="invoicesFetchBind" [data]="invoicesPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>-->
                        </div>
                    </div>
        
                    <div class="ui-records-no-data" *ngIf="monitoring.firewall.scanIssues.length <= 0">
                        No se encontraron problemas en los escaneos recientes
                    </div>
                </ui-card>

                <ui-card class="ui-files">
                    <ui-card-header>
                        <ui-title>Archivos modificados</ui-title>
                    </ui-card-header>
                        
                    <div class="ui-records" *ngIf="monitoring.firewall.filesModified.length > 0">
                        <div class="ui-records-head ui-record">
                            <div class="ui-file-el">Archivo</div>
                            <div class="ui-date-el">Fecha</div>
                        </div>
        
                        <div class="ui-records-list">
                            <div class="ui-record" *ngFor="let item of monitoring.firewall.filesModified">
                                <div class="ui-file-el">{{item.file}}</div>
                                <div class="ui-date-el">{{item.date}}</div>
                            </div>
                        
                            <!--<ui-spinner-layer></ui-spinner-layer>-->
                        </div>
                        
                        <div class="ui-records-pagination">
                            <!--<ui-pagination [fetch]="invoicesFetchBind" [data]="invoicesPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>-->
                        </div>
                    </div>
        
                    <div class="ui-records-no-data" *ngIf="monitoring.firewall.filesModified.length <= 0">
                        No se encontraron archivos modificadoss recientemente.
                    </div>
                </ui-card>         
            </div>
        </div>
    </ng-container>

    <ui-screen-loader *ngIf="!loadData"></ui-screen-loader>
</ui-content>
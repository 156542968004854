import { Component, OnInit } from '@angular/core';
import { UiAlertRef } from 'src/app/library/components/ui-alert';

@Component({
  selector: 'app-workspaces-payment-process',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.scss']
})
export class WorkspacesPaymentProcessComponent implements OnInit {
  public transaction = '';

  constructor(
    private uiAlertRef: UiAlertRef,
  ) { }

  process(event: any){
    this.uiAlertRef.data['transaction'] = this.transaction;
  }

  ngOnInit(): void { }
}

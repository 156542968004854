<div class="ui-auth-text">
    <h1>¡Hola {{user.firstname}}!</h1>
    <p *ngIf="user.workspaces.length > 0">Seleccioná un espacio de trabajo <ui-helper text="Un espacio de trabajo es una cuenta de cliente asociada a tu usuario."></ui-helper></p>
</div>
            
<div class="ui-auth-form">
    <ul *ngIf="user.workspaces.length > 0">
        <ng-container *ngFor="let item of user.workspaces">
            <li class="el-workspace" [ngClass]="item.status" (click)="set(item.id)" *ngIf="item.status === 'acepted'">
                <div class="el-workspace-data">
                    <span class="el-name">{{item.name}}</span>
                </div>

                <div class="el-workspace-icon">
                    <ui-icon *ngIf="!formLoading[item.id]" name="navigate_next"></ui-icon>
                    <ui-spinner *ngIf="formLoading[item.id]" class="ui-spinner" color="primary" diameter="20"></ui-spinner>
                </div>
            </li>
            
            <li class="el-workspace" [ngClass]="item.status" *ngIf="item.status === 'pending'">
                <div class="el-workspace-data">
                    <span class="el-name">{{item.name}}</span>
                    <span class="el-status">Invitación pendiente</span>
                </div>

                <div class="el-workspace-icon">
                    <ui-icon *ngIf="!formLoading[item.id]" name="close" (click)="invitationRejected(item)" matTooltip="Rechazar invitación"></ui-icon>
                    <ui-icon *ngIf="!formLoading[item.id]" name="done" (click)="invitationAcepted(item)" matTooltip="Aceptar invitación"></ui-icon>
                    <ui-spinner *ngIf="formLoading[item.id]" class="ui-spinner" color="primary" diameter="20"></ui-spinner>
                </div>
            </li>
        </ng-container>
    </ul>

    <div class="el-workspace-empty" *ngIf="user.workspaces.length <= 0">
        <ui-icon name="workspaces"></ui-icon>
        <p>No tenes espacios de trabajo disponibles.</p>
    </div>

    <div class="ui-auth-form-button">
        <ui-button color="primary" (click)="logout()" [disabled]="formLoading['first']">
            <span *ngIf="!formLoading['first']">Cerrar la sesión</span>
            <ui-spinner class="ui-spinner" color="white" diameter="20" *ngIf="formLoading['first']"></ui-spinner>
        </ui-button>
    </div>
</div>
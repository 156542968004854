import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

// Library 
import { UiAlertRef } from 'src/app/library/components/ui-alert';
import { UiModalboxCloseEvent } from '@app/library/components/ui-modalbox';

// Utils
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';

// Models
import { Module } from 'src/app/platform/models/module';
import { Workspace } from 'src/app/modules/workspaces/models/workspace';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { ModuleService } from 'src/app/platform/services/module.service';
import { BillingPaymentsService } from 'src/app/modules/billing/services/payments.service';
import { WorkspacesService } from 'src/app/modules/workspaces/services/workspaces.service';

// Components
import { WorkspaceFormComponent } from 'src/app/modules/workspaces/components/workspace-form/workspace-form.component';
import { WorkspacesPaymentDetailsComponent } from 'src/app/modules/workspaces/components/payment-details/payment-details.component';
import { WorkspaceUserAssignFormComponent } from '../../components/workspace-user-assign-form/workspace-user-assign-form.component';


@Component({
  selector: 'app-workspaces',
  templateUrl: './workspaces.component.html',
  styleUrls: ['./workspaces.component.scss']
})
export class WorkspacesComponent implements OnInit {
  public moduleData: Module | undefined;

  public workspacesSearchQuery = '';
  public workspacesSearchWorkspace = 0;
  public workspacesSearchServer = 0;
  public workspacesData: Array<Workspace> = [];
  public workspacesPagination: PaginationData = new PaginationData(0);
  public workspacesFetch = this.workspacesList.bind(this);
  public workspacePreviewBind = this.workspacePreview.bind(this);
  public workspacesLoading = false;

  public errors = {
    init: {
      title: 'Espacios de trabajo',
      message: 'Buscá un espacio de trabajo por su nombre.',
      icon: '',
    },
    load: {
      title: 'Cargando lista de espacios de trabajo',
      message: 'Estamos buscando la información los espacios de trabajo.',
      loading: true,
    },
    empty: {
      title: 'Espacio de trabajo inexistente',
      message: 'No existe un espacios de trabajo con esa búsqueda.',
    },
    error: {
      title: 'Espacios de trabajo',
      message: 'No se pudo cargar el listado de espacios de trabajo. Intentar nuevamente.',
    }
  };

  constructor(
    private router: Router,
    private uiService: UiService,
    private modulesService: ModuleService,
    private workspacesService: WorkspacesService,
    private billingPaymentsService: BillingPaymentsService,
  ) {
    this.modulesService.current.subscribe((module: Module) => {
      this.moduleData = module
    });

    this.uiService.screenLoader(this.errors.init);
  }

  ngOnInit(): void {
    
  }

  workspacesSearch(event?: any){
    if(typeof event === 'undefined' || event.code == 'Enter'){
      this.workspacesList();
    }
  }

  workspacesList(page = 1): void{
    this.workspacesLoading = true;
    this.uiService.screenLoader(this.errors.load);

    const filters: any = {
      search: this.workspacesSearchQuery,
    };
    
    this.workspacesService.getAll(page, this.workspacesPagination.pageSize, filters).then((success: HttpResponseData) => {
      this.workspacesLoading = false;
      
      if (success.result.length > 0) {
        this.workspacesData = success.result;
        this.workspacesPagination.totalUpdate(success.result.length);
        this.workspacesPagination.pageUpdate(1);

        this.uiService.screenLoader(this.errors.init);
      } else {
        this.uiService.screenLoader(this.errors.empty);
      }
    }).catch(() => {
      this.workspacesLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }

  workspacePreview(workspace: Workspace): void {
    this.router.navigate(['/workspaces/accounts/' + workspace.id]);
  }
  
  workspaceCreate(): void {
    const modal = this.uiService.modalbox({
      component: WorkspaceFormComponent,
      backdrop: true,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    modal.afterClosed.subscribe((response: UiModalboxCloseEvent<any>) => {
      if(response.data.id && response.data.id > 0){
        this.router.navigate(['/workspaces/accounts/' + response.data.id]);
      }
    })
  }
  
  workspaceUserAssignCreate(): void {
    const modal = this.uiService.modalbox({
      component: WorkspaceUserAssignFormComponent,
      backdrop: true,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    modal.afterClosed.subscribe((response: UiModalboxCloseEvent<any>) => {
      if(response.data.id && response.data.id > 0){
        this.router.navigate(['/workspaces/accounts/' + response.data.id]);
      }
    })
  }

  paymentDetails(): Promise<string> {
    return new Promise((resolve, reject) => { 
      this.uiService.alert({
        title: 'Detalles de cobro',
        message: 'Ingresá el número de transacción del pago realizado para verificar los detalles.',
        content: WorkspacesPaymentDetailsComponent,
        buttons: [
            { text: 'Cancelar', role: 'cancel', click: () => { reject(''); } },
            { text: 'Continuar', color: 'primary', click: (response: UiAlertRef) => {
              if(
                typeof response.data.gateway !== 'undefined' && 
                typeof response.data.emitter !== 'undefined' && 
                typeof response.data.transaction !== 'undefined' && 
                response.data.gateway !== '' &&
                response.data.emitter !== '' &&
                response.data.transaction !== ''
              ){
                this.uiService.loader(true);

                this.billingPaymentsService.details({
                  gateway: response.data.gateway,
                  emitter: response.data.emitter,
                  transaction: response.data.transaction
                }).then((success: HttpResponseData) => {
                  this.uiService.loader(false);
                  this.uiService.dialog('Detalles de cobro', `<p>
                    <strong>Transacción:</strong> ` + success.result.transaction + `<br>
                    <strong>Descripción:</strong>  ` + success.result.description + `<br>
                    <strong>Monto:</strong>  ` + success.result.amount + `<br>
                    <strong>Pagador:</strong>  ` + success.result.payer + `<br>
                    <strong>Método de pago:</strong>  ` + success.result.method + `<br>
                    <strong>Estado:</strong>  ` + success.result.status + `<br>
                    <strong>Fecha de pago:</strong>  ` + success.result.date_approved + `<br>
                    <strong>Referencia interna:</strong>  ` + success.result.reference + `
                  </p>`);
                }).catch((error: HttpResponseData) => {
                  this.uiService.loader(false);
                  this.uiService.snackbar(error.message);
                });

              } else {
                this.uiService.snackbar('Seleccioná un cobrador e ingresá un número de transacción para continuar.');
              }              
            } }
          ]
      });
    });
  }

  paymentProcess(): Promise<string> {
    return new Promise((resolve, reject) => { 
      this.uiService.alert({
        title: 'Procesar un cobro',
        message: 'Ingresá el número de transacción del pago realizado.',
        content: WorkspacesPaymentDetailsComponent,
        buttons: [
            { text: 'Cancelar', role: 'cancel', click: () => { reject(''); } },
            { text: 'Continuar', color: 'primary', click: (response: UiAlertRef) => {
              if(
                typeof response.data.transaction !== 'undefined' && 
                response.data.transaction !== ''
              ){
                this.uiService.loader(true);
                
                this.billingPaymentsService.process({
                  gateway: response.data.gateway,
                  emitter: response.data.emitter,
                  transaction: response.data.transaction
                }).then((success: HttpResponseData) => {
                  const status: any = {
                    null: 'error',
                    pending: 'info',
                    confirmed: 'success',
                    cancelled: 'alert',
                    expired: 'alert',
                    refused: 'alert',
                    disputed: 'alert',
                    active: 'info',
                  };
                  
                  this.uiService.loader(false);
                  this.uiService.dialog('Procesar un cobro', success.message, status[success.result]);

                  if(success.result === 'confirmed'){
                    response.close();
                  }
                }).catch((error: HttpResponseData) => {
                  this.uiService.loader(false);
                  this.uiService.snackbar(error.message);
                });

              } else {
                this.uiService.snackbar('Ingresá un número de transacción para continuar.');
              }              
            } }
          ]
      });
    });
  }

}

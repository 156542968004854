import { Component, Input, OnInit } from '@angular/core';
import { UiAlertRef } from '@app/library/components/ui-alert';

// Models
import { HostbiDomainRegister } from '@modules/hostbi/models/hostbi-domain-register';
import { HostbiDomainRegisterService } from '../../services/hostbi-domain-register.service';
import { HostbiDomainRegisterStatusComponent } from '../domain-register-status/domain-register-status.component';
import { UiService } from '@app/library/services/ui.service';
import { HttpResponseData } from '@app/library/models/utils';

// Services

// Components

@Component({
  selector: 'hostbi-domain-register',
  templateUrl: './domain-register.component.html',
  styleUrls: ['./domain-register.component.scss']
})
export class HostbiDomainRegisterComponent implements OnInit {
  @Input() domain: HostbiDomainRegister = new HostbiDomainRegister();

  constructor(
    private uiService : UiService,
    private hostbiDomainRegisterService: HostbiDomainRegisterService
  ) {
    console.log(this.domain);
  }

  ngOnInit(): void { }

  status(){
    this.uiService.alert({
      title: 'Cambiar estado del dominio',
      content: HostbiDomainRegisterStatusComponent,
      buttons: [
          { text: 'Cancelar', role: 'cancel' },
          { text: 'Continuar', color: 'primary', click: (response: UiAlertRef) => {
            if(response.data.status){
              this.uiService.loader(true);
              
              this.hostbiDomainRegisterService.statusChange(this.domain.id, response.data.status).then((success: HttpResponseData) => {
                this.uiService.loader(false);
                this.uiService.dialog('Estado actualizado', success.message, 'success');

                this.domain.status = response.data.status;

                response.close();
              }).catch((error: HttpResponseData) => {
                this.uiService.loader(false);
                this.uiService.snackbar(error.message);
              });

            } else {
              this.uiService.snackbar('Seleccioná un estado para continuar.');
            }              
          } }
        ]
    });
  }

  register(){
    this.uiService.alert({
      title: 'Registrar dominio',
      message: 'Si continuas, se registrara el dominio y se generaran los costos correspondientes.',
      buttons: [
          { text: 'Cancelar', role: 'cancel' },
          { text: 'Continuar', color: 'primary', click: (response: UiAlertRef) => {
              this.uiService.loader(true);
              
              this.hostbiDomainRegisterService.register(this.domain.id).then((success: HttpResponseData) => {
                this.uiService.loader(false);
                this.uiService.dialog('Registro completado', success.message, 'success');

                this.domain.status = response.data.status;

                response.close();
              }).catch((error: HttpResponseData) => {
                this.uiService.loader(false);
                this.uiService.snackbar(error.message);
              });        
          } }
        ]
    });
  }
}

declare let fbq: (...args: any[]) => any;
declare let gtag: (...args: any[]) => any;

const TRACK_TYPES: Record<string, (...args: any[]) => any> = {
    order_register_checkout: () => {
        fbq('track', 'InitiateCheckout');
        gtag('event', 'initiate_checkout')
    },
    order_register_initiate: () => {
        fbq('track', 'AddToCart');
        gtag('event', 'add_to_cart');
    },
    order_register_finish: () => {
        fbq('track', 'Purchase', { value: 0.00, currency: 'USD' });
        //gtag('event', 'conversion', { send_to: 'AW-CONVERSION_ID/CONVERSION_LABEL',  value: 0.0, currency: 'USD', transaction_id: Date.now() });
        //gtag('event', 'purchase', { transaction_id: Date.now(), value: 0, currency: 'USD', items: [ { item_id: 'SUBSCRIBE', item_name: 'Subscribe', }]});
    },
    user_register: () => {
        fbq('track', 'CompleteRegistration');
        gtag('event', 'complete_registration');
    },
};

export function track(type: string, data: Record<string, any> = {}): any{    
    console.log('Tracking', type);

    if(Object.keys(TRACK_TYPES).includes(type)){
        return TRACK_TYPES[type](data);
    }

    return null;
}

/*
Google Ads Events
1. View Item: gtag('event', 'view_item')
   Se utiliza cuando un usuario ve un artículo o producto específico en tu sitio.
2. Add to Cart: gtag('event', 'add_to_cart')
   Se dispara cuando un usuario agrega un artículo al carrito de compras en tu sitio.
3. Remove from Cart: gtag('event', 'remove_from_cart')
   Se utiliza cuando un usuario elimina un artículo del carrito de compras en tu sitio.
4. Initiate Checkout: gtag('event', 'initiate_checkout')
   Se activa cuando un usuario inicia el proceso de pago en tu sitio.
5. Purchase: gtag('event', 'purchase')
   Se utiliza para realizar un seguimiento de las conversiones de compras completadas en tu sitio.
6. Generate Lead: gtag('event', 'generate_lead')
   Se dispara cuando un usuario realiza una acción que genera un lead, como completar un formulario de contacto.
7. Complete Registration: gtag('event', 'complete_registration')
   Se utiliza para hacer un seguimiento de las conversiones de registros completados, como crear una cuenta en tu sitio.
8. Contact: gtag('event', 'contact')
   Se utiliza para realizar un seguimiento de las interacciones de contacto, como hacer clic en un botón de "Contacto".
9. Customize Product: gtag('event', 'customize_product')
   Se dispara cuando un usuario personaliza un producto en tu sitio, como seleccionar opciones de color o tamaño.

Facebook Pixel Events
1. View Content: fbq('track', 'ViewContent')
   Se utiliza cuando un usuario ve el contenido de una página, como un producto o artículo.
2. Search: fbq('track', 'Search')
   Se activa cuando un usuario realiza una búsqueda en tu sitio web.
3. Add to Cart: fbq('track', 'AddToCart')
   Se dispara cuando un usuario agrega un producto al carrito de compras en tu sitio.
4. Add to Wishlist: fbq('track', 'AddToWishlist')
   Se utiliza cuando un usuario agrega un producto a su lista de deseos en tu sitio.
5. Initiate Checkout: fbq('track', 'InitiateCheckout')
   Se activa cuando un usuario inicia el proceso de pago en tu sitio web.
6. Add Payment Info: fbq('track', 'AddPaymentInfo')
   Se utiliza para realizar un seguimiento cuando un usuario agrega información de pago durante el proceso de compra.
7. Make Purchase: fbq('track', 'Purchase')
   Se dispara cuando un usuario completa una compra en tu sitio web.
8. Lead: fbq('track', 'Lead')
   Se utiliza para realizar un seguimiento de las conversiones de leads, como completar un formulario o registrarse en un sitio.
9. Complete Registration: fbq('track', 'CompleteRegistration')
   Se activa cuando un usuario completa el registro en tu sitio web.
10. Contact: fbq('track', 'Contact')
   Se dispara cuando un usuario realiza una acción de contacto, como enviar un mensaje o realizar una llamada.
*/
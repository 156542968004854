<ui-header>
    <ui-title>Listado de ordenes</ui-title>

    <div class="ui-orders-filters">
        <ui-field class="ui-field">
            <select class="ui-input" [(ngModel)]="ordersSearchStatus" (change)="ordersSearch($event)">
                <option *ngFor="let item of statusData" [value]="item">{{item}}</option>
            </select>
        </ui-field>
    </div>
</ui-header>

<ui-content>
    <div class="ui-orders" *ngIf="ordersData.length > 0">
        <div class="ui-orders-head">
            <div class="ui-head-order-el">Pedido</div>
            <div class="ui-head-ammount-el">Importe</div>
            <div class="ui-head-actions-el"></div>
        </div>
            
        <div class="ui-orders-list" *ngIf="ordersData.length > 0">
            <billing-order [order]="order" [manage]="true" *ngFor="let order of ordersData"></billing-order>

            <ui-spinner-layer *ngIf="ordersLoading"></ui-spinner-layer>
        </div>
        
        <div class="ui-orders-pagination" *ngIf="ordersData.length > 0">
            <ui-pagination [fetch]="ordersFetchBind" [data]="ordersPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
    </div>
        
    <ui-screen-loader *ngIf="ordersData.length == 0"></ui-screen-loader>
</ui-content>


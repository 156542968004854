ERRORRRRR

<p>
  <ui-button color="transparent">
    <ui-icon name="menu" type="outlined" slot="left"></ui-icon> Boton transparente
  </ui-button>
  <ui-button color="secondary">
    <ui-icon name="menu" type="outlined" slot="icon-only"></ui-icon>
  </ui-button>
  <ui-button color="primary">
    <ui-icon name="menu" type="outlined" slot="left"></ui-icon> Boton primario <ui-icon name="menu" type="outlined" slot="right"></ui-icon>
  </ui-button>
  <ui-button>
    <ui-icon name="menu" type="outlined" slot="right"></ui-icon> Boton
  </ui-button>
</p>
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// Utils
import { formValidate, formValidateInit } from 'src/app/library/utils/form-validators';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { User } from 'src/app/modules/user/models/user';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';

// Components
import { UserAccountPasswordComponent } from 'src/app/modules/user/components/account-password/account-password.component';
import { UiAlertRef } from 'src/app/library/components/ui-alert';

@Component({
  selector: 'app-user-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class UserAccountComponent implements OnInit {
  public userData: User = new User();

  public formData: FormGroup = new FormGroup({});
  public formValidates: any;
  public formInputsErrors: object = {
    firstname: {
      required: 'Ingresa tu nombre'
    },
    lastname: {
      required: 'Ingresa tu apellido'
    },
    email: {
      required: 'Ingresa tu dirección de correo'
    },
    phone: {
      required: 'Ingresa tu número de teléfono'
    },
    passwordNew: {
      notSame: 'Las contraseñas no son similares'
    },
    passwordRepeat: {
      notSame: 'Las contraseñas no son similares'
    },
  };

  constructor(
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private usersService: UserService,
  ) {
    this.usersService.userData.subscribe((value: User) => {
      this.userData = value;

      this.formData = this.formBuilder.group({
        firstname: new FormControl(this.userData.firstname, [Validators.required]),
        lastname: new FormControl(this.userData.lastname, [Validators.required]),
        email: new FormControl({value: this.userData.email, disabled: true}, [Validators.required]),
        phone: new FormControl(this.userData.phone, [Validators.required]),
        passwordNew: new FormControl('', [this.validatePassword()]),
        passwordRepeat: new FormControl('', [this.validatePassword()]),
      });

      this. formValidates = formValidateInit(this.formData);
    });
  }

  ngOnInit(): void { }

  onUpdate(): void {
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    
    
    if (this.formData.valid) {
      this.changeEmail().then(() => {
        this.passwordVerify().then((passwordCurrent: string) => {
          this.uiService.loader(true);

          this.usersService.update({
            firstname: this.formData.get('firstname')?.value,
            lastname: this.formData.get('lastname')?.value,
            email: this.formData.get('email')?.value,
            phone: this.formData.get('phone')?.value,
            password_new: this.formData.get('passwordNew')?.value,
            password_current: passwordCurrent
          }).then((result: HttpResponseData) => {
            this.uiService.loader(false);

            if (result.status) {
              this.uiService.dialog('Datos actualizados', 'Tus datos se actualizaron correctamente.', 'success');
            } else {
              this.uiService.snackbar(result.message, 'alert');
            }
          }, (error: HttpResponseData) => {
            this.uiService.loader(false);
            this.uiService.snackbar(error.message, 'error');
            //this.uiService.snackbar('No es posible actualizar tus datos en este momento. Estamos trabajando para solucionar el inconveniente.');
          });
        }).catch(() => { });
      }).catch(() => { });
    }
  }

  changeEmail(): Promise<void> {
    return new Promise((resolve, reject) => { 
      if(this.userData.email !== this.formData.get('email')?.value){
        this.uiService.alert({
          title: 'Modificar email',
          message: 'Vas a modificar tu direccion de email, te enviaremos un correo a ' + this.userData.email + ' para que confirmes el cambio.',
          buttons: [
            { role: 'cancel', text: 'Cancelar', click: () => { reject(); } },
            { role: 'accept', color: 'primary', text: 'Continuar', click: () => {  resolve(); } }
          ]
        });
      } else {
        resolve();
      }
    });
  }

  passwordVerify(): Promise<string> {
    return new Promise((resolve, reject) => { 
      this.uiService.alert({
        title: 'Ingresar contraseña',
        message: 'Ingresá tu contraseña actual para realizar los cambios.',
        content: UserAccountPasswordComponent,
        buttons: [
            { text: 'Cancelar', role: 'cancel', click: () => { reject(''); } },
            { text: 'Continuar', color: 'primary', click: (response: UiAlertRef) => {
              if(
                typeof response.data.password !== 'undefined' && 
                response.data.password !== ''
              ){
                response.close();
                resolve(response.data.password); 
              } else {
                this.uiService.snackbar('Ingresá tu contraseña actual para continuar.');
              }              
            } }
          ]
      });
    });
  }

  private validatePassword(): ValidatorFn {
    return (input: AbstractControl): ValidationErrors | null => {
      if (!input.root || !input.parent) {
        return null;
      }

      const root = input.root as FormGroup;

      if (root.get('passwordNew')?.value !== input.value) {
        return { notSame: true };
      }

      return null;
    };
  }
}

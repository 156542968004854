import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import OneSignal, { NotificationWillDisplayEvent, OSNotification } from 'onesignal-cordova-plugin';
import { NotificationClickEvent } from 'onesignal-cordova-plugin/dist/models/NotificationClicked';

// Utils
import { isDefined } from '@library/utils/functions';
import { isMobile } from '@app/platform/functions/platform';

// Models

// Services

@Injectable()
export class PushService {
    constructor(
        private router: Router,
    ) { }

    init(){
        if(isMobile && typeof OneSignal !== 'undefined'){
            OneSignal.initialize(environment.oneSignalKey);

            OneSignal.Notifications.addEventListener('click', (event: NotificationClickEvent) => {
                console.log(event.notification);

                if(isDefined(event.notification.additionalData)){
                    this.run(event.notification.additionalData, event.notification);
                }
            });

            // Se ejecuta cuando llega la notificación
            OneSignal.Notifications.addEventListener('foregroundWillDisplay', (event: NotificationWillDisplayEvent) => {
                console.log(event.getNotification());
            });
        }
    }

    setUser(id: any, name = ''){
        if(isMobile && typeof OneSignal !== 'undefined'){
            console.log('OneSignal setUser');
            
            OneSignal.login(id);

            if(name !== ''){
                OneSignal.User.addTag('Name', name);
            }
        }
    }

    removeUser(){
        if(isMobile && typeof OneSignal !== 'undefined'){
            console.log('OneSignal removeUser');

            OneSignal.logout();
        }
    }

    run(actions: any, notification?: OSNotification){
        // eslint-disable-next-line prefer-const
        let screen: Array<string> = [];
        // eslint-disable-next-line prefer-const
        let parameters = {};

        if(typeof actions.screen != 'undefined' && actions.screen !== ''){
            

            if(isDefined(screen)){
                this.router.navigate(screen, { queryParams: parameters });
            }
        }
    }
}

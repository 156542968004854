import { Component, Input, OnInit } from '@angular/core';

// Model
import { WorkspaceUser } from 'src/app/modules/workspaces/models/workspace';

@Component({
  selector: 'workspace-user-record',
  templateUrl: './workspace-user-record.component.html',
  styleUrls: ['./workspace-user-record.component.scss']
})
export class WorkspaceUserRecordComponent implements OnInit {
  @Input() workspaceUser = new WorkspaceUser();

  constructor() { }

  ngOnInit(): void { }
}

const platformDomains: any = {
  'clousis': 'panel.clousis.dev',
  'hostbi': 'panel.clousis.dev'
};

export const environment = {
  production: true,
  device: (typeof process.env['NG_APP_DEVICE'] != 'undefined') ? process.env['NG_APP_DEVICE'] : 'web',
  appName: (typeof process.env['NG_APP_NAME'] != 'undefined') ? process.env['NG_APP_NAME'] : 'clousis',
  apiUrl: 'https://api.clousis.dev/',
  platformDomain: platformDomains[process.env['NG_APP_NAME'] ?? 'clousis'],
  platformSsl: true,
  withCredentials: true,
  encryptKey: 'base64:oEghCpkuxoEZ3kOOcU2UVVmsjnTus9j7wtrofR7xsbE=',
  websocketKey: 'Y2xzZW5jcnlwdGtleWZvcmxhcmF2ZWw=',
  websocketCluster: 'Y2xzZW5jcnlwdGtleWZvcmxhcmF2ZWw=',
  assets: '/assets/',
  oneSignalKey: '0fc0bd33-989b-4c74-9a7e-b475f94b38a5',
};

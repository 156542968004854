import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

// Library 
import { UiAlertRef } from 'src/app/library/components/ui-alert';

// Utils
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';

// Models
import { Module } from 'src/app/platform/models/module';
import { Transfer } from 'src/app/modules/integrator/models/transfer';
import { Integration } from 'src/app/modules/integrator/models/integration';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { ModuleService } from 'src/app/platform/services/module.service';

// Components
import { IntegratorTransfersService } from 'src/app/modules/integrator/services/transfers.service';
import { IntegratorIntegrationsService } from 'src/app/modules/integrator/services/integrations.service';
import { IntegrationRunFormComponent } from 'src/app/modules/integrator/components/integration-run-form/integration-run-form.component';

@Component({
  selector: 'app-integrator-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {
  public moduleData: Module | undefined;
  public showBlock: any = {
    fields: false
  }

  public integrationId = 0;
  public integration = new Integration();

  public transfersData: Array<Transfer> = []
  public transfersPagination: PaginationData = new PaginationData(0);
  public transfersFetchBind = this.transfersFetch.bind(this);
  public transfersLoading = false;

  public errors = {
    load: {
      icon: 'move_down',
      title: 'Cargando datos',
      message: 'Estamos cargando la información de la integraciones.',
      loading: true,
    },
    error: {
      icon: 'move_down',
      title: 'Integraciones',
      message: 'No es posible obtener los datos de la integración en este momento. Estamos trabajando para solucionar el inconveniente.',
    }
  };

  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,
    private modulesService: ModuleService,
    private transfersService: IntegratorTransfersService,
    private integrationsService: IntegratorIntegrationsService,
  ) {
    this.integrationId = Number(this.route.snapshot.paramMap.get('id') ?? 0);

    this.modulesService.current.subscribe((module: Module) => {
      this.moduleData = module
    });
  }

  ngOnInit(): void {
    this.uiService.screenLoader(this.errors.load);

    this.integrationsService.get(this.integrationId).then((success: HttpResponseData) => {
      if (success.status) {
        this.integration = success.result;

        this.transfersFetch();        
      } else {
        this.uiService.screenLoader(this.errors.load);
      }
    }).catch(() => {
      this.uiService.screenLoader(this.errors.error);
    });
  }

  migrate() {
    this.uiService.alert({
      title: 'Ejecución manual',
      message: 'No es posible ejecutar una migración manual para esta integración.'
    });
    
    /*return new Promise((resolve, reject) => { 
      this.uiService.alert({
        title: 'Ejecución manual',
        message: 'Seleccioná un rango de fechas en los que la integracion obtendrá datos de origen para migrar.',
        content: IntegrationRunFormComponent,
        buttons: [
            { text: 'Cancelar', role: 'cancel', click: () => { reject(''); } },
            { text: 'Continuar', color: 'primary', click: (response: UiAlertRef) => {
              if(response.data.dateStart && response.data.dateEnd){
                this.uiService.loader(true);
                
                this.integrationsService.run(
                  this.integration.id,
                  response.data.dateStart,
                  response.data.dateEnd
                ).then((success: HttpResponseData) => {
                  this.uiService.loader(false);
                  this.uiService.dialog('Importación manual', success.message, 'success');

                  response.close();
                }).catch((error: HttpResponseData) => {
                  this.uiService.loader(false);
                  this.uiService.snackbar(error.message);
                });

              } else {
                this.uiService.snackbar('Es necesario un rago de fechas válido.');
              }              
            } }
          ]
      });
    });*/
  }

  transfersFetch(page = 1): void{
    this.transfersLoading = true;

    this.transfersService.getAll(this.integration.id, page, this.transfersPagination.pageSize).then((success: HttpResponseData) => {
      this.transfersLoading = false;

      if (success.result.records.length > 0) {
        this.transfersData = success.result.records;
        this.transfersPagination.totalUpdate(success.result.recordsTotal);
        this.transfersPagination.pageUpdate(page);
      }
    }).catch(() => {
      this.transfersLoading = false;
    });
  } 
}

import { Component, OnInit } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { BillingOrder } from 'src/app/modules/billing//models/orders';
import { PaginationData } from 'src/app/library/components/ui-pagination';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { BillingOrdersService } from 'src/app/modules/billing/services/orders.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class BillingOrdersListComponent implements OnInit {
  public statusData = ['', 'pending', 'partial', 'complete'];
  
  public ordersSearchStatus = '';
  public ordersData: Array<BillingOrder> = [];
  public ordersPagination: PaginationData = new PaginationData(0);
  public ordersFetchBind = this.fetch.bind(this);
  public ordersLoading = false;

  public errors = {
    load: {
      title: 'Cargando ordenes y renovaciones',
      message: 'Estamos buscando las ordenes y renovaciones.',
      icon: 'fact_check',
      loading: true,
    },
    empty: {
      title: 'Sin registros',
      message: 'Todavia no hay ordenes o renovaciones generadas.',
      icon: 'fact_check',
    },
    error: {
      title: 'Sin registros',
      message: 'No pudimos cargar lss ordenes y renovaciones. Estamos trabajando para solucionar este problema.',
      icon: 'fact_check',
    }
  };  

  constructor(
    private uiService: UiService,
    private ordersService: BillingOrdersService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(page = 1): void{
    this.ordersLoading = true;
    this.uiService.screenLoader(this.errors.load);

    const filters = {
      status: this.ordersSearchStatus,
    };

    this.ordersService.getAll(page, this.ordersPagination.pageSize, filters, null).then((success: HttpResponseData) => {
      this.ordersLoading = false;

      this.ordersData = success.result.records;
      this.ordersPagination.totalUpdate(success.result.recordsTotal);
      this.ordersPagination.pageUpdate(page);
      
      if (success.result.records.length <= 0) {
        this.uiService.screenLoader(this.errors.empty);
      }
    }).catch(() => {
      this.ordersLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }

  ordersSearch(event: any){

    this.fetch(1);
  }
}

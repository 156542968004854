import { Component, HostListener, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';

// Utils
import { isPath } from '@library/utils/functions';
import { appCurrent, appName, navigateStrategy } from '@platform/functions/platform';

// Models
import { User } from '@modules/user/models/user';
import { Module, ModulesGroup } from '@platform/models/module';
import { UserWorkspace } from '@modules/user/models/workspaces';
import { DataNotification, HttpResponseData } from '@library/models/utils';

// Services
import { UiService } from '@library/services/ui.service';
import { PushService } from '@library/services/push.service';
import { AuthService } from '@modules/auth/services/auth.service';
import { UserService } from '@modules/user/services/user.service';
import { ModuleService } from '@platform/services/module.service';
import { WebsocketService } from '@library/services/websocket.service';
import { GlobalsService } from '@modules/globals/services/globals.service';
import { NotificationsService } from '@modules/user/services/notifications.service';

// Components
import { SupportComponent } from '@modules/user/views/support/support.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('workspacesMenuTrigger')
  workspacesMenuTrigger!: MatMenuTrigger;
  
  public menu = 'primary';
  public appName: any = appName;  
  public appCurrent: any = appCurrent;  
  public authentication = false;
  
  public userData: User = new User(); 
  public modulesGroupsData: Array<ModulesGroup> = [];
  public moduleCurrent: Module = new Module('', '', '', '', '');
  public workspacesData: Array<UserWorkspace> = [];
  public notificationsData: Array<DataNotification> = [];
  public notificationsUnread = 0;
  public notificationReadMethod = this.notificationRead.bind(this);

  constructor(
    private router: Router,
    private uiService: UiService,
    private authService: AuthService,
    private userService: UserService,
    private pushService: PushService,
    private moduleService: ModuleService,
    private globalsService: GlobalsService,
    private websocketService: WebsocketService,
    private notificationsService: NotificationsService,
  ) {
    this.pushService.init();

    /**
     * Esta suscripción verifica la autenticación en cada llamada a una nueva ruta y comprueba si existe una sesión activa.
     * Además, verifica si la pantalla actual no requiere la interfase del panel.
     * - Si la pantalla no requiere o no la interfase entonces se establecerá el valor 
     *   correspondiente a la variable de autenticación que controla la interfase.
     * Esta verificación se realiza mediante la variable whitoutInterfase definida en los datos de cada ruta.
     */
    router.events.subscribe((route) => {
      if(route instanceof NavigationEnd){
        const whitoutInterfaseRoutes = this.router.config
          .filter((route) => typeof route.data !== 'undefined' && route.data['whitoutInterfase'])
          .map((route) => route.path);

        // Se remueve todo lo que este despues del ? porque sino afecta a la validación
        const url = this.router.url.replace(/^\/|\/$/g, '').split('?')[0];
  
        const authenticationVerify = this.userService.userData.value.isSessionSet() && !whitoutInterfaseRoutes.includes(url);

        if(authenticationVerify !== this.authentication){
          this.authentication = authenticationVerify;
        }
      }
    });

    this.userService.userData.subscribe((user: User) => {
      this.userData = user;
      this.authentication = this.userData.isSessionSet() && !isPath('/order/new');
      this.workspacesData = this.userData.workspacesApp(appCurrent.module);

      router.events.subscribe((route) => {
        if(route instanceof NavigationEnd && this.authentication){
          if(appCurrent.module !== 'clousis' && (route.url === '/' || route.url === '/dashboard')){
            this.router.navigate(['/'+ appCurrent.module + '/dashboard']);
          }
        }
      });

      if(this.authentication){
        this.notificationsService.getAll().then((success: HttpResponseData) => {
          this.notificationsData = success.result.records;
          this.notificationsUnread = success.result.data.recordsUnreads;
        });
        
        this.globalsService.settingsLoad();
      }
    });

    /**
     * Esta suscripción verifica los cambios en la sesión y comprueba si finalizó.
     * Ademas, verifica que la pantalla actual no sea una pantalla publica:
     * - Si no es publica (privada) entonces requiere redirigir al usuario a la pantalla de login.
     * - Si es publica no redirige al usuario
     * Esta verificación se realiza mediante la variable "public" definida en los datos de cada ruta.
     */
    
    this.authService.session.subscribe(() => {
      const publicRoutes = this.router.config
        .filter((route) => typeof route.data !== 'undefined' && route.data['public'])
        .map((route) => route.path);

      if(!this.authService.sessionValidate() && !publicRoutes.includes(this.router.url.replace(/^\/|\/$/g, ''))){
        navigateStrategy('/auth/login', this.router);
      }
    });
    

    this.moduleService.groups.subscribe((data) => {
      this.modulesGroupsData = data;
    });

    this.moduleService.current.subscribe((data) => {
      this.moduleCurrent = data;
    });

    /*this.websocketService.conection.channel('').listen('', (response: any) => {
      console.log(response); 
    })*/
    
    this.router.errorHandler = (error: any) => {
      const routerError = error.toString();

      if (
        routerError.indexOf('Cannot match any routes') >= 0 || 
        routerError.includes("Error: Cannot find module")
      ) {
        this.router.navigate(['/404']);
      } else {
        throw error;
      }
    }
  }

  /**
   * Detect screen resizing to configure interface
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.uiService.onWindowResize(event);
  }

  screenSet(): void {
    return this.router.routerState.snapshot.root.data['animation'] || String(this.router.url).replaceAll('/', '-');
  }

  workspaceSelect(){
    if(this.workspacesData.length > 0){
      if(appCurrent.module != 'clousis'){
        this.workspacesMenuTrigger.openMenu();
      } else {
        console.log('Open in modal');
      }    
    }    
  }

  workspaceChange(workspace: UserWorkspace){
    this.uiService.loader(true);

    this.userService.workspaceSet(workspace.id).then(() => {   
      this.uiService.loader(false);
        
      navigateStrategy('/dashboard', this.router);
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
  
      this.uiService.snackbar(error.message);
    });
  }

  support(): void {
    this.uiService.sidebox({
      component: SupportComponent,
      backdrop: true,
      width: 600,
    });
  }

  logout(): void {
    this.uiService.alert({
      title: 'Cerrar sesión',
      message: '¿Estás seguro que deseas salir? Para acceder nuevamente tendrás que ingresar tu usuario y contraseña.',
      width: 300,
      buttons: [
        { text: 'Cancelar', role: 'cancel',  },
        { text: 'Cerrar', color: 'primary', role: 'accept', click: () => {
            this.uiService.loader(true);
    
            this.authService.logout().then(() => {   
              this.uiService.loader(false);
              navigateStrategy('/auth/login', this.router);
            }).catch((error: HttpResponseData) => {
              this.uiService.loader(false);
              this.uiService.snackbar(error.message);
            });
          }
        }
      ]
    });
  }

  navigate(screen: string): void {
    this.router.navigate([screen]);
  }

  notificationRead(notification: DataNotification){
    notification.loading = true;

    this.notificationsService.read(notification.id).then(() => {
      notification.loading = false;
      notification.status = 'confirmed';
    }).catch((error: HttpResponseData) => {
      notification.loading = false;
      console.log(error);
    })
  }
}

<div class="ui-auth-text">
    <p>Inicia sesión para continuar</p>
</div>
            
<div class="ui-auth-form">
    <form [formGroup]="formData">
        <ui-field class="ui-field" label="Correo electrónico" [validate]="formValidates.email">
            <input class="ui-input" type="email" formControlName="email">
        </ui-field> 
    
        <ui-field class="ui-field" label="Contraseña" [validate]="formValidates.password">
            <input class="ui-input" type="password" formControlName="password" (keydown.enter)="login()">
        </ui-field>
    </form>
    
    <div class="ui-auth-form-actions">
        <a (click)="screenChange('recover')">Olvidé mi contraseña</a>
    </div>
                    
    <div class="ui-auth-form-button">
        <ui-button color="primary" (click)="login()" [disabled]="formLoading">
            <span *ngIf="!formLoading">Ingresar</span>
            <ui-spinner class="ui-spinner" color="white" diameter="20" *ngIf="formLoading"></ui-spinner>
        </ui-button>
    </div>
</div>
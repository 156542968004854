import { Component, forwardRef, Inject, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgControl, NgModel, FormControlName, FormGroupDirective, FormControlDirective } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'ui-autocomplete',
  templateUrl: './ui-autocomplete.component.html',
  styleUrls: ['./ui-autocomplete.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiAutocompleteComponent),
    multi: true
  }],
})
export class UiAutocompleteComponent implements ControlValueAccessor, OnInit {
  @Input() data: Array<any>|null = [];
  @Input() value = 'id';
  @Input() name = 'name';
  @Input() display = (item: any) => { return item };
  @Input() loading = false;
  @Input() placeholder = 'Buscar...';
  
  public control!: FormControl;
  public valueInitial = '';
  
  protected readonly destroy = new Subject<void>();
  
  constructor(
    @Inject(Injector) private injector: Injector,
  ) { } 

  ngOnInit(): void {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case NgModel: {
          const { control, update } = formControl as NgModel;

          this.control = control;

          this.control.valueChanges.pipe(
            tap((value) => update.emit(value)),
            takeUntil(this.destroy),
          ).subscribe();
          break;
        }

        case FormControlName: {          
          this.control = this.injector.get(FormGroupDirective).getControl(formControl as FormControlName);
          break;
        }

        default: {
          this.control = (formControl as FormControlDirective).form as FormControl;
          break;
        }
      }
    } catch (error) {
      this.control = new FormControl();
    }

    this.control.patchValue((typeof this.control.value !== 'undefined') ? this.control.value : '');
  }

  change(event: any): void {
    this.control.patchValue(event.target.value);
  }
  
  selection(event: any): void {
    this.control.patchValue(event.option.value);
  }

  writeValue(value: any): void {
    if(value !== ''){
      this.valueInitial = this.display(value);
    }
  }

  registerOnChange(fn: (value: any | null) => any): void { }

  registerOnTouched(fn: () => void): void { }

  setDisabledState?(isDisabled: boolean): void { }
}

import { Attribute, Component, Input, OnInit } from '@angular/core';
import { isDefined } from '../../utils/functions';

@Component({
  selector: 'ui-spinner',
  templateUrl: './ui-spinner.component.html',
  styleUrls: ['./ui-spinner.component.scss']
})
export class UiSpinnerComponent implements OnInit {
  @Input() public diameter: any = 36;

  constructor() {
    this.diameter = Number((typeof this.diameter !== 'undefined' && this.diameter > 0) ? this.diameter : 36);
  }

  ngOnInit(): void { }
}

import { Component, Input, OnInit } from '@angular/core';
import { UiAlertRef } from 'src/app/library/components/ui-alert';

@Component({
  selector: 'app-hostbi-domain-ssl-status',
  templateUrl: './domain-ssl-status.component.html',
  styleUrls: ['./domain-ssl-status.component.scss']
})
export class HostbiDomainSslStatusComponent implements OnInit {
  @Input() status = '';

  constructor(
    private uiAlertRef: UiAlertRef,
  ) { }

  change(event: any){
    this.uiAlertRef.data['status'] = this.status;
  }

  ngOnInit(): void { }
}

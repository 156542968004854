import { Type } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export interface UiSideboxCloseEvent<R> {
  type: 'backdropClick' | 'close';
  data: R;
}

// R = Response Data Type, T = Data passed to Modal Type
export class UiSidebox<R = any, T = any> {
  afterClosed = new Subject<UiSideboxCloseEvent<R>>();

  constructor(
    public overlay: OverlayRef,
    public content: any,
    public data: T
  ) {
    overlay.backdropClick().subscribe(() => this._close('backdropClick'));
  }

  close(data?: any): void {
    this._close('close', data);
  }

  private _close(type: 'backdropClick' | 'close', data?: any): void {
    this.overlay.dispose();

    this.afterClosed.next({type, data});
    this.afterClosed.complete();
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-title',
  templateUrl: './ui-title.component.html',
  styleUrls: ['./ui-title.component.scss']
})
export class UiTitleComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}

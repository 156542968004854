import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

// Library
import { UiAlertRef } from 'src/app/library/components/ui-alert';

// Utils
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';

// Models
import { BillingData } from 'src/app/modules/billing/models/billing';
import { Workspace } from 'src/app/modules/workspaces/models/workspace';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { BillingOrder } from 'src/app/modules/billing/models/orders';
import { BillingInvoice } from 'src/app/modules/billing/models/invoices';
import { BillingService } from 'src/app/modules/billing/models/services';
import { BillingMovement } from 'src/app/modules/billing/models/movements';
import { BillingOrdersService } from 'src/app/modules/billing/services/orders.service';
import { WorkspacesService } from 'src/app/modules/workspaces/services/workspaces.service';
import { BillingInvoicesService } from 'src/app/modules/billing/services/invoices.service';
import { BillingServicesService } from 'src/app/modules/billing/services/services.service';
import { BillingPaymentsService } from 'src/app/modules/billing/services/payments.service';
import { BillingMovementsService } from 'src/app/modules/billing/services/movements.service';
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';

// Components
import { BillingDataUpdateFormComponent } from 'src/app/modules/billing/components/data-update-form/data-update-form.component';
import { WorkspacesPaymentProcessComponent } from 'src/app/modules/workspaces/components/payment-process/payment-process.component';
import { BillingOrderFormComponent } from 'src/app/modules/billing/components/order-form/order-form.component';

@Component({
  selector: 'app-workspace-details',
  templateUrl: './workspace-details.component.html',
  styleUrls: ['./workspace-details.component.scss']
})
export class WorkspaceDetailsComponent implements OnInit {
  public workspaceId = '';
  public workspace = new Workspace();
  public accounting = new BillingData();
  public tabsContent = 'orders';
  public showBlock: any = {
    data: true,
    users: true,
    services: true
  }

  public servicesData: Array<BillingService> = [];
  public servicesPagination: PaginationData = new PaginationData(0);
  public servicesFetchBind = this.servicesFetch.bind(this);
  public servicesLoading = false;

  public ordersData: Array<BillingOrder> = [];
  public ordersPagination: PaginationData = new PaginationData(0);
  public ordersFetchBind = this.ordersFetch.bind(this);
  public ordersPayBind = this.ordersPay.bind(this);
  public ordersLoading = false;

  public invoicesData: Array<BillingInvoice> = [];
  public invoicesPagination: PaginationData = new PaginationData(0);
  public invoicesFetchBind = this.invoicesFetch.bind(this);
  public invoicesDownloadBind = this.invoicesDownload.bind(this);
  public invoicesLoading = false;

  public movementsData: Array<BillingMovement> = [];
  public movementsPagination: PaginationData = new PaginationData(0);
  public movementsFetchBind = this.movementsFetch.bind(this);
  public movementsPayBind = this.movementsPay.bind(this);
  public movementsCancelBind = this.movementsCancel.bind(this);
  public movementsLoading = false;

  public firstLoad = false;

  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,
    private ordersService: BillingOrdersService,
    private workspacesService: WorkspacesService,
    private invoicesService: BillingInvoicesService,
    private servicesService: BillingServicesService,
    private movementsService: BillingMovementsService,
    private billingAccountingService: BillingAccountingService,
  ) {
    this.workspaceId = this.route.snapshot.paramMap.get('id') ?? '';
  }

  ngOnInit(): void {
    this.servicesFetch();
    this.ordersFetch();
    this.invoicesFetch();
    this.movementsFetch();

    const initData = [];
  
    initData.push(this.workspacesService.get(this.workspaceId));
    initData.push(this.billingAccountingService.get(this.workspaceId));

    this.uiService.screenLoader({
      title: 'Cargando datos de la cuenta',
      message: 'Estamos buscando toda la información relacionada a la cuenta.',
      icon: 'workspaces',
      loading: true,
    });
    
    Promise.all(initData).then(values => {
      this.workspace = values[0].result;
      this.accounting = values[1].result;
      console.log(this.accounting);
      console.log(this.workspace);
      this.firstLoad = true;
    }).catch(() => {
      this.uiService.screenLoader({
        title: 'Ups.. ¡Algo salio mal!',
        message: 'Se produjo un error mientras buscábamos la información relacionada a la cuenta. Intenta nuevaente actualizando la página',
        icon: 'error',
        loading: false,
      });
    });
  }

  dataEdit(): void {
    this.uiService.modalbox({
      component: BillingDataUpdateFormComponent,
      backdrop: true,
      data:{ workspaceId: this.workspaceId }
    });
  }

  paymentProcess(): Promise<string> {
    return new Promise((resolve, reject) => { 
      this.uiService.alert({
        title: 'Informar un pago',
        message: 'Ingresá el número de transacción del pago realizado.',
        content: WorkspacesPaymentProcessComponent,
        buttons: [
            { text: 'Cancelar', role: 'cancel', click: () => { reject(''); } },
            { text: 'Continuar', color: 'primary', click: (response: UiAlertRef) => {
              if(
                typeof response.data.transaction !== 'undefined' && 
                response.data.transaction !== ''
              ){
                this.uiService.loader(true);
                
                this.billingAccountingService.paymentProcess(response.data.transaction, this.workspaceId).then((success: HttpResponseData) => {
                  const status: any = {
                    null: 'error',
                    pending: 'info',
                    confirmed: 'success',
                    cancelled: 'alert',
                    expired: 'alert',
                    refused: 'alert',
                    disputed: 'alert',
                    active: 'info',
                  };
                  
                  this.uiService.loader(false);
                  this.uiService.dialog('Informar un pago', success.message, status[success.result.status]);

                  if(success.result.status === 'confirmed'){
                    response.close();
                  }
                }).catch((error: HttpResponseData) => {
                  this.uiService.loader(false);
                  this.uiService.snackbar(error.message);
                });

              } else {
                this.uiService.snackbar('Ingresá un número de transacción para continuar.');
              }              
            } }
          ]
      });
    });
  }

  // Services
  
  servicesFetch(page = 1): void{
    this.servicesLoading = true;

    this.servicesService.getAll(page, this.servicesPagination.pageSize, this.workspaceId).then((success: HttpResponseData) => {
      this.servicesLoading = false;

      if (success.result.records.length > 0) {
        this.servicesData = success.result.records;
        this.servicesPagination.totalUpdate(success.result.recordsTotal);
        this.servicesPagination.pageUpdate(page);
      }
    }).catch(() => {
      this.servicesLoading = false;
    });
  }  

  // Orders

  ordersFetch(page = 1): Promise<void> {
    return new Promise((resolve, reject) => { 
      this.ordersLoading = true;

      this.ordersService.getAll(page, this.ordersPagination.pageSize, {}, this.workspaceId).then((success: HttpResponseData) => {
        this.ordersLoading = false;

        if (success.result.records.length > 0) {
          this.ordersData = success.result.records;
          this.ordersPagination.totalUpdate(success.result.recordsTotal);
          this.ordersPagination.pageUpdate(page);
        }

        resolve();
      }).catch(() => {
        this.ordersLoading = false;

        reject();
      });
    });
  }

  ordersPay(service: BillingOrder): void {
    
  }

  ordersGenerate(){
    const modal = this.uiService.modalbox({
      component: BillingOrderFormComponent,
      backdrop: true,
      data: { workspace: this.workspace }
    });

    modal.afterClosed.subscribe((event) => {
      if(typeof event.data.status !== 'undefined' && event.data.status){
        this.ordersFetch();
      }
    });
  }

  // Invoices

  invoicesFetch(page = 1): Promise<void> {
    return new Promise((resolve, reject) => { 
      this.invoicesLoading = true;

      this.invoicesService.getAll(page, this.invoicesPagination.pageSize, this.workspaceId).then((success: HttpResponseData) => {
        this.invoicesLoading = false;

        if (success.result.records.length > 0) {
          this.invoicesData = success.result.records;
          this.invoicesPagination.totalUpdate(success.result.recordsTotal);
          this.invoicesPagination.pageUpdate(page);
        }

        resolve();
      }).catch(() => {
        this.invoicesLoading = false;
        
        reject();
      });
    });
  }

  invoicesDataEdit(): void {
    this.uiService.modalbox({
      component: BillingDataUpdateFormComponent,
      backdrop: true,
    });
  }

  invoicesDownload(service: BillingInvoice): void {
    this.uiService.loader(true);
    
    this.invoicesService.download(service.id, this.workspaceId).then((success: HttpResponseData) => {   
      this.uiService.loader(false);
              
      window.open(success.result, '_blank');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Descargar factura', error.message, 'error');
    });
  }

  // Movements

  movementsFetch(page = 1): Promise<void> {
    return new Promise((resolve, reject) => { 
      this.movementsLoading = true;

      this.movementsService.getAll(page, this.movementsPagination.pageSize, this.workspaceId).then((success: HttpResponseData) => {
        this.movementsLoading = false;

        if (success.result.records.length > 0) {
          this.movementsData = success.result.records;
          this.movementsPagination.totalUpdate(success.result.recordsTotal);
          this.movementsPagination.pageUpdate(page);
        }

        resolve();
      }).catch(() => {
        this.movementsLoading = false;
        
        reject();
      });
    });
  }

  movementsPay(movement: BillingMovement): void {
    
  }

  movementsCancel(movement: BillingMovement): void {
    if(movement.isRemovable){
      this.uiService.alert({
        title: 'Cancelar depósito en cuenta',
        message: '¿Estás seguro de querer cancelar este movimiento?',
        width: 300,
        buttons: [
          { text: 'No', role: 'cancel',  },
          { text: 'Si, Cancelar', role: 'accept', click: () => {
              this.uiService.loader(true);
        
              this.movementsService.delete(movement.id, this.workspaceId).then((success: HttpResponseData) => {   
                this.uiService.loader(false);
                this.uiService.dialog('Cancelar depósito en cuenta', 'El movimiento de depósito en cuenta se canceló correctamente.', 'success');
                
                this.movementsFetch(this.movementsPagination.pageCurrent);
              }).catch((error: HttpResponseData) => {
                this.uiService.loader(false);
                this.uiService.dialog('Cancelar depósito en cuenta', error.message, 'error');
              });
            }
          }
        ]
      });
    }
  }
}

<ui-content>
    <div class="ui-dashboard">
        <div class="ui-dashboard-start">
            <div class="ui-dashboard-welcome" [ngClass]="{'full' : !['owner', 'admin'].includes(userData.workspace.role) }">
                <p><span>{{ dateCurrent | date: 'EEEE' }}</span> {{ dateCurrent | date: 'd' }} de <span>{{ dateCurrent | date: 'MMMM' }}</span></p>
                <ui-title>¡Hola {{userData.firstname}}!</ui-title>
                <p>A continuación podes ver un resumen de tus cuentas de alojamiento</p>
            </div>        
            
            <div class="ui-dashboard-resume" *ngIf="['owner', 'admin'].includes(userData.workspace.role)">
                <billing-resume mode="vertical"></billing-resume>
            </div>
        </div>

        <ul class="ui-dashboard-data">
            <li><ui-icon name="notifications" type="outlined"></ui-icon> <span>{{notificationsUnreadsData}}</span> {{(notificationsUnreadsData === 1) ? 'Notificación' : 'Notificaciones' }}</li>
            <li><ui-icon name="report_problem" type="outlined"></ui-icon> <span>0</span> Acciones necesarias</li>
            <li routerLink="/billing/resume/"><ui-icon name="credit_card" type="outlined"></ui-icon> <span>{{ordersUnpaidsData}}</span> {{(ordersUnpaidsData === 1) ? 'Renovación pendiente' : 'Renovaciones pendientes' }}</li>
        </ul>

        <div class="ui-dashboard-accounts" *ngIf="dataLoaded">
            <hostbi-account class="ui-account" [routerLink]="'/hostbi/accounts/' + account.id" [account]="account" *ngFor="let account of accountsData"></hostbi-account>

            <ui-card class="ui-account-new ui-account" (click)="accountAdd()" *ngIf="accountsData.length > 0">
                <div>
                    <div class="ui-icon-el">
                        <ui-icon name="dns" type="outlined"></ui-icon>
                    </div>
                    <div class="ui-label-el">Agregar cuenta</div>
                </div>
            </ui-card>
        </div>

        <div class="ui-dashboard-monitoring" *ngIf="dataLoaded && monitoringData.length > 0">
            <ui-title>Monitoreo de sitios Wordpress</ui-title>

            <hostbi-monitor-record class="ui-monitor" [monitor]="monitor" *ngFor="let monitor of monitoringData"></hostbi-monitor-record>
        </div>

        <ui-screen-loader *ngIf="!dataLoaded || (dataLoaded && accountsData.length <= 0 && monitoringData.length <= 0)"></ui-screen-loader>
    </div>
</ui-content>
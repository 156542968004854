<div class="ui-field" [class]="{'ui-field-error': validate.isValid === false}">
    <label class="ui-label" *ngIf="label !== ''">{{label}}</label>
    
    <div class="ui-field-content">
        <div class="ui-field-prefix">
            <ng-content select="[slot=left]"></ng-content>
        </div>

        <div class="ui-field-input">
            <ng-content></ng-content>
        </div>

        <div class="ui-field-spinner" *ngIf="loading">
            <ui-spinner [diameter]="18" color="primary"></ui-spinner>
        </div>

        <div class="ui-field-sufix">
            <ng-content select="[slot=right]"></ng-content>
        </div>
    </div>

    <span *ngIf="legend !== ''">{{legend}}</span>
    <span *ngIf="validate.error !== ''">{{validate.error}}</span>
</div>
<ui-card>
    <div class="ui-data-el">
        <div class="ui-status-el" [ngClass]="account.status.status" [matTooltip]="account.status.reason" (click)="issues()">
            <ui-icon *ngIf="account.status.status === 'online'" name="check_circle" type="outlined"></ui-icon>
            <ui-icon *ngIf="account.status.status === 'partial'" name="report_problem" type="outlined"></ui-icon>
            <ui-icon *ngIf="account.status.status === 'offline'" name="error_outline" type="outlined"></ui-icon>
        </div>

        <div class="ui-account-el">
            <span class="ui-domain-el">{{account.domain}}</span>
            <span class="ui-server-el">{{account.server.name}} · {{account.user}}</span>
        </div>

        <div class="ui-structure-el">
            <span class="ui-workspace-el">{{account.workspace.name}}</span>
            <span class="ui-client-el">{{account.plan.name}}</span>
        </div>

        <div class="ui-stats-el" [ngClass]="{'full': account.bandwidth.usagePercent >= 100}">
            <ui-stat-progress name="Transferencia" [value]="account.bandwidth.usage" [total]="account.bandwidth.total" [percent]="account.bandwidth.usagePercent" [unity]="account.bandwidth.unity"></ui-stat-progress>
        </div>

        <div class="ui-stats-el" [ngClass]="{'full': account.storage.usagePercent >= 100}">
            <ui-stat-progress name="Almacenamiento" [value]="account.storage.usage" [total]="account.storage.total" [percent]="account.storage.usagePercent" [unity]="account.storage.unity"></ui-stat-progress>
        </div>
    </div>

    <div class="ui-actions-el">
        <ui-button color="transparent" [matMenuTriggerFor]="contextMenu">
            <ui-icon name="more_vert" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-button color="transparent" (click)="details()">
            <ui-icon name="chevron_right" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>
        
        <mat-menu #contextMenu="matMenu">
            <button mat-menu-item (click)="summary()">Actualizar estado</button>
            <button mat-menu-item (click)="remove()">Eliminar cuenta</button>
        </mat-menu>
    </div>

    <div class="ui-report-el" [ngClass]="account.status.status" *ngIf="account.status.status === 'offline' || account.status.status === 'partial'">
        {{account.status.message}}
    </div>
</ui-card>
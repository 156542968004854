import { Component, Input, OnInit } from '@angular/core';

// Models
import { BillingMovement } from 'src/app/modules/billing/models/movements';

@Component({
  selector: 'billing-movement',
  templateUrl: './movement.component.html',
  styleUrls: ['./movement.component.scss']
})
export class BillingMovementComponent implements OnInit {
  @Input() movement: BillingMovement = new BillingMovement();
  @Input() pay: (movement: BillingMovement) => void = (): void => { };
  @Input() cancel: (movement: BillingMovement) => void = (): void => { };

  constructor() { /* Silence is golden */ }

  ngOnInit(): void { /* Silence is golden */ }
}

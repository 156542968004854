
<ui-header>
    <ui-title>Mi cuenta de usuario</ui-title>
</ui-header>

<ui-content>
    <ui-card class="ui-form">
        <div class="ui-card-content">
            <form [formGroup]="formData">
                <div class="ui-form-row-double">
                    <ui-field class="ui-field" label="Nombre" [validate]="formValidates.firstname">
                        <input class="ui-input" type="text" formControlName="firstname">
                    </ui-field> 
                        
                    <ui-field class="ui-field" label="Apellido" [validate]="formValidates.lastname">
                        <input class="ui-input" type="text" formControlName="lastname">
                    </ui-field>
                </div>
                    
                <ui-field class="ui-field" label="Dirección de correo" [validate]="formValidates.email">
                    <input class="ui-input" type="email" formControlName="email">
                </ui-field>

                <ui-field class="ui-field" label="Teléfono" [validate]="formValidates.phone">
                    <input class="ui-input" type="text" formControlName="phone">
                </ui-field>                    
                        
                <div class="ui-divider">Cambio de contraseña</div>

                <div class="ui-form-row-double">
                    <ui-field class="ui-field" label="Nueva contraseña">
                        <input class="ui-input" type="password" formControlName="passwordNew">
                    </ui-field>
                            
                    <ui-field class="ui-field" label="Repetir contraseña" [validate]="formValidates.passwordRepeat">
                        <input class="ui-input" type="password" formControlName="passwordRepeat">
                    </ui-field>
                </div>

                <ui-note icon="vpn_key">
                    Completá estos campos solo si queres actualizar tu contraseña, de lo contrario, podes dejarlos en blanco.
                </ui-note>
            </form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" (click)="onUpdate()">Actualizar datos</ui-button>    
        </div>
    </ui-card>
</ui-content>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-badge',
  templateUrl: './ui-badge.component.html',
  styleUrls: ['./ui-badge.component.scss']
})
export class UiBadgeComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void { }

}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { screenTransitions } from './ui-pane.animations';

// Service
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'ui-pane',
  templateUrl: './ui-pane.component.html',
  styleUrls: ['./ui-pane.component.scss'],
  animations: [ screenTransitions ]
})
export class UiPaneComponent implements OnInit {
  @Input() split: boolean = true;
  
  constructor(
    private router: Router,
    private uiService: UiService
  ) { }

  ngOnInit(): void {
    this.uiService.onResizeObserver('[data-resizes]');
  }

  screenSet(): void {
    return this.router.routerState.snapshot.root.data['animation'] || String(this.router.url).replaceAll('/', '-');
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MenuService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor( ) { }

    set(status: boolean): void {
        this.status.next(status);
    }

    get(): boolean {
        return this.status.value;
    }
}

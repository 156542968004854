import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'ui-backdrop',
  templateUrl: './ui-backdrop.component.html',
  styleUrls: ['./ui-backdrop.component.scss'],
  animations: [
    trigger('fade', [
      state('true', style({
        opacity: '1',
        visibility: 'visible'
      })),
      state('false', style({
        opacity: '0',
        visibility: 'hidden'
      })),
      transition('true => false', animate('200ms ease-in-out')),
      transition('false => true', animate('200ms ease-in-out')),
    ]),
  ]
})
export class UiBackdropComponent implements OnInit {
  @Input() show = true;

  constructor() { }

  ngOnInit(): void { }
}


<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">Crear cuenta de alojamiento</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card>
        <div class="ui-card-content ui-form">
            <form [formGroup]="formData">
                <ui-field class="ui-field" label="Espacio de trabajo" [validate]="formValidates.workspace">
                    <ui-autocomplete
                        value="id"
                        name="name"
                        formControlName="workspace"
                        [data]="workspacesDataFiltered | async"
                        [loading]="workspacesDataLoading"                            
                        [display]="workspacesDataDisplay.bind(this)"
                    ></ui-autocomplete>

                    <ui-icon slot="right" name="search" type="outlined"></ui-icon>
                </ui-field>

                <ui-field class="ui-field" label="Plan" [validate]="formValidates.service">
                    <select class="ui-input" formControlName="service">
                        <option *ngFor="let item of servicesData" [value]="item.id">{{item.name}} $&nbsp;{{item.price | number: '1.2-2'}}/mes</option>
                    </select>
                </ui-field>

                <ui-field class="ui-domain-el" label="Dominio. Ej: dominio.com" [validate]="formValidates.domain" [loading]="domainSpinner" *ngIf="formData.value.domainStatus !== ''">
                    <input class="ui-input" type="text" formControlName="domain" (keyup)="domainVerify()">
                
                    <ui-icon name="check" type="outlined" slot="right" *ngIf="domainVerified"></ui-icon>
                </ui-field>
            </form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" (click)="create()" [disabled]="formLoading || domainSpinner || !domainVerified">
                <span *ngIf="!formLoading">Crear</span>
                <ui-spinner class="ui-spinner" color="white" diameter="20" *ngIf="formLoading"></ui-spinner>
            </ui-button>
        </div>
    </ui-card>
</ui-content>

import moment from "moment";

// Models
import { DataMeta } from "@app/library/models/utils";
import { camelcase } from "@app/library/utils/functions";

export const HOSTBI_DOMAIN_REGISTER_STATES: Array<DataMeta> = [
    new DataMeta('pending', 'Pendiente'),
    new DataMeta('registered', 'Registrado'),
    new DataMeta('active', 'Activo'),
    new DataMeta('canceled', 'Cancelado'),
    new DataMeta('deleted', 'Eliminado'),
]

export class HostbiDomainRegister {
    id = 0;
    workspace = '';
    domain = '';
    status = '';
    registrant = '';
    nameServers: Array<string> = [];
    expirationDate: Date = moment().toDate();

    constructor(parameters?: any) {
        if(parameters !== undefined){
            for (const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }
    
    get statusName(): string{
        const status: any = {
            'pending': 'Pendiente',
            'registered': 'Registrado',
            'active': 'Activo',
            'canceled': 'Cancelado',
            'deleted': 'Eliminado',
        }
        
        return status[this.status];
    }

    private filter(key: string, value: any) {
        if(key === 'nameServers'){
            value = value ?? [];
        } else if(['expirationDate'].includes(key)){
            value = moment(value).toDate();
        }
        
        return value;
    }
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-label',
  templateUrl: './ui-label.component.html',
  styleUrls: ['./ui-label.component.scss']
})
export class UiLabelComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}

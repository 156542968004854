<ui-content>
    <ui-spinner-layer *ngIf="categoriesLoader"></ui-spinner-layer>

    <ng-container *ngIf="!categoriesLoader">
        <div class="ui-header-el">
            <div>
                <ui-button color="transparent" (click)="back()" *ngIf="['posts', 'post'].includes(screen)">
                    <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
                </ui-button>
                    
                <ui-title *ngIf="!categoriesLoader">{{title}}</ui-title>
            </div>

            <div>
                <ui-button color="secondary" (click)="ticket()" class="ui-support-el" [ngClass]="screen">Contactar a soporte</ui-button>
                
                <ui-button color="transparent" (click)="close()">
                    <ui-icon name="close" type="outlined" slot="icon-only"></ui-icon>
                </ui-button>
            </div>
        </div>

        <div class="ui-search-el" *ngIf="screen !== 'post'">
            <ui-field>
                <input class="ui-input" type="text" placeholder="Encontrar una respuesta" (keydown.enter)="search()" [(ngModel)]="postsFilters.search">
                        
                <ui-button slot="right" (click)="search()">
                    <ui-icon slot="icon-only" name="search" type="outlined"></ui-icon>
                </ui-button>
            </ui-field>
        </div>
        
        <div class="ui-list-el" [ngClass]="screen">
            <ng-container *ngIf="screen === 'categories'">
                <support-category *ngFor="let category of categoriesList" [category]="category" (click)="categoriesSelect(category)"></support-category>

                <ui-empty *ngIf="categoriesList.length <= 0" icon="help">
                    <p>No pudimos cargar los artículos de ayuda.</p>
                    <ui-button color="secondary" (click)="ticket()">Contactar a soporte</ui-button>
                </ui-empty>
            </ng-container>

            <ng-container *ngIf="screen === 'posts'">
                <ui-spinner-layer *ngIf="postsLoader"></ui-spinner-layer>

                <support-post *ngFor="let post of postsList" [post]="post" (click)="postsSelect(post)"></support-post>
                
                <ui-pagination *ngIf="postsList.length > 0" [fetch]="postsFetch" [data]="postsPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
                
                <ui-empty *ngIf="postsList.length <= 0 && !postsLoader" icon="description"><p>No hay artículos con esta búsqueda</p></ui-empty>
            </ng-container>
        </div>

        <div class="ui-post-el" *ngIf="screen === 'post'" [innerHTML]="post.content | htmlSafe"></div>
    </ng-container>
</ui-content>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Utils
import { formValidate, formValidateInit, validateEmail } from '@app/library/utils/form-validators';

// Models
import { User } from '@app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';
import { UiModalbox } from 'src/app/library/components/ui-modalbox';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UsersService } from '@app/modules/user/services/users.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  public response = new User();
  
  public formData: FormGroup = new FormGroup({});
  public formLoading = false;
  public formValidates: any;
  public formInputsErrors: object = {
    firstname: { required: '' },
    lastname: { required: '' },
    email: { required: '',  pattern: 'Es necesario buscar y seleccionar un usuario.' },
    password: { required: '',  pattern: 'Es necesario buscar y seleccionar un usuario.' }
  };
 
  constructor(
    private uiService: UiService,
    private uiModalbox: UiModalbox,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
  ) {    
    this.formData = this.formBuilder.group({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, validateEmail()]),
      password: new FormControl('', [Validators.required]),
    });

    this.formValidates = formValidateInit(this.formData);
  }

  ngOnInit(): void {
  }

  close(){
    this.uiModalbox.close(this.response);
  }

  create(): void {
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    

    if (this.formData.valid) {
      this.formLoading = true;

      this.usersService.create(this.formData.value).then((result: HttpResponseData) => {
        this.formLoading = false;

        if (result.status) {
          this.response = result.result;
          
          this.close();

          this.uiService.dialog('Usuario creado', 'El usuario se creó correctamente', 'success');
        } else {
          this.uiService.snackbar(result.message);
        }
      }).catch(() => {
        this.formLoading = false;
        this.uiService.snackbar('No es posible crear el usuario en este momento. Estamos trabajando para solucionar el inconveniente.');
      });
    } else {
      this.uiService.snackbar('Algunos datos estan incompletos.');
    }
  }
}

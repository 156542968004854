import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-progress-bar',
  templateUrl: './ui-progress-bar.component.html',
  styleUrls: ['./ui-progress-bar.component.scss']
})
export class UiProgressBarComponent implements OnInit {
  @Input() value = 0;
  @Input() mode = '';
  @Input() color: string|'primary'|'success'|'alert'|'error' = 'primary';

  constructor() { }

  ngOnInit(): void { }

}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { HostbiMonitoring } from 'src/app/modules/hostbi/models/hostbi-monitoring';

@Component({
  selector: 'hostbi-monitor-record',
  templateUrl: './monitor-record.component.html',
  styleUrls: ['./monitor-record.component.scss']
})
export class HostbiMonitorRecordComponent implements OnInit {
  @Input() monitor = new HostbiMonitoring();

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void { }

  preview(): void {
    this.router.navigate(['/hostbi/monitoring/' + this.monitor.id]);
  }
}

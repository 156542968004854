import { Component, OnInit } from '@angular/core';
import { UiAlertRef } from '@app/library/components/ui-alert';

// Models
import { DataMeta } from '@app/library/models/utils';
import { HOSTBI_DOMAIN_REGISTER_STATES } from '@modules/hostbi/models/hostbi-domain-register';

// Services

// Components

@Component({
  selector: 'hostbi-domain-register-status',
  templateUrl: './domain-register-status.component.html',
  styleUrls: ['./domain-register-status.component.scss']
})
export class HostbiDomainRegisterStatusComponent implements OnInit {
  public status = '';
  public states: Array<DataMeta> = HOSTBI_DOMAIN_REGISTER_STATES;

  constructor(
    private uiAlertRef: UiAlertRef,
  ) { }

  change(event: any){
    this.uiAlertRef.data['status'] = this.status;
  }

  ngOnInit(): void { }
}

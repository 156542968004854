import { Component, Input, OnInit } from '@angular/core';

// Models
import { PaginationData } from 'src/app/library/components/ui-pagination/pagination';

@Component({
  selector: 'ui-pagination',
  templateUrl: './ui-pagination.component.html',
  styleUrls: ['./ui-pagination.component.scss'],
})
export class UiPaginationComponent implements OnInit {
  @Input() data: PaginationData = new PaginationData(0);    
  @Input() pageSizeOptions: Array<number> = [];

  public pageSizeTotal = 0;
  public pageSizeStart = 0;
  public pageSizeEnd = 0;

  public pageValue: any = '';
  public pageSizeValue: any = 10;

  constructor() { }

  @Input() fetch = (page: number) => { };

  ngOnInit() {
    this.data.update.subscribe((value: PaginationData) => {
      this.data = value;

      this.onPageSizeUpdate();
    });
  }
  
  onFetch(page = null) {
    const pageValue = (page === null) ? this.pageValue : page;
    
    if(pageValue !== '...' && (pageValue !== this.data.pageCurrent || page == null)){
      this.data.pageCurrent = Number(pageValue);

      this.fetch(Number(pageValue));

      this.onPageSizeUpdate();
    }
  }

  onPageSizeChange() {
    const length = this.pageSizeValue;

    this.data.pageCurrent = 1;

    this.data.pageSizeUpdate(Number(length));
    
    this.onPageSizeUpdate();

    this.pageValue = this.data.pageCurrent;

    this.onFetch();
  }

  inMiddle(page: number){
    return this.data.pagesMiddle.includes(Number(page));
  }
  
  onPageSizeUpdate(){    
    this.pageSizeTotal = this.data.itemsTotal;
    this.pageSizeStart = ((this.data.pageCurrent - 1) * this.data.pageSize) + 1;
    this.pageSizeEnd = ((this.data.pageCurrent - 1) * this.data.pageSize) + this.data.pageSize;
    this.pageSizeEnd = (this.pageSizeEnd < this.pageSizeTotal) ? this.pageSizeEnd : this.pageSizeTotal;
  }
}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Directive({
  selector: '[uiHideWhen]',
  
})
export class UiHideWhenDirective {
  @Input() uiHideWhen = '';
  
  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(){
    this.check();
  }

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.check();
  }

  check(){
    this.elementRef.nativeElement.style.display = 'inline';
    
    const options: any = {
      'mobile': Capacitor.isNativePlatform(),
      'desktop': !Capacitor.isNativePlatform(),
      'web': Capacitor.getPlatform() === 'web',
      'android': Capacitor.getPlatform() === 'android',
      'ios': Capacitor.getPlatform() === 'ios',
      'landscape': screen.orientation.type === 'landscape-primary',
      'portrait': screen.orientation.type === 'portrait-primary',
    }

    this.uiHideWhen.split(',').forEach((condition): any => {
      const maxwidth = Number(condition.replace('maxwidth:', ''));
      const minwidth = Number(condition.replace('minwidth:', ''));
      const maxheight = Number(condition.replace('maxheight:', ''));
      const minheight = Number(condition.replace('minheight:', ''));
      
      if(
        options[condition] ||
        (window.innerWidth < maxwidth && maxwidth > 0)||
        (window.innerWidth >= minwidth && minwidth > 0) ||
        (window.innerHeight < maxheight && maxheight > 0) ||
        (window.innerHeight >= minheight && minheight > 0)
      ){
        this.elementRef.nativeElement.style.display = 'none';
        
        return true;
      }
    });
  }
}
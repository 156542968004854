
<ui-header>
    <ui-title>Facturas emitidas</ui-title>

    <div class="ui-accounts-filters">
        <ui-button color="primary" (click)="dataEdit()">Modificar datos de faturación</ui-button>
    </div>
</ui-header>

<ui-content>
    <div class="ui-invoices">
        <div class="ui-invoices-resume">
            <billing-resume mode="horizontal"></billing-resume>
        </div>
    
        <div class="ui-invoices-list" *ngIf="invoicesData.length > 0">
            <billing-invoice 
                [invoice]="invoice"
                [download]="invoicesDownload" 
                *ngFor="let invoice of invoicesData"
            ></billing-invoice>

            <ui-spinner-layer *ngIf="invoicesLoading"></ui-spinner-layer>
        </div>

        <div class="ui-invoices-pagination" *ngIf="invoicesData.length > 0">
            <ui-pagination [fetch]="invoicesFetch" [data]="invoicesPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
        
        <ui-screen-loader *ngIf="invoicesData.length == 0"></ui-screen-loader>
    </div>
</ui-content>


import { Component, OnInit } from '@angular/core';
import { UiAlertRef } from 'src/app/library/components/ui-alert';

@Component({
  selector: 'app-user-account-password',
  templateUrl: './account-password.component.html',
  styleUrls: ['./account-password.component.scss']
})
export class UserAccountPasswordComponent implements OnInit {
  public password = '';

  constructor(
    private uiAlertRef: UiAlertRef,
  ) { }

  change(event: any){
    this.uiAlertRef.data['password'] = this.password;
  }

  ngOnInit(): void { }
}

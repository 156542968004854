
<ui-header>
    <ui-title>Pagos y facturas</ui-title>

    <div class="ui-billing-filters">
        <ui-button color="primary" (click)="invoicesDataEdit()">Modificar datos de faturación</ui-button>
    </div>
</ui-header>

<ui-content>
    <div class="ui-billing">
        <div class="ui-billing-resume">
            <billing-resume mode="vertical"></billing-resume>

            <ui-card class="ui-billing-services">
                <ui-header class="ui-billing-services-header">
                    <ui-title>Servicios activos</ui-title>

                    <ui-spinner color="primary" [diameter]="20" *ngIf="servicesLoading"></ui-spinner>
                </ui-header>

                <div class="ui-billing-services-content">
                    <ng-container *ngIf="servicesData.length > 0">
                        <billing-service [service]="service" *ngFor="let service of servicesData"></billing-service>
                    </ng-container>

                    <div class="ui-billing-services-empty" *ngIf="servicesData.length === 0 && !servicesLoading">
                        <ui-icon name="cancel" type="outlined"></ui-icon>
                        <p>Aún no hay servicios activos.</p>
                    </div>
                </div>
            </ui-card>
        </div>
    
        <div class="ui-billing-content">
            <ui-tabs>
                <ui-tab (click)="tabsContent = 'orders'" [active]="true">
                  <ui-label>Renovaciones y pedidos</ui-label>
                </ui-tab>

                <ui-tab (click)="tabsContent = 'invoices'">
                  <ui-label>Facturas</ui-label>
                </ui-tab>

                <ui-tab (click)="tabsContent = 'movements'">
                    <ui-label>Cuenta corriente</ui-label>
                </ui-tab>
            </ui-tabs>

            <ui-card>
                <ng-container *ngIf="tabsContent == 'orders' && firstLoad">
                    <div class="ui-orders" *ngIf="ordersData.length > 0">
                        <div class="ui-records-head">
                            <div class="ui-head-order-el">Pedido</div>
                            <div class="ui-head-ammount-el">Importe</div>
                            <div class="ui-head-actions-el"></div>
                        </div>
                        
                        <div class="ui-records-list" *ngIf="ordersData.length > 0">
                            <billing-order [order]="order" [pay]="ordersPayBind" *ngFor="let order of ordersData"></billing-order>

                            <ui-spinner-layer *ngIf="ordersLoading"></ui-spinner-layer>
                        </div>
                    
                        <div class="ui-records-pagination" *ngIf="ordersData.length > 0">
                            <ui-pagination [fetch]="ordersFetchBind" [data]="ordersPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
                        </div>
                    </div>

                    <div class="ui-billing-no-data" *ngIf="ordersData.length <= 0">
                        Todavia no hay pedidos o renovaciones generados.
                    </div>
                </ng-container>

                <ng-container *ngIf="tabsContent == 'invoices' && firstLoad">
                    <div class="ui-invoices" *ngIf="invoicesData.length > 0">
                        <div class="ui-records-head">
                            <div class="ui-head-date-el">Fecha</div>
                            <div class="ui-head-invoice-el">Factura</div>
                            <div class="ui-head-ammount-el">Importe</div>
                            <div class="ui-head-actions-el"></div>
                        </div>

                        <div class="ui-records-list">
                            <billing-invoice [invoice]="invoice" [download]="invoicesDownloadBind"  *ngFor="let invoice of invoicesData"></billing-invoice>
                    
                            <ui-spinner-layer *ngIf="invoicesLoading"></ui-spinner-layer>
                        </div>
                    
                        <div class="ui-records-pagination">
                            <ui-pagination [fetch]="invoicesFetchBind" [data]="invoicesPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
                        </div>
                    </div>

                    <div class="ui-billing-no-data" *ngIf="invoicesData.length <= 0">
                        Todavia no hay facturas emitidas.
                    </div>
                </ng-container>
        
                <ng-container *ngIf="tabsContent == 'movements' && firstLoad">
                    <div class="ui-movements" *ngIf="movementsData.length > 0">
                        <div class="ui-records-head">
                            <div class="ui-head-name-el">Detalle</div>
                            <div class="ui-head-movement-el">Movimiento</div>
                            <div class="ui-head-balance-el">Saldo</div>
                        </div>
                
                        <div class="ui-records-list">
                            <billing-movement [movement]="movement" [pay]="movementsPayBind" [cancel]="movementsCancelBind" *ngFor="let movement of movementsData"></billing-movement>
                            
                            <ui-spinner-layer *ngIf="movementsLoading"></ui-spinner-layer>
                        </div>
                
                        <div class="ui-records-pagination">
                            <ui-pagination [fetch]="movementsFetchBind" [data]="movementsPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
                        </div>
                    </div>

                    <div class="ui-billing-no-data" *ngIf="movementsData.length <= 0">
                        Todavia no hay movimientos generados en tu cuenta.
                    </div>
                </ng-container>
                
                <ui-screen-loader *ngIf="!firstLoad"></ui-screen-loader>
            </ui-card>
        </div>
    </div>
</ui-content>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Utils
import { RoutingGuard } from 'src/app/platform/functions/routing.guard';

// Components
import { UsersComponent } from 'src/app/modules/user/views/users/users.component';
import { UserAccountComponent } from 'src/app/modules/user/views/account/account.component';

const routes: Routes = [
  { path: '', redirectTo: '/user/account', pathMatch: 'full' },
  { path: 'user/account', component: UserAccountComponent },
  { path: 'user/support', component: UserAccountComponent },
  { path: 'users/accounts', component: UsersComponent, canActivate: [RoutingGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }

import { Workspace } from '@app/modules/workspaces/models/workspace';
import { Moment } from 'moment';
import * as moment from 'moment';

// Utils
import { camelcase } from 'src/app/library/utils/functions';

export class BillingOrder {
    id = 0; 
    number = 0; 
    type = '';
    subtotal = 0; 
    taxAmount = 0; 
    total = 0; 
    totalPending = 0; 
    status = 'pending'; 
    details: Array<BillingOrderDetail> = [];
    expiresOn: Date = moment().toDate();
    createdAt: Date = moment().toDate();
    deletedAt: Date = moment().toDate();
    paymentDate: Date = moment().toDate();
    requests: Array<BillingOrderRequest> = [];
    
    workspace = new Workspace();

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }

    private filter(key: string, value: any){
        if(key === 'details'){
            const details: Array<BillingOrderDetail> = [];
            
            if(value.length > 0){
                value.forEach((item: any) => {
                    details.push(new BillingOrderDetail(item));
                });
            }

            return details;
        } else if (key === 'workspace'){
            value = new Workspace(value);
        }

        return value;
    }
    
    get isPending() {
        return this.status === 'pending';
    }

    get typeName() {
        return (this.type === 'renewal') ? 'Renovación' : 'Pedido';
    }
    
    get title() {
        return this.typeName + ' #' + this.number;
    }

    get statusName() {
        const types: any = {
            'paid': 'Abonada',
            'pending': 'Pendiente de pago',
            'expired': 'Vencida',
        };
        
        return types[this.status];
    }
}

export class BillingOrderDetail {
    item = '';
    details = '';
    unity = '';
    quantity = 0;
    price = 0; 
    taxAmount = 0;
    amount = 0;
    description = '';
    createdAt: Date = moment().toDate();

    request: { module: string; data: any } = {module: '', data: {}};

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = value;
                }
            }
        }
    }

    get title() {
        return this.item + ((this.details !== '') ? ' - ' + this.details : '');
    }
}

export class BillingOrderRequest {
    status = '';
    module = '';
    method = '';
    action = '';
    payload = {};
    response = {};

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = value;
                }
            }
        }
    }
}

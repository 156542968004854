import { Component, OnInit } from '@angular/core';

// Models
import { UiModalbox } from 'src/app/library/components/ui-modalbox';

@Component({
  selector: 'app-hostbi-account-add',
  templateUrl: './account-add.component.html',
  styleUrls: ['./account-add.component.scss']
})
export class HostbiAccountAddComponent implements OnInit {
  public closeMethod = this.close.bind(this);
  
  constructor(
    private modalbox: UiModalbox,
  ) { }

  ngOnInit(): void { }

  close(): void {
    this.modalbox.close();
  }

  finish(){
    
  }
}

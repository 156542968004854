<ui-content>
    <div class="ui-workspace" *ngIf="firstLoad">
        <ui-title>Información de la cuenta</ui-title>

        <ui-card class="ui-workspace-data">
            <ui-card-content>
                <div class="ui-data-el">
                    <ui-title>{{workspace.name}}</ui-title>
                    <span>Creada el {{workspace.creatredAt | date: 'dd/MM/YYYY'}}</span>
                </div>

                <div class="ui-menu-el">
                    <ui-button color="tertiary" [matMenuTriggerFor]="contextMenu">
                        <ui-icon name="more_vert" type="outlined" slot="icon-only"></ui-icon>
                    </ui-button>
                    
                    <mat-menu #contextMenu="matMenu">
                        <button mat-menu-item (click)="dataEdit()">Editar datos de facturación</button>
                        <button mat-menu-item (click)="paymentProcess()">Informar pago</button>
                        <button mat-menu-item (click)="ordersGenerate()">Generar una orden</button>
                    </mat-menu>
                </div>
            </ui-card-content>
        </ui-card>

        <ui-card>
            <ui-card-header (click)="showBlock.data = !showBlock.data">
                <ui-title>Datos de facturación</ui-title>

                <ui-icon [name]="(showBlock.data) ? 'expand_less' : 'expand_more'" type="outlined"></ui-icon>
            </ui-card-header>

            <ui-card-content class="data-list" *ngIf="showBlock.data">
                <ul>
                    <li><label>Razón social</label> {{accounting.name}}</li>
                    <li><label>{{accounting.taxType}}</label> {{accounting.taxNumber}}</li>
                    <li><label>Condición fiscal</label> {{accounting.taxCondition}}</li>
                    <li><label>Direccion</label> {{accounting.address}}, {{accounting.location.name}}</li>
                    <li><label>Emisor de comprobantes</label> {{accounting.taxEmitter}} mediante {{accounting.taxAgent}}</li>
                    <li><label>Forma de pago</label> Pago manual mediante <strong>{{accounting.paymentGateway}}</strong> en {{accounting.currency}}</li>
                </ul>
            </ui-card-content>
        </ui-card>

        <ui-card>
            <ui-card-header (click)="showBlock.users = !showBlock.users">
                <ui-title>Usuarios de la cuenta</ui-title>
                
                <ui-icon [name]="(showBlock.users) ? 'expand_less' : 'expand_more'" type="outlined"></ui-icon>
            </ui-card-header>
                
            <ui-card-content *ngIf="showBlock.users">
                <div class="ui-users" *ngIf="workspace.users.length > 0">
                    <div class="ui-records-list">
                        <workspace-user-record [workspaceUser]="user" *ngFor="let user of workspace.users"></workspace-user-record>
                    </div>
                </div>
            </ui-card-content>
        </ui-card>

        <ui-card>
            <ui-card-header (click)="showBlock.services = !showBlock.services">
                <ui-title>Servicios activos</ui-title>

                <ui-icon [name]="(showBlock.services) ? 'expand_less' : 'expand_more'" type="outlined"></ui-icon>
            </ui-card-header>

            <ui-card-content *ngIf="showBlock.services">
                <ng-container *ngIf="servicesData.length > 0">
                    <billing-service [service]="service" [removable]="true" *ngFor="let service of servicesData"></billing-service>
                </ng-container>

                <div class="ui-billing-services-empty" *ngIf="servicesData.length === 0 && !servicesLoading">
                    <ui-icon name="cancel" type="outlined"></ui-icon>
                    <p>Aún no hay servicios activos.</p>
                </div>
            </ui-card-content>
        </ui-card>

        <div class="ui-workspace-billing">
            <ui-tabs>
                <ui-tab (click)="tabsContent = 'orders'" [active]="true">
                <ui-label>Renovaciones y pedidos</ui-label>
                </ui-tab>

                <ui-tab (click)="tabsContent = 'invoices'">
                <ui-label>Facturas</ui-label>
                </ui-tab>

                <ui-tab (click)="tabsContent = 'movements'">
                    <ui-label>Cuenta corriente</ui-label>
                </ui-tab>
            </ui-tabs>

            <ui-card>
                <ui-card-content>
                    <ng-container *ngIf="tabsContent == 'orders'">
                        <div class="ui-orders" *ngIf="ordersData.length > 0">
                            <div class="ui-records-head">
                                <div class="ui-head-order-el">Pedido</div>
                                <div class="ui-head-ammount-el">Importe</div>
                                <div class="ui-head-actions-el"></div>
                            </div>
                            
                            <div class="ui-records-list" *ngIf="ordersData.length > 0">
                                <billing-order [order]="order" [pay]="ordersPayBind" *ngFor="let order of ordersData"></billing-order>

                                <ui-spinner-layer *ngIf="ordersLoading"></ui-spinner-layer>
                            </div>
                        
                            <div class="ui-records-pagination" *ngIf="ordersData.length > 0">
                                <ui-pagination [fetch]="ordersFetchBind" [data]="ordersPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
                            </div>
                        </div>

                        <div class="ui-records-no-data" *ngIf="ordersData.length <= 0">
                            Todavia no hay pedidos o renovaciones generados.
                        </div>
                    </ng-container>

                    <ng-container *ngIf="tabsContent == 'invoices'">
                        <div class="ui-invoices" *ngIf="invoicesData.length > 0">
                            <div class="ui-records-head">
                                <div class="ui-head-date-el">Fecha</div>
                                <div class="ui-head-invoice-el">Factura</div>
                                <div class="ui-head-ammount-el">Importe</div>
                                <div class="ui-head-actions-el"></div>
                            </div>

                            <div class="ui-records-list">
                                <billing-invoice [invoice]="invoice" [download]="invoicesDownloadBind"  *ngFor="let invoice of invoicesData"></billing-invoice>
                        
                                <ui-spinner-layer *ngIf="invoicesLoading"></ui-spinner-layer>
                            </div>
                        
                            <div class="ui-records-pagination">
                                <ui-pagination [fetch]="invoicesFetchBind" [data]="invoicesPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
                            </div>
                        </div>

                        <div class="ui-records-no-data" *ngIf="invoicesData.length <= 0">
                            Todavia no hay facturas emitidas.
                        </div>
                    </ng-container>
            
                    <ng-container *ngIf="tabsContent == 'movements'">
                        <div class="ui-movements" *ngIf="movementsData.length > 0">
                            <div class="ui-records-head">
                                <div class="ui-head-name-el">Detalle</div>
                                <div class="ui-head-movement-el">Movimiento</div>
                                <div class="ui-head-balance-el">Saldo</div>
                            </div>
                    
                            <div class="ui-records-list">
                                <billing-movement [movement]="movement" [pay]="movementsPayBind" [cancel]="movementsCancelBind" *ngFor="let movement of movementsData"></billing-movement>
                                
                                <ui-spinner-layer *ngIf="movementsLoading"></ui-spinner-layer>
                            </div>
                    
                            <div class="ui-records-pagination">
                                <ui-pagination [fetch]="movementsFetchBind" [data]="movementsPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
                            </div>
                        </div>

                        <div class="ui-records-no-data" *ngIf="movementsData.length <= 0">
                            Todavia no hay movimientos generados en tu cuenta.
                        </div>
                    </ng-container>
                </ui-card-content>
            </ui-card>
        </div>
    </div>
    
    <div class="ui-workspace" *ngIf="!firstLoad">
        <ui-screen-loader></ui-screen-loader>
    </div>
</ui-content>
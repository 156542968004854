import { Component, OnInit } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';
import { BillingService } from 'src/app/modules/billing/models/services';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { BillingServicesService } from 'src/app/modules/billing/services/services.service';

@Component({
  selector: 'app-billing-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class BillingServicesComponent implements OnInit {
  public servicesData: Array<BillingService> = [];
  public servicesPagination: PaginationData = new PaginationData(0);
  public servicesFetch = this.fetch.bind(this);
  public servicesDelete = this.delete.bind(this);
  public servicesLoading = false;

  public errors = {
    load: {
      title: 'Cargando servicios',
      message: 'Estamos buscando los servicios de tu cuenta.',
      icon: 'checklist',
      loading: true,
    },
    empty: {
      title: 'Sin serivicios',
      message: 'Todavia no tenes servicios contratados.',
      icon: 'checklist',
    },
    error: {
      title: 'Servicios',
      message: 'No pudimos cargar los servicios de tu cuenta. Estamos trabajando para solucionar este problema.',
      icon: 'checklist',
    }
  };  

  constructor(
    private uiService: UiService,
    private servicesService: BillingServicesService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(page = 1): void{
    this.servicesLoading = true;
    this.uiService.screenLoader(this.errors.load);

    this.servicesService.getAll(page, this.servicesPagination.pageSize).then((success: HttpResponseData) => {
      this.servicesLoading = false;

      if (success.result.records.length > 0) {
        this.servicesData = success.result.records;
        this.servicesPagination.totalUpdate(success.result.recordsTotal);
        this.servicesPagination.pageUpdate(page);
      } else {
        this.uiService.screenLoader(this.errors.empty);
      }
    }).catch(() => {
      this.servicesLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }

  delete(service: BillingService): void {
    this.uiService.alert({
      title: 'Eliminar servicio',
      message: '¿Estás seguro de querer eliminar este servicio? Ya no se facturará en el próximo periodo.',
      width: 300,
      buttons: [
        { text: 'Cancelar', role: 'cancel',  },
        { text: 'Eliminar', role: 'accept', click: () => {
            this.uiService.loader(true);
    
            this.servicesService.delete(service.id).then(() => {   
              this.uiService.loader(false);
              this.uiService.dialog('Eliminar servicio', 'El servicio se eliminó correctamente', 'success');
              
              this.fetch(this.servicesPagination.pageCurrent);
            }).catch((error: HttpResponseData) => {
              this.uiService.loader(false);
              this.uiService.dialog('Eliminar servicio', error.message, 'error');
            });
          }
        }
      ]
    });
  }
}

import { Injectable } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import {
    HostbiAccountDomain,
    HostbiAccountDomainEntity,
    HostbiAccountDomainRecord,
    HostbiAccountDomainRecordEntity
} from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { HostbiService } from '../models/hostbi-service';

@Injectable()
export class HostbiDomainRegisterService {
    public workspace = '';

    constructor(
        private apiService: ApiService,
    ) {}

    whitWorkspace(){
        this.workspace = 'workspace/';

        return this;
    }

    whitoutWorkspace(){
        this.workspace = 'manage/';

        return this;
    }

    getAll(page = 1, pageSize = 10, filter: any = null): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'get',
                'module/hostbi/v1/' + this.workspace + 'domains/?page=' + page + '&pageSize=' + pageSize
            ).toPromise().then((success: any) => {
                if (success.status) {
                    const domainsData: Array<HostbiAccountDomain> = [];

                    success.data.results.forEach((value: any) => {
                        domainsData.push(new HostbiAccountDomain(value));
                    });

                    resolve(this.apiService.successDataPrepare(success.data.message, domainsData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    statusChange(domainId: number, status: 'pending'|'registered'|'active'|'canceled'|'deleted'): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'domains/' + domainId + '/status',
                { status },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    register(domainId: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'domains/' + domainId + '/register',
                { },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    checkExist(domain: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'public/module/hostbi/v1/' + this.workspace + 'domains/exist',
                { domain },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, { status: success.data.exist }));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    checkAvailability(domain: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'public/module/hostbi/v1/' + this.workspace + 'domains/availability',
                { domain },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, { 
                        status: success.data.availability, 
                        service: new HostbiService(success.data.service ?? {})
                    }));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}

import moment from "moment";
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { UiAlertRef } from "src/app/library/components/ui-alert";
import { HostbiAccount, HostbiAccountDomain } from '@modules/hostbi/models/hostbi-account';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UiModalboxService } from 'src/app/library/components/ui-modalbox';
import { HostbiDomainsService } from '@modules/hostbi/services/hostbi-domains.service';
import { HostbiAccountsService } from '@modules/hostbi/services/hostbi-accounts.service';

// Components
import { HostbiAccountPlanComponent } from '@modules/hostbi/components/account-plan/account-plan.component';
import { HostbiAccountDeleteComponent } from '@modules/hostbi/components/account-delete/account-delete.component';
import { HostbiAccountIssuesComponent } from "@modules/hostbi/components/account-issues/account-issues.component";
import { HostbiDomainSslStatusComponent } from "@modules/hostbi/components/domain-ssl-status/domain-ssl-status.component";
import { HostbiAccountDomainAddComponent } from '@modules/hostbi/components/account-domain-add/account-domain-add.component';
import { HostbiAccountDomainRecordFormComponent } from "@modules/hostbi/components/account-domain-record-form/account-domain-record-form.component";

@Component({
  selector: 'app-hostbi-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class HostbiAccountDetailsComponent implements OnInit {
  public accountId = 0;
  public account = new HostbiAccount();
  public blocksExpand: {[key: string] : any} = {
    emails: true,
    domains: true,
  };

  public errors = {
    load: {
      title: 'Cargando datos',
      message: 'Estamos cargando la información de la cuenta.',
      icon: 'public',
      loading: true,
    },
    error: {
      title: 'Cuenta de alojamiento',
      message: 'No es posible obtener los datos de la cuenta en este momento. Estamos trabajando para solucionar el inconveniente.',
      icon: 'public',
    },
    invalid: {
      title: 'Cuenta de alojamiento',
      message: 'Al aprecer, no se accedió correctamente a los detalles de la cuenta. Podes volver al listado de cluentas y seleccionarla nuevamente.',
      icon: 'report_problem',
    }
  };

  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,    
    private uiModalboxService: UiModalboxService,
    private hostbiDomainsService: HostbiDomainsService,
    private hostbiAccountsService: HostbiAccountsService,
  ) {
    this.accountId = Number(this.route.snapshot.paramMap.get('id') ?? 0);
  }

  ngOnInit(): void {
    this.uiService.screenLoader(this.errors.load);

    if(this.accountId > 0){
      const initData = [];
  
      initData.push(this.hostbiAccountsService.whitWorkspace().get(this.accountId));
      initData.push(this.hostbiDomainsService.whitWorkspace().getAll(this.accountId));
      
      Promise.all(initData).then(values => {        
        this.account = values[0].result;
        this.account.domainRecords = values[1].result;     
        
        console.log(this.account);
      }).catch(() => {
        this.uiService.loader(false);
        this.uiService.screenLoader(this.errors.error);
      });
    } else {
      this.uiService.screenLoader(this.errors.invalid);
    }
  }

  // Actions

  login(): void {
    this.uiService.loader(true);

    this.hostbiAccountsService.whitWorkspace().login(this.account.id).then((success: HttpResponseData) => {
      this.uiService.loader(false);
      window.open(success.result.data.url, '_blank');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Error', error.message, 'error');
    });
  }

  domainAdd(): void {
    this.uiModalboxService.open({
      component: HostbiAccountDomainAddComponent,
      width: 400,
      title: 'Agregar un dominio',
      data: {
        account: this.account
      }
    });
  }

  domainDelete(domain: HostbiAccountDomain): void {
    this.uiService.alert({
      title: 'Eliminar dominio',
      message: '¿Estas seguro de querer eliminar <strong>' + domain.domain + '</strong> de tu cuenta?',
      buttons: [
        { role: 'cancel', text: 'Cancelar' },
        { role: 'accept', text: 'Eliminar', color: 'primary-red', click: () => {
          this.uiService.loader(true);

          this.hostbiDomainsService.delete(this.account.id, domain.id).then(() => {
            this.uiService.loader(false);
            this.uiService.dialog('Dominio eliminado', 'El dominio se eliminó correctamente. Tené en cuenta que <strong>esta acción no elimina el dominio en tu cuenta de cPanel</strong>. Para quitarlo, es necesario que puedas ingresar al panel y eliminarlo de forma manual.', 'success');

            const domains = this.account.domainRecords.filter(item => item.id != domain.id);
            
            this.account.domainRecords = domains;
          }, (error: HttpResponseData) => {
            this.uiService.loader(false);
            this.uiService.dialog('Eliminar dominio', error.message, 'error');
          });
        },
      }]
    });
  }

  domainRecordAdd(domain: HostbiAccountDomain): void {
    const modal = this.uiModalboxService.open({
      component: HostbiAccountDomainRecordFormComponent,
      width: 500,
      data: {
        account: this.account,
        domain: domain,
      }
    });

    modal.afterClosed.subscribe((response) => {
      if(typeof response.data !== 'undefined'){
        domain.dns.push(response.data);
      }
    });
  }

  domainSslStatus(domain: HostbiAccountDomain) {
    this.uiService.alert({
      title: 'Encriptación SSL',
      message: 'La encriptación SSL permite que tu sitio sea seguro ya que, cada vez que un visitante carga tu página, todos los datos viajan por internet de forma encriptada.',
      content: HostbiDomainSslStatusComponent,
      data: { status: domain.sslStatus},
      buttons: [
        { text: 'Cancelar', role: 'cancel'},
        { text: 'Modificar', color: 'primary', click: (response: UiAlertRef) => {
          if(typeof response.data.status !== 'undefined' && response.data.status !== ''){
            this.uiService.loader(true);

            this.hostbiDomainsService.sslStatus(this.account.id, domain.id, response.data.status).then(() => {
              response.close();
              domain.sslStatus = response.data.status;

              this.uiService.loader(false);
              this.uiService.dialog('Encriptación SSL', 'Se modificó el estado de la encriptación SSL sobre tu dominio ' + domain.domain, 'success');
            }, () => {
              this.uiService.loader(false);
              this.uiService.dialog('Error', 'No fue posible modificar el estado de la encriptación SSL sobre tu dominio ' + domain.domain, 'error');
            });
          } else {
            this.uiService.snackbar('Seleccioná un estado para continuar.');
          }              
        } }
      ]
    });
  }

  planUpdate(): void {
    this.uiModalboxService.open({
      title: 'Cambiar de plan',
      width: 400,
      data: {
        account: this.account,
      },
      component: HostbiAccountPlanComponent
    });
  }

  cancellation(): void {
    this.uiModalboxService.open({
      title: 'Solicitar baja',
      width: 400,
      data: { account: this.account },
      component: HostbiAccountDeleteComponent
    });
  }
  
  cancellationAbort(): void {
    this.uiService.alert({
      title: 'Cancelar baja',
      message: 'Si continuas, vamos a anular el proceso de baja y tu cuenta permanecerá activa.',
      buttons: [
        { text: 'Cerrar', role: 'cancel' },
        { text: 'Si, cancelar baja', role: 'accept', color: 'primary-red', click: () => {
          this.uiService.loader(true);

          this.hostbiAccountsService.whitWorkspace().cancellationAbort(this.account.id).then(() => {
            this.uiService.loader(false);
            this.account.cancellation = false;
            this.account.cancellationDate = moment().toDate();

            this.uiService.snackbar('La baja fue cancelada.', 'success');
           }, () => {
            this.uiService.loader(false);
            this.uiService.dialog('Cancelar baja', 'No es posible cancelar el proceso de baja en este momento. Te sugerimos que puedas contactarte con soporte técnico para anular la baja.', 'error');
          });
        }}
      ]
    });
  }

  issues(){
    this.uiService.alert({
      title: 'Problemas con la cuenta',
      content: HostbiAccountIssuesComponent,
      data: { issues: this.account.issues },
    });
  }
}

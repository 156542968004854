import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { HttpResponseData, HttpResponseError } from 'src/app/library/models/utils';
import { BillingData, BillingBalance } from 'src/app/modules/billing/models/billing';

// Services
import { ApiService } from 'src/app/library/services/api.service';

@Injectable()
export class BillingPaymentsService {
    public balanceData = new BehaviorSubject<BillingBalance>(new BillingBalance());
    
    constructor(
        private apiService: ApiService,
    ) {}

    details(data: {gateway: string; emitter: string; transaction: string; }): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request(
                'post',
                'module/payments/v1/payments/details',
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data.payment));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success.data));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data.errors)));
            });
        });
    }

    process(data: {gateway: string; emitter: string; transaction: string; }): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request(
                'post',
                'module/payments/v1/payments/process',
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data.status));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success.data));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data.errors)));
            });
        });
    }
}

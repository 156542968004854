import { Component, OnInit } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';
import { BillingInvoice } from 'src/app/modules/billing/models/invoices';

// invoices
import { UiService } from 'src/app/library/services/ui.service';
import { BillingInvoicesService } from 'src/app/modules/billing/services/invoices.service';

// Components
import { BillingDataFormComponent } from 'src/app/modules/billing/components/data-form/data-form.component';

@Component({
  selector: 'app-billing-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class BillingInvoicesComponent implements OnInit {
  public invoicesData: Array<BillingInvoice> = [];
  public invoicesPagination: PaginationData = new PaginationData(0);
  public invoicesFetch = this.fetch.bind(this);
  public invoicesDownload = this.download.bind(this);
  public invoicesLoading = false;

  public errors = {
    load: {
      title: 'Cargando facturas',
      message: 'Estamos buscando las facturas de tu cuenta.',
      icon: 'payment',
      loading: true,
    },
    empty: {
      title: 'Sin facturas',
      message: 'Todavia no hay facturas emitidas.',
      icon: 'payment',
    },
    error: {
      title: 'Facturas',
      message: 'No pudimos cargar las facturas de tu cuenta. Estamos trabajando para solucionar este problema.',
      icon: 'payment',
    }
  };  

  constructor(
    private uiService: UiService,
    private invoicesService: BillingInvoicesService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(page = 1): void{
    this.invoicesLoading = true;
    this.uiService.screenLoader(this.errors.load);

    this.invoicesService.getAll(page, this.invoicesPagination.pageSize).then((success: HttpResponseData) => {
      this.invoicesLoading = false;

      this.invoicesData = success.result.records;
      this.invoicesPagination.totalUpdate(success.result.recordsTotal);
      this.invoicesPagination.pageUpdate(page);

      if (success.result.records.length <= 0) {
        this.uiService.screenLoader(this.errors.empty);
      }
    }).catch(() => {
      this.invoicesLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }

  dataEdit(): void {
    this.uiService.modalbox({
      component: BillingDataFormComponent,
      backdrop: true,
    });
  }

  download(service: BillingInvoice): void {
    this.uiService.loader(true);
    
    this.invoicesService.download(service.id).then((success: HttpResponseData) => {   
      this.uiService.loader(false);
              
      window.open(success.result, '_blank');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Descargar factura', error.message, 'error');
    });
  }
}

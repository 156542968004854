import { Component, Input, OnInit } from '@angular/core';

// Model
import { User } from 'src/app/modules/user/models/user';

@Component({
  selector: 'user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnInit {
  @Input() user: User = new User();

  constructor() { }

  ngOnInit(): void { }
}


<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">{{(formEdit) ? 'Editar registro ' + record.type : 'Agregar registro DNS'}}</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card class="ui-form">
        <div class="ui-card-content">
            <form [formGroup]="formData" class="ui-form"> 
                <ui-field label="Tipo" [validate]="formValidates.type" *ngIf="!formEdit">
                    <select class="ui-input" formControlName="type" (change)="typeChange()">
                        <option *ngFor="let item of recordTypes.types" [value]="item">{{item}}</option>
                    </select>
                </ui-field>
                
                <ui-field [label]="recordTypes.input('name').label" [legend]="recordTypes.input('name').legend" [validate]="formValidates.name" *ngIf="recordTypes.check('name')">
                    <input class="ui-input" type="text" formControlName="name" inputClearSpaces>
                </ui-field>

                <ui-field [label]="recordTypes.input('content').label" [legend]="recordTypes.input('content').legend" [validate]="formValidates.content" *ngIf="recordTypes.check('content')">
                    <input class="ui-input" type="text" formControlName="content">
                </ui-field>

                <ui-field [label]="recordTypes.input('ttl').label" [legend]="recordTypes.input('ttl').legend" [validate]="formValidates.ttl" *ngIf="recordTypes.check('ttl')">
                    <select class="ui-input" formControlName="ttl">
                        <option value="1">Automático</option>
                        <option value="60">1 minuto (60)</option>
                        <option value="120">2 minutos (120)</option>
                        <option value="300">5 minutos (300)</option>
                        <option value="600">10 minutos (600)</option>
                        <option value="900">15 minutos (900)</option>
                        <option value="1800">30 minutos (1800)</option>
                        <option value="3600">1 hora (3600)</option>
                        <option value="7200">2 horas (7200)</option>
                        <option value="18000">5 horas (18000)</option>
                        <option value="43200">12 horas (43200)</option>
                        <option value="86400">24 horas (86400)</option>
                    </select>
                </ui-field>

                <ui-field [label]="recordTypes.input('priority').label" [legend]="recordTypes.input('priority').legend" [validate]="formValidates.priority" *ngIf="recordTypes.check('priority')">
                    <input class="ui-input" type="text" formControlName="priority">
                </ui-field>

                <ui-switch formControlName="proxied" *ngIf="recordTypes.check('proxied')">
                    {{recordTypes.input('proxied').label}}
                </ui-switch>
            </form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button (click)="close()">Cancelar</ui-button>
            <ui-button color="primary" (click)="continue()">{{(formEdit) ? 'Modificar' : 'Agregar'}}</ui-button>       
        </div>
    </ui-card>
</ui-content>
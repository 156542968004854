
<ui-content>
    <div class="ui-order">
        <div class="ui-order-container">
            <div class="ui-order-brand">
                <div class="ui-logo">
                    <div [class]="'el-logo ' + appCurrent.module + '-logo'"></div>
                </div>

                <h1>¡Esto puede ser el comienzo de algo grandioso!</h1>
                <a *ngIf="data.modalbox ?? false" (click)="close()"><ui-icon name="arrow_back"></ui-icon> Volver</a>
            </div>
        
            <div class="ui-order-content">
                <ul class="ui-order-steps">
                    <li [class]="{'active': step === 1, 'done': step > 1}"><span><i>1</i><ui-icon name="done"></ui-icon></span><p> Alta</p></li>
                    <li [class]="{'active': step === 2, 'done': step > 2}"><span><i>2</i><ui-icon name="done"></ui-icon></span><p> Identificación</p></li>
                    <li [class]="{'active': [3, 4].includes(step)}"><span><i>3</i><ui-icon name="done"></ui-icon></span><p> Finalizar</p></li>
                </ul>

                <ui-card class="ui-order-form">
                    <ui-content class="ui-card-content">
                        <ng-container *ngIf="step === 1">
                            <hostbi-account-order *ngIf="appCurrent.module === 'hostbi'" [(order)]="orderData" (orderChange)="order($event)"></hostbi-account-order>
                        </ng-container>

                        <ng-container *ngIf="step === 2">
                            <app-auth-box [(user)]="userData" (login)="login()" [url]="url" [screen]="screen"></app-auth-box>
                        </ng-container>
                        
                        <ng-container *ngIf="step === 3">
                            <ng-container *ngIf="!load && orderType === 'billeable'">
                                <user-box [user]="userData"></user-box>
                                
                                <ng-container *ngIf="!billingData.isSet()">
                                    <h2>Datos de facturación</h2>
                                    <billing-data-form [data]="billingData" [(update)]="billingDataUpdate"></billing-data-form>
                                </ng-container>
                                
                                <h2>Resumen</h2>
                                <div class="ui-resume">
                                    <dl>                        
                                        <ng-container *ngFor="let orderDetail of orderData">
                                            <dt>{{orderDetail.item}} <ui-helper [text]="orderDetail.details" *ngIf="orderDetail.details !== ''"></ui-helper></dt><dd>$ {{orderDetail.amount | number: '1.2-2'}}</dd>
                                        </ng-container>
                                    </dl>
                                        
                                    <dl>
                                        <dt>Subtotal</dt><dd>$ {{orderResume.subtotal | number: '1.2-2'}}</dd>
                                        <dt>IVA</dt><dd>$ {{orderResume.iva | number: '1.2-2'}}</dd>
                                    </dl>

                                    <dl>
                                        <dt>Total a pagar</dt><dd>$ {{orderResume.total | number: '1.2-2'}}</dd>
                                    </dl>
                                </div>
                                
                                <ui-button color="primary" (click)="process()" [disabled]="!isActionable">Finalizar</ui-button>
                            </ng-container>

                            <ui-screen-loader *ngIf="load"></ui-screen-loader>
                        </ng-container>

                        <ng-container *ngIf="step === 4">
                            <ui-screen-loader></ui-screen-loader>
                        </ng-container>
                    </ui-content>
                </ui-card>

                <div class="ui-order-footer">
                    <p>Al usar los servicios de Clousis estas aceptando los <a href="https://clousis.com/politicas/terminos-y-condiciones/" target="_blank">términos y condiciones</a> y las <a href="https://clousis.com/politicas/politicas-de-privacidad/" target="_blank">políticas de privacidad</a>.</p>
                    <div class="el-logo"></div>
                </div>
            </div>
        </div>
    </div>
</ui-content>


<ui-card [ngClass]="mode">
    <div class="ui-balance">
        <ui-header routerLink="/billing/resume/">
            <ui-title>Resumen de cuenta</ui-title>
            <!--<ui-button color="transparent"><ui-icon name="more_vert" type="outlined"></ui-icon></ui-button>
            -->
        </ui-header>
        
        <ui-content routerLink="/billing/resume/">
            <p *ngIf="(data.ordersAmount + data.renewalsAmount) > 0">Pendiente de pago <small>Balance de la cuenta</small></p>
            <p *ngIf="(data.ordersAmount + data.renewalsAmount) <= 0">Tu cuenta esta al día <small>Balance de la cuenta</small></p>
            <span class="ui-price-el"><i>$</i> {{(data.ordersAmount + data.renewalsAmount) | number : '1.2-2'}}</span>
        </ui-content>

        <ui-footer *ngIf="userData.workspace.role === 'owner' && (settings['automaticDebit'] ?? false)">
            <div>{{(data.debitStatus) ? 'Estas adherido al débito automático' : 'Sin débito automático'}}</div>
            <div>
                <ui-button color="transparent" (click)="debitCancel()" *ngIf="data.debitStatus">Cancelar débito</ui-button>
                <ui-button color="secondary" (click)="debitAdd()" *ngIf="!data.debitStatus">Adherir al débito</ui-button>
            </div>
        </ui-footer>
    </div>
    <!--<ul>
        <li>
            <p>{{data.services}} {{(data.services === 1) ? 'servicio activo' : 'servicios activos'}} <span>Importe del próximo mes</span></p>
            <span class="ui-price-el"><i>$</i> {{data.servicesAmount | number : '1.2-2'}}</span>
        </li>
        <li>
            <p>{{data.charges}} {{(data.charges === 1) ? 'cargo pendiente' : 'cargos pendientes'}} <span>Cargo extra del próximo mes</span></p>
            <span class="ui-price-el"><i>$</i> {{data.chargesAmount | number : '1.2-2'}}</span>
        </li>
        <li>
            <p>{{data.credits}} {{(data.credits === 1) ? 'credito pendiente' : 'creditos pendientes'}} <span>Descuento del próximo mes</span></p>
            <span class="ui-price-el"><i>$</i> {{data.creditsAmount | number : '1.2-2'}}</span>
        </li>
    </ul>-->
    <ui-spinner-layer *ngIf="loading"></ui-spinner-layer>
</ui-card>
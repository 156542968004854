import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-status-box',
  templateUrl: './ui-status-box.component.html',
  styleUrls: ['./ui-status-box.component.scss']
})
export class UiStatusBoxComponent implements OnInit {
  @Input() color = '';
  @Output() click = new EventEmitter();
  
  constructor() {}

  ngOnInit(): void { }
}

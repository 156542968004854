<ui-header>
    <ui-title>Registro de dominios</ui-title>

    <div class="ui-domains-filters">
        <ui-field>
            <input class="ui-input" type="text" placeholder="Buscar dominio" (keydown)="domainsSearch($event)" [(ngModel)]="domainsSearchQuery">
            
            <ui-button slot="right" (click)="domainsSearch()">
                <ui-icon slot="icon-only" name="search" type="outlined"></ui-icon>
            </ui-button>
        </ui-field>
    </div>
</ui-header>

<ui-content>
    <div class="ui-domains">
        <div class="ui-domains-list" *ngIf="domainsData.length > 0">
            <hostbi-domain-register class="ui-domain" [domain]="domain" *ngFor="let domain of domainsData"></hostbi-domain-register>

            <ui-spinner-layer *ngIf="domainsLoading"></ui-spinner-layer>
        </div>

        <div class="ui-domains-pagination">
            <ui-pagination [fetch]="domainsFetch" [data]="domainsPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
    </div>

    <ui-screen-loader *ngIf="domainsData.length == 0"></ui-screen-loader>
</ui-content>
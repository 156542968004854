import { UserWorkspace } from "src/app/modules/user/models/workspaces";

export class User {
    id!: string;
    name!: string;
    firstname!: string;
    lastname!: string;
    nameInitials!: string;
    image!: string;
    email!: string;
    phone!: string;
    verified!: boolean;
    workspace: UserWorkspace = new UserWorkspace();
    workspaces: Array<UserWorkspace> = [];

    constructor(parameters?: any) {
        if(typeof parameters != 'undefined' && parameters !== null){
            this.id = parameters.id;
            this.name = parameters.firstname + ' ' + parameters.lastname;
            this.firstname = parameters.firstname;
            this.lastname = parameters.lastname;
            this.nameInitials = ((this.name.split(/\s/).reduce((response, word) => response += word.slice(0,1), '')).toUpperCase()).substring(0, 2);
            this.image = parameters.image;
            this.email = parameters.email;
            this.phone = parameters.phone;
            this.verified = parameters.verified;

            if(typeof parameters.workspaces != 'undefined' && parameters.workspaces.length > 0){
                parameters.workspaces.forEach((workspace: any) => {
                    const workspaceData = new UserWorkspace(workspace);

                    this.workspaces.push(workspaceData);

                    if(workspace.id === parameters.workspace){
                        this.workspace = workspaceData;
                    }
                });
            }
        }
    }  

    /**
     * Checks if the permissions passed as parameters are present in the permissions of the current workspace.
     * With only one match it will be considered allowed
     * 
     * @param Array<string> permissions 
     * @returns boolean
     */
    permissionsCheck(permissions: Array<string>): boolean{
        let isPermitted = false;

        if(typeof this.workspace.permissions !== 'undefined'){
            if(permissions.length > 0){
                isPermitted = this.workspace.permissions.some(perm => permissions.includes(perm));
            } else {
                isPermitted = true;
            }
        }

        return isPermitted;
    }

    workspacesApp(appName: string): Array<UserWorkspace> {
        const workspacesApp: Array<UserWorkspace> = [];

        if(typeof this.workspaces !== 'undefined'){
            this.workspaces.forEach((workspace) => {
                workspace.apps.forEach((app) => {
                    if(app.module == appName && workspace.id != this.workspace.id/* && workspace.status == 'acepted'*/){
                        workspacesApp.push(workspace);
                    }
                })
            })
        }

        return workspacesApp;
    }

    workspacesRemove(id: string): Array<UserWorkspace> {
        if(typeof this.workspaces !== 'undefined'){
            const workspaces: Array<UserWorkspace> = [];

            this.workspaces.forEach((workspace) => {
                if(workspace.id !== id){
                    workspaces.push(workspace);
                }
            });

            this.workspaces = workspaces;
        }

        return this.workspaces;
    }

    isSet(){
        return typeof this.id !== 'undefined' && this.id !== '';
    }
    
    isSessionSet(){
        return this.isSet() && this.workspace.isSet() && typeof this.verified !== 'undefined' && this.verified === true;
    }
}















export class HostbiServerData {
    id: number;
    name: string;
    description: string;
    ipv4: string;
    ipv6: string;
    dns: Array<string>;
    host: string;
    info: object;
    status: HostbiStatusData;
    services: Array<HostbiServerServiceData>;

    constructor() {
        this.id = 0;
        this.name = '';
        this.description = '';
        this.ipv4 = '';
        this.ipv6 = '';
        this.dns = [];
        this.host = '';
        this.info = this.infoVarSet();
        this.status = new HostbiStatusData();
        this.services = [];
    }

    configData(parameters: any): HostbiServerData {
        this.id = parameters.id;
        this.name = parameters.name;
        this.description = parameters.description;
        this.ipv4 = parameters.ipv4;
        this.ipv6 = parameters.ipv6;
        this.dns = parameters.dns;
        this.host = parameters.host;
        this.info = this.infoVarSet(parameters.info);
        this.status = new HostbiStatusData().configData(parameters.status);

        parameters.services.forEach((service: any, index: number) => {
            this.services.push(new HostbiServerServiceData().configData(service));
        });

        return this;
    }

    infoVarSet(parameters: any = {}): object{
        const defaultParams: any = {
            packages: [],
            actions: []
        };
        
        Object.keys(parameters).forEach((param) => {
            Object.keys(defaultParams).forEach((defaultParam) => {
                if(param == defaultParam){
                    defaultParams[param] = parameters[param];
                } else {
                    // eslint-disable-next-line no-self-assign
                    defaultParams[param] = defaultParams[param];
                }
            });
        })

        return defaultParams;
    }
}

export class HostbiServerServiceData {
    name: string;
    port: number;
    status: boolean;

    constructor() {
        this.name = '';
        this.port = 0;
        this.status = false;
    }

    configData(parameters: any): HostbiServerServiceData {
        this.name = parameters.name;
        this.port = parameters.port;
        this.status = parameters.status;

        return this;
    }
}


export class HostbiAccountData {
    id: number;
    account: number;
    plan: HostbiPlanData;
    server: HostbiServerData;
    domain: string;
    user: string;
    password: string;
    status: HostbiStatusData;
    price: number;
    dateChange: string;
    storage: HostbiAccountResourceData;
    bandwich: HostbiAccountResourceData;
    autossl: boolean;
  
    constructor() {
        this.id = 0;
        this.account = 0;
        this.plan = new HostbiPlanData();
        this.server = new HostbiServerData();
        this.domain = '';
        this.user = '';
        this.password = '';
        this.status = new HostbiStatusData();
        this.price = 0;
        this.dateChange = '';
        this.storage = new HostbiAccountResourceData();
        this.bandwich = new HostbiAccountResourceData();
        this.autossl = false;
    }



    configData(parameters: any): HostbiAccountData {
        this.id = parameters.id;
        this.account = parameters.account;
        this.plan = new HostbiPlanData().configData(parameters.plan);
        this.server = new HostbiServerData().configData(parameters.server);
        this.domain = parameters.domain;
        this.user = parameters.user;
        this.password = parameters.password;
        this.status = new HostbiStatusData().configData(parameters.status);
        this.price = parameters.price;
        this.dateChange = parameters.dateChange;
        this.storage = new HostbiAccountResourceData().configData(parameters.storage);
        this.bandwich = new HostbiAccountResourceData().configData(parameters.bandwich);
        this.autossl = parameters.autossl;
  
        return this;
    }
  }
  
    
export class HostbiAccountResourceData {
    total: number;
    usage: number;
    unity: string;
    usagePercent: number;
  
    constructor() {
        this.total = 0;
        this.usage = 0;
        this.unity = '';
        this.usagePercent = 0;
    }
  
    configData(parameters: any): HostbiAccountResourceData {
        this.total = parameters.total;
        this.usage = parameters.usage;
        this.unity = parameters.unity;
        this.usagePercent = parameters.usagePercent;        
  
        return this;
    }
  }

export class HostbiPlanData {
    id: number;
    name: string;
    package: string;
    price: number;
    bandwich: number;
	storage: number;
    server: HostbiServerData;

    constructor() {
        this.id = 0;
        this.name = '';
        this.package = '';
        this.price = 0;
        this.bandwich = 0;
        this.storage = 0;
        this.server = new HostbiServerData();
    }

    configData(parameters: any): HostbiPlanData {
        this.id = parameters.id;
        this.name = parameters.name;
        this.package = parameters.package;
        this.price = parameters.price;
        this.bandwich = parameters.bandwich;
        this.storage = parameters.storage;
        this.server = new HostbiServerData().configData(parameters.server);

        return this;
    }
}

export class HostbiServiceData {
    id: number;
    name: string;
    price: number;
    action: HostbiServiceActionData;
    actionParams: object;

    constructor() {
        this.id = 0;
        this.name = '';
        this.price = 0;
        this.action = new HostbiServiceActionData();
        this.actionParams = {};
    }

    configData(parameters: any): HostbiServiceData {
        this.id = parameters.id;
        this.name = parameters.name;
        this.price = parameters.price;
        this.action = new HostbiServiceActionData().configData(parameters.action);
        this.actionParams = parameters.parameters;

        return this;
    }
}

export class HostbiServiceActionData {
    id: string;
    name: string;
    parameters: object;

    constructor() {
        this.id = '';
        this.name = '';
        this.parameters = {};
    }

    configData(parameters: any): HostbiServiceActionData {
        this.id = parameters.id;
        this.name = parameters.name;
        this.parameters = parameters.parameters;

        return this;
    }
}

export class HostbiStatusData {
    status: string;
    statusReason: string;
    statusMessage: string;

    constructor() {
        this.status = '';
        this.statusReason = '';
        this.statusMessage = '';
    }

    configData(parameters: any): HostbiStatusData {
        this.status = parameters.status;
        this.statusReason = parameters.statusReason;
        this.statusMessage = parameters.statusMessage;

        return this;
    }
}
import { Component, OnInit } from '@angular/core';

// Models
import { Module } from 'src/app/platform/models/module';
import { isEmpty } from 'src/app/library/utils/functions';
import { HttpResponseData } from 'src/app/library/models/utils';
import { HostbiAccount } from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { ModuleService } from 'src/app/platform/services/module.service';
import { HostbiAccountsService } from 'src/app/modules/hostbi/services/hostbi-accounts.service';
import { UiModalboxCloseEvent, UiModalboxService } from 'src/app/library/components/ui-modalbox';

// Components
import { Router } from '@angular/router';
import { PaginationData } from 'src/app/library/components/ui-pagination';
import { HostbiAccountFormComponent } from '../../components/account-form/account-form.component';

@Component({
  selector: 'app-hostbi-accounts-manage',
  templateUrl: './accounts-manage.component.html',
  styleUrls: ['./accounts-manage.component.scss']
})
export class HostbiAccountsManageComponent implements OnInit {
  public moduleData: Module | undefined;

  public accountsSearchQuery = '';
  public accountsSearchWorkspace = 0;
  public accountsSearchServer = 0;
  public accountsData: Array<HostbiAccount> = [];
  public accountsPagination: PaginationData = new PaginationData(0);
  public accountsFetch = this.accountsList.bind(this);
  public accountsLoading = false;

  public errors = {
    load: {
      title: 'Cuentas del servicio web',
      message: 'Buscá una cuenta de alojamiento por su dominio.',
      icon: '',
    },
    empty: {
      title: 'Cuentas del servicio web',
      message: 'No existen cuentas del servicio web alojadas.',
      icon: 'dns',
    },
    search: {
      title: 'Cuentas no encontrada',
      message: 'No existen cuentas con esos términos de búsqueda.',
      icon: 'dns',
    },
    error: {
      title: 'Cuentas del servicio web',
      message: 'No se pudieron cargar las cuentas del servicio web. Intentar nuevamente.',
      icon: 'dns',
    }
  };

  constructor(
    private router: Router,
    private uiService: UiService,
    private hostbiAccountsService: HostbiAccountsService,
    private modulesService: ModuleService,
  ) {
    this.modulesService.current.subscribe((module: Module) => {
      this.moduleData = module
    });
  }

  ngOnInit(): void { }

  accountsSearch(event?: any){
    if(typeof event === 'undefined' || event.code == 'Enter'){
      this.accountsList();
    }
  }

  accountsList(page = 1): void{
    this.accountsLoading = true;
    this.uiService.screenLoader(this.errors.load);

    const filters = {
      search: this.accountsSearchQuery,
      workspace: this.accountsSearchWorkspace,
      server: this.accountsSearchServer,
    };
    
    this.hostbiAccountsService.whitoutWorkspace().getAll(page, this.accountsPagination.pageSize, filters).then((success: HttpResponseData) => {
      this.accountsLoading = false;
      
      if (success.result.length > 0) {
        this.accountsData = success.result;
        this.accountsPagination.totalUpdate(success.result.length);
        this.accountsPagination.pageUpdate(1);
      } else {
        this.accountsData = [];
        this.accountsPagination.totalUpdate(0);
        this.accountsPagination.pageUpdate(1);
        this.uiService.screenLoader((!isEmpty(filters.search)) ? this.errors.search : this.errors.empty);
      }
    }).catch(() => {
      this.accountsLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }

  accountsCreate(): void {
    const modal = this.uiService.modalbox({
      component: HostbiAccountFormComponent,
      backdrop: true,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    modal.afterClosed.subscribe((response: UiModalboxCloseEvent<any>) => {
      if(response.data.id && response.data.id > 0){
        this.router.navigate(['/hostbi/accounts/' + response.data.id]);
      }
    })
  }
}

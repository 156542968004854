import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

// Utils
import { platformUrl } from 'src/app/platform/functions/platform';
import { formValidate, formValidateInit, validateEmail } from 'src/app/library/utils/form-validators';
import { isEmpty } from 'src/app/library/utils/functions';
import { User } from 'src/app/modules/user/models/user';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent implements OnInit {
  @Input() user: User = new User();
  @Output() userChange: EventEmitter<User> = new EventEmitter();
  @Output() screen: EventEmitter<string> = new EventEmitter();
  
  public params: any;
  
  public formActivation = { loading: false, disabled: false, timing: 0, countdown: '02:00' };
  public formData: UntypedFormGroup;
  public formLoading = false;
  public formValidates: any;
  public formInputsErrors: object = {
    email: {
      invalid:  'El email es inválido.',
    },
    code: { },
  };

  constructor(
    private router: Router,
    private uiService: UiService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
  ) { 
    if(this.user.isSet() && this.user.verified){
      this.screen.emit('workspaces');
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    });

    this.formData = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required, validateEmail()]),
      code: new UntypedFormControl((this.params.code !== '') ? this.params.code : '', [Validators.required]),
    });

    this.formValidates = formValidateInit(this.formData);
  }

  ngOnInit(): void {
    this.formData.patchValue({ email: (!isEmpty(this.user.email)) ? this.user.email : '' });
  }

  screenChange(screen: string){
    this.screen.emit(screen);
  }

  activation(){
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    

    if (this.formData.valid) {
      this.formLoading = true;

      this.userService.activation(
        this.formData.value.email,
        this.formData.value.code,
      ).then((success: HttpResponseData) => {   
        this.formLoading = false;
        
        this.uiService.snackbar(success.message);

        this.user.verified = true;

        this.userChange.emit(this.user);        
        this.screen.emit(this.user.isSessionSet() ? 'finish' : (this.user.isSet() ? 'workspaces' : 'login'));
      }).catch((error: HttpResponseData) => {
        this.formLoading = false;

        this.uiService.snackbar(error.message);
      });
    }
  }
  
  resend(): void {
    this.formActivation.loading = true;
    
    this.userService.activationSend(platformUrl('auth/activation?code=')).then(() => {   
      this.formActivation.loading = false;
      this.formActivation.disabled = true;
      this.formActivation.timing = 120;
      this.formActivation.countdown = '02:00';

      const timer = setInterval(() => {
        if(this.formActivation.timing <= 0){
          this.formActivation.disabled = false;

          clearInterval(timer);
        }
        this.formActivation.timing -= 1;
        this.formActivation.countdown = moment.utc(moment.duration(this.formActivation.timing, 'seconds').asMilliseconds()).format('mm:ss');
      }, 1000)
      
    }).catch((error: HttpResponseData) => {
      this.formActivation.loading = false;
      this.formActivation.disabled = false;
      this.formActivation.timing = 0;
  
      this.uiService.snackbar(error.message);
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

// Utils
import { ObjectCompact, getAllUrlParams } from 'src/app/library/utils/functions';

// Models
import { HttpOptionsData, HttpResponseData, HttpResponseError } from 'src/app/library/models/utils';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public sessionValidation = new BehaviorSubject<boolean>(true);
  public options: HttpOptionsData = new HttpOptionsData();

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Execute a call to the api
   */
  request(method: string, path: string, data: any = {}, options?: HttpOptionsData, apiUrl: string | null = null): Observable<object> {
    this.options = new HttpOptionsData({
      headers: new HttpHeaders(),
      params: new HttpParams(),
      responseType: 'json',
      withCredentials: environment.withCredentials,
    });
    
    if(typeof options != 'undefined'){
      this.options = {...this.options, ...options};
    }

    this.options.headers = (this.options.headers instanceof HttpHeaders) ? this.options.headers : new HttpHeaders(this.options.headers);

    if (method === 'post' || method === 'put' || method === 'delete') {
      if (this.options.headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
        const bodySetup = new URLSearchParams(ObjectCompact(data));

        this.options.body = bodySetup.toString();
      } else {
        this.options.body = data;
      }
    } else if (method === 'get') {
      this.options.params = new HttpParams(data);
    }
    
    this.sessionValidation.next(true);   

    let url = ((apiUrl !== null && apiUrl !== '') ? apiUrl : environment.apiUrl) + path;
    
    if(url.split('?')[1] != ''){
      const parameters: any = getAllUrlParams(url);

      //parameters['sleep'] = 3;

      url = url.split('?')[0] + '?' + this.paramsBuild(parameters);
    }

    const optionsSet: any = Object.assign({}, this.options);

    return this.http.request(method, url, optionsSet).pipe(
      catchError(error => {
        throw this.responseInterceptor(error, this);
      })
    )
  }

  paramsSet(params: {[param: string]: string | number | boolean}): void{
    this.options.params = new HttpParams({fromObject: params});
  }

  paramsBuild(params: any): string {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((paramName: string) => {
      if(Array.isArray(params[paramName])){
        params[paramName].forEach((paramValue: any) => {
          searchParams.append(paramName, paramValue)
        })
      } else {
        searchParams.append(paramName, params[paramName]);
      }
    })

    return searchParams.toString();
  }

  paramsFilter(filters: {[key:string]: any}|undefined): {[key:string]: any} {
    if(typeof filters !== 'undefined'){
      const filtered: any = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v !== null && Number(v) !== 0 && v !== ''));

      if(Object.keys(filtered).length > 0){
        return filtered;
      }
    }

    return {};
  }

  /**
   * Prepare and format an success response
   */
  successDataPrepare(message: string, result: any): HttpResponseData{
    const response = new HttpResponseData(true, message, result);

    // console.log('HANDLE SUCCESS', response);

    return response;
  }

  /**
   * Prepare and format an error response
   */
  errorDataPrepare(message: string, result: any): HttpResponseData{
    const errors: Array<string> = [];
    
    if(result instanceof HttpResponseError){
      result.errors.forEach(error => {
        errors.push(error.description);
      });
    }    

    const response = new HttpResponseData(false, message + ((errors.length > 0) ? ' (' + errors.join(' | ') + ')' : ''), result);

    // console.log('HANDLE ERROR', response);

    return response;
  }

  /**
   * Intercepts api calls and parses headers to execute actions
   */
  private responseInterceptor(error: HttpErrorResponse, service: ApiService): object {  
    let logTitle = 'API';
    let logMessage = '';
    let response = {status: false, data: {message: 'Ocurrió un error al solicitar la información. Estamos trabajando para solucionar este problema.'}};

    if (error.status === 401 && this.sessionValidation.value === true) {
      logTitle = 'TOKEN EXPIRATION';

      this.sessionValidation.next(false);
    } else if (error.status === 0) {
      logTitle = 'BACKEND NOT AVAILABLE';

      response = {status: false, data: {message: 'El servicio no esta disponible momentáneamente. Estamos trabajando para solucionar este problema.'}};
    }

    if (error.error instanceof ErrorEvent) {
      logMessage = 'An error occurred:', error.error.message;      
    } else {
      logMessage = 'Backend returned code ' + error.status + ', body was: ' + error.message;
    }

    console.error('%c \u2718 ' + logTitle + ': ' + logMessage, 'font-weight:bold;');
    
    return response;
  }
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor { 
  
  constructor(private tokenExtractor: HttpXsrfTokenExtractor) { }  
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const CSRFToken = this.tokenExtractor.getToken() as string;

    if (CSRFToken !== null && !request.headers.has('X-XSRF-TOKEN')) {
      request = request.clone({ 
        headers: request.headers.set('X-XSRF-TOKEN', CSRFToken)
      });
    }

    if(!request.headers.has('Content-Type') && request.method === 'POST'){
      request = request.clone({ 
        headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded')
      });
    }

    request = request.clone({
      withCredentials: environment.withCredentials,
    });

    return next.handle(request);
  }
}
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

// Utils
import { HttpResponseData } from 'src/app/library/models/utils';
import { platformUrl } from 'src/app/platform/functions/platform';
import { UiModalbox } from 'src/app/library/components/ui-modalbox';

// Models
import { User } from 'src/app/modules/user/models/user';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';

// Components
import { SupportFormComponent } from 'src/app/modules/user/views/support-form/support-form.component';

@Component({
  selector: 'news-modal',
  templateUrl: './news-modal.component.html',
  styleUrls: ['./news-modal.component.scss']
})
export class NewsModalComponent implements OnInit {
  public userData: User = new User(); 
  public assets: string = environment.assets;

  constructor(
    private uiService: UiService,
    private uiModalbox: UiModalbox,
    private userService: UserService,
    private billingAccountingService: BillingAccountingService
  ) {
    this.userService.userData.subscribe((user: User) => {
      this.userData = user;
    });
  }

  ngOnInit(): void { }

  close(){
    this.uiModalbox.close();
  }

  action(){
    this.billingAccountingService.debitAdd(this.userData);
  }

  support(): void {
    this.uiService.modalbox({
      component: SupportFormComponent,
      backdrop: true,
      width: 600,
    });
  }
}

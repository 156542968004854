import { Component, OnInit } from '@angular/core';

// Models
import { isEmpty } from 'src/app/library/utils/functions';
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';
import { HostbiMonitoring } from 'src/app/modules/hostbi/models/hostbi-monitoring';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiMonitoringService } from 'src/app/modules/hostbi/services/hostbi-monitoring.service';

@Component({
  selector: 'app-hostbi-monitoring-manage',
  templateUrl: './monitoring-manage.component.html',
  styleUrls: ['./monitoring-manage.component.scss']
})
export class HostbiMonitoringManageComponent implements OnInit {
  public monitoringSearchQuery = '';
  public monitoringSearchWorkspace = 0;
  public monitoringSearchServer = 0;
  public monitoringData: Array<HostbiMonitoring> = [];
  public monitoringPagination: PaginationData = new PaginationData(0);
  public monitoringFetch = this.monitoringList.bind(this);
  public monitoringLoading = false;

  public errors = {
    load: {
      title: 'Cuentas de monitoreo',
      message: 'Cargando cuentas de monitoreo.',
      icon: 'browse_activity',
      loading: true,
    },
    empty: {
      title: 'Cuentas de monitoreo',
      message: 'No existen cuentas de monitoreo.',
      icon: 'browse_activity',
    },
    search: {
      title: 'Cuenta no encontrada',
      message: 'No existen cuentas con esos términos de búsqueda.',
      icon: 'browse_activity',
    },
    error: {
      title: 'Cuentas de monitoreo',
      message: 'No se pudieron cargar las cuentas de monitoreo. Intentar nuevamente.',
      icon: 'browse_activity',
    }
  };

  constructor(
    private uiService: UiService,
    private hostbiMonitoringService: HostbiMonitoringService,
  ) { }

  ngOnInit(): void {
    this.monitoringList();
  }

  monitoringSearch(event?: any){
    if(typeof event === 'undefined' || event.code == 'Enter'){
      this.monitoringList();
    }
  }

  monitoringList(page = 1): void{
    this.monitoringLoading = true;
    this.uiService.screenLoader(this.errors.load);

    const filters = {
      search: this.monitoringSearchQuery,
    };
    
    this.hostbiMonitoringService.whitoutWorkspace().getAll(page, this.monitoringPagination.pageSize, filters).then((success: HttpResponseData) => {
      this.monitoringLoading = false;
      
      if (success.result.records.length > 0) {
        this.monitoringData = success.result.records;
        this.monitoringPagination.totalUpdate(success.result.recordsTotal);
        this.monitoringPagination.pageUpdate(1);
      } else {
        this.monitoringData = [];
        this.monitoringPagination.totalUpdate(0);
        this.monitoringPagination.pageUpdate(1);
        this.uiService.screenLoader((!isEmpty(filters.search)) ? this.errors.search : this.errors.empty);
      }
    }).catch(() => {
      this.monitoringLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }
}

import { Injectable, Injector, Type } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

// Services
import { UiModalbox } from 'src/app/library/components/ui-modalbox/ui-modalbox.ref';

// Components
import { UiModalboxComponent } from 'src/app/library/components/ui-modalbox/ui-modalbox.component';

export interface UiModalboxOptions {
    backdrop?: boolean;
    backdropClass?: string;
    panelClass?: string;
    width?: number;
    title?: string;
    data?: any;
    component: any;
}

const DEFAULT_CONFIG: UiModalboxOptions = {
    backdrop: true,
    backdropClass: 'ui-backdrop-modal',
    panelClass: 'ui-overlay-modal',
    width: 350,
    title: '',
    data: {},
    component: ''
};

@Injectable({
    providedIn: 'root'
})
export class UiModalboxService {
    private overlayRef: OverlayRef | undefined;

    constructor(
        private overlay: Overlay,
        private injector: Injector
    ) { }

    open<R = any, T = any>(options: UiModalboxOptions): UiModalbox<R> {
        this.overlayRef = this.overlay.create(this._config({ ...DEFAULT_CONFIG, ...options }));

        const dataSet: any = { options: { ...DEFAULT_CONFIG, ...options }, data: options.data };

        const uiModalboxRef = new UiModalbox<R, T>(this.overlayRef, options.component, dataSet);
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: UiModalbox,
                    useValue: uiModalboxRef
                }
            ]
        });

        this.overlayRef.attach(new ComponentPortal(UiModalboxComponent, null, injector));

        return uiModalboxRef;
    }

    private _config(config: UiModalboxOptions): OverlayConfig {
        const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

        const overlayConfig = new OverlayConfig({
            hasBackdrop: config.backdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }
}

import { Component, Input, OnInit } from '@angular/core';

// Models
import { SupportPost } from 'src/app/modules/user/models/support';

@Component({
  selector: 'support-post',
  templateUrl: './support-post.component.html',
  styleUrls: ['./support-post.component.scss']
})
export class SupportPostComponent implements OnInit {
  @Input() post = new SupportPost();

  constructor( ) { }

  ngOnInit(): void { }
}

<div class="ui-notification" [ngClass]="{'ui-notification-unread': data.status == 'pending'}">
    <div class="ui-notification-container" (click)="open(data)">
      <span class="ui-notification-title">
        {{data.subject}}
      </span> 
      
      <span class="ui-notification-date">{{data.dateCreate | dateAgo}}</span>            

      <span class="ui-notification-content">{{data.message}}</span>                  
    
      <div class="ui-notification-actions" *ngIf="data.actions.length > 0">
        <ui-button *ngFor="let actions of data.actions; let name = index">{{name}}</ui-button>
      </div>
    </div>

    <div class="ui-notification-more">
      <ui-button color="transparent" *ngIf="data.status == 'pending'" (click)="read(data)" [disabled]="data.loading">
        <ui-icon name="done" type="outlined" slot="icon-only" *ngIf="!data.loading"></ui-icon>
        <ui-spinner class="ui-spinner" color="white" diameter="20" slot="icon-only" *ngIf="data.loading"></ui-spinner>
      </ui-button>
    </div>
</div>
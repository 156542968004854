import { Component, OnInit } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { platformUrl } from 'src/app/platform/functions/platform';
import { BillingOrder } from 'src/app/modules/billing/models/orders';
import { BillingService } from 'src/app/modules/billing/models/services';
import { PaginationData } from 'src/app/library/components/ui-pagination';

// orders
import { UiService } from 'src/app/library/services/ui.service';
import { BillingInvoice } from 'src/app/modules/billing/models/invoices';
import { BillingMovement } from 'src/app/modules/billing/models/movements';
import { BillingOrdersService } from 'src/app/modules/billing/services/orders.service';
import { BillingInvoicesService } from 'src/app/modules/billing/services/invoices.service';
import { BillingServicesService } from 'src/app/modules/billing/services/services.service';
import { BillingMovementsService } from 'src/app/modules/billing/services/movements.service';

// Components
import { BillingDataUpdateFormComponent } from 'src/app/modules/billing/components/data-update-form/data-update-form.component';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingBillingComponent implements OnInit {
  public tabsContent = 'orders';

  public servicesData: Array<BillingService> = [];
  public servicesPagination: PaginationData = new PaginationData(0);
  public servicesFetchBind = this.servicesFetch.bind(this);
  public servicesLoading = false;

  public ordersData: Array<BillingOrder> = [];
  public ordersPagination: PaginationData = new PaginationData(0);
  public ordersFetchBind = this.ordersFetch.bind(this);
  public ordersPayBind = this.ordersPay.bind(this);
  public ordersLoading = false;

  public invoicesData: Array<BillingInvoice> = [];
  public invoicesPagination: PaginationData = new PaginationData(0);
  public invoicesFetchBind = this.invoicesFetch.bind(this);
  public invoicesDownloadBind = this.invoicesDownload.bind(this);
  public invoicesLoading = false;

  public movementsData: Array<BillingMovement> = [];
  public movementsPagination: PaginationData = new PaginationData(0);
  public movementsFetchBind = this.movementsFetch.bind(this);
  public movementsPayBind = this.movementsPay.bind(this);
  public movementsCancelBind = this.movementsCancel.bind(this);
  public movementsLoading = false;

  public firstLoad = false;

  constructor(
    private uiService: UiService,
    private ordersService: BillingOrdersService,
    private invoicesService: BillingInvoicesService,
    private servicesService: BillingServicesService,
    private movementsService: BillingMovementsService,
  ) {}

  ngOnInit(): void {
    this.servicesFetch();

    const initData = [];
  
    initData.push(this.ordersFetch());
    initData.push(this.invoicesFetch());
    initData.push(this.movementsFetch());

    this.uiService.screenLoader({
      title: 'Cargando pedidos y renovaciones',
      message: 'Estamos buscando las pedidos y renovaciones de tu cuenta.',
      icon: 'currency_exchange',
      loading: true,
    });
    
    Promise.all(initData).then(values => {
      this.firstLoad = true;
    });
  }

  dataEdit(): void {
    this.uiService.modalbox({
      component: BillingDataUpdateFormComponent,
      backdrop: true,
    });
  }

  // Services
  
  servicesFetch(page = 1): void{
    this.servicesLoading = true;

    this.servicesService.getAll(page, this.servicesPagination.pageSize).then((success: HttpResponseData) => {
      this.servicesLoading = false;

      if (success.result.records.length > 0) {
        this.servicesData = success.result.records;
        this.servicesPagination.totalUpdate(success.result.recordsTotal);
        this.servicesPagination.pageUpdate(page);
      }
    }).catch(() => {
      this.servicesLoading = false;
    });
  }  

  // Orders

  ordersFetch(page = 1): Promise<void> {
    return new Promise((resolve, reject) => { 
      this.ordersLoading = true;

      this.ordersService.getAll(page, this.ordersPagination.pageSize).then((success: HttpResponseData) => {
        this.ordersLoading = false;

        if (success.result.records.length > 0) {
          this.ordersData = success.result.records;
          this.ordersPagination.totalUpdate(success.result.recordsTotal);
          this.ordersPagination.pageUpdate(page);
        }

        resolve();
      }).catch(() => {
        this.ordersLoading = false;

        reject();
      });
    });
  }

  ordersPay(service: BillingOrder): void {
    this.uiService.loader(true);
    
    this.ordersService.paymentLink(service.id, platformUrl('/billing/resume/')).then((success: HttpResponseData) => {   
      this.uiService.loader(false);
      this.uiService.alert({
        title: 'Pagar',
        message: `
          <p>Vamos a abrir una nueva ventana para que puedas realizar el pago.
          <strong>Revisá que tu navegador no esté bloqueando las ventanas emergentes.</strong>.</p>
          <p>Si tenes problemas para abrir la ventana de pago podes hacer 
          <a href="` + success.result + `" target="_blank" style="text-decoration:underline;font-weight:500;">click aca</a></p>
        `,
        width: 300,
        buttons: [
          { text: 'Cerrar', role: 'cancel',  },
          { text: 'Ir a pagar', role: 'accept', color: 'primary', click: () => { window.open(success.result, '_blank'); } }
        ]
      });
              
      window.open(success.result, '_blank');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Pagar', error.message, 'error');
    });
  }

  // Invoices

  invoicesFetch(page = 1): Promise<void> {
    return new Promise((resolve, reject) => { 
      this.invoicesLoading = true;

      this.invoicesService.getAll(page, this.invoicesPagination.pageSize).then((success: HttpResponseData) => {
        this.invoicesLoading = false;

        if (success.result.records.length > 0) {
          this.invoicesData = success.result.records;
          this.invoicesPagination.totalUpdate(success.result.recordsTotal);
          this.invoicesPagination.pageUpdate(page);
        }

        resolve();
      }).catch(() => {
        this.invoicesLoading = false;
        
        reject();
      });
    });
  }

  invoicesDataEdit(): void {
    this.uiService.modalbox({
      component: BillingDataUpdateFormComponent,
      backdrop: true,
    });
  }

  invoicesDownload(service: BillingInvoice): void {
    this.uiService.loader(true);
    
    this.invoicesService.download(service.id).then((success: HttpResponseData) => {   
      this.uiService.loader(false);
              
      window.open(success.result, '_blank');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Descargar factura', error.message, 'error');
    });
  }

  // Movements

  movementsFetch(page = 1): Promise<void> {
    return new Promise((resolve, reject) => { 
      this.movementsLoading = true;

      this.movementsService.getAll(page, this.movementsPagination.pageSize).then((success: HttpResponseData) => {
        this.movementsLoading = false;

        if (success.result.records.length > 0) {
          this.movementsData = success.result.records;
          this.movementsPagination.totalUpdate(success.result.recordsTotal);
          this.movementsPagination.pageUpdate(page);
        }

        resolve();
      }).catch(() => {
        this.movementsLoading = false;
        
        reject();
      });
    });
  }

  movementsPay(movement: BillingMovement): void {
    this.uiService.loader(true);
    
    this.movementsService.paymentLink(movement.id, platformUrl('billing/movements')).then((success: HttpResponseData) => {   
      this.uiService.loader(false);
      this.uiService.alert({
        title: 'Pagar',
        message: `
          <p>Vamos a abrir una nueva ventana para que puedas realizar el pago.
          <strong>Revisá que tu navegador no esté bloqueando las ventanas emergentes.</strong>.</p>
          <p>Si tenes problemas para abrir la ventana de pago podes hacer 
          <a href="` + success.result + `" target="_blank" style="text-decoration:underline;font-weight:500;">click aca</a></p>
        `,
        width: 300,
        buttons: [
          { text: 'Cerrar', role: 'cancel',  },
          { text: 'Ir a pagar', role: 'accept', click: () => { window.open(success.result, '_blank'); } }
        ]
      });

      window.open(success.result, '_blank');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Pagar', error.message, 'error');
    });
  }

  movementsCancel(movement: BillingMovement): void {
    if(movement.isRemovable){
      this.uiService.alert({
        title: 'Cancelar depósito en cuenta',
        message: '¿Estás seguro de querer cancelar este movimiento?',
        width: 300,
        buttons: [
          { text: 'No', role: 'cancel',  },
          { text: 'Si, Cancelar', role: 'accept', click: () => {
              this.uiService.loader(true);
        
              this.movementsService.delete(movement.id).then((success: HttpResponseData) => {   
                this.uiService.loader(false);
                this.uiService.dialog('Cancelar depósito en cuenta', 'El movimiento de depósito en cuenta se canceló correctamente.', 'success');
                
                this.movementsFetch(this.movementsPagination.pageCurrent);
              }).catch((error: HttpResponseData) => {
                this.uiService.loader(false);
                this.uiService.dialog('Cancelar depósito en cuenta', error.message, 'error');
              });
            }
          }
        ]
      });
    }
  }
}

import { Injectable } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';

// Models
import { SupportCategory, SupportPost, SupportPostsFilters } from 'src/app/modules/user/models/support';

// Services
import { ApiService } from 'src/app/library/services/api.service';


@Injectable()
export class SupportService {
    constructor(
        private apiService: ApiService,
    ) { }

    postGetAll(filters?: SupportPostsFilters): Promise<HttpResponseData> {
        let data: any = filters ?? {};
        data.page = data.page ?? 1;
        data.per_page = data.per_page ?? 20;

        if(typeof data.category !== 'undefined'){
            data['soporte-categorias'] = data.category;
        }

        data = this.apiService.paramsFilter(data);

        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'v1/support/posts?' + this.apiService.paramsBuild(data), data).toPromise().then((success: any) => {
                if(success.status){
                    const list: Array<SupportPost> = [];
    
                    success.data.response.forEach((value: any) => {
                        list.push(new SupportPost(value));
                    });
                    
                    resolve(this.apiService.successDataPrepare('', list));
                } else {
                    resolve(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    postGet(id: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'v1/support/posts/' + id).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare('', new SupportPost(success.data.response)));
                } else {
                    resolve(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    categoryGetAll(): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'v1/support/categories?hide_empty=true').toPromise().then((success: any) => {
                if(success.status){
                    const list: Array<SupportCategory> = [];
        
                    success.data.response.forEach((value: any) => {
                        list.push(new SupportCategory(value));
                    });
        
                    resolve(this.apiService.successDataPrepare('', list));
                } else {
                    resolve(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}


<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">Solicitar asistencia</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card class="ui-form">
        <div class="ui-card-content">
            <form [formGroup]="formData">
                <ui-field class="ui-field" label="Área" [validate]="formValidates.area">
                    <select class="ui-input" formControlName="area" (change)="areaChange()">
                        <option *ngFor="let item of areasData" [value]="item.id">{{item.name}}</option>
                    </select>
                </ui-field>

                <ui-field class="ui-field" label="Motivo" [validate]="formValidates.reason">
                    <select class="ui-input" formControlName="reason">
                        <option *ngFor="let item of reasonsDataSelect" [value]="item">{{item}}</option>
                    </select>
                </ui-field>

                <ui-field class="ui-field" label="Asunto" [validate]="formValidates.subject">
                    <input class="ui-input" type="text" formControlName="subject">
                </ui-field>

                <div class="ui-form-row-double">
                    <ui-field class="ui-field" label="Nombre" [validate]="formValidates.firstname">
                        <input class="ui-input" type="text" formControlName="firstname">
                    </ui-field>
                            
                    <ui-field class="ui-field" label="Email" [validate]="formValidates.email">
                        <input class="ui-input" type="text" formControlName="email">
                    </ui-field>
                </div>

                <div class="ui-divider">Consulta</div>
                        
                <ui-field class="ui-field" label="Mensaje" [validate]="formValidates.message">
                    <textarea class="ui-input" formControlName="message"></textarea>
                </ui-field>
            </form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" [disabled]="!formData.valid" (click)="onSend()">Crear ticket</ui-button>    
        </div>
    </ui-card>
</ui-content>
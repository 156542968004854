import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { LibraryModule } from 'src/app/library/library.module';
import { AuthRoutingModule } from 'src/app/modules/auth/auth-routing.module';

// Services
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { ApiService } from 'src/app/library/services/api.service';
import { PushService } from 'src/app/library/services/push.service';

// Components
import { LoginComponent } from 'src/app/modules/auth/views/login/login.component';
import { RecoverComponent } from 'src/app/modules/auth/views/recover/recover.component';
import { RegisterComponent } from 'src/app/modules/auth/views/register/register.component';
import { RestoreComponent } from 'src/app/modules/auth/views/restore/restore.component';
import { WorkspacesComponent } from 'src/app/modules/auth/views/workspaces/workspaces.component';
import { ActivationComponent } from 'src/app/modules/auth/views/activation/activation.component';
import { AuthComponent } from 'src/app/modules/auth/views/auth/auth.component';
import { AuthBoxComponent } from 'src/app/modules/auth/views/auth-box/auth-box.component';

@NgModule({
  declarations: [
    AuthComponent,
    AuthBoxComponent,
    LoginComponent,
    RecoverComponent,
    RegisterComponent,
    RestoreComponent,
    WorkspacesComponent,
    ActivationComponent,
  ],
  imports: [
    CommonModule,
    LibraryModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AuthBoxComponent,
  ],
  providers: [
    AuthService,
    ApiService,
    PushService
  ]
})
export class AuthModule { }


<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">Crear usuario</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card>
        <div class="ui-card-content ui-form">
            <form [formGroup]="formData">
                <div class="ui-form-row-double">
                    <ui-field class="ui-field" label="Nombre" [validate]="formValidates.firstname">
                        <input class="ui-input" type="text" formControlName="firstname">
                    </ui-field>

                    <ui-field class="ui-field" label="Apellido" [validate]="formValidates.lastname">
                        <input class="ui-input" type="text" formControlName="lastname">
                    </ui-field>
                </div>

                <ui-field class="ui-field" label="Email" [validate]="formValidates.email">
                    <input class="ui-input" type="text" formControlName="email">
                </ui-field>

                <ui-field class="ui-field" label="Clave" [validate]="formValidates.password">
                    <input class="ui-input" type="text" formControlName="password">
                </ui-field>
            </form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" (click)="create()" [disabled]="formLoading">
                <span *ngIf="!formLoading">Crear</span>
                <ui-spinner class="ui-spinner" color="white" diameter="20" *ngIf="formLoading"></ui-spinner>
            </ui-button>
        </div>
    </ui-card>
</ui-content>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable, startWith, switchMap } from 'rxjs';

// Utils
import { isDomain, isUUID } from '@app/library/utils/functions';
import { HttpResponseData } from 'src/app/library/models/utils';
import { formValidate, formValidateInit, validateDomain } from '@app/library/utils/form-validators';

// Models
import { User } from '@app/modules/user/models/user';
import { UiModalbox } from 'src/app/library/components/ui-modalbox';
import { Workspace } from '@app/modules/workspaces/models/workspace';
import { HostbiAccount } from '@app/modules/hostbi/models/hostbi-account';
import { HostbiService } from '@app/modules/hostbi/models/hostbi-service';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { HostbiAccountsService } from '@app/modules/hostbi/services/hostbi-accounts.service';
import { HostbiDomainsService } from '../../services/hostbi-domains.service';
import { HostbiServicesService } from '../../services/hostbi-services.service';

@Component({
  selector: 'app-hostbi-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss']
})
export class HostbiAccountFormComponent implements OnInit {
  public response = new HostbiAccount();
  public servicesData: Array<HostbiService> = [];

  public workspacesData: Array<User> = [];
  public workspacesDataFiltered: Observable<Array<User>>;
  public workspacesDataLoading = false;

  private domainTimer: any;
  public domainSpinner = false;
  public domainVerified = false;
  
  public formData: FormGroup = new FormGroup({});
  public formLoading = false;
  public formValidates: any;
  public formInputsErrors: object = {
    workspace: { required: '',  pattern: 'Es necesario buscar y seleccionar un usuario.' },
    service: {
      required: 'Indicá el plan para la cuenta'
    },
    domain: {
      required: 'Ingresá el dominio para la cuenta',
      invalid:  'El dominio es inválido',
      registered:  'Este dominio no puede ser utilizado',
      timeout:  'No podemos validar el dominio, ingresalo nuevamente',
      exist: 'El domino no está disponible. intenta con otra alternativa.'
    },
  }; 
 
  constructor(
    private uiService: UiService,
    private uiModalbox: UiModalbox,
    private formBuilder: FormBuilder,
    private workspacesService: WorkspacesService,
    private hostbiDomainsService: HostbiDomainsService,
    private hostbiAccountsService: HostbiAccountsService,
    private hostbiServicesService: HostbiServicesService,
  ) {    
    this.formData = this.formBuilder.group({
      workspace: new FormControl('', [Validators.required]),
      service: new FormControl('', [Validators.required]),
      domain: new FormControl('', [Validators.required, validateDomain()]),
    });

    this.workspacesDataFiltered = this.formData.get('workspace')!.valueChanges.pipe(
      startWith(''), debounceTime(300), distinctUntilChanged(), switchMap(value => this.workspacesDataGet(value))
    );

    this.formValidates = formValidateInit(this.formData);

    this.uiService.loader(true);

    this.hostbiServicesService.plans(['hosting'], 'ARS').then((success) => {
      this.uiService.loader(false);

      this.servicesData = success.result;
    }).catch(() => {
      this.uiService.loader(false);
      this.uiService.dialog('Error', 'Hubo un problema al intentar cargar los datos para crear una cuenta.', 'error');

      this.close();
    });
  }

  ngOnInit(): void {
  }

  close(){
    this.uiModalbox.close(this.response);
  }

  create(): void {
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    

    if (this.formData.valid) {
      this.formLoading = true;

      this.hostbiAccountsService.create(this.formData.value).then((result: HttpResponseData) => {
        this.formLoading = false;

        if (result.status) {
          this.response = result.result;
          
          this.close();

          this.uiService.dialog('Cuenta generada', 'La cuenta de hosting fue creada correctamente', 'success');
        } else {
          this.uiService.snackbar(result.message);
        }
      }).catch((error) => {
        this.formLoading = false;
        this.uiService.snackbar(error.message ?? 'No es posible crear la cuenta de hosting en este momento. Estamos trabajando para solucionar el inconveniente.');
      });
    } else {
      this.uiService.snackbar('Algunos datos estan incompletos.');
    }
  }

  workspacesDataDisplay(workspaceId: string){
    let response = '';

    if (this.workspacesData.length > 0) {
      if (this.workspacesData[0] instanceof Workspace) {
        response = this.workspacesData.find(workspace => workspace.id === workspaceId)?.name ?? 'error';
      }
    }

    return response;
  }

  private workspacesDataGet(value: string): Promise<Array<User>> {
    return new Promise((resolve, reject) => {
      // If the value of the field is not empty and if it is not a whole
      // uuid since when selecting a value the field is updated by the ID
      if (value !== '' && !isUUID(value)) {
        this.workspacesDataLoading = true;

        this.workspacesService.getAll(1, 20, { search: value }).then((response: HttpResponseData) => {
          this.workspacesDataLoading = false;
          this.workspacesData = response.result;

          resolve(this.workspacesData);
        }).catch(() => {
          this.workspacesDataLoading = false;
          resolve(this.workspacesData);
        });
      } else {
        resolve(this.workspacesData);
      }
    });
  }

  domainVerify(): void {
    clearTimeout(this.domainTimer);

    this.domainVerified = false;

    this.domainTimer = setTimeout(() => {
      const domain = this.formData.value.domain;

      this.formData.controls['domain'].setErrors(null);
  
      if (isDomain(domain)) {
        this.domainSpinner = true;

        this.hostbiDomainsService.checkExist(domain).then((success: HttpResponseData) => {
          this.domainSpinner = false;

          if (success.result.status) {
            this.formData.controls['domain'].setErrors({registered: true});
          } else {
            this.domainVerified = true;
          }
          
          formValidate(this.formInputsErrors, this.formData, this.formValidates);
        }).catch(() => {
          this.domainSpinner = false;
          this.formData.controls['domain'].setErrors({timeout: true});

          formValidate(this.formInputsErrors, this.formData, this.formValidates);
        });
      } else {
        this.formData.controls['domain'].setErrors({invalid: true});

        formValidate(this.formInputsErrors, this.formData, this.formValidates);
      }
    }, 1000);
  }
}

import { AfterContentInit, Component, ContentChildren, HostListener, Input, OnInit, QueryList } from '@angular/core';
import { UiTabComponent } from '../ui-tab/ui-tab.component';

@Component({
  selector: 'ui-tabs',
  templateUrl: './ui-tabs.component.html',
  styleUrls: ['./ui-tabs.component.scss']
})
export class UiTabsComponent implements OnInit, AfterContentInit {
  @Input() disabled = false;
  @ContentChildren(UiTabComponent) uiTabContentChild!: QueryList<UiTabComponent>;
  
  constructor() { }

  ngOnInit(): void { }

  @HostListener('click') onClick() {
    this.uiTabContentChild.toArray().forEach((uiTab: UiTabComponent) => {
      if(uiTab.click) {
        uiTab.active = true;
        uiTab.click = false;
      } else {
        uiTab.active = false;
      }
    })
  }

  ngAfterContentInit(): void { }
}

<ui-content>
    <div class="ui-integration" *ngIf="integration.id > 0">
        <div class="ui-integration-resume">
            <ui-title>{{integration.name}}</ui-title>
            
            <div class="el-thirdparties">
                <div class="el-thirdparty">
                    <div class="el-icon">
                        <ui-icon name="database" type="outlined"></ui-icon>
                    </div>

                    <div class="el-data">
                        <div>Origen de datos</div>
                        <h6>{{integration.origin.name}}</h6>
                        <span class="el-type">{{integration.origin.typeName}}</span>
                    </div>
                </div>

                <ui-icon name="arrow_forward" type="outlined"></ui-icon>

                <div class="el-thirdparty">
                    <div class="el-icon">
                        <ui-icon name="database" type="outlined"></ui-icon>
                    </div>

                    <div class="el-data">
                        <div>Destino</div>
                        <h6>{{integration.destination.name}}</h6>
                        <div class="el-type">{{integration.destination.typeName}}</div>
                    </div>
                </div>
            </div>

            <div class="el-actions">
                <div class="el-job">
                    <ui-icon name="update" type="outlined"></ui-icon> Se ejecuta {{integration.job}}
                </div>

                <ui-button color="secondary" (click)="migrate()">Ejecutar manualmente</ui-button>
            </div>
        </div>

        <ui-card class="ui-integation-fields">
            <ui-card-header (click)="showBlock.fields = !showBlock.fields">
                <ui-title>Datos que procesa la integración</ui-title>

                <ui-icon [name]="(showBlock.fields) ? 'expand_less' : 'expand_more'" type="outlined"></ui-icon>
            </ui-card-header>

            <ui-card-content class="data-list" *ngIf="showBlock.fields">
                <dl>
                    <ng-container *ngFor="let item of integration.fields">
                        <dt>{{item.origin}}</dt>
                        <dd>{{item.destination}}</dd>
                    </ng-container>
                </dl>
            </ui-card-content>
        </ui-card>
        
        <ui-card class="ui-integation-transfers">
            <ui-card-header class="ui-billing-services-header">
                <ui-title>Integraciones ejecutadas</ui-title>
            </ui-card-header>

            <div class="ui-records-list" *ngIf="transfersData.length > 0">
                <integrator-transfer-record [transfer]="item" *ngFor="let item of transfersData"></integrator-transfer-record>

                <ui-spinner-layer *ngIf="transfersLoading"></ui-spinner-layer>
            </div>
                    
            <div class="ui-records-pagination" *ngIf="transfersData.length > 0">
                <ui-pagination [fetch]="transfersFetchBind" [data]="transfersPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
            </div>

            <div class="ui-records-no-data" *ngIf="transfersData.length <= 0">
                Todavia no hay migraciones realizadas.
            </div>
        </ui-card>
    </div>

    <ui-screen-loader *ngIf="integration.id <= 0"></ui-screen-loader>
</ui-content>
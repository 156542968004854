<div class="ui-pagination" *ngIf="data.pagesTotal > 0">
  <div class="ui-pagination-list">
    <ul>
      <li *ngFor="let page of data.pagesRange" [class]="{active: (data.pageCurrent === page || (inMiddle(data.pageCurrent) && page === '...'))}">
        <!--<ui-button *ngIf="page == data.pageStart && data.pageCurrent > 1"><ui-icon slot="icon-only" name="navigate_before" type="outlined"></ui-icon></ui-button>-->

        <ui-button (click)="onFetch(page)" [color]="(data.pageCurrent === page) ? 'primary' : ''" *ngIf="page != '...' && page != '<' && page != '>'">
          {{page}}
        </ui-button>

        <ui-field *ngIf="page == '...'">
          <select [(ngModel)]="pageValue" (change)="onFetch()">
            <option [ngValue]="''">...</option>
            <option *ngFor="let pageMiddle of data.pagesMiddle" [ngValue]="pageMiddle">{{pageMiddle}}</option>
          </select>
        </ui-field>

        <!--<ui-button *ngIf="page == data.pageEnd && data.pageCurrent < data.pagesTotal"><ui-icon slot="icon-only" name="navigate_next" type="outlined"></ui-icon></ui-button>-->
      </li>
    </ul>
  </div>

  <div class="ui-pagination-data">
    <ui-field>
      <select class="ui-input" [(ngModel)]="pageSizeValue" (change)="onPageSizeChange()">
        <option *ngFor="let option of pageSizeOptions" [ngValue]="option">{{option}}</option>
      </select>
    </ui-field>

    Registros por página · {{pageSizeStart}} - {{pageSizeEnd}} de {{pageSizeTotal}}
  </div>
</div>

<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">Generar orden</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card>
        <div class="ui-card-content">
            <div class="ui-form">
                <form [formGroup]="form.data">
                    <ui-field class="ui-field" label="Tipo de orden" [validate]="form.validates.type">
                        <select class="ui-input" formControlName="type">
                            <option value="order">Pedido</option>
                            <option value="renewal">Renovación</option>
                        </select>
                    </ui-field>
                    
                    <div class="ui-form-row-double">
                        <ui-field class="ui-field" label="Notificar al usuario" [validate]="form.validates.notify">
                            <select class="ui-input" formControlName="notify">
                                <option value="true">Si</option>
                                <option value="false">No</option>
                            </select>
                        </ui-field>
                                            
                        <ui-field class="ui-field" label="Fecha de vencimiento" [validate]="form.validates.expiresOn">
                            <input class="ui-input" type="date" formControlName="expiresOn">
                        </ui-field>
                    </div>  
                
                
                <ui-divider>Detalles de la orden</ui-divider>              
                    
                <div class="ui-details-list" formArrayName="details">
                    <div class="ui-el-head ui-el-detail">
                        <span class="ui-el-item">Detalle</span>
                        <span class="ui-el-quantity">Cantidad</span>
                        <span class="ui-el-price">Precio</span>
                        <span class="ui-el-amount">Total</span>
                        <span class="ui-el-remove">&nbsp;</span>
                    </div>
                        
                    <div class="ui-el-detail" *ngFor="let item of detailsControls; index as i" [formGroupName]="i">
                        <ui-field class="ui-field ui-el-item" [validate]="form.validates.details[i].item">
                            <input class="ui-input" type="text" formControlName="item">
                        </ui-field>

                        <ui-field class="ui-field ui-el-quantity" [validate]="form.validates.details[i].quantity">
                            <input class="ui-input" type="number" inputDecimal formControlName="quantity">
                        </ui-field>
                                                
                        <ui-field class="ui-field ui-el-price" [validate]="form.validates.details[i].price">
                            <input class="ui-input" type="number" inputDecimal formControlName="price">
                        </ui-field>

                        <span class="ui-el-amount">$&nbsp;{{(item.value.price * item.value.quantity) | number : '1.2-2'}}</span>

                        <ui-button class="ui-el-remove" color="transparent" (click)="detailDelete(i)" *ngIf="detailsControls.length > 1">
                            <ui-icon name="close" type="outlined" slot="icon-only"></ui-icon>
                        </ui-button>

                        <span class="ui-el-remove" *ngIf="detailsControls.length === 1">&nbsp;</span>
                    </div>

                    <div class="ui-el-foot">
                        <ui-button color="secondary" (click)="detailCreate()">Agregar detalle</ui-button>

                        <span class="ui-el-amount">Total: $&nbsp;{{detailsTotal | number : '1.2-2'}}</span>
                    </div>
                </div>

                </form>
            </div>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" (click)="generate()">Generar order</ui-button>    
        </div>
    </ui-card>
</ui-content>
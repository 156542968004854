import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Location } from '@angular/common';

// Utils
import { appCurrent } from 'src/app/platform/functions/platform';

// Models
import { ModulesGroup, groupsData, ModuleApp, Module, modulesData } from 'src/app/platform/models/module';
import { User } from 'src/app/modules/user/models/user';

// Services
import { UserService } from 'src/app/modules/user/services/user.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class ModuleService {
    public userData: User = new User();
    public modulesData: Array<ModuleApp> = [];
    public groupsData: Array<ModulesGroup> = [];

    public current: BehaviorSubject<Module> = new BehaviorSubject<Module>(new Module('', '', '', '', ''));
    public groups: BehaviorSubject<Array<ModulesGroup>> = new BehaviorSubject<Array<ModulesGroup>>([]);
    
    constructor(
        private router: Router,
        private location: Location,
        private userService: UserService,
    ) {
        this.modulesData = modulesData;
        this.groupsData = groupsData;
        
        this.userService.userData.subscribe((data) => {
            this.userData = data;

            this.groupSet();
        });

        router.events.subscribe((route) => {
            if(route instanceof NavigationEnd){
                const module: Module|null = this.getByPath(route.url);

                if(module instanceof Module){
                    this.current.next(module);                    
                }
            }
        });
    }
    
    groupAdd(id: string, type: 'app'|'workspace', name = ''): void{
        this.groupsData.push(new ModulesGroup(id, type, name));
    }
    
    groupSet(): void {
        const groups: Array<ModulesGroup> = Array.from(this.groupsData);

        groups.forEach((group: ModulesGroup) => {
            group.modules = [];

            this.modulesData.forEach((moduleApp: ModuleApp) => {        
                if((moduleApp.type == 'app' && moduleApp.id == appCurrent.module) || moduleApp.type == 'workspace'){
                    moduleApp.modules.forEach((module: Module) => {
                        if(this.userData.permissionsCheck(module.permissions)){                            
                            if(group.id == module.group){
                                group.modules.push(module);
                            }
                        }
                    })
                }
            });

        });

        this.groups.next(groups);
    }

    groupClear(group: string): void{
        this.modulesData.forEach((moduleApp) => {
            const modules: Array<Module> = [];
            
            moduleApp.modules.forEach((module) => {
                if(module.group !== group){
                    modules.push(module);
                }
            });
            
            moduleApp.modules = modules;
        });

        this.groupSet();
    }

    add(appId: string, module: Module): void{
        this.modulesData.forEach((moduleApp) => {
            if(moduleApp.id === appId){
                moduleApp.modules.push(module);
            }
        });

        this.groupSet();
    }

    clear(appId: string): void{
        this.modulesData.forEach((moduleApp) => {
            if(moduleApp.id === appId){
                moduleApp.modules = [];
            }
        });

        this.groupSet();
    }

    getByPath(path: string): Module|null {
        path = path.replace(/^\/|\/$/g, '');
        
        const modulesPath: any = {};
        
        this.modulesData.forEach((moduleApp: ModuleApp) => {
            if(moduleApp.modules.length > 0){
                moduleApp.modules.forEach((module: Module) => {
                    modulesPath[module.path] = module;
                });
            }
        });
    
        return (typeof modulesPath[path] != 'undefined') ? modulesPath[path] : null;
    }
    
    get(app: string): ModuleApp{
        let response: any;
    
        this.modulesData.forEach((module: ModuleApp) => {
            if(module.id === app){
                response = module;
            }
        })
    
        return response;
    }
}

import { Component, OnInit } from '@angular/core';
import { UiAlertRef } from 'src/app/library/components/ui-alert';
import { BillingPaymentEmitter } from 'src/app/modules/billing/models/emitter';

@Component({
  selector: 'app-workspaces-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class WorkspacesPaymentDetailsComponent implements OnInit {
  public collector = '';
  public transaction = '';

  public emittersData: Array<BillingPaymentEmitter> = [
    {name: 'Dante Piazza Quiroga - Mercado Pago', emitter: '20341005850', gateway: 'mercadopago'},
    {name: 'Grupo Nunxio - Mercado Pago', emitter: '30718046897', gateway: 'mercadopago'},
  ];

  constructor(
    private uiAlertRef: UiAlertRef,
  ) { }

  change(event: any){
    const collector = this.emittersData.find((i) => i.emitter === this.collector) ?? {name: '', emitter: '', gateway: ''};

    this.uiAlertRef.data['gateway'] = collector.gateway;
    this.uiAlertRef.data['emitter'] = collector.emitter;
    this.uiAlertRef.data['transaction'] = this.transaction;

    console.log(collector);
    console.log(this.uiAlertRef.data);
  }

  ngOnInit(): void { }
}

import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

// Models
import { User } from 'src/app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';
import { platformUrl } from 'src/app/platform/functions/platform';
import { BillingBalance } from 'src/app/modules/billing/models/billing';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { GlobalsService } from 'src/app/modules/globals/services/globals.service';
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';

@Component({
  selector: 'billing-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class BillingResumeComponent implements OnInit {
  @Input() mode: 'vertical'|'horizontal' = 'vertical';

  public settings: any = {};
  public userData: User = new User(); 

  public data = new BillingBalance();
  public loading = false;
  
  constructor(
    private uiService: UiService,
    private userService: UserService,
    private globalsService: GlobalsService,
    private billingAccountingService: BillingAccountingService
  ) {
    this.userService.userData.subscribe((user: User) => {
      this.userData = user;
    });
  
    this.billingAccountingService.balanceData.subscribe((balance) => {
      this.data = balance;
    });

    this.globalsService.settings.subscribe((settings) => {
      this.settings = settings.list;
    });
  }

  ngOnInit(): void {
    this.load();
  }

  load(){
    this.loading = true;
    
    this.billingAccountingService.balance().then((success: HttpResponseData) => {
      this.loading = false;
      this.data = success.result;
    }).catch(() => {
      this.loading = false;
    });
  }

  debitCancel(){
    const alertCancel = this.uiService.alert({
      title: 'Cancelar débito automatico',
      message: 'Si continúas se cancelará el débito automático de tu cuenta.',
      buttons: [
          { text: 'Cerrar', role: 'cancel' },
          { text: 'Cancelar', color: 'primary-red', click: () => {
              this.uiService.loader(true);
    
              this.billingAccountingService.subscription('cancel').then((success: HttpResponseData) => {
                this.uiService.loader(false);

                if (success.status) {
                  alertCancel.close();
                  this.data.debitStatus = false;
                  this.uiService.dialog('Débito cancelado', 'El débito automatico de tu cuenta fue cancelado correctamente. A partir de este momento deberas abonar tus renovaciones de forma manual.', 'success');
                } else {
                  this.uiService.snackbar(success.message, 'alert');
                }
              }).catch((error: HttpResponseData) => {
                this.uiService.loader(false);
                this.uiService.snackbar(error.message, 'error');
              });
          } }
        ]
    });
  }

  debitAdd(){
    this.billingAccountingService.debitAdd(this.userData);
  }
}

<div class="ui-auth-text">
    <h1>Recuperar contraseña</h1>
    <p>Ingresá tu direccion de correo, te enviaremos un enlace para que puedas recuperar tu contraseña.</p>
</div>
        
<div class="ui-auth-form">
    <form [formGroup]="formData">
        <ui-field class="ui-field" label="Correo electrónico" [validate]="formValidates.email">
            <input class="ui-input" type="email" formControlName="email">
        </ui-field> 
    </form>
    
    <div class="ui-auth-form-actions">
        <a (click)="screenChange('login')">Ingresar</a>
    </div>
            
    <div class="ui-auth-form-button">
        <ui-button color="primary" (click)="recover()" [disabled]="formLoading">
            <span *ngIf="!formLoading">Enviar</span>
            <ui-spinner class="ui-spinner" color="white" diameter="20" *ngIf="formLoading"></ui-spinner>
        </ui-button>
    </div>
</div>
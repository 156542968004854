import { Component, OnInit } from '@angular/core';

// Models
import { ScreenLoaderData } from './ui-screen-loader.model';

// Services
import { UiService } from 'src/app/library/services/ui.service';

@Component({
  selector: 'ui-screen-loader',
  templateUrl: './ui-screen-loader.component.html',
  styleUrls: ['./ui-screen-loader.component.scss']
})
export class UiScreenLoaderComponent implements OnInit {
  public default: ScreenLoaderData = {
    title: '',
    message: '',
    icon: '',
    loading: false,
    actions: [],
    loadingDiameter: 20
  };
  public data: any = this.default;

  constructor(
    private uiService: UiService
  ) {
    this.uiService.screenLoaderData.subscribe((value: ScreenLoaderData) => {
      this.data = { ...this.default, ...value };
    });
  }

  ngOnInit(): void { /* Silence is golden */ }
}

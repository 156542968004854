import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { Module } from 'src/app/platform/models/module';
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';
import { HostbiServer } from 'src/app/modules/hostbi/models/hostbi-server';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { ModuleService } from 'src/app/platform/services/module.service';
import { HostbiServersService } from 'src/app/modules/hostbi/services/hostbi-servers.service';

@Component({
  selector: 'app-hostbi-servers-manage',
  templateUrl: './servers-manage.component.html',
  styleUrls: ['./servers-manage.component.scss']
})
export class HostbiServersManageComponent implements OnInit {
  public moduleData: Module | undefined;

  public serversSearchQuery = '';
  public serversSearchWorkspace = 0;
  public serversSearchServer = 0;
  public serversData: Array<HostbiServer> = [];
  public serversPagination: PaginationData = new PaginationData(0);
  public serversFetch = this.serversList.bind(this);
  public serversLoading = false;

  public errors = {
    load: {
      title: 'Cargando lista de servidores',
      message: 'Estamos buscando la información los servidores.',
      icon: 'dns',
      loading: true,
    },
    empty: {
      title: 'Servidores',
      message: 'No existen servidores.',
      icon: 'dns',
    },
    error: {
      title: 'Servidores',
      message: 'No se pudo cargar el listado de servidores. Intentar nuevamente.',
      icon: 'dns',
    }
  };

  constructor(
    private router: Router,
    private uiService: UiService,
    private hostbiServersService: HostbiServersService,
    private modulesService: ModuleService,
  ) {
    this.modulesService.current.subscribe((module: Module) => {
      this.moduleData = module
    });
  }

  ngOnInit(): void {
    this.serversList();
  }

  serversSearch(event?: any){
    if(typeof event === 'undefined' || event.code == 'Enter'){
      this.serversList();
    }
  }

  serversList(page = 1): void{
    this.serversLoading = true;
    this.uiService.screenLoader(this.errors.load);

    const filters = {
      search: this.serversSearchQuery,
    };
    
    this.hostbiServersService.getAll(page, this.serversPagination.pageSize).then((success: HttpResponseData) => {
      this.serversLoading = false;
      
      if (success.result.length > 0) {
        this.serversData = success.result;
        this.serversPagination.totalUpdate(success.result.length);
        this.serversPagination.pageUpdate(1);
      } else {
        this.uiService.screenLoader(this.errors.empty);
      }
    }).catch(() => {
      this.serversLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }

  serverPreview(account: HostbiServer): void {
    this.router.navigate(['/hostbi/servers/' + account.id]);
  }

  serverCreate(): void {
    /*this.uiModalbox.open(HostbiAccountAddComponent, { }, {
      title: 'Agregar nueva cuenta',
      width: 400,
    });*/
  }
}

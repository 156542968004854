import { Component, OnInit } from '@angular/core';

// Models
import { Module } from 'src/app/platform/models/module';
import { User } from 'src/app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';
import { HostbiAccount } from 'src/app/modules/hostbi/models/hostbi-account';
import { HostbiMonitoring } from 'src/app/modules/hostbi/models/hostbi-monitoring';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { ModuleService } from 'src/app/platform/services/module.service';
import { GlobalsService } from 'src/app/modules/globals/services/globals.service';
import { BillingOrdersService } from 'src/app/modules/billing/services/orders.service';
import { HostbiAccountsService } from 'src/app/modules/hostbi/services/hostbi-accounts.service';
import { HostbiMonitoringService } from 'src/app/modules/hostbi/services/hostbi-monitoring.service';
import { NotificationsData, NotificationsService } from 'src/app/modules/user/services/notifications.service';

// Components
import { HostbiAccountAddComponent } from 'src/app/modules/hostbi/components/account-add/account-add.component';

@Component({
  selector: 'app-hostbi-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class HostbiDashboardComponent implements OnInit {
  public dateCurrent: number = Date.now();
  
  public userData: User = new User();
  public moduleData: Module | undefined;
  
  public accountsLoaded = false;
  public monitoringLoaded = false;

  public notificationsUnreadsData = 0;
  public ordersUnpaidsData = 0;  
  
  public accountsData: Array<HostbiAccount> = [];
  public monitoringData: Array<HostbiMonitoring> = [];

  public errors: any = {
    load: {
      title: 'Cargando cuentas de alojamiento',
      message: 'Estamos buscando la información de tus cuentas alojadas.',
      icon: 'dns',
      loading: true,
    },
    empty: {
      title: 'Cuentas de alojamiento',
      message: 'Todavía no tenes ninguna cuenta del alojamiento. Podes consultar los <a href="https://hostbi.net" target="_blank">planes y servicios</a> y solicitar tu primer cuenta.',
      icon: 'dns',
      actions: [{ name: 'Crear cuenta', action: (): void => { this.accountAdd(); } }],
      //actions: [{ name: 'Ver planes', action: (): void => { window.open('https://hostbi.clousis.com', '_blank'); } }],
    },
    error: {
      title: 'Cuentas de alojamiento',
      message: 'No pudimos obtener los datos de tus cuentas en este momento. Estamos trabajando para solucionar este problema. Por favor intentá revisar nuevamente más tarde.',
      icon: 'dns',
    }
  };  

  constructor(
    private uiService: UiService,
    private userService: UserService,
    private modulesService: ModuleService,
    private globalsService: GlobalsService,
    private ordersService: BillingOrdersService,
    private notificationsService: NotificationsService,
    private hostbiAccountsService: HostbiAccountsService,
    private hostbiMonitoringService: HostbiMonitoringService,
  ) {
    this.modulesService.current.subscribe((module: Module) => {
      this.moduleData = module
    });

    this.userService.userData.subscribe((userData: User) => {
      this.userData = userData
    });

    this.notificationsService.notificationsData.subscribe((notifications: NotificationsData) => {
      this.notificationsUnreadsData = notifications.data.recordsUnreads;
    });

    this.ordersService.getUnpaids().then((unpaids: HttpResponseData) => {
      this.ordersUnpaidsData = unpaids.result;
    });

    this.globalsService.settings.subscribe((settings) => {
      if(settings.load){
        this.globalsService.newsInit();
      }
    });
  }

  ngOnInit(): void {
    this.hostbiAccountsService.list.subscribe((list) => {
      this.accountsData = list;

      /*this.modulesService.groupClear('hostbi-accounts');
      
      this.accountsData.forEach((account) => {
        this.modulesService.add('hostbi', new Module(
          'hostbi-account-' +  + account.id,
          'hostbi-accounts',
          account.domain,
          'language',
          'hostbi/accounts/' + account.id,
          ['hostbi', 'hostbi-accounts']
        ));
      });*/
    });

    this.hostbiAccountsService.listLoaderStatus.subscribe((status) => {
      this.uiService.screenLoader(this.errors[status]);

      this.accountsLoaded = ['success', 'empty'].includes(status);
    });

    this.hostbiMonitoringService.whitWorkspace().getAll().then((success: HttpResponseData) => {
      if (success.result.records.length > 0) {
        this.monitoringData = success.result.records;
      }
    }).catch((error: HttpResponseData) => {

    }).finally(() => {
      this.monitoringLoaded = true;
    });
  }

  get dataLoaded(): boolean {
    return this.accountsLoaded && this.monitoringLoaded;
  }

  accountAdd(): void {
    this.uiService.modalbox({
      component: HostbiAccountAddComponent,
      panelClass: 'ui-order-modal',
    });
  }
}

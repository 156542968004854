import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Utils
import { appName } from 'src/app/platform/functions/platform';
import { formValidate, formValidateInit, validateEmail } from 'src/app/library/utils/form-validators';

// Models
import { User } from 'src/app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() user: User = new User();
  @Output() userChange: EventEmitter<User> = new EventEmitter();
  @Output() screen: EventEmitter<string> = new EventEmitter();
  
  public formData: FormGroup;
  public formLoading = false;
  public formValidates: any;
  public formInputsErrors: object = {
    email: {
      invalid:  'El email es inválido.',
    },     
    password: { }, 
  };

  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
  ) {
    this.formData = this.formBuilder.group({
      email: new FormControl('', [Validators.required, validateEmail()]),
      password: new FormControl('', [Validators.required]),
    });

    this. formValidates = formValidateInit(this.formData);
  }

  ngOnInit() {
    this.formData.get('email')?.valueChanges.subscribe(event => {
      this.formData.get('email')?.setValue(event.toLowerCase(), {emitEvent: false});
    });
  }

  screenChange(screen: string){
    this.screen.emit(screen);
  }

  login(): void {
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    

    if (this.formData.valid) {
      this.formLoading = true;

      this.authService.login(
        this.formData.get('email')?.value,
        this.formData.get('password')?.value,
        false
      ).then((success: HttpResponseData) => { 
        this.user = success.result.user;
        
        this.userChange.emit(this.user);

        if(this.user.verified){
          if(!this.user.workspace.isSet() || this.user.workspaces.length > 1){
            this.formLoading = false;
            this.screen.emit('workspaces');
          } else {
            this.formLoading = false;
            this.screen.emit('finish');
          }
        } else {
          this.screen.emit('activation');
        }
      }).catch((error: HttpResponseData) => {
        this.formLoading = false;
        this.uiService.snackbar(error.message);
      });
    }    
  }
}

import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isCardNumber, isCBU, isCUIT, isDefined, isDomain, isEmail, isIPv4, isIPv6, isPhone, isSubdomain } from './functions';

export class FormControlValidate {
  name = '';
  error = '';
  isValid: boolean|null  = null;

  private controls: any;
  private fields: any;

  constructor(name?: string, controls?: any, fields: object = {}){
    this.name = name ?? '';
    this.controls = controls;
    this.fields = fields;
  }

  hasError(){
    return this.isValid === false;
  }

  isError(): boolean {
    return this.error !== '';
  }

  validate(): void {
    const errors = (typeof this.controls !== 'undefined') ? this.controls.errors : [];
    let validate = false;
    let type = '';

    for (const error in errors) {
      if (errors[error]) {
        validate = errors[error];
        type = error;
      }
    }

    this.isValid = !validate;
    this.error = (type !== '' && isDefined(this.fields[this.name])) ? this.fields[this.name][type] : '';
  }
}

export class FormControlIntegrate{
  data: FormGroup = new FormGroup({});
  validates: any = {};
  errors: {[key: string]: {[key: string]: any}} = {};

  constructor(
    errors: {[key: string]: {[key: string]: any}},
  ){
    this.errors = errors;

    this.init();
  }
  
  init(){
    this.validates = this.validationsGenerate(this.data);

    return this;
  }
  
  validate(){
    this.init().validationsCheck(this.validates);
  }

  validationsCheck(validates: {[key: string]: any}){
    Object.entries(validates).forEach(([name, validation]) => {
      if(validation instanceof FormControlValidate){
        validation.validate();
      } else {
        Object.keys(validation).forEach((key: any) => {
          this.validationsCheck(validation[key]);
        });
      }
    });
  }

  validationsGenerate(data: FormGroup){
    const validates: any = {};

    Object.entries(data.controls).forEach(([controlName, controlData]) => {
      if(controlData instanceof FormArray){
        validates[controlName] = {};

        controlData.controls.forEach((control: any, index) => {
          validates[controlName][index] = this.validationsGenerate(control);
        });
      } else {
        validates[controlName] = new FormControlValidate(controlName, controlData, this.errors);
      }    
    });

    return validates;
  }
}

export function formValidateInit(formData: FormGroup, fields: object = {}): any {
  const validates: any = {};

  Object.entries(formData.controls).forEach(([controlName, controlData]) => {
    if(controlData instanceof FormArray){
      controlData.controls.forEach((control: any, index) => {
        validates[controlName] = {};
        validates[controlName][index] = formValidateInit(control, fields);
      });
    } else {
      validates[controlName] = new FormControlValidate(controlName, controlData, fields);
    }
  });

  return validates;
}

export function formValidate(fields: any, formData: FormGroup, formValidates: any): any {
  Object.entries(formData.controls).forEach(([controlName, controlData]) => {
    if(controlData instanceof FormArray){
      controlData.controls.forEach((control: any, index) => {
        formValidates[controlName] = {};
        formValidates[controlName][index] = {};
        
        formValidate(fields, control, formValidates[controlName][index]);
      });
    } else {
      formValidates[controlName] = new FormControlValidate(controlName, controlData, fields);
      formValidates[controlName].validate();
    }    
  });
}

export function validateEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validationResult: ValidationErrors | null = (isEmail(String(control.value)) === true) ? null : { invalid: true };

      if (typeof validationResult === 'undefined' || control.value == '') {
        validationResult = null;
      }

      return validationResult;
    };
}

export function validateCUIT(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (isCUIT(String(control.value)) === true) ? null : { invalid: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return (control.value !== '' && control.value !== null) ? validationResult : null;
  };
}

export function validateCBU(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (isCBU(String(control.value)) === true) ? null : { invalid: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return (control.value !== '' && control.value !== null) ? validationResult : null;
  };
}

export function validatePhone(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (isPhone(String(control.value)) === true) ? null : { invalid: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return (control.value !== '' && control.value !== null) ? validationResult : null;
  };
}

export function validateIntPositive(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (Number(control.value) > 0) ? null : { negative: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return (control.value !== '' && control.value !== null) ? validationResult : null;
  };
}

export function validateCardNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (isCardNumber(String(control.value)) === true) ? null : { invalid: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return (control.value !== '' && control.value !== null) ? validationResult : null;
  };
}

export function validateDomain(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (isDomain(String(control.value)) === true) ? null : { invalid: true, domainInvalid: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return validationResult;
  };
}

export function validateSubdomain(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (isSubdomain(String(control.value)) === true) ? null : { invalid: true, subdomainInvalid: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return validationResult;
  };
}

export function validateIP(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (isIPv4(String(control.value)) || isIPv6(String(control.value)) === true) ? null : { invalid: true, ipInvalid: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return validationResult;
  };
}

export function validateIPv4(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (isIPv4(String(control.value)) === true) ? null : { invalid: true, ipv4Invalid: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return validationResult;
  };
}

export function validateIPv6(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = (isIPv6(String(control.value)) === true) ? null : { invalid: true, ipv6Invalid: true };

    if (typeof validationResult === 'undefined' || control.value == '') {
      validationResult = null;
    }

    return validationResult;
  };
}
import { Component, OnInit } from '@angular/core';

// Utils
import { UiSidebox } from 'src/app/library/components/ui-sidebox';
import { PaginationData } from 'src/app/library/components/ui-pagination';

// Models
import { User } from 'src/app/modules/user/models/user';
import { SupportCategory, SupportPost, SupportPostsFilters } from 'src/app/modules/user/models/support';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { SupportService } from 'src/app/modules/user/services/support.service';

// Components
import { SupportFormComponent } from 'src/app/modules/user/views/support-form/support-form.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  public userData: User = new User();

  public screen = 'categories';

  get title(): string{
    return this.screen === 'post' ? this.post.title : (this.screen === 'posts' && this.category.name !== '' ? this.category.name : '¿En qué podemos ayudarte?');
  }

  // Categories 

  public category = new SupportCategory();
  public categoriesLoader = false;
  public categoriesList: Array<SupportCategory> = [];
  
  // Posts

  public post = new SupportPost();
  public postsLoader = false;
  public postsFetchBind = this.postsFetch.bind(this);
  public postsFilters: SupportPostsFilters = { };
  public postsList: Array<SupportPost> = [];
  public postsPagination: PaginationData = new PaginationData(0);

  constructor(
    private uiSidebox: UiSidebox,
    private uiService: UiService,
    private usersService: UserService,
    private supportService: SupportService,
  ) {
    this.usersService.userData.subscribe((value: User) => {
      this.userData = value;
    });
  }

  ngOnInit(): void {
    this.categoriesFetch();
  }

  ticket(): void {
    this.uiService.modalbox({
      component: SupportFormComponent,
      backdrop: true,
      width: 600,
    });
  }

  close(){
    this.uiSidebox.close();
  }

  back(){
    if(this.screen === 'posts'){
      this.category = new SupportCategory();
      this.postsList = [];
      this.postsFilters.search = '';
      this.postsFilters.category = 0;
    }

    this.post = new SupportPost();
    this.screen = this.screen === 'post' ? 'posts' : 'categories';
  }

  search(){
    this.screen = 'posts';
    
    this.postsFetch(1);
  }

  // Categories

  categoriesFetch(){
    this.categoriesLoader = true;

    this.supportService.categoryGetAll().then((success) => {
      this.categoriesLoader = false;

      this.categoriesList = success.result;
    }).catch((error) => {
      this.categoriesLoader = false;
    });
  }

  categoriesSelect(category: SupportCategory){
    this.screen = 'posts';
    this.category = category;
    this.postsFilters = { category: this.category.id };

    this.postsFetch(1);
  }

  // Posts  

  postsFetch(page = 1){
    this.postsLoader = true;

    this.supportService.postGetAll({...{page}, ...this.postsFilters}).then((success) => {
      this.postsLoader = false;
      
      this.postsList = success.result;
      this.postsPagination.totalUpdate(success.result.length);
      this.postsPagination.pageUpdate(page);
    }).catch((error) => {
      this.postsLoader = false;
    });
  }

  postsSelect(post: SupportPost){
    this.screen = 'post';
    this.post = post;
  }
}

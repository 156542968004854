import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

// Models
import { DataRecords, HttpResponseData, HttpResponseError } from 'src/app/library/models/utils';
import { CryptoJSAesEncrypt } from 'src/app/library/utils/functions';
import { isDefined } from 'src/app/library/utils/functions';

// Models
import { AuthSession } from 'src/app/modules/auth/models/session';
import { User } from 'src/app/modules/user/models/user';
import { UserWorkspace } from 'src/app/modules/user/models/workspaces';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Injectable()
export class UserService {
    public userData: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
    public notificationsData: BehaviorSubject<DataRecords> = new BehaviorSubject<DataRecords>(new DataRecords());
    
    constructor(
        private apiService: ApiService,
        private authService: AuthService,
    ) {
        this.authService.session.subscribe((session: AuthSession) => {
            let data = new User();
            
            if(session.user.isSet()){
                if(session.workspace.isSet()){
                    session.user.workspace = session.workspace;
                }

                data = session.user;
            }
            
            if(session.userSet){
                this.dataSet(data);
            }
        });
    }

    dataSet(data: User): void{
        this.userData.next(data);
    }

    dataSetCustom(key: string, value: any): void{
        (this.userData.value as any)[key] = value;
        
        this.dataSet(this.userData.value);
    }

    dataGet(): User{
        return this.userData.value;
    }

    get(): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'v1/user').toPromise().then((success: any) => {
                resolve(this.apiService.successDataPrepare(success.data.message, new User(success.data.user)));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    update(data: {
        firstname: string;
        lastname: string;
        email: string;
        phone: string;
        password_new: string;
        password_current: string;
    }): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            if(data.password_new !== ''){
                data.password_new = CryptoJSAesEncrypt(data.password_new);
            }
            
            data.password_current = CryptoJSAesEncrypt(data.password_current);
            
            this.apiService.request(
                'put',
                'v1/user/',
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if(success.status){
                    const user = new User(success.data.user);

                    this.dataSetCustom('firstname', user.firstname);
                    this.dataSetCustom('lastname', user.lastname);
                    this.dataSetCustom('phone', user.phone);
                    
                    resolve(this.apiService.successDataPrepare(success.data.message, user));
                } else {
                    resolve(this.apiService.errorDataPrepare(success.data.message, new HttpResponseError(success.data.errors)));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data.errors)));
            });
        });
    }
    
    // Crea un usuario y un workspace y devuevle una sesion activa con el workspace seteado.
    // Se establece la sesion en frontend y se devuevle los datos de una sesion.
    register(data: {
        firstname: string;
        lastname: string;
        email: string;
        phone?: string;
        password: string;
        workspace: string
        currency: 'ARS'|'USD'|'EUR';
        app: string;
        verification_url: string;
        partner?: string;
    }): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            data.password = CryptoJSAesEncrypt(data.password);

            if(typeof data.partner !== 'undefined' && data.partner === ''){
                delete data.partner;
            }

            this.apiService.request(
                'post',
                'v1/user/register',
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if(success.status){
                    const user = new User(success.data.user);

                    user.workspace = new UserWorkspace(success.data.workspace);

                    const session = { user, workspace: user.workspace, userSet: false };

                    this.authService.sessionSet(session, session.userSet);
                    
                    resolve(this.apiService.successDataPrepare(success.data.message, session));
                } else {
                    resolve(this.apiService.errorDataPrepare(success.data.message, new HttpResponseError(success.data.errors)));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data.errors)));
            });
        });
    }

    support(data: {
        area: string;
        reason: string;
        subject: string;
        firstname: string;
        email: string;
        message: string;
    }): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request(
                'post',
                'v1/user/support',
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success.data));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    permissionsCheck(permissions: Array<string>): boolean{
        let isPermitted = false;
        
        if(permissions.length > 0){
            isPermitted = this.userData.value.workspace.permissions.some(perm => permissions.includes(perm));
        } else {
            isPermitted = true;
        }
        
        return isPermitted;
    }
   
    passwordRecover(email: string, url: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {            
            this.apiService.request('post', 'v1/user/recover', { email, url }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data));
                } else {
                    resolve(this.apiService.errorDataPrepare(success.data.message, success.data));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error.data));
            });
        });
    }

    passwordRestore(token: string, email: string, password: string): Promise<HttpResponseData> {
        password = CryptoJSAesEncrypt(password);

        return new Promise((resolve, reject) => {            
            this.apiService.request('post', 'v1/user/restore', { token, email, password, password_confirmation: password }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success.data));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error.data));
            });
        });
    }

    activationSend(url: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('post', 'v1/user/activate/send', { url }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare(success.data.message, success.status));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success.status));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    activation(email: string, code: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('post', 'v1/user/activate', { email, code }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare(success.data.message, success.status));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success.status));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    /**
     * Get all user workspaces
     */
    workspaces(app?: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'v1/user/workspaces' + ((app != '') ? '?app=' + app : '')).toPromise().then((success: any) => {
                const data: Array<UserWorkspace> = [];
                
                if(isDefined(success.data.workspaces)){
                    if(success.data.workspaces.length > 0){
                        success.data.workspaces.forEach((item: any) => {
                            data.push(new UserWorkspace(item));
                        });
                    }

                    this.dataSetCustom('workspaces', data);
                    
                    resolve(this.apiService.successDataPrepare(success.data.message, data));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, data));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    /**
     * Set workspace in backend
     */
    workspaceSet(id: string, userSet = true): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('post', 'v1/user/workspaces/' + id + '/set').toPromise().then((success: any) => {
                if(success.status){
                    const session = this.authService.sessionGet();
          console.log('session', session);
                    session.workspace = new UserWorkspace(success.data.workspace);
                    session.user.workspace = session.workspace;
            
          console.log('session', session);
                    this.authService.sessionSet(session, userSet);
                    
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    /**
     * Response workspace invitaction
     */
    workspaceInvitation(id: string, status: 'acepted'|'rejected'): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('post', 'v1/user/workspaces/' + id + '/invitation', { status }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare(success.data.message, success.status));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success.status));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}

<ui-card>
    <div class="ui-invoice-el" (click)="details()">
        <div class="ui-date-el">{{invoice.date | date: 'dd/MM/YYYY'}}</div>
        <div class="ui-invoice-el">{{invoice.title}}</div>
        <div class="ui-total-el">$&nbsp;{{invoice.total | number : '1.2-2'}}</div>
    </div>

    <div class="ui-actions-el">
        <ui-button (click)="download(invoice)" matTooltip="Descargar factura">
            <ui-icon name="download" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>
    </div>
</ui-card>
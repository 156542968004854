<ui-card>
    <div class="ui-content-el">
        <div class="ui-status-el" (click)="availability()" [ngClass]="{'online': server.available, 'partial': !server.available}" [matTooltip]="server.status.reason">
            <ui-icon *ngIf="server.available" name="check_circle" type="outlined"></ui-icon>
            <ui-icon *ngIf="!server.available" name="report_problem" type="outlined"></ui-icon>
        </div>

        <div class="ui-data-el">
            <span class="ui-name-el">{{server.name}}</span>
            <span class="ui-info-el">{{server.ipv4}} - <a href="https://{{server.host}}">{{server.host}}</a></span>
        </div>
    </div>

    <div class="ui-actions-el">
        <ui-button color="transparent" (click)="login()">
            <ui-icon name="input" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>
    </div>
</ui-card>
<ui-card>
    <div class="ui-el-image">
        <div *ngIf="user.image === ''">{{user.nameInitials}}</div>                
        <img *ngIf="user.image !== ''" [src]="user.image">
    </div>

    <div class="ui-el-data">
        <span class="ui-el-name">{{user.name}}</span>           
        <span class="ui-el-details">{{user.email}} · {{user.phone}}</span>
    </div>

    <div class="ui-el-actions">
        <ui-button color="transparent" [matMenuTriggerFor]="contextMenu">
            <ui-icon name="more_vert" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>
        
        <mat-menu #contextMenu="matMenu">
            <button mat-menu-item (click)="verify()" *ngIf="!user.verified">Verificar usuario</button>
            <button mat-menu-item (click)="paswordReset()">Blanquear contraseña</button>
            <button mat-menu-item (click)="workspaces()">Ver espacios de trabajo</button>
        </mat-menu>
    </div>
</ui-card>
<div class="ui-auth-text">
    <h1>Verificá tu dirección de correo</h1>
    <p>Tu cuenta aun no esta activa. Ingresá el código que te enviamos a tu dirección de correo para poder activar tu cuenta.</p>
    <p *ngIf="params.code !== 'undefined' && user.isSet()">Si no recibiste el correo de verificación, podemos enviarte uno nuevo.</p>
</div>
        
<div class="ui-auth-form">
    <form [formGroup]="formData">
        <ui-field class="ui-field" label="Correo electrónico" [validate]="formValidates.email" *ngIf="formData.value.email === ''">
            <input class="ui-input" type="email" formControlName="email">
        </ui-field>
        
        <ui-field class="ui-field" label="Código de verificación" [validate]="formValidates.code">
            <input class="ui-input" type="text" formControlName="code">
        </ui-field>
    </form>

    <div class="ui-auth-form-actions">
        <a (click)="screenChange('login')">Ingresar</a>
    </div>
            
    <div class="ui-auth-form-button">
        <ui-button color="primary" (click)="activation()" [disabled]="formLoading">
            <span *ngIf="!formLoading">Verificar</span>
            <ui-spinner class="ui-spinner" color="white" diameter="20" *ngIf="formLoading"></ui-spinner>
        </ui-button>

        <ui-button color="secondary" (click)="resend()" [disabled]="formActivation.loading || formActivation.disabled" *ngIf="user.isSet()">
            <span *ngIf="!formActivation.loading && !formActivation.disabled && formActivation.timing <= 1">Reenviar correo</span>
            <span *ngIf="!formActivation.loading && formActivation.disabled && formActivation.timing > 1">{{ formActivation.countdown }} Para reenviar</span>
            <ui-spinner class="ui-spinner" color="primary" diameter="20" *ngIf="formActivation.loading"></ui-spinner>
        </ui-button>
    </div>
</div>
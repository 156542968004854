import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { Integration } from 'src/app/modules/integrator/models/integration';

@Component({
  selector: 'integrator-integration-record',
  templateUrl: './integration-record.component.html',
  styleUrls: ['./integration-record.component.scss']
})
export class IntegrationRecordComponent implements OnInit {
  @Input() integration = new Integration();

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void { }

  preview(): void {
    this.router.navigate(['/integrations/' + this.integration.id]);
  }
}


<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">Crear espacio de trabajo</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card>
        <div class="ui-card-content ui-form">
            <form [formGroup]="formData">
                <ui-field class="ui-field" label="Nombre" [validate]="formValidates.name">
                    <input class="ui-input" type="text" formControlName="name">
                </ui-field>

                <ui-field class="ui-field" label="Moneda" [validate]="formValidates.currency">
                    <select class="ui-input" formControlName="currency">
                        <option *ngFor="let item of currencyData" [value]="item">{{item}}</option>
                    </select>
                </ui-field>

                <ui-field class="ui-field" label="Usuario (Dueño)" [validate]="formValidates.userId">
                    <ui-autocomplete
                        value="id"
                        name="name"
                        formControlName="userId"
                        [data]="usersDataFiltered | async"
                        [loading]="usersDataLoading"                            
                        [display]="usersDataDisplay.bind(this)"
                    ></ui-autocomplete>

                    <ui-icon slot="right" name="search" type="outlined"></ui-icon>
                </ui-field>
            </form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" (click)="create()" [disabled]="formLoading">
                <span *ngIf="!formLoading">Crear</span>
                <ui-spinner class="ui-spinner" color="white" diameter="20" *ngIf="formLoading"></ui-spinner>
            </ui-button>
        </div>
    </ui-card>
</ui-content>
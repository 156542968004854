<ui-card>
    <div class="ui-view-el" (click)="view()">
        <span class="ui-type-el">{{record.type}}</span>
        <span class="ui-name-el">{{record.name}}</span>
        <span class="ui-content-el" [attr.title]="record.content">{{record.content}}</span>
    </div>

    <span class="ui-proxied-el">
        <ui-switch (statusChange)="proxyChange($event)" [(status)]="record.proxied" matTooltip="Activar/Desactivar proxy de CloudFlare" *ngIf="['A','AAAA','CNAME'].includes(record.type)"></ui-switch>
    </span>

    <span class="ui-actions-el" (click)="edit()">Editar</span>
</ui-card>
import { ModelDefault } from "@app/library/models/commons";
import { HostbiPlan } from "./hostbi-plan";
import { camelcase } from "@app/library/utils/functions";

export class HostbiService{
    id = 0;
    name = '';
    detail = '';
    model = '';
    type = '';
    price = 0;
    currency = 0;
	period = 0;
    periodCount = 0;
    periodValidity = '';
    data: any;
    //plan!: HostbiPlan;

    constructor(parameters?: Record<string, any>) {
        if (typeof parameters !== 'undefined') {
            for (const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }

    protected filter(key: string, value: any){
        if(key === 'plan'){
            return new HostbiPlan(value);
        }

        return value;
    }
}

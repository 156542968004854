import { Moment } from 'moment';
import * as moment from 'moment';

// Utils
import { camelcase } from 'src/app/library/utils/functions';

export class BillingInvoice {
    id = 0; 
    number = 0; 
    type = '';
    subtotal = 0; 
    taxAmount = 0; 
    total = 0; 
    date: Date = moment().toDate();
    details: Array<BillingInvoiceDetail> = [];
    
    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }

    private filter(key: string, value: any){
        if(key === 'details'){
            const details: Array<BillingInvoiceDetail> = [];
            
            if(value.length > 0){
                value.forEach((item: any) => {
                    details.push(new BillingInvoiceDetail(item));
                });
            }

            return details;
        }

        return value;
    }

    get typeName() {
        const types: any = {
            invoice: 'Factura',
            credit_note: 'Nota de crédito',
            debit_note: 'Nota de débito',
            receipt: 'Recibo',
        }
        
        return types[this.type];
    }
    
    get title() {
        return this.typeName + ' Nº ' + this.number;
    }
}

export class BillingInvoiceDetail {
    quantity = 0;
    price = 0; 
    taxAmount = 0;
    amount = 0;
    description = '';
    createdAt: Date = moment().toDate();

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = value;
                }
            }
        }
    }
}
<div class="news">
    <ui-button class="close" color="transparent" (click)="close()"><ui-icon name="close" type="outlined" slot="icon-only"></ui-icon></ui-button>
    
    <div class="image">
        <img [src]="assets + 'news/automatic-debit.png'">
    </div>

    <div class="content">
        <h1>Configurá tu débito automático</h1>
        
        <p>Ya podes aderirte el débito automatico en tu cuenta y tener la tranquilidad de llevar al día tus renovaciones.</p>

        <ui-button color="primary" (click)="action()">Configurar débito automatico</ui-button>

        <p><small>¿Necesitas ayuda? <a (click)="support()"><strong>Contactá con soporte</strong></a></small></p>
    </div>
</div>

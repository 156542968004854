import { Component, Input, OnInit } from '@angular/core';

// Models
import { HostbiAccount } from '@modules/hostbi/models/hostbi-account';

// Services
import { UiService } from '@app/library/services/ui.service';

// Components
import { HostbiAccountIssuesComponent } from '@modules/hostbi/components/account-issues/account-issues.component';

@Component({
  selector: 'hostbi-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class HostbiAccountComponent implements OnInit {
  @Input() account: HostbiAccount = new HostbiAccount();

  constructor(
    private uiService: UiService
  ) {  }

  ngOnInit(): void { }

  issues(){
    if(this.account.issues.length > 0){
      this.uiService.alert({
        title: 'Problemas con la cuenta',
        content: HostbiAccountIssuesComponent,
      });
    }
  }
}

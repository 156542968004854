import { BehaviorSubject } from 'rxjs';
import _, { range } from 'underscore';

export class HttpOptionsData {
  body?: any;
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  responseType?: 'json'|'blob'|'arraybuffer'|'document'|'text'|'ms-stream'|'';
  reportProgress?: boolean;
  withCredentials?: boolean;

  constructor(options?: HttpOptionsData) {
    const _options: any = options;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _assing: any = this;

    if(typeof _options != 'undefined'){
      Object.keys(_options).forEach((option: any) => {
        _assing[option] = _options[option];
      })
    }
  }
}

export class HttpResponseData {
    status: boolean;
    message: string;
    result: any;

    constructor(status: boolean, message: string, result: any) {
        this.status = status;
        this.message = (typeof message !== 'undefined' && message !== '') ? message : '';
        this.result = result;
    }
}

export class HttpResponseError {
  errors: Array<{
    error: string;
    description: string;
    code: string|null;
  }> = [];  

  constructor(items: Array<any>) {
    if(typeof items === 'object'){
      if(items.length > 0){
        items.forEach((item) => {
          this.errors.push({
            error: item.error,
            description: item.description,
            code: (typeof item.code !== 'undefined') ? item.code : null,
          });
        });
      }
    }
  }
}

export class DataMeta {
    public id: number|string;
    public name: string;

    constructor(id?: number|string, name?: string) {
        this.id = (typeof id !== 'undefined' && (id !== '' || Number(id) > 0)) ? id : 0;
        this.name = (typeof name !== 'undefined' && name !== '') ? name : '';
    }
}

export class DataRecords {
  records: Array<any> = [];
  recordsTotal = 0;
  recordsCurrent = 0;
  pageCurrent = 0;
  pageTotal = 0;
  pageSize = 0;
  data: any;
 
  constructor(parameters?: any) {
    if(typeof parameters != 'undefined'){        
      this.recordsTotal = parameters.records_total;
      this.recordsCurrent = parameters.records_current;
      this.pageCurrent = parameters.page_current;
      this.pageTotal = parameters.page_total;
      this.pageSize = parameters.page_size;
    }
  }
}

export class DataPaginated {
    registers: any;
    registersTotal: number;
    page: number;
    pageSize: number;
    pageTotal: number;

    constructor(registers: any, total: number, pageSize: number, pages: number, page: number) {
        this.registers = registers;
        this.registersTotal = total;
        this.page = page;
        this.pageSize = pageSize;
        this.pageTotal = pages;
    }
}

export class DataPagination {
    update: BehaviorSubject<DataPagination> = new BehaviorSubject<DataPagination>(this);
    pages: Array<any> = [];
    pagesRange: Array<any> = [];
    pagesMiddle: Array<any> = [];
    pagesTotal = 0;
    pageCurrent = 0;
    pageSize = 0;
    pageStart = 0;
    pageEnd = 0;
    indexStart = 0;
    indexEnd = 0;
    itemsTotal = 0;
    pagedMax = 0;
    
    constructor(itemsTotal: number, pageCurrent = 1, pageSize = 10, pagedMax = 5) {
      this.construct(itemsTotal, pageCurrent, pageSize, pagedMax);
    }
  
    construct(itemsTotal: number, pageCurrent = 1, pageSize = 10, pagedMax = 5) {
      this.itemsTotal = itemsTotal;    
      this.pageCurrent = (pageCurrent > 0) ? pageCurrent : 1;
      this.pageSize = (pageSize > 0) ? pageSize : 10;
      this.pagesTotal = Math.ceil(this.itemsTotal / this.pageSize);
   
      this.pages = [];
      this.pagesRange = [];
      this.pagesMiddle = [];
  
      this.pagedMax = pagedMax;
      const pagedMiddle = Math.floor(this.pagedMax / 2);
      const pagedMinRange = _.range(1, pagedMiddle + 1);
      const pagedMidRange = _.range(pagedMiddle + 1, (this.pagesTotal + 1) - pagedMiddle);
      const pagedMaxRange = _.range((this.pagesTotal + 1) - pagedMiddle, this.pagesTotal + 1);
      let pagedItineration = false;
      let pagedRangeItineration = false;
    
      for(let i = 1; i <= this.pagesTotal; i++){
        if(pagedMidRange.includes(i)){
          this.pagesMiddle.push(i);
        }
        
        if(this.pagesTotal <= this.pagedMax){
          this.pages.push(i);
          this.pagesRange.push(i);
        }
        else{
          if(pagedMinRange.includes(i) || pagedMaxRange.includes(i)){
            this.pages.push(i);
            this.pagesRange.push(i);
          }
          else if(pagedMidRange.includes(pageCurrent) && i == pageCurrent){
            if(!pagedMinRange.includes(i - 1)){
              this.pages.push('<');
            }
            
            this.pages.push(i);
  
            if(!pagedMaxRange.includes(i + 1)){
              if(pagedMidRange.includes(i + 2)){
                this.pages.push('...');
              }
  
              this.pages.push('>');
            }
          }
          else if(!pagedMidRange.includes(pageCurrent) && pagedMidRange.includes(i)){
            if(pagedItineration == false){
              if(pagedMaxRange.includes(pageCurrent)){
                this.pages.push('<');
              }
              else{
                this.pages.push('...');
                this.pages.push('>');
              }
  
              this.pagesRange.push('...');
            }
  
            pagedItineration = true;
          }
  
          if(!this.pagesRange.includes('...') && this.pagesMiddle.length > 0 && pagedRangeItineration == false){
            this.pagesRange.push('...');
            pagedRangeItineration = true;
          }
        }
      }
  
      // calculate start and end item indexes
      this.indexStart = (this.pageCurrent - 1) * this.pageSize;
      this.indexEnd = Math.min(this.indexStart + this.pageSize - 1, this.itemsTotal - 1);
    
      this.update.next(this);
    }
  
    pageSizeUpdate(length: number){
      this.construct(this.itemsTotal, this.pageCurrent, length, this.pagedMax);
    }

    totalUpdate(total: number){
      this.construct(total, this.pageCurrent, this.pageSize, this.pagedMax);
    }

    pageUpdate(page: number){
      this.construct(this.itemsTotal, page, this.pageSize, this.pagedMax);
    }
}

import { Moment } from 'moment';
import * as moment from 'moment';
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { isDefined } from '../utils/functions';

export class DataNotification {
    id = '';
    subject = '';
    message = '';
    status: 'pending'|'confirmed' = 'pending';
    actions: Array<any> = [];
    loading = false;
    dateCreate: Date = moment().toDate();
    dateRead: Date = moment().toDate();   

    constructor(parameters?: any) {
        if(parameters !== undefined){
          this.id = parameters.id;
          this.status = parameters.status;
          this.dateCreate = moment(parameters.created_at).toDate();
          this.dateRead = moment(parameters.read_at).toDate();

          if(isDefined(parameters.data.subject)){
            this.subject = parameters.data.subject;
          }
          
          if(isDefined(parameters.data.message)){
            this.message = parameters.data.message;
          }
          
          if(isDefined(parameters.data.actions)){
            //this.actions = parameters.data.actions;
          }
        }
    }
}
export interface Settings {
  time: number;
  load: boolean;
  list: Record<string, any>;
}

export const SETTINGS_DEFAULTS = {
  debitStatus: null,
  automaticDebit: false,
  newsModal: false,
};
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { HttpResponseData } from '@app/library/models/utils';
import { HostbiAccount } from '@modules/hostbi/models/hostbi-account';

// Services
import { UiService } from '@app/library/services/ui.service';
import { HostbiAccountsService } from '@modules/hostbi/services/hostbi-accounts.service';

// Components
import { HostbiAccountIssuesComponent } from '@modules/hostbi/components/account-issues/account-issues.component';

@Component({
  selector: 'hostbi-account-admin',
  templateUrl: './account-admin.component.html',
  styleUrls: ['./account-admin.component.scss']
})
export class HostbiAccountAdminComponent implements OnInit {
  @Input() account: HostbiAccount = new HostbiAccount();

  constructor(
    private router: Router,
    private uiService: UiService,
    private hostbiAccountsService: HostbiAccountsService,
  ) { }

  ngOnInit(): void {
  }

  details(){
    this.router.navigate(['/hostbi/accounts/' + this.account.id]);
  }

  remove(){
    this.uiService.alert({
      title: 'Eliminar cuenta',
      message: '¿Estas seguro de querer eliminar la cuenta <strong>' + this.account.domain + '</strong>?',
      buttons: [
        { role: 'cancel', text: 'Cancelar' },
        { role: 'accept', text: 'Eliminar', color: 'primary-red', click: () => {
          this.uiService.loader(true);
    
          this.hostbiAccountsService.whitoutWorkspace().delete(this.account.id).then(() => {
            this.uiService.loader(false);
            this.uiService.dialog('Cuenta eliminada', 'La cuenta se eliminó correctamente.', 'success');
          }).catch((error: HttpResponseData) => {
            this.uiService.loader(false);
            this.uiService.dialog('Eliminar cuenta', error.message, 'error');
          });
        },
      }]
    });
  }
  
  summary(){
    this.uiService.loader(true);
    
    this.hostbiAccountsService.whitoutWorkspace().summary(this.account.id).then((response: HttpResponseData) => {
      this.uiService.loader(false);
      this.account = new HostbiAccount(response.result);
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Actualización de estado', error.message, 'error');
    });
  }

  issues(){
    if(this.account.issues.length > 0){
      this.uiService.alert({
        title: 'Problemas con la cuenta',
        content: HostbiAccountIssuesComponent,
      });
    }
  }
}

<ui-card>
    <div class="ui-service-el" [ngClass]="{'full': !removable}" (click)="details()">
        <div class="ui-icon-el">
            <ui-icon name="history_toggle_off" type="outlined"></ui-icon>
        </div>

        <div class="ui-data-el">
            <span class="ui-title-el">{{service.name}}</span>
            <span class="ui-legend-el">{{service.detail}}</span>
        </div>

        <div class="ui-data-el">
            <span class="ui-title-el">$&nbsp;{{service.amount | number : '1.2-2'}}</span>
            <span class="ui-legend-el">{{service.periodLegend}}</span>
        </div>
    </div>

    <div class="ui-actions-el" *ngIf="removable">
        <ui-button (click)="delete(service)" matTooltip="Eliminar servicio">
            <ui-icon name="close" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>
    </div>
</ui-card>
import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ui-tab',
  templateUrl: './ui-tab.component.html',
  styleUrls: ['./ui-tab.component.scss']
})
export class UiTabComponent implements OnInit {
  @Input() value = '';
  @Input() disabled = false;
  @Input() type: 'button'|'reset'|'submit' = 'button'
  @Input() layout: 'icon-bottom'|'icon-end'|'icon-hide'|'icon-start'|'icon-top'|'label-hide'|undefined = undefined;
  @Input() active = false;

  click = false;

  constructor() { }

  @HostListener('click') onClick() {
    this.click = true;
  }

  ngOnInit(): void { }
}

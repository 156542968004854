<div class="ui-transfer">
    <div class="el-status" [matTooltip]="transfer.statusName" [ngClass]="transfer.status">
        <ui-icon name="update" type="outlined" *ngIf="transfer.status === 'processing'"></ui-icon>
        <ui-icon name="report" type="outlined" *ngIf="transfer.status === 'truncated'"></ui-icon>
        <ui-icon name="published_with_changes" type="outlined" *ngIf="transfer.status === 'finished'"></ui-icon>
    </div>

    <div class="el-data">
        <strong>Migración {{transfer.type === 'schedule' ? 'automática' : 'manual' }}</strong><br>{{transfer.recordsTotal}} registros desde origen
    </div>

    <div class="el-stats">
        <div class="el-stat">
            <ui-icon name="add_circle" type="outlined"></ui-icon>
                
            <div>
                <span class="el-stat-number">{{transfer.recordsCreated}}</span>
                <span class="el-stat-legend">Creados</span>
            </div>
        </div>

        <div class="el-stat">
            <ui-icon name="edit" type="outlined"></ui-icon>
                
            <div>
                <span class="el-stat-number">{{transfer.recordsUpdated}}</span>
                <span class="el-stat-legend">Editados</span>
            </div>
        </div>

        <div class="el-stat">
            <ui-icon name="warning" type="outlined"></ui-icon>
                
            <div>
                <span class="el-stat-number">{{transfer.recordsWrong}}</span>
                <span class="el-stat-legend">Erroneos</span>
            </div>
        </div>
    </div>

    <div class="el-resume">
        Ejecutada {{transfer.type === 'schedule' ? 'automáticamente' : 'manualmente' }} en <span class="el-duration">{{transfer.duration | timeFormat}}</span> el {{transfer.createdAt | momentDate: 'DD/MM/YYYY HH:mm'}}
    </div>
</div>
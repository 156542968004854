<span class="ui-divider-left">
    <ng-content select="[slot=left]"></ng-content>
</span>

<span class="ui-divider-content">
    <ng-content></ng-content>
</span>

<span class="ui-divider-right">
    <ng-content select="[slot=right]"></ng-content>
</span>

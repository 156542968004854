import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-flag',
  templateUrl: './ui-flag.component.html',
  styleUrls: ['./ui-flag.component.scss']
})
export class UiFlagComponent implements OnInit {
  @Input() code = '';

  constructor() { }

  ngOnInit(): void {
    this.code = this.code.toLowerCase();
  }

}

<ui-card>
    <div class="ui-content-el">
        <div class="ui-status-el" [ngClass]="domain.status" [matTooltip]="domain.statusName">
            <ui-icon *ngIf="domain.status === 'pending'" name="pending_actions" type="outlined"></ui-icon>
            <ui-icon *ngIf="domain.status === 'registered'" name="preliminary" type="outlined"></ui-icon>
            <ui-icon *ngIf="domain.status === 'active'" name="done_all" type="outlined"></ui-icon>
            <ui-icon *ngIf="domain.status === 'canceled'" name="do_not_disturb_off" type="outlined"></ui-icon>
            <ui-icon *ngIf="domain.status === 'deleted'" name="close" type="outlined"></ui-icon>
        </div>

        <div class="ui-data-el">
            <span class="ui-name-el">{{domain.domain}}</span>
            <span class="ui-info-el">{{domain.registrant}} - Expira el {{domain.expirationDate | momentDate: 'DD/MM/YYYY'}} ({{ domain.nameServers && domain.nameServers.length > 0 ? domain.nameServers.join(', ') : 'Sin NS.' }})</span>
        </div>
    </div>

    <div class="ui-actions-el">
        <ui-button color="tertiary" [matMenuTriggerFor]="contextMenu">
            <ui-icon name="more_vert" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <mat-menu #contextMenu="matMenu">
            <button mat-menu-item (click)="status()">Cambiar estado</button>
            <button mat-menu-item (click)="register()" *ngIf="domain.status === 'pending'">Completar registro</button>
        </mat-menu>
    </div>
</ui-card>
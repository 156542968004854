import { Component, Input, OnInit } from '@angular/core';

// Models
import { Transfer } from 'src/app/modules/integrator/models/transfer';

@Component({
  selector: 'integrator-transfer-record',
  templateUrl: './transfer-record.component.html',
  styleUrls: ['./transfer-record.component.scss']
})
export class TransferRecordComponent implements OnInit {
  @Input() transfer = new Transfer();

  constructor() { }

  ngOnInit(): void { }
}

import { Component, Input, OnInit } from '@angular/core';
import { UiModalboxService } from 'src/app/library/components/ui-modalbox';

// Models
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiAccount, HostbiAccountDomain, HostbiAccountDomainRecord } from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { HostbiDomainsService } from 'src/app/modules/hostbi/services/hostbi-domains.service';

// Components
import { HostbiAccountDomainRecordFormComponent } from 'src/app/modules/hostbi/components/account-domain-record-form/account-domain-record-form.component';

@Component({
  selector: 'hostbi-domain-record',
  templateUrl: './domain-record.component.html',
  styleUrls: ['./domain-record.component.scss']
})
export class HostbiDomainRecordComponent implements OnInit {
  @Input() account = new HostbiAccount();
  @Input() domain = new HostbiAccountDomain();
  @Input() record = new HostbiAccountDomainRecord();

  constructor(
    private uiService: UiService,
    private uiModalboxService: UiModalboxService,
    private hostbiDomainsService: HostbiDomainsService,
  ) { }

  ngOnInit(): void { }

  view(){
    const alertView = this.uiService.alert({
      title: 'Registro DNS',
      width: 450,
      message: `
        <dl style="margin: 0px;">
          <dt>Tipo:</dt> <dd>` + this.record.type + `</dd>
          <dt>Nombre:</dt> <dd>` + this.record.name + `</dd>
          <dt>Contenido:</dt> <dd>` + this.record.content + `</dd>
          <dt>TTL:</dt> <dd>` + this.record.ttl + `</dd>
          <dt>Prioridad:</dt> <dd>` + this.record.priority + `</dd>
          ` + ((['A','AAAA','CNAME'].includes(this.record.type)) ? `<dt>Proxy de Cloudflare:</dt> <dd>` + (this.record.proxied ? `Si` : `No`) + `</dd>` : `` ) + `
        </dl>
      `,
      buttons: [
        { role: 'cancel', text: 'Cerrar' },
        { role: 'button', text: 'Eliminar registro', color: 'primary-red', click: () => {
          const alertDelete = this.uiService.alert({
            title: 'Eliminar registro DNS',
            message: '¿Estas seguro de querer eliminar este registro DNS?',
            buttons: [
              { role: 'cancel', text: 'Cancelar' },
              { role: 'button', text: 'Eliminar', color: 'primary-red', click: () => {
                this.uiService.loader(true);

                this.hostbiDomainsService.whitWorkspace().recordDelete(this.account.id, this.domain.id, this.record.id).then(() => {
                  this.record.deleted = true;
                  
                  this.uiService.loader(false);
                  this.uiService.snackbar('El registro se eliminó correctamente.');

                  alertDelete.close();
                  alertView.close();
                }).catch(() => {
                  this.uiService.loader(false);
                  this.uiService.snackbar('No es posible eliminar el registro DNS en este momento. Estamos trabajando para solucionar este problema.');
                })
              }}
            ]
          });
        }}
      ]
    });
  }

  edit(): void {
    const modal = this.uiModalboxService.open({
      component: HostbiAccountDomainRecordFormComponent,
      width: 500,
      data: {
        account: this.account,
        domain: this.domain,
        record: this.record
      }
    });

    modal.afterClosed.subscribe((response) => {
      if(typeof response.data !== 'undefined'){
        this.record = response.data;
      }
    });
  }

  proxyChange(event: any): void {
    this.hostbiDomainsService.whitWorkspace().recordUpdate(
      this.account.id,
      this.domain.id,
      this.record.id,
      { proxied: (event === true || event === 'true') }
    ).then(() => { }).catch(() => {
      this.record.proxied = false;
      this.uiService.snackbar(
        'No fue posible ' + ((event) ? 'activar' : 'desactivar') + ' el proxy para el registro ' + this.record.type + ': ' + this.record.name,
        'error'
      );
    });
  }
}

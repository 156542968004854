import { Moment } from 'moment';
import * as moment from 'moment';

// Utils
import { camelcase } from 'src/app/library/utils/functions';

export class BillingService {
    id = 0;
    name = '';
    detail = '';
    amount = 0;   
    period = '';
    periodCount = 0;    
    createdAt: Date = moment().toDate();

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }

    get periodName(): string {
        const index: Record<string, any> = {
            diary: 'Diario',
            weekly: 'Semanal',            
            monthly: 'Mensual',
            annual: 'Anual',
        }
        
        return index[this.period];
    }   
    
    get periodLegend(): string {
        let legend = '';
        
        if(this.period === 'diary'){
            legend = (this.periodCount > 1) ? 'Cada ' + this.periodCount + ' días' : 'Diariamente';
        } else if(this.period === 'weekly'){
            legend = (this.periodCount > 1) ? 'Cada ' + this.periodCount + ' semanas' : 'Semanalmente';
        } else if(this.period === 'monthly'){
            legend = (this.periodCount > 1) ? 'Cada ' + this.periodCount + ' meses' : 'Mensualmente';
        } else if(this.period === 'annual'){
            legend = (this.periodCount > 1) ? 'Cada ' + this.periodCount + ' años' : 'Anualmente';
        }
        
        return legend;
    }

    private filter(key: string, value: any){
        if(key === 'createdAt'){
            return moment(value).toDate();
        }

        return value;
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { DataRecords, HttpResponseData } from 'src/app/library/models/utils';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { CommonsLocation } from 'src/app/library/models/commons';

@Injectable()
export class CommonsService {
    public accountsListIsUpdate = new BehaviorSubject<boolean>(false);

    constructor(
        private apiService: ApiService,
    ) {}

    locationsGetAll(search = '', page = 1, pageSize = 50): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'v1/locations?' + ((search !== '') ? 'search=' + search + '&' : '') +  'page=' + page + '&page_size=' + pageSize).toPromise().then((success: any) => {
                const data: DataRecords = new DataRecords(success.data.details);

                if(data.recordsTotal > 0){
                    success.data.results.forEach((item: any) => {
                        data.records.push(new CommonsLocation(item));
                    });
                }

                resolve(this.apiService.successDataPrepare(success.data.message, data));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}

<div class="ui-button" (click)="onClick($event)">
    <span class="ui-button-icon-only">
        <ng-content select="[slot=icon-only]"></ng-content>
    </span>

    <span class="ui-button-left">
        <ng-content select="[slot=left]"></ng-content>
    </span>

    <span class="ui-button-content">
        <ng-content></ng-content>
    </span>

    <span class="ui-button-right">
        <ng-content select="[slot=right]"></ng-content>
    </span>
</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { Settings, SETTINGS_DEFAULTS } from 'src/app/modules/globals/models/settings';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { ApiService } from 'src/app/library/services/api.service';

// Components
import { NewsModalComponent } from 'src/app/modules/globals/views/news-modal/news-modal.component';

@Injectable()
export class GlobalsService {
    settings = new BehaviorSubject<Settings>({time: 0, load: false, list: {}});
    newsFirstime = true;
    
    constructor(
        private uiService: UiService,
        private apiService: ApiService
    ) { }

    settingsLoad(): Promise<Settings>{
        return new Promise((resolve) => {
            const local = JSON.parse(localStorage.getItem('settings') ?? '[]');
            const settings: Settings = typeof local.list !== 'undefined' ? local : this.settings.value;

            settings.load = true;

            if (settings.time === 0 || (Date.now() - settings.time) > (6 * 60 * 60 * 1000) /* 6 horas en milisegundos */) {
                settings.time = Date.now();
                settings.list = SETTINGS_DEFAULTS;

                this.apiService.request('get', 'v1/settings').toPromise().then((success: any) => {
                    if(typeof success.data.settings !== 'undefined'){
                        settings.list = {...SETTINGS_DEFAULTS, ...success.data.settings};
                    }                    
                    
                    localStorage.setItem('settings', JSON.stringify({...settings}));
                    this.settings.next(settings);
                    resolve(settings);
                }, (error: any) => {
                    console.error('Settings load default:', error);

                    this.settings.next(settings);
                    resolve(settings);
                });
            } else {
                this.settings.next(settings);

                resolve(settings);
            }
        });
    }

    newsInit(){
        //console.log('newsFirstime', this.newsFirstime);

        if(this.newsFirstime){
            if(
                this.settings.value.list['newsModal'] &&
                this.settings.value.list['automaticDebit'] &&
                this.settings.value.list['debitStatus'] === false
            ){
                this.newsFirstime = false;

                this.uiService.modalbox({
                    component: NewsModalComponent,
                    backdrop: true,
                });
            }
        }
    }
}

import { BehaviorSubject } from 'rxjs';
import _, { range } from 'underscore';

export class PaginationData {
    update: BehaviorSubject<PaginationData> = new BehaviorSubject<PaginationData>(this);
    pages: Array<any> = [];
    pagesRange: Array<any> = [];
    pagesMiddle: Array<any> = [];
    pagesTotal = 0; 
    pageCurrent = 0;
    pageSize = 0;
    pageStart = 0;
    pageEnd = 0;
    indexStart = 0;
    indexEnd = 0;
    itemsTotal = 0;
    pagedMax = 0;
    
    constructor(itemsTotal: number, pageCurrent = 1, pageSize = 10, pagedMax = 5) {
      this.construct(itemsTotal, pageCurrent, pageSize, pagedMax);
    }
  
    construct(itemsTotal: number, pageCurrent = 1, pageSize = 10, pagedMax = 5) {
      this.itemsTotal = itemsTotal;    
      this.pageCurrent = (pageCurrent > 0) ? pageCurrent : 1;
      this.pageSize = (pageSize > 0) ? pageSize : 10;
      this.pagesTotal = Math.ceil(this.itemsTotal / this.pageSize);
      this.pageStart = 1;
      this.pageEnd = this.pagesTotal;

      this.pages = [];
      this.pagesRange = [];
      this.pagesMiddle = [];
  
      this.pagedMax = pagedMax;
      const pagedMiddle = Math.floor(this.pagedMax / 2);
      const pagedMinRange = _.range(1, pagedMiddle + 1);
      const pagedMidRange = _.range(pagedMiddle + 1, (this.pagesTotal + 1) - pagedMiddle);
      const pagedMaxRange = _.range((this.pagesTotal + 1) - pagedMiddle, this.pagesTotal + 1);
      let pagedItineration = false;
      let pagedRangeItineration = false;
    
      for(let i = 1; i <= this.pagesTotal; i++){
        if(pagedMidRange.includes(i)){
          this.pagesMiddle.push(i);
        }
        
        if(this.pagesTotal <= this.pagedMax){
          this.pages.push(i);
          this.pagesRange.push(i);
        }
        else{
          if(pagedMinRange.includes(i) || pagedMaxRange.includes(i)){
            this.pages.push(i);
            this.pagesRange.push(i);
          }
          else if(pagedMidRange.includes(pageCurrent) && i == pageCurrent){
            if(!pagedMinRange.includes(i - 1)){
              this.pages.push('<');
            }
            
            this.pages.push(i);
  
            if(!pagedMaxRange.includes(i + 1)){
              if(pagedMidRange.includes(i + 2)){
                this.pages.push('...');
              }
  
              this.pages.push('>');
            }
          }
          else if(!pagedMidRange.includes(pageCurrent) && pagedMidRange.includes(i)){
            if(pagedItineration == false){
              if(pagedMaxRange.includes(pageCurrent)){
                this.pages.push('<');
              }
              else{
                this.pages.push('...');
                this.pages.push('>');
              }
  
              this.pagesRange.push('...');
            }
  
            pagedItineration = true;
          }
  
          if(!this.pagesRange.includes('...') && this.pagesMiddle.length > 0 && pagedRangeItineration == false){
            this.pagesRange.push('...');
            pagedRangeItineration = true;
          }
        }
      }
  
      // calculate start and end item indexes
      this.indexStart = (this.pageCurrent - 1) * this.pageSize;
      this.indexEnd = Math.min(this.indexStart + this.pageSize - 1, this.itemsTotal - 1);
    
      this.update.next(this);
    }
  
    pageSizeUpdate(length: number){
      this.construct(this.itemsTotal, this.pageCurrent, length, this.pagedMax);
    }

    totalUpdate(total: number){
      this.construct(total, this.pageCurrent, this.pageSize, this.pagedMax);
    }

    pageUpdate(page: number){
      this.construct(this.itemsTotal, page, this.pageSize, this.pagedMax);
    }
  }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-spinner-layer',
  templateUrl: './ui-spinner-layer.component.html',
  styleUrls: ['./ui-spinner-layer.component.scss'],
})
export class UiSpinnerLayerComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
  
}

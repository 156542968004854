export class HostbiPlan {
    id = 0;
    name = '';
    package = '';
    bandwidth = 0;
	storage = 0;
    status: 'active'|'inactive' = 'active';
    price = '';
    currency = '';
    period = '';

    constructor(parameters?: any) {
        if(parameters !== undefined){
            for (const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[key] = value;
                }
            }
        }
    }

    get periodName(): string {
        const index: Record<string, any> = {
            diary: 'Diario',
            weekly: 'Semanal',            
            monthly: 'Mensual',
            annual: 'Anual',
        }
        
        return index[this.period];
    } 
}

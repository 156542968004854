import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventEmitter } from 'events';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

// Models
import { ScreenSizeData } from '../models/screen-size';
import { UiAlertConfig, UiAlertRef, UiAlertService } from '../components/ui-alert';
import { UiModalbox, UiModalboxOptions, UiModalboxService } from '../components/ui-modalbox';
import { UiSidebox, UiSideboxOptions, UiSideboxService } from '../components/ui-sidebox';
import { UiSpinnerComponent } from '../components/ui-spinner/ui-spinner.component';
import { ScreenLoaderData } from '../components/ui-screen-loader/ui-screen-loader.model';

/**
 * This service is in charge of managing on screen components that can be
 * triggered from several other components. Like toasts, spinners, etc.
 */
@Injectable()
export class UiService {
    public events: EventEmitter = new EventEmitter();
    public screen = new BehaviorSubject<ScreenSizeData>({
        width: window.innerWidth,
        height: window.innerHeight
    });
    public spinner: any; 
    public screenLoaderData = new BehaviorSubject<ScreenLoaderData>({
        title: '',
        message: '',
        icon: '',
        loading: false,
        actions: [],
        loadingDiameter: 20
    });

    constructor(
        private matSnackBar: MatSnackBar,
        private overlay: Overlay,
        private uiAlert: UiAlertService,
        private uiModalbox: UiModalboxService,
        private uiSidebox: UiSideboxService
    ) { }

    /**
     * Assign classes to elements according to their width in DOM.
     */
    onResizeObserver(selector: string): void {
        const resizeObserver = new ResizeObserver((elements) => {
            const defaultBreakpoints = {
                'ui-bp-1280' : 1280,
                'ui-bp-1200' : 1200,
                'ui-bp-1100' : 1100,
                'ui-bp-1024' : 1024,
                'ui-bp-900' : 900,
                'ui-bp-800' : 800,
                'ui-bp-700' : 700,
                'ui-bp-640' : 640,
                'ui-bp-600' : 600,
                'ui-bp-500' : 500,
                'ui-bp-480' : 480,
                'ui-bp-400' : 400,
                'ui-bp-360' : 360,
            };

            elements.forEach((element) => {
                if (element.target instanceof HTMLElement) {
                    // tslint:disable-next-line: max-line-length
                    const breakpoints = element.target.dataset['breakpoints'] ? JSON.parse(element.target.dataset['breakpoints']) : defaultBreakpoints;

                    Object.keys(breakpoints).forEach((breakpoint) => {
                        if (element.contentRect.width <= breakpoints[breakpoint]) {
                            element.target.classList.add(breakpoint);
                        } else {
                            element.target.classList.remove(breakpoint);
                        }
                    });
                }
            });
        });

        const elementsResizes = document.querySelectorAll(selector);

        for (let i = 0; i < elementsResizes.length; i++) {
            resizeObserver.observe(elementsResizes[i]);
        }
    }

    onWindowResize(event: any): void {
        this.screen.next({
            width: event.target.innerWidth,
            height: event.target.innerHeight
        });
    }

    /**
     * Set loader visibility
     */
    public loader(state: boolean){
        if(state){
            this.spinner = this.overlay.create({
                hasBackdrop: true,
                backdropClass: 'ui-backdrop-modal',
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
            });
        
            this.spinner.attach(new ComponentPortal(UiSpinnerComponent))
        } else {
            this.spinner.detach();
        }
    }

    /**
     * Shows a snack bar to display errors and messages.
     */
    public snackbar(message: string, type = ''): void {
        const options: any = {
            duration: 1000 * 5
        }

        if(type != ''){
            options['panelClass'] = type;
        }
        
        this.matSnackBar.open(message, '', options);
    }

    /**
     * Shows a snack bar to display errors and messages.
     */
    public dialog(title: string, message: string, type: ''|'info'|'alert'|'error'|'success' = ''): void {
        this.uiAlert.open({
            backdrop: true,
            title,
            message,
            type,
            buttons: [{ text: 'Aceptar', role: 'accept', click: () => { }} ]
        });
    }

    public alert(config: UiAlertConfig): UiAlertRef {
        return this.uiAlert.open(config);
    }
    
    public modalbox(options: UiModalboxOptions): UiModalbox {
        return this.uiModalbox.open(options);
    }

    public sidebox(options: UiSideboxOptions): UiSidebox {
        return this.uiSidebox.open(options);
    }

    /**
     * Set screen loader component data.
     */
    public screenLoader(data: ScreenLoaderData): void {
        this.screenLoaderData.next(data);
    }
}

import { Component, ComponentRef, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss']
})
export class UiButtonComponent implements OnInit {
  @Input() color = '';
  @Input() set disabled(value: boolean) {
    this._disabled = value === true;
  }
  @Output() click = new EventEmitter();

  private _disabled = false;

  constructor(
    private readonly elementRef: ElementRef,
  ) { }

  ngOnInit() {    
    /*this.elementRef.nativeElement.parentElement.addEventListener('click', (event: any) => {
      if(this._disabled) {
        event.stopImmediatePropagation();
        event.stopPropagation();
      }
    }, true);*/
  }

  public onClick(event: Event) {
    if (this._disabled) {
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

// Models
import { DataNotification } from 'src/app/library/models/utils';

@Component({
  selector: 'ui-notification-box',
  templateUrl: './ui-notification-box.component.html',
  styleUrls: ['./ui-notification-box.component.scss']
})
export class UiNotificationBoxComponent implements OnInit {
  @Input() data: DataNotification = new DataNotification();
  @Input() openMethod = (notification: DataNotification) => { };
  @Input() readMethod = (notification: DataNotification) => { };

  constructor() { }

  ngOnInit(): void { }

  open(notification: DataNotification) {
    this.openMethod(notification);
  }

  read(notification: DataNotification) {
    this.readMethod(notification);
  }
}

<div class="ui-user-box">
    <div class="ui-el-image">
        <div *ngIf="user.image === ''">{{user.nameInitials}}</div>                
        <img *ngIf="user.image !== ''" [src]="user.image">
    </div>

    <div class="ui-el-data">
        <span class="ui-el-name">{{user.name}}</span>           
        <span class="ui-el-details">{{user.email}} · {{user.workspace.name}}</span>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { UiAlertRef } from 'src/app/library/components/ui-alert';

@Component({
  selector: 'app-integration-run-form',
  templateUrl: './integration-run-form.component.html',
  styleUrls: ['./integration-run-form.component.scss']
})
export class IntegrationRunFormComponent implements OnInit {
  public date = '';

  constructor(
    private uiAlertRef: UiAlertRef,
  ) { }

  change(event: any){
    this.uiAlertRef.data['date'] = this.date;
  }

  ngOnInit(): void { }
}

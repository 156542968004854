import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  public pusher = Pusher;
  public conection!: Echo;

  constructor( ) { 
    /*this.conection = new Echo({      
      key: environment.websocketKey,
      cluster: environment.websocketCluster,
      wsHost: environment.websocketHost,
      wsPort: environment.websocketPort,
      broadcaster: 'pusher',
      forceTLS: false,
      disableStats: true,
      //enableTransports: ['ws']
    });*/
  }
}

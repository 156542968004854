import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isDefined } from 'src/app/library/utils/functions';

// Utils
import { navigateStrategy } from 'src/app/platform/functions/platform';

// Services
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { ModuleService } from 'src/app/platform/services/module.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private moduleService: ModuleService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {    
    let response = false;
    const path: string = (isDefined(route.routeConfig?.path) ? String(route.routeConfig?.path) : '');

    const session = this.authService.sessionGet();
 
    // If the user session is established
    if (session.user.isSessionSet()) {   
      
      // If a workspace is selected
      //if(session.workspace){

        // Modules that do not require permissions
        if(['/dashboard', 'dashboard'].includes(path)){
          response = true;
        }
        
        // If it's about the authentication screens
        else if(!['/auth/login', '/auth/recover', '/auth/restore', '/auth/workspaces', '/auth/activation', '/order/new'].includes(path)){
          const module = this.moduleService.getByPath(path);

          if(module != null && this.userService.permissionsCheck(module.permissions)){
            response = true;
          }
        } 
      /*} else {
        navigateStrategy('/auth/login', this.router);
      }*/
    } else {
      navigateStrategy('/auth/login', this.router);
    }

    console.log('GUARD AUTHENTICATION', path + ' - ' + response);

    return response;
  }
}

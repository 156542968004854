import { Component, Input, OnInit } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { HostbiAccount, HostbiAccountEmail } from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiAccountsService } from 'src/app/modules/hostbi/services/hostbi-accounts.service';
import { isEmpty } from 'src/app/library/utils/functions';

@Component({
  selector: 'hostbi-email-record',
  templateUrl: './email-record.component.html',
  styleUrls: ['./email-record.component.scss']
})
export class HostbiEmailRecordComponent implements OnInit {
  @Input() account = 0;
  @Input() email: HostbiAccountEmail = new HostbiAccountEmail();
  
  public status = 'correct';
  public title = 'Cuenta operativa';
  public message = 'Esta cuenta no presenta ningun inconveniente.';  

  constructor(
    private uiService: UiService,
    private hostbiAccountsService: HostbiAccountsService,
  ) { }

  ngOnInit(): void {
    if(this.email.usagePercent > 80 && this.email.usagePercent < 100) {
      this.status = 'alert'; 
      this.title = 'Próxima a alcanzar el límite de almacenamiento'; 
      this.message = 'Si se alcanza el límite, no se podrán recibir correos en esta casilla'; 
    } else if(this.email.usagePercent >= 100) {
      this.status = 'error';
      this.title = 'Límite de almacenamiento alcanzado';
      this.message = 'No se pueden recibir correos en esta casilla.';    
    }
  }

  configData(){
    this.uiService.alert({
      title: 'Datos de configuración',
      width: 450,
      message: `
      <style>
        .email-record-modalbox-config{
          font-size: 13px;
          line-height: 140%;
        }
        .email-record-modalbox-config h3{
          font-weight: 500;
          font-size: 16px;
          margin-top: 30px;
        }
        .email-record-modalbox-config strong{
          font-weight:500;
        }
        .email-record-modalbox-config ul{
          padding-left: 25px;
        }
        .email-record-modalbox-config  a{
          color:#005FFE;
          text-decoration:underline;
        }
      </style>

      <div class="email-record-modalbox-config">
        <h3 style="margin-top: 0px;">Configuraciones seguras SSL/TLS (Recomendado)</h3>
        <ul>
          <li><strong>Nombre de usuario:</strong> ` + this.email.email + `</li>
          <li><strong>Contraseña:</strong> [CONTRASEÑA]</li>
          <li>
            <strong>Servidor entrante:</strong> mail. ` + this.email.domain + `
            <ul>
              <li><strong>Puerto POP3:</strong> 995</li>
              <li><strong>Puerto IMAP:</strong> 993</li>
            </ul>
          </li>
          <li>
            <strong>Servidor saliente:</strong> mail. ` + this.email.domain + `
            <ul>
              <li><strong>Puerto SMTP:</strong> 465</li>
            </ul>
          </li>
        </ul>

        <h3>Configuraciones sin SSL/TLS (No recomendado)</h3>
        <ul>
          <li><strong>Nombre de usuario:</strong> ` + this.email.email + `</li>
          <li><strong>Contraseña:</strong> [CONTRASEÑA]</li>
          <li>
            <strong>Servidor entrante:</strong> mail. ` + this.email.domain + `
            <ul>
              <li><strong>Puerto POP3:</strong> 110</li>
              <li><strong>Puerto IMAP:</strong> 143</li>
            </ul>
          </li>
          <li>
            <strong>Servidor saliente:</strong> mail. ` + this.email.domain + `
            <ul>
              <li><strong>Puerto SMTP:</strong> 587</li>
            </ul>
          </li>
        </ul>

        <h3>Guías</h3>
        <ul>
          <li><a href="https://clousis.com/soporte/hostbi/configurar-una-cuenta-de-correo-en-outlook/" target="_blank">Configurar una cuenta de correo en Outlook</a></li>
          <li><a href="https://clousis.com/soporte/hostbi/configurar-una-cuenta-de-correo-en-gmail/" target="_blank">Configurar una cuenta de correo en Gmail</a></li>
        </ul>
      </div>
      `,
      buttons: [{ role: 'cancel', text: 'Cerrar' }]
    });
  }

  login(): void {
    this.uiService.alert({
      title: 'Contraseña de la cuenta',
      width: 300,
      message: `
        <style>
          #email-password{
            color: #8B96AF;
            font-family: "Roboto", "Helvetica Neue", sans-serif;
            font-size: 13px;
            padding: 7px;
            outline: none;
            box-sizing: border-box;
            width: 100%;
            border: 1px solid #E9ECF3;
            border-radius: 3px;
            transition: all 0.1s linear;
            background: #fff;
          }
          #email-password:focus {
            border-color: #005FFE;
          }
        </style>
        <input type="password" id="email-password">
      `,
      buttons: [
        { role: 'cancel', text: 'Cerrar' },
        { role: 'accept', text: 'Ingresar', color: 'primary', click: () => {
          const input = <HTMLInputElement>document.getElementById('email-password');

          if(!isEmpty(input.value)){
            this.uiService.loader(true);

            this.hostbiAccountsService.whitWorkspace().emailLogin(this.account, this.email.email, input.value).then((success: HttpResponseData) => {
              this.uiService.loader(false);
              window.open(success.result.data.url, '_blank');
            }).catch((error: HttpResponseData) => {
              this.uiService.loader(false);
              this.uiService.dialog('Error', error.message, 'error');
            });
          } else {
            this.uiService.snackbar('Es necesario que ingreses la contraseña de la casilla.')
          }
        }}
      ]
    });
    
    
  }
}

import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'htmlSafe'})
export class HtmlSafePipe {
  constructor(private sanitizer: DomSanitizer){}

  transform(style: any) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
  }
}
import { Component, Input, OnInit, Type } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { UiModalbox } from 'src/app/library/components/ui-modalbox';
import { HostbiAccount } from 'src/app/modules/hostbi/models/hostbi-account';
import { HostbiPlan } from 'src/app/modules/hostbi/models/hostbi-plan';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiAccountsService } from 'src/app/modules/hostbi/services/hostbi-accounts.service';

@Component({
  selector: 'app-hostbi-account-plan',
  templateUrl: './account-plan.component.html',
  styleUrls: ['./account-plan.component.scss']
})
export class HostbiAccountPlanComponent implements OnInit {
  @Input() account = new HostbiAccount();

  public formData: FormGroup;
  public plansData: Array<HostbiPlan> = [];

  constructor(
    private uiService: UiService,
    private modalbox: UiModalbox,
    private formBuilder: FormBuilder,
    private hostbiAccountsService: HostbiAccountsService
  ) {
    this.formData = this.formBuilder.group({
      plan: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.uiService.loader(true)

    this.hostbiAccountsService.whitWorkspace().plans(this.account.id).then((success) => {
      this.uiService.loader(false);
      this.plansData = success.result;
      console.log(success.result);
    }).catch((error) => {
      this.uiService.loader(false);
      this.uiService.snackbar('Momentaneamente no es posible modificar el plan de la cuenta.');
    });
  }

  close(): void {
    this.modalbox.close();
  }

  continue(): void{
    if (this.formData.valid) {
      this.uiService.loader(true);

      this.hostbiAccountsService.whitWorkspace().planChange(this.account.id, this.formData.value.plan.id).then((result: HttpResponseData) => {
        this.modalbox.close();

        this.uiService.loader(false);
        this.uiService.dialog(
          'Plan actualizado',
          'El plan de tu cuenta fue actualizado correctamente. Puede que los cambios tomen unos minutos en verse reflejados.',
          'success'
        );
      }, (error: HttpResponseData) => {
        this.uiService.loader(false);
        this.uiService.dialog('Cambiar de plan', error.message);
      });
    } else {
      this.formData.markAllAsTouched();
      this.uiService.snackbar('Algunos datos no están completos.');
    }
  }
}

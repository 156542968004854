import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { DataRecords, HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from '../components/ui-pagination';

export class DataList {
  records: DataRecords = new DataRecords();
  pagination: PaginationData = new PaginationData(0);
  fetch: () => any = () => { }
}
            
@Injectable({
  providedIn: 'root'
})
export class ListService {
  public method?: () => Promise<HttpResponseData>;
  public records: DataRecords = new DataRecords();
  public pagination: PaginationData = new PaginationData(0);

  constructor(
    private http: HttpClient
  ) { }
  
  fetch(args: []): Promise<HttpResponseData>{
    return new Promise((resolve, reject) => { 
      if(typeof this.method !== 'undefined'){
        // eslint-disable-next-line prefer-spread
        this.method.apply(this, args).then((success: HttpResponseData) => {
          const data = new DataList()
          
          if (success.result.records.length > 0) {
            data.records = success.result.records;
            data.pagination.totalUpdate(this.records.pageTotal);
            data.pagination.pageUpdate(this.records.pageCurrent);
          }

          resolve(new HttpResponseData(true, success.message, data));
        }).catch((error: HttpResponseData) => {
          reject(error);
        });
      } else {
        reject(new HttpResponseData(false, 'The loading method is not defined in the listing service', false));
      }
    });
  }
}

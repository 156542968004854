import { Route } from '@angular/router';

// Groups

export class ModulesGroup {
    id: string;
    name = '';
    type: string;
    modules: Array<Module> = [];

    constructor(id: string, type: 'app'|'workspace', name = '') {
        this.id = id;
        this.name = name;
        this.type = type;
    }
}

export const groupsData: Array<ModulesGroup> = [];

groupsData.push(new ModulesGroup('default', 'workspace'));
groupsData.push(new ModulesGroup('settings', 'workspace', 'Configuraciones'));
groupsData.push(new ModulesGroup('workspaces', 'workspace', 'Espacio de trabajo'));
groupsData.push(new ModulesGroup('hostbi', 'app'));
groupsData.push(new ModulesGroup('hostbi-accounts', 'app', 'Mis cuentas de alojamiento'));
groupsData.push(new ModulesGroup('hostbi-manage', 'app', 'Gestión de infraestructura'));

// Modules

export class ModuleApp {
    type: string;
    id: string;
    name: string;
    routing: Route;
    modules: Array<Module>;

    constructor(type: 'app'|'workspace', id: string, name: string, modules: Array<Module>) {
        this.type = type;
        this.id = id;
        this.name = name;
        this.routing = { 
            path: id, 
            loadChildren: () => import('src/app/modules/' + id + '/' + id + '.module').then(module => module[(id[0].toUpperCase() + id.slice(1)) + 'Module']) 
        };
        this.modules = modules;
    }
}

export class Module {
    id: string;
    group: string|null;
    name: string;
    icon: string;
    path: string;
    permissions: Array<string>;    

    constructor(
        id: string, 
        group: string|null, 
        name: string, 
        icon: string, 
        path: string, 
        permissions: Array<string> = []
    ) {
        this.id = id;
        this.group = group;
        this.name = name;
        this.icon = icon;
        this.path = path;
        this.permissions = permissions;
    }
}

export const modulesData: Array<ModuleApp> = [];

// Default

modulesData.push(new ModuleApp('workspace', 'default', 'Usuario', [
    new Module('user-account','default','Mi cuenta de usuario','account_circle','user/account', ['user']),
    new Module('accounting-billing', 'default', 'Pagos y facturas', 'payment', 'billing/resume', ['accounting', 'accounting-billing']),
    //new Module('accounting-commissions', 'default', 'Comisiones', 'local_atm', 'billing/commissions', ['accounting-commissions']),
    //new Module('workspaces-settings','default','Configuración','settings_applications','workspaces/settings', ['workspace']),
]));

// Workspaces

modulesData.push(new ModuleApp('workspace', 'workspaces', 'Espacios de trabajo', [
    new Module('users', 'workspaces', 'Gestión de usuarios', 'group', 'users/accounts', ['users-manage']),
    new Module('workspaces-accounts','workspaces', 'Gestión de espacios de trabajo', 'workspaces', 'workspaces/accounts', ['workspaces-manage']),
    new Module('workspaces-account', null, 'Espacio de trabajo', 'workspaces', 'workspaces/accounts/:id', ['workspaces-manage']),
    new Module('accounting-manage', 'workspaces', 'Ordenes y renovaciones', 'payment', 'billing/orders', ['accounting-manage']),
]));

// Hostbi

modulesData.push(new ModuleApp('app', 'hostbi', 'Hostbi', [
    new Module('hostbi-dashboard', 'hostbi', 'Mis cuentas de alojamiento', 'view_carousel', 'hostbi/dashboard', ['hostbi', 'hostbi-accounts']),
    new Module('hostbi-account', null, 'Cuenta de alojamiento', 'list_alt', 'hostbi/accounts/:id', ['hostbi', 'hostbi-accounts', 'hostbi-manage', 'hostbi-accounts-manage']),
    new Module('hostbi-accounts', 'hostbi-manage', 'Gestión de cuentas', 'list_alt', 'hostbi/accounts', ['hostbi-manage', 'hostbi-accounts-manage']),
    
    //new Module('hostbi-plans', 'hostbi-manage', 'Planes', 'view_day', 'hostbi/plans', ['hostbi-manage', 'hostbi-plans-manage']),
    //new Module('hostbi-services', 'hostbi-manage', 'Servicios', 'subscriptions', 'hostbi/services', ['hostbi-manage', 'hostbi-services-manage']),
    new Module('hostbi-servers', 'hostbi-manage', 'Servidores', 'dns', 'hostbi/servers', ['hostbi-manage', 'hostbi-servers-manage']),
    new Module('hostbi-domains', 'hostbi-manage', 'Registro de dominios', 'public', 'hostbi/domains', ['hostbi-manage', 'hostbi-servers-manage']),
    new Module('hostbi-monitor', null, 'Monitoreo', 'browse_activity', 'hostbi/monitoring/:id', ['hostbi']),
    new Module('hostbi-monitoring', 'hostbi-manage', 'Cuentas de monitoreo', 'browse_activity', 'hostbi/monitoring', ['hostbi-manage']),
]));

// Integrator

modulesData.push(new ModuleApp('workspace', 'integrator', 'Integraciones', [
    new Module('integrator-dashboard', 'hostbi', 'Mis integraciones', 'move_down', 'integrations/dashboard', ['integrator']),
    new Module('integrator-integration', null, 'Integración', 'move_down', 'integrations/:id', ['integrator']),
]));

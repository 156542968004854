<ui-header>
    <ui-title>Servidores</ui-title>

    <div class="ui-servers-filters">        
        <ui-button color="primary">Alta nuevo servidor</ui-button>
    </div>
</ui-header>

<ui-content>
    <div class="ui-servers">
        <div class="ui-servers-list" *ngIf="serversData.length > 0">
            <hostbi-server-record class="ui-account" [server]="server" *ngFor="let server of serversData"></hostbi-server-record>

            <ui-spinner-layer *ngIf="serversLoading"></ui-spinner-layer>
        </div>

        <div class="ui-servers-pagination">
            <ui-pagination [fetch]="serversFetch" [data]="serversPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
    </div>

    <ui-screen-loader *ngIf="serversData.length == 0"></ui-screen-loader>
</ui-content>
import { Injectable, Injector, Type } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

// Services
import { UiAlertRef } from 'src/app/library/components/ui-alert/ui-alert.ref';

// Components
import { UiAlertComponent } from 'src/app/library/components/ui-alert/ui-alert.component';

export interface UiAlertConfig {
    backdrop?: boolean;
    backdropClass?: string;
    panelClass?: string;
    width?: number;
    type?: ''|'info'|'alert'|'error'|'success',
    title?: string;
    message?: string;
    buttons?: Array<UiAlertButton>;
    content?: any|null;
    data?: any;
}

export interface UiAlertButton {
    id?: string,
    role?: 'cancel'|'accept'|'button',
    color?: string,
    text: string,   
    click?: Function
}

const DEFAULT_CONFIG: UiAlertConfig = {
    backdrop: true,
    backdropClass: 'ui-backdrop-modal',
    panelClass: 'ui-overlay-modal',
    width: 350,
    type: '',
    title: '',
    message: '',
    buttons: [
        { text: 'Cancelar', role: 'cancel', click: () => { }},
        { text: 'Aceptar', role: 'accept', click: () => { }},
    ],
    content: null,
    data: {},
};

@Injectable({
    providedIn: 'root'
})
export class UiAlertService {
    private overlayRef: OverlayRef | undefined;

    constructor(
        private overlay: Overlay,
        private injector: Injector
    ) { }

    open<R = any, T = any>(config: UiAlertConfig = {}): UiAlertRef<R> {
        this.overlayRef = this.overlay.create(this._config({ ...DEFAULT_CONFIG, ...config }));

        const dataSet: any = { options: { ...DEFAULT_CONFIG, ...config }, data: config.data };

        const uiAlertRef = new UiAlertRef<R, T>(this.overlayRef, dataSet);
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: UiAlertRef,
                    useValue: uiAlertRef
                }
            ]
        });

        this.overlayRef.attach(new ComponentPortal(UiAlertComponent, null, injector));

        return uiAlertRef;
    }

    private _config(config: UiAlertConfig): OverlayConfig {
        const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

        const overlayConfig = new OverlayConfig({
            hasBackdrop: config.backdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }
}

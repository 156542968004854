import { Attribute, Component, Input, OnInit } from '@angular/core';
import { FormControlValidate } from '../../utils/form-validators';
import { isDefined, isEmpty } from '../../utils/functions';

@Component({
  selector: 'ui-field',
  templateUrl: './ui-field.component.html',
  styleUrls: ['./ui-field.component.scss']
})
export class UiFieldComponent implements OnInit {
  @Input() label = '';
  @Input() legend = '';
  @Input() loading = false;
  @Input() validate: FormControlValidate = new FormControlValidate();

  constructor(
    //@Attribute('label') public label: string,
  ) {    
    this.label = (!isEmpty(this.label)) ? this.label : '';
  }

  ngOnInit(): void { }
}

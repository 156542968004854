<div class="ui-alert" [style.width.px]="width" cdkDragBoundary="body" cdkDrag>
    <ui-header class="ui-alert-header" cdkDragHandle *ngIf="type === ''">
        <div class="ui-alert-title">{{title}}</div>

        <ui-button (click)="close()" color="transparent">
            <ui-icon slot="icon-only" name="close" type="outlined"></ui-icon>
        </ui-button>
    </ui-header>
    
    <ui-content class="ui-alert-content">
        <div class="ui-notify-icon ui-notify-warning" *ngIf="type == 'alert'"></div>
      
        <div class="ui-notify-icon ui-notify-info" *ngIf="type == 'info'"></div>
        
        <div class="ui-notify-icon ui-notify-error" *ngIf="type == 'error'">
            <span class="ui-notify-error-cross">
                <span class="ui-notify-line ui-notify-left"></span>
                <span class="ui-notify-line ui-notify-right"></span>
            </span>
        </div>
        
        <div class="ui-notify-icon ui-notify-success animate" *ngIf="type === 'success'">
            <span class="ui-notify-line ui-notify-tip animateSuccessTip"></span>
            <span class="ui-notify-line ui-notify-long animateSuccessLong"></span>
            
            <div class="ui-notify-placeholder"></div>
            <div class="ui-notify-fix"></div>
        </div>
        
        <div [innerHTML]="message | htmlSafe" class="ui-content-el" [ngClass]="{'content-whit-type': type !== '', 'content-whit-component': content !== null}"></div>
    
        <ng-container *ngIf="content !== null">
            <ng-container *ngComponentOutlet="content; inputs: data"></ng-container>
        </ng-container>
    </ui-content>
    
    <ui-footer class="ui-alert-footer">
        <ui-button *ngFor="let button of buttons" (click)="click(button)" [attr.color]="button.color">{{button.text}}</ui-button>
    </ui-footer>
</div>

<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">Agregar un dominio</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card class="ui-form">
        <div class="ui-card-content">
            <form [formGroup]="formData" class="ui-form ui-account-plna-change"> 
                <ui-field label="Dominio. Ej: dominio.com" [validate]="formValidates.domain">
                    <input class="ui-input" type="text" formControlName="domain">
                </ui-field>
                
                <ui-switch formControlName="pathPublic" (statusChange)="pathPublicChange($event)">
                    Usar directorio público <code>/public_html</code>
                </ui-switch>

                <ui-field label="Directorio" [validate]="formValidates.pathValue" *ngIf="!formData.value.pathPublic">
                    <input class="ui-input" type="text" formControlName="pathValue">
                </ui-field>
            </form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button (click)="close()">Cancelar</ui-button>
            <ui-button color="primary" [disabled]="!formData.valid" (click)="add()">Agregar dominio</ui-button>       
        </div>
    </ui-card>
</ui-content>
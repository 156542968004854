import moment from "moment";

// Models
import { camelcase } from "src/app/library/utils/functions";

export class Transfer {
    id = '';
    name = '';
    duration = 0;
    errors = [];
    job = null;
    status = 'processing';
    recordsCreated = 0;
    recordsTotal = 0;
    recordsUpdated = 0;
    recordsWrong = 0;
    createdAt: Date = moment().toDate();
    finishedAt: Date = moment().toDate();

    constructor(parameters?: any) {
        if(parameters !== undefined){
            for (const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }

    get type(): string{
        return this.job === null ? 'manual' : 'schedule'
    }
    
    get statusName(): string{
        const status: any = {
            'processing': 'En proceso',
            'truncated': 'Truncado',
            'finished': 'Finalizado',
        }
        
        return status[this.status];
    }

    private filter(key: string, value: any) {
        if(['createdAt', 'finishedAt'].includes(key)){
            value = moment(value).toDate();
        }
        
        return value;
    }
}
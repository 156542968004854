import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { FormsModule } from '@angular/forms';

// Modules
import { MaterialModule } from 'src/app/library/material.module';

// Pipes
import { DateAgoPipe } from 'src/app/library/pipes/date-ago.pipe';
import { HtmlSafePipe } from 'src/app/library/pipes/html-safe.pipe';
import { MomentDatePipe } from 'src/app/library/pipes/moment.pipe';
import { TimeFormatPipe } from 'src/app/library/pipes/time-format.pipe';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { UiPaneComponent } from './components/ui-pane/ui-pane.component';
import { UiToolbarComponent } from './components/ui-toolbar/ui-toolbar.component';
import { UiMenuComponent } from './components/ui-menu/ui-menu.component';
import { UiBackdropComponent } from './components/ui-backdrop/ui-backdrop.component';
import { ListService } from 'src/app/library/services/list.service';
//import { ListExportService } from 'src/app/library/services/list-export.service';
import { UiService } from 'src/app/library/services/ui.service';
import { MenuService } from './components/ui-menu/ui-menu.service';
import { UiModalboxService } from 'src/app/library/components/ui-modalbox';
import { UiSideboxService } from 'src/app/library/components/ui-sidebox';
import { UiAlertService } from 'src/app/library/components/ui-alert';
import { CommonsService } from 'src/app/library/services/common.service';
import { WebsocketService } from 'src/app/library/services/websocket.service';
import { PushService } from 'src/app/library/services/push.service';

// Directives
import { UiMenuToggleDirective } from './directives/ui-menu-toggle.directive';
import { UiHideWhenDirective } from './directives/ui-hide-when.directive';
import { 
  UiInputIntDirective,
  UiInputDecimalDirective,
  UiInputMaxLengthDirective,
  UiInputClearSpacesDirective,
  UiInputCodeDirective,
  UiInvoiceNumberMaskDirective
} from './directives/inputs.directive';

// Components
import { UiAlertComponent } from 'src/app/library/components/ui-alert/ui-alert.component';
import { UiAutocompleteComponent } from 'src/app/library/components/ui-autocomplete/ui-autocomplete.component';
import { UiSideboxComponent } from './components/ui-sidebox/ui-sidebox.component';
import { UiModalboxComponent } from './components/ui-modalbox/ui-modalbox.component';
import { UiButtonComponent } from './components/ui-button/ui-button.component';
import { UiIconComponent } from './components/ui-icon/ui-icon.component';
import { UiFlagComponent } from './components/ui-flag/ui-flag.component';
import { UiDividerComponent } from './components/ui-divider/ui-divider.component';
import { UiEmptyComponent } from './components/ui-empty/ui-empty.component';
import { UiHeaderComponent } from './components/ui-header/ui-header.component';
import { UiFooterComponent } from './components/ui-footer/ui-footer.component';
import { UiContentComponent } from './components/ui-content/ui-content.component';
import { UiNotificationBoxComponent } from './components/ui-notification-box/ui-notification-box.component';
import { UiBadgeComponent } from './components/ui-badge/ui-badge.component';
import { UiFieldComponent } from './components/ui-field/ui-field.component';
import { UiSpinnerComponent } from './components/ui-spinner/ui-spinner.component';
import { UiSpinnerLayerComponent } from './components/ui-spinner-layer/ui-spinner-layer.component';
import { UiHelperComponent } from './components/ui-helper/ui-helper.component';
import { UiScreenLoaderComponent } from './components/ui-screen-loader/ui-screen-loader.component';
import { UiTitleComponent } from './components/ui-title/ui-title.component';
import { UiStatProgressComponent } from './components/ui-stat-progress/ui-stat-progress.component';
import { UiStatusBoxComponent } from './components/ui-status-box/ui-status-box.component';
import { UiProgressBarComponent } from './components/ui-progress-bar/ui-progress-bar.component';
import { UiPaginationComponent } from './components/ui-pagination/ui-pagination.component';
import { UiCardComponent } from './components/ui-card/ui-card.component';
import { UiCardHeaderComponent } from './components/ui-card-header/ui-card-header.component';
import { UiCardContentComponent } from './components/ui-card-content/ui-card-content.component';
import { UiCardFooterComponent } from './components/ui-card-footer/ui-card-footer.component';
import { UiNoteComponent } from './components/ui-note/ui-note.component';
import { UiLabelComponent } from './components/ui-label/ui-label.component';
import { UiTabsComponent } from './components/ui-tabs/ui-tabs.component';
import { UiTabComponent } from './components/ui-tab/ui-tab.component';
import { UiSwitchComponent } from './components/ui-switch/ui-switch.component';
import { UiProgressDonutComponent } from './components/ui-progress-donut/ui-progress-donut.component';

@NgModule({
  declarations: [
    UiPaneComponent,
    UiToolbarComponent,
    UiMenuComponent,
    UiBackdropComponent,

    UiMenuToggleDirective,
    UiHideWhenDirective,
    UiInputIntDirective,
    UiInputDecimalDirective,
    UiInputMaxLengthDirective,
    UiInputClearSpacesDirective,
    UiInputCodeDirective,
    UiInvoiceNumberMaskDirective,

    UiButtonComponent,
    UiIconComponent,
    UiFlagComponent,
    UiDividerComponent,
    UiEmptyComponent,
    UiHeaderComponent,
    UiFooterComponent,
    UiContentComponent,
    UiNotificationBoxComponent,
    UiBadgeComponent,
    UiFieldComponent,
    UiSpinnerComponent,
    UiSpinnerLayerComponent,
    UiHelperComponent,
    UiSideboxComponent,
    UiModalboxComponent,
    UiScreenLoaderComponent,
    UiAlertComponent,
    UiAutocompleteComponent,
    UiTitleComponent,
    UiStatProgressComponent,
    UiStatusBoxComponent,
    UiProgressBarComponent,
    UiPaginationComponent,
    UiCardComponent,
    UiCardHeaderComponent,
    UiCardContentComponent,
    UiCardFooterComponent,
    UiNoteComponent,
    UiLabelComponent,
    UiTabsComponent,
    UiTabComponent,
    UiSwitchComponent,
    UiProgressDonutComponent,
    
    DateAgoPipe,
    HtmlSafePipe,
    MomentDatePipe,
    TimeFormatPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule
  ],
  exports: [
    UiPaneComponent,
    UiToolbarComponent,
    UiMenuComponent,
    UiBackdropComponent,

    UiMenuToggleDirective,
    UiHideWhenDirective,
    UiInputIntDirective,
    UiInputDecimalDirective,
    UiInputMaxLengthDirective,
    UiInputClearSpacesDirective,
    UiInputCodeDirective,
    UiInvoiceNumberMaskDirective,

    UiButtonComponent,
    UiIconComponent,
    UiFlagComponent,
    UiDividerComponent,
    UiEmptyComponent,
    UiHeaderComponent,
    UiFooterComponent,
    UiContentComponent,
    UiNotificationBoxComponent,
    UiBadgeComponent,
    UiFieldComponent,
    UiSpinnerComponent,
    UiSpinnerLayerComponent,
    UiHelperComponent,
    UiSideboxComponent,
    UiModalboxComponent,
    UiScreenLoaderComponent,
    UiAlertComponent,
    UiAutocompleteComponent,
    UiTitleComponent,
    UiStatProgressComponent,
    UiStatusBoxComponent,
    UiProgressBarComponent,
    UiPaginationComponent,
    UiCardComponent,
    UiCardHeaderComponent,
    UiCardContentComponent,
    UiCardFooterComponent,
    UiNoteComponent,
    UiLabelComponent,
    UiTabsComponent,
    UiTabComponent,
    UiSwitchComponent,
    UiProgressDonutComponent,

    DateAgoPipe,
    HtmlSafePipe,
    MomentDatePipe,
    TimeFormatPipe,
    MaterialModule,
  ],
  providers: [
    ApiService,
    ListService,
    //ListExportService,
    UiService,
    MenuService,
    UiModalboxService,
    UiSideboxService,
    UiAlertService,
    CommonsService,
    WebsocketService,
    PushService,
  ],
  bootstrap: [
    UiSideboxComponent,
    UiModalboxComponent,
  ],
  /*entryComponents: [
    
  ]*/
})
export class LibraryModule { }

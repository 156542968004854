import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable, startWith, switchMap } from 'rxjs';

// Utils
import { formValidate, formValidateInit } from '@app/library/utils/form-validators';

// Models
import { User } from '@app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';
import { UiModalbox } from 'src/app/library/components/ui-modalbox';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UsersService } from '@app/modules/user/services/users.service';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { isUUID } from '@app/library/utils/functions';
import { Workspace } from '../../models/workspace';

@Component({
  selector: 'app-workspace-user-assign-form',
  templateUrl: './workspace-user-assign-form.component.html',
  styleUrls: ['./workspace-user-assign-form.component.scss']
})
export class WorkspaceUserAssignFormComponent implements OnInit {
  public response = new Workspace();
  public rolesData: Array<string> = ['owner', 'admin', 'user'];

  public usersData: Array<User> = [];
  public usersDataFiltered: Observable<Array<User>>;
  public usersDataLoading = false;

  public workspacesData: Array<User> = [];
  public workspacesDataFiltered: Observable<Array<User>>;
  public workspacesDataLoading = false;
  
  public formData: FormGroup = new FormGroup({});
  public formLoading = false;
  public formValidates: any;
  public formInputsErrors: object = {
    workspace: { required: '',  pattern: 'Es necesario buscar y seleccionar un usuario.' },
    user: { required: '',  pattern: 'Es necesario buscar y seleccionar un usuario.' },
    role: { required: '' },
  };
 
  constructor(
    private uiService: UiService,
    private uiModalbox: UiModalbox,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private workspacesService: WorkspacesService,
  ) {    
    this.formData = this.formBuilder.group({
      workspace: new FormControl('', [Validators.required]),
      user: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
    });

    this.workspacesDataFiltered = this.formData.get('workspace')!.valueChanges.pipe(
      startWith(''), debounceTime(300), distinctUntilChanged(), switchMap(value => this.workspacesDataGet(value))
    );

    this.usersDataFiltered = this.formData.get('user')!.valueChanges.pipe(
      startWith(''), debounceTime(300), distinctUntilChanged(), switchMap(value => this.usersDataGet(value))
    );

    this.formValidates = formValidateInit(this.formData);
  }

  ngOnInit(): void {
  }

  close(){
    this.uiModalbox.close(this.response);
  }

  assign(): void {
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    

    if (this.formData.valid) {
      this.formLoading = true;

      const data = {...this.formData.value};
      const workspace = data.workspace;

      delete data.workspace;

      this.workspacesService.userAssign(workspace, data).then((result: HttpResponseData) => {
        this.formLoading = false;

        if (result.status) {
          this.response = result.result;
          
          this.close();

          this.uiService.dialog('Usuario asignado', 'El usuario se asignó correctamente al espacio de trabajo.', 'success');
        } else {
          this.uiService.snackbar(result.message);
        }
      }).catch((error: HttpResponseData) => {
        this.formLoading = false;
        this.uiService.snackbar(error.message);
      });
    } else {
      this.uiService.snackbar('Algunos datos estan incompletos.');
    }
  }

  workspacesDataDisplay(workspaceId: string){
    let response = '';

    if (this.workspacesData.length > 0) {
      if (this.workspacesData[0] instanceof Workspace) {
        response = this.workspacesData.find(workspace => workspace.id === workspaceId)?.name ?? 'error';
      }
    }

    return response;
  }

  private workspacesDataGet(value: string): Promise<Array<User>> {
    return new Promise((resolve, reject) => {
      // If the value of the field is not empty and if it is not a whole
      // uuid since when selecting a value the field is updated by the ID
      if (value !== '' && !isUUID(value)) {
        this.workspacesDataLoading = true;

        this.workspacesService.getAll(1, 20, { search: value }).then((response: HttpResponseData) => {
          this.workspacesDataLoading = false;
          this.workspacesData = response.result;

          resolve(this.workspacesData);
        }).catch(() => {
          this.workspacesDataLoading = false;
          resolve(this.workspacesData);
        });
      } else {
        resolve(this.workspacesData);
      }
    });
  }

  usersDataDisplay(userId: string){
    let response = '';

    if (this.usersData.length > 0) {
      if (this.usersData[0] instanceof User) {
        response = this.usersData.find(user => user.id === userId)?.name ?? 'error';
      }
    }

    return response;
  }

  private usersDataGet(value: string): Promise<Array<User>> {
    return new Promise((resolve, reject) => {
      // If the value of the field is not empty and if it is not a whole
      // uuid since when selecting a value the field is updated by the ID
      if (value !== '' && !isUUID(value)) {
        this.usersDataLoading = true;

        this.usersService.getAll(1, 20, { search: value }).then((response: HttpResponseData) => {
          this.usersDataLoading = false;
          this.usersData = response.result;

          resolve(this.usersData);
        }).catch(() => {
          this.usersDataLoading = false;
          resolve(this.usersData);
        });
      } else {
        resolve(this.usersData);
      }
    });
  }
}

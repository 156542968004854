import { Injectable } from '@angular/core';

// Models
import { DataRecords, HttpResponseData } from 'src/app/library/models/utils';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { Transfer } from '../models/transfer';

@Injectable({
  providedIn: 'root'
})
export class IntegratorTransfersService {
  public workspace = 'workspace/';

  constructor(
    private apiService: ApiService,
  ) {}

  whitWorkspace(){
    this.workspace = 'workspace/';

    return this;
  }

  whitoutWorkspace(){
    this.workspace = 'manage/';

    return this;
  }

  getAll(integrationId: number, page = 1, pageSize = 10): Promise<HttpResponseData> {
    return new Promise((resolve, reject) => {
      this.apiService.request(
        'get',
        'module/integrator/v1/' + this.workspace + 'integrations/' + integrationId + '/transfers?page=' + page + '&page_size=' + pageSize
      ).toPromise().then((success: any) => {
        if (success.status) {
          const data = new DataRecords(success.data.details);

          if(data.recordsTotal > 0){
            success.data.results.forEach((item: any) => {
              data.records.push(new Transfer(item));
            });
          }

          resolve(this.apiService.successDataPrepare(success.data.message, data));
        } else {
          reject(this.apiService.errorDataPrepare(success.data.message, success));
        }
      }, (error) => {
        reject(this.apiService.errorDataPrepare(error.data.message, error));
      });
    });
  }
}

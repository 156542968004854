import { Component, OnInit } from '@angular/core';
import { UiAlertRef } from 'src/app/library/components/ui-alert';

@Component({
  selector: 'app-billing-debit-config',
  templateUrl: './debit-config.component.html',
  styleUrls: ['./debit-config.component.scss']
})
export class BillingDebitConfigComponent implements OnInit {
  public email = '';

  constructor(
    private uiAlertRef: UiAlertRef,
  ) { }

  change(event: any){
    this.uiAlertRef.data['email'] = this.email;
  }

  ngOnInit(): void { }
}

<div class="ui-screen-loader">
    <div class="ui-screen-loader-container">
        <div class="el-icon" *ngIf="data.icon !== ''">
            <ui-icon [name]="data.icon" type="outlined"></ui-icon>
        </div>
        
        <ui-title>{{data.title}}</ui-title>
        
        <div class="el-message" [innerHTML]="data.message"></div>
        
        <div class="el-message" *ngIf="data.actions && data.actions.length > 0">
            <ui-button color="primary" *ngFor="let action of data.actions" (click)="action.action()">{{action.name}}</ui-button>
        </div>

        <ui-spinner color="primary" [diameter]="data.loadingDiameter" *ngIf="data.loading"></ui-spinner>
    </div>
</div>
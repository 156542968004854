import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-card-footer',
  templateUrl: './ui-card-footer.component.html',
  styleUrls: ['./ui-card-footer.component.scss']
})
export class UiCardFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}

import { Component, OnInit } from '@angular/core';
import { isPath } from 'src/app/library/utils/functions';
import { ActivatedRoute, Router } from '@angular/router';

// Utils
import { appCurrent, isMobile, navigateStrategy, platformUrl } from 'src/app/platform/functions/platform';

// Service
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { User } from 'src/app/modules/user/models/user';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {  
  public userData: User = new User();
  public appCurrent: any = appCurrent;
  public isMobile: boolean = isMobile;
  public whitRegister = false;
  
  public params: any;
  public screen = 'login';
  public url = platformUrl('auth');

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    /**
     * If the screen is called when the session is active it redirects to the home screen.
     * This is not done through a subscription since, instead, at login time, it shows the 
     * home screen before refreshing and causes a jump in the user interface.
     */
    const session = this.authService.sessionGet();
    this.userData = session.user;

    if (session.user.isSessionSet()) {
      navigateStrategy('/dashboard', this.router);
    } else {

      this.activatedRoute.queryParams.subscribe(params => {
        this.params = params;

        this.whitRegister = (String(this.params.register) === 'true') ? true : false;
      });

      /**
       * Detect the current screen according to the route
       */
      if (isPath('/auth/login')) {
        this.screen = 'login';
      } else if (isPath('/auth/recover')) {
        this.screen = 'recover';
      } else if (isPath('/auth/restore')) {
        if (this.params.token !== '' && typeof this.params.token !== 'undefined') {
          this.screen = 'restore';
        } else {
          this.router.navigate(['/auth/login']);
        }
      } else if (isPath('/auth/workspaces')) {
        if (session.user) {
          this.screen = 'workspaces';
        } else {
          this.router.navigate(['/auth/login']);
        }
      } else if (isPath('/auth/activation')) {
        this.screen = 'activation';
      }
    }
  }

  login(){ 
    this.authService.sessionSet(this.authService.session.value, true);

    navigateStrategy('/dashboard', this.router);
  }
}

import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// Utils
import { track } from 'src/app/platform/functions/marketing';
import { appName } from 'src/app/platform/functions/platform';
import { formValidate, formValidateInit, validateEmail } from 'src/app/library/utils/form-validators';

// Models
import { User } from 'src/app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  @Input() url = '';
  @Input() user: User = new User();
  @Input() autologin = true;
  @Output() userChange: EventEmitter<User> = new EventEmitter();
  @Output() screen: EventEmitter<string> = new EventEmitter();
  
  public params: any;
  public formData: FormGroup;
  public formLoading = false;
  public formValidates: any;
  public formInputsErrors: object = {
    firstname: { }, 
    lastname: { }, 
    email: {
      invalid:  'El email es inválido.',
    },  
    workspace: { }, 
    currency: { }, 
    passwordNew: {
      notSame: 'Las contraseñas no son similares'
    },
    passwordRepeat: {
      notSame: 'Las contraseñas no son similares'
    },
  };

  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    });

    this.formData = this.formBuilder.group({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, validateEmail()]),
      workspace: new FormControl('', [Validators.required]),
      currency: new FormControl('ARS', [Validators.required]),
      passwordNew: new FormControl('', [this.validatePassword()]),
      passwordRepeat: new FormControl('', [this.validatePassword()]),
    });

    this.formValidates = formValidateInit(this.formData);
  }

  ngOnInit() {
    this.formData.get('email')?.valueChanges.subscribe(event => {
      this.formData.get('email')?.setValue(event.toLowerCase(), {emitEvent: false});
    });
  }

  register(): void {
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    

    if (this.formData.valid) {
      this.formLoading = true;

      this.userService.register({
        firstname: this.formData.value.firstname,
        lastname: this.formData.value.lastname,
        email: this.formData.value.email,
        password: this.formData.value.password,
        workspace: this.formData.value.workspace,
        currency: this.formData.value.currency,
        app: appName,
        verification_url: this.url + '/activation?code=',
        partner: this.params.partner ?? ''
      }).then((success: HttpResponseData) => {   
        this.formLoading = false;
        
        this.user = success.result.user;
        
        this.userChange.emit(this.user);
        this.screen.emit('activation');

        track('user_register');
      }).catch((error: HttpResponseData) => {
        this.formLoading = false;
        this.uiService.snackbar(error.message);
      });
    }    
  }

  private validatePassword(): ValidatorFn {
    return (input: AbstractControl): ValidationErrors | null => {
      if (!input.root || !input.parent) {
        return null;
      }

      const root = input.root as FormGroup;

      if (root.get('passwordNew')?.value !== input.value) {
        return { notSame: true };
      }

      return null;
    };
  }
}

<div [class]="'ui-auth ui-' + screen + ((appCurrent.module != 'clousis') ? ' ui-auth-app' : '')" >
    <div class="ui-auth-area">
        <div class="ui-auth-logo">
            <div [class]="'el-logo ' + appCurrent.module + '-logo'"></div>
        </div>

        <div class="ui-auth-content">
            <app-auth-box [screen]="screen" [whitRegister]="whitRegister" [url]="url" [(user)]="userData" (login)="login()"></app-auth-box>
        </div>
    </div>

    <div class="ui-auth-footer">
        <div class="el-logo" *ngIf="!isMobile"></div>
        <p>Al usar los servicios de Clousis estas aceptando los <a href="https://clousis.com/politicas/terminos-y-condiciones/" target="_blank">términos y condiciones</a> y las <a href="https://clousis.com/politicas/politicas-de-privacidad/" target="_blank">políticas de privacidad</a>.</p>
    </div>
</div>
import { Injectable } from '@angular/core';

// Models
import { DataRecords, HttpResponseData } from 'src/app/library/models/utils';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { Transfer } from 'src/app/modules/integrator/models/transfer';
import { Integration } from 'src/app/modules/integrator/models/integration';

@Injectable({
  providedIn: 'root'
})
export class IntegratorIntegrationsService {
  public workspace = 'workspace/';

  constructor(
    private apiService: ApiService,
  ) {}

  whitWorkspace(){
    this.workspace = 'workspace/';

    return this;
  }

  whitoutWorkspace(){
    this.workspace = 'manage/';

    return this;
  }

  getAll(page = 1, pageSize = 10): Promise<HttpResponseData> {
    return new Promise((resolve, reject) => {
      this.apiService.request(
        'get',
        'module/integrator/v1/' + this.workspace + 'integrations/?page=' + page + '&page_size=' + pageSize
      ).toPromise().then((success: any) => {
        if (success.status) {
          const data = new DataRecords(success.data.details);

          if(data.recordsTotal > 0){
            success.data.results.forEach((item: any) => {
              data.records.push(new Integration(item));
            });
          }

          resolve(this.apiService.successDataPrepare(success.data.message, data));
        } else {
          reject(this.apiService.errorDataPrepare(success.data.message, success));
        }
      }, (error) => {
        reject(this.apiService.errorDataPrepare(error.data.message, error));
      });
    });
  }

  get(integrationId: number): Promise<HttpResponseData> {
    return new Promise((resolve, reject) => {
      this.apiService.request(
        'get',
        'module/integrator/v1/' + this.workspace + 'integrations/' + integrationId
      ).toPromise().then((success: any) => {
        if (success.status) {
          resolve(this.apiService.successDataPrepare(success.data.message, new Integration(success.data.integration)));
        } else {
          reject(this.apiService.errorDataPrepare(success.data.message, success));
        }
      }, (error) => {
        reject(this.apiService.errorDataPrepare(error.data.message, error));
      });
    });
  }

  run(integrationId: number, dateStart: any, dateEnd: any): Promise<HttpResponseData> {
    return new Promise((resolve, reject) => {
      this.apiService.request(
        'post',
        'module/integrator/v1/' + this.workspace + 'integrations/' + integrationId + '/run'
      ).toPromise().then((success: any) => {
        if (success.status) {
          resolve(this.apiService.successDataPrepare(success.data.message, new Transfer(success.data.domain)));
        } else {
          reject(this.apiService.errorDataPrepare(success.data.message, success));
        }
      }, (error) => {
        reject(this.apiService.errorDataPrepare(error.data.message, error));
      });
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Models
import { User } from 'src/app/modules/user/models/user';

// Service
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Component({
  selector: 'app-auth-box',
  templateUrl: './auth-box.component.html',
  styleUrls: ['./auth-box.component.scss'],
})
export class AuthBoxComponent implements OnInit {    
  @Input() screen = 'login';
  @Input() whitRegister = true;
  @Input() user: User = new User();
  @Input() url = '';
  @Output() userChange: EventEmitter<User> = new EventEmitter();
  @Output() login: EventEmitter<User> = new EventEmitter();
  
  constructor( ) { }

  ngOnInit(): void {  
    if (this.user.isSessionSet()) {
      this.login.emit(this.user);
    }
  }

  userChanged(user: any){
    this.user = user;
    this.userChange.emit(this.user);
  }

  screenChange(screen: any){
    this.screen = screen;

    if(screen === 'finish'){
      if(this.user.isSessionSet()){
        this.login.emit(this.user);
      }
    }
  }
}

import { Injectable } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import {
    HostbiAccountDomain,
    HostbiAccountDomainEntity,
    HostbiAccountDomainRecord,
    HostbiAccountDomainRecordEntity
} from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { HostbiService } from '../models/hostbi-service';

@Injectable()
export class HostbiDomainsService {
    public workspace = '';

    constructor(
        private apiService: ApiService,
    ) {}

    whitWorkspace(){
        this.workspace = 'workspace/';

        return this;
    }

    getAll(accountId: number, page = 1, pageSize = 10): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'get',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/?page=' + page + '&pageSize=' + pageSize
            ).toPromise().then((success: any) => {
                if (success.status) {
                    const domainsData: Array<HostbiAccountDomain> = [];

                    success.data.results.forEach((value: any) => {
                        domainsData.push(new HostbiAccountDomain(value));
                    });

                    resolve(this.apiService.successDataPrepare(success.data.message, domainsData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    get(accountId: number, domainId: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'get',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/' + domainId
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccountDomain(success.data.domain)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    create(accountId: number, data: HostbiAccountDomainEntity): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/',
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccountDomain(success.data.domain)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    install(accountId: number, domainId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/' + domainId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccountDomain(success.data.domain)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    delete(accountId: number, domainId: number): Promise<any> {
       return new Promise((resolve, reject) => {
            this.apiService.request(
                'delete',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/' + domainId,
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    sslStatus(accountId: number, domainId: number, status: 'off'|'flexible'|'full'|'strict'): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/' + domainId + '/ssl/status',
                {status},
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                 if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                 } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                 }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
     }

    verify(accountId: number, domain: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('post', 'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/verify', { domain }).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data.exist));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    checkExist(domain: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'public/module/hostbi/v1/' + this.workspace + 'domains/exist',
                { domain },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, { status: success.data.exist }));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    checkAvailability(domain: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'public/module/hostbi/v1/' + this.workspace + 'domains/availability',
                { domain },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, { 
                        status: success.data.availability, 
                        service: new HostbiService(success.data.service ?? {})
                    }));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
    
    recordGetAll(accountId: number, domainId: number, page = 1, pageSize = 10): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'get',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/' + domainId + '/dns?page=' + page + '&pageSize=' + pageSize
            ).toPromise().then((success: any) => {
                if (success.status) {
                    const recordsData: Array<HostbiAccountDomainRecord> = [];

                    success.data.results.forEach((value: any) => {
                        recordsData.push(new HostbiAccountDomainRecord(value));
                    });

                    resolve(this.apiService.successDataPrepare(success.data.message, recordsData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    recordGet(accountId: number, domainId: number, recordId: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'get',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/' + domainId + '/dns' + recordId
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccountDomainRecord(success.data.record)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    recordCreate(accountId: number, domainId: number, data: HostbiAccountDomainRecordEntity): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'post',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/' + domainId + '/dns',
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccountDomainRecord(success.data.record)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    recordUpdate(accountId: number, domainId: number, recordId: number, data: HostbiAccountDomainRecordEntity): Promise<HttpResponseData> {
        /*if(data.proxied !== undefined){
            data.proxied = (data.proxied) ? 1 : 0;
        }*/
        
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'put',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/' + domainId + '/dns/' + recordId,
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiAccountDomainRecord(success.data.record)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    recordDelete(accountId: number, domainId: number, recordId: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request(
                'delete',
                'module/hostbi/v1/' + this.workspace + 'accounts/' + accountId + '/domains/' + domainId + '/dns/' + recordId,
            ).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}

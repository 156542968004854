import { Component, ElementRef, HostBinding, Injector, OnInit, Renderer2, Type } from '@angular/core';

// Serivices
import { UiSidebox } from 'src/app/library/components/ui-sidebox/ui-sidebox.ref';

@Component({
  selector: 'ui-sidebox',
  templateUrl: './ui-sidebox.component.html',
  styleUrls: ['./ui-sidebox.component.scss'],
})
export class UiSideboxComponent implements OnInit {
  public data: any;
  public content: Type<any>;
  public injectData: Injector;

  constructor(
    private uiSideboxRef: UiSidebox,
    private injector: Injector,

    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.data = this.uiSideboxRef.data.data;
    this.content = this.uiSideboxRef.content;

    this.renderer.setStyle(this.elementRef.nativeElement, 'width', (this.uiSideboxRef.data.options.width ?? 400) + 'px');
    this.renderer.addClass(this.elementRef.nativeElement, this.uiSideboxRef.data.options.position ?? 'right');

    this.injectData = Injector.create({
      parent: this.injector,
      providers: [
          {
            provide: Type,
            useValue: this.uiSideboxRef.data.data
          }
      ]
    });
  }

  ngOnInit(): void { }
}

export class UserWorkspace {
    id!: string;
    name!: string;
    currency!: string;
    status!: 'acepted' | 'pending';
    apps!: Array<{name: string, module: string}>;
    permissions!: Array<string>
    role: 'owner'|'admin'|'user' = 'user';

    constructor(parameters?: any) {
        if(typeof parameters !== 'undefined'){
            for (const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[key] = value;
                }
            }
        }
    }

    isSet(){
        return typeof this.id !== 'undefined' && this.id !== '';
    }

    get roleName(): string{
        const names = {
            owner: 'Dueño',
            admin: 'Administrador',
            user: 'Usuario',
        };

        return names[this.role];
    }
}

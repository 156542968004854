
<div class="ui-form">
    <form [formGroup]="formData">
        <ui-field class="ui-field" label="Razón Social o Nombre y Apellido" [validate]="formValidates.name">
            <input class="ui-input" type="text" formControlName="name">
        </ui-field> 

        <div class="ui-form-row-double">
            <!--<ui-field class="ui-field" label="Tipo de documento" [validate]="formValidates.taxType">
                <select class="ui-input" formControlName="taxType">
                    <option *ngFor="let item of taxTypesData" [value]="item.id">{{item.name}}</option>
                </select>
            </ui-field>-->
                                
            <ui-field class="ui-field" [label]="taxNumberLabel" [validate]="formValidates.taxNumber">
                <input class="ui-input" type="text" formControlName="taxNumber">
            </ui-field>

            <ui-field class="ui-field" label="Condición fiscal" [validate]="formValidates.taxCondition">
                <select class="ui-input" formControlName="taxCondition">
                    <option *ngFor="let item of taxConditionsData" [value]="item.id">{{item.name}}</option>
                </select>
            </ui-field>
        </div>         
        
        <div class="ui-form-row-double">
            <ui-field class="ui-field" label="Dirección" [validate]="formValidates.address">
                <input class="ui-input" type="text" formControlName="address">
            </ui-field>
                                
            <ui-field class="ui-field" label="Ciudad" [validate]="formValidates.location">
                <ui-autocomplete
                    value="cityId"
                    name="name"
                    formControlName="location"
                    [data]="locationsDataFiltered | async"
                    [loading]="locationsDataLoading"                            
                    [display]="locationsDataDisplay.bind(this)"
                ></ui-autocomplete>

                <ui-icon slot="right" name="search" type="outlined"></ui-icon>
            </ui-field>
        </div>
    </form>
</div>
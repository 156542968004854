import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

// Utils
import { modulesData } from 'src/app/platform/models/module';
import { RoutingGuard } from 'src/app/platform/functions/routing.guard';

// Components
import { DashboardComponent } from 'src/app/modules/globals/views/dashboard/dashboard.component';
import { ErrorComponent } from 'src/app/modules/globals/views/error/error.component';
import { BillingOrdersComponent } from './modules/billing/views/orders/orders.component';

let routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

  // Globals module
  { path: '404', component: ErrorComponent },
  { path: 'order/new', component: BillingOrdersComponent, data: { public: true, whitoutInterfase: true }},
  { path: 'order/new/recover', component: BillingOrdersComponent, data: { public: true, whitoutInterfase: true }},
  { path: 'order/new/restore', component: BillingOrdersComponent, data: { public: true, whitoutInterfase: true }},
  { path: 'order/new/workspaces', component: BillingOrdersComponent, data: { public: true, whitoutInterfase: true }},
  { path: 'order/new/activation', component: BillingOrdersComponent, data: { public: true, whitoutInterfase: true }},
  { path: 'dashboard', canActivate: [RoutingGuard], component: DashboardComponent },

  // Auth module
  { path: 'auth', loadChildren: () => import('src/app/modules/auth/auth.module').then(module => module.AuthModule) },
  
  // User module already delcarated

  // Billing module
  { path: 'billing', loadChildren: () => import('src/app/modules/billing/billing.module').then(module => module.BillingModule) },
    
  // Workspaces module
  { path: 'workspaces', loadChildren: () => import('src/app/modules/workspaces/workspaces.module').then(module => module.WorkspacesModule) },
  
  // Hostbi module
  { path: 'hostbi', loadChildren: () => import('src/app/modules/hostbi/hostbi.module').then(module => module.HostbiModule) },
  
  // Integrator module
  { path: 'integrations', loadChildren: () => import('src/app/modules/integrator/integrator.module').then(module => module.IntegratorModule) },
];

routes = routes.concat(modulesData.map(module => (module.routing)));

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: !environment.production })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

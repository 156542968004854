import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable, startWith, switchMap } from 'rxjs';

// Utils
import { formValidate, formValidateInit, validateCUIT } from 'src/app/library/utils/form-validators';

// Models
import { CommonsLocation } from 'src/app/library/models/commons';
import { DataMeta, HttpResponseData } from 'src/app/library/models/utils';
import { BillingData, BillingDataUpdate } from 'src/app/modules/billing/models/billing';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { CommonsService } from 'src/app/library/services/common.service';
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';

@Component({
  selector: 'billing-data-form',
  templateUrl: './data-form.component.html',
  styleUrls: ['./data-form.component.scss']
})
export class BillingDataFormComponent implements OnInit {
  @Input() workspaceId: string|undefined;
  
  @Input() data: BillingData = new BillingData();
  @Input() update: () => Promise<BillingDataUpdate> = () => new Promise((resolve) => { resolve(new BillingDataUpdate()); });
  @Output() updateChange: EventEmitter<() => Promise<BillingDataUpdate>> = new EventEmitter();

  public taxTypesData: Array<DataMeta> = [
    { id: 'dni', name: 'DNI'},
    { id: 'cuit', name: 'CUIT'},
    { id: 'cuil', name: 'CUIL'}
  ];
  public taxConditionsData: Array<DataMeta> = [
    { id: 'consumidor-final', name: 'Consumidor final'},
    { id: 'exento', name: 'Exento'},
    { id: 'responsable-monotributo', name: 'Responsable Monotributo'},
    { id: 'responsable-inscripto', name: 'Responsable inscripto'}
  ];

  public locationsData: Array<CommonsLocation> = [];
  public locationsDataFiltered: Observable<Array<CommonsLocation>>;
  public locationsDataLoading = false;
  
  public formData: FormGroup = new FormGroup({});
  public formValidates: any;
  public formInputsErrors: object = {
    name: { required: '' },
    //taxType: { required: '' },
    taxNumber: { invalid: 'El número es inválido.' },
    taxCondition: { required: '' },
    address: { required: '' },
    location: { required: '',  pattern: 'Es necesario buscar y seleccionar una ciudad.' }
  };
 
  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private commonsService: CommonsService,
    private billingAccountingService: BillingAccountingService,
  ) {
    this.locationsData = [this.data.location];
    
    this.formData = this.formBuilder.group({
      name: new FormControl(this.data.name, [Validators.required]),
      //taxType: new FormControl('cuit', [Validators.required]),
      taxNumber: new FormControl(this.data.taxNumber, [Validators.required, validateCUIT()]),
      taxCondition: new FormControl(this.data.taxCondition, [Validators.required]),
      address: new FormControl(this.data.address, [Validators.required]),
      location: new FormControl(this.data.location.cityId, [Validators.required, Validators.pattern(/^\d+$/)]),
    });

    this.locationsDataFiltered = this.formData.get('location')!.valueChanges.pipe(
      startWith(''), debounceTime(300), distinctUntilChanged(), switchMap(value => this.locationsDataGet(value))
    );

    this.formValidates = formValidateInit(this.formData);
  }

  get taxNumberLabel() {
    //const filter = this.taxTypesData.filter((type) => type.id == this.formData.value.taxType);

    //return 'Número' + (typeof filter[0] !== 'undefined' ? ' de ' + filter[0].name : '');
    return 'CUIT / CUIL';
  }  

  ngOnChanges(changes: SimpleChanges) {
    if(typeof changes['data'] !== 'undefined'){
      if(changes['data'].currentValue instanceof BillingData){
        this.data = changes['data'].currentValue;

        this.locationsData = [this.data.location];
        
        this.formData.patchValue({
          name: this.data.name,
          //taxType: this.data.taxType,
          taxNumber: this.data.taxNumber,
          taxCondition: this.data.taxCondition,
          address: this.data.address,
          location: this.data.location.cityId,
        });
      }
    }
  }

  ngOnInit(): void {
    this.updateChange.emit((): Promise<BillingDataUpdate> => {
      return new Promise((resolve, reject) => { 
        formValidate(this.formInputsErrors, this.formData, this.formValidates);    
  
        if (this.formData.valid) {
          this.data.name = this.formData.value.name;
          this.data.taxType = 'cuit';
          this.data.taxNumber = this.formData.value.taxNumber;
          this.data.taxCondition = this.formData.value.taxCondition;
          this.data.address = this.formData.value.address;
          this.data.location = this.formData.value.location;
          
          this.billingAccountingService.update({
            name: this.formData.value.name,
            tax_type: 'cuit',
            tax_number: this.formData.value.taxNumber,
            tax_condition: this.formData.value.taxCondition,
            address: this.formData.value.address,
            location: this.formData.value.location
          }, this.workspaceId).then((result: HttpResponseData) => {
            if (result.status) {
              resolve(new BillingDataUpdate('Los datos se actualizaron correctamente.', this.data));
            }
            else {
              reject(new BillingDataUpdate(result.message, this.data));
            }
          }).catch(() => {
            reject(new BillingDataUpdate('No es posible actualizar los datos en este momento. Estamos trabajando para solucionar el inconveniente.', this.data));
          });
        } else {
          reject(new BillingDataUpdate('Algunos datos estan incompletos.', this.data));
        }
      });
    });
  }

  locationsDataDisplay(cityId: number){
    let response = '';

    if (this.locationsData.length > 0) {
      if (this.locationsData[0] instanceof CommonsLocation) {
        response = this.locationsData.find(loc => loc.cityId === cityId)?.name ?? '';
      }
    }

    return response;
  }

  private locationsDataGet(value: string): Promise<Array<CommonsLocation>> {
    return new Promise((resolve, reject) => {
      // If the value of the field is not empty and if it is not a whole
      // number since when selecting a value the field is updated by the ID
      if (value !== '' && isNaN(+value)) {
        this.locationsDataLoading = true;

        this.commonsService.locationsGetAll(value).then((response: HttpResponseData) => {
          this.locationsDataLoading = false;
          this.locationsData = response.result.records;
          resolve(this.locationsData.filter((option: CommonsLocation) => option.name.toLowerCase().includes(String(value).toLowerCase())));
        }).catch(() => {
          this.locationsDataLoading = false;
          resolve(this.locationsData);
        });
      } else {
        resolve(this.locationsData);
      }
    });
  }
}

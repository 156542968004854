import { CommonModule } from '@angular/common'; 
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');

// Modules
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LibraryModule } from 'src/app/library/library.module';
import { PlatformModule } from 'src/app/platform/platform.module';
import { GlobalsModule } from 'src/app/modules/globals/globals.module';
import { AuthModule } from 'src/app/modules/auth/auth.module';
import { UserModule } from 'src/app/modules/user/user.module';
import { WorkspacesModule } from 'src/app/modules/workspaces/workspaces.module';
import { HostbiModule } from 'src/app/modules/hostbi/hostbi.module';
import { BillingModule } from 'src/app/modules/billing/billing.module';
import { IntegratorModule } from 'src/app/modules/integrator/integrator.module';

// Service
import { PushService } from '@library/services/push.service';
import { ApiInterceptor } from '@library/services/api.service';
import { AuthService } from '@modules/auth/services/auth.service';
import { UserService } from '@modules/user/services/user.service';

// Components
import { AppComponent } from 'src/app/app.component';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    BrowserAnimationsModule,
    
    LibraryModule,
    PlatformModule,
    GlobalsModule,
    AuthModule,  
    UserModule,
    WorkspacesModule,
    HostbiModule,
    BillingModule,
    IntegratorModule,

    MatMenuModule
  ],
  providers: [
    AuthService,
    UserService,
    PushService,
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService): any => {
        return (): Promise<any> => {
          return authService.sessionVerification();
        };
      },
      multi: true,
      deps: [AuthService]
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }

<ui-header>
    <ui-title>Cuentas de monitoreo</ui-title>

    <div class="ui-monitoring-filters">
        <ui-field>
            <input class="ui-input" type="text" placeholder="Buscar cuenta" (keydown)="monitoringSearch($event)" [(ngModel)]="monitoringSearchQuery">
            
            <ui-button slot="right" (click)="monitoringSearch()">
                <ui-icon slot="icon-only" name="search" type="outlined"></ui-icon>
            </ui-button>
        </ui-field>
    </div>
</ui-header>

<ui-content>
    <div class="ui-monitoring">
        <div class="ui-monitoring-list" *ngIf="monitoringData.length > 0">
            <hostbi-monitor-record class="ui-monitor" [monitor]="monitor" *ngFor="let monitor of monitoringData"></hostbi-monitor-record>

            <ui-spinner-layer *ngIf="monitoringLoading"></ui-spinner-layer>
        </div>

        <div class="ui-monitoring-pagination">
            <ui-pagination [fetch]="monitoringFetch" [data]="monitoringPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
    </div>

    <ui-screen-loader *ngIf="monitoringData.length == 0"></ui-screen-loader>
</ui-content>
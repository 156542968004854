import { NgModule } from '@angular/core';

// Services
import { ModuleService } from 'src/app/platform/services/module.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    ModuleService,
  ]
})
export class PlatformModule { }

<ui-header>
    <ui-title>Integraciones</ui-title>
</ui-header>

<ui-content>
    <div class="ui-integrations">
        <div class="ui-integrations-list" *ngIf="integrationsData.length > 0">
            <integrator-integration-record class="ui-account" [integration]="integration" *ngFor="let integration of integrationsData"></integrator-integration-record>

            <ui-spinner-layer *ngIf="integrationsLoading"></ui-spinner-layer>
        </div>

        <div class="ui-integrations-pagination">
            <ui-pagination [fetch]="integrationsFetch" [data]="integrationsPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
    </div>

    <ui-screen-loader *ngIf="integrationsData.length == 0"></ui-screen-loader>
</ui-content>
import { Component, Input, OnInit } from '@angular/core';

// Models
import { BillingInvoice } from 'src/app/modules/billing/models/invoices';
import { BillingBalance } from 'src/app/modules/billing/models/billing';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';

// Components
import { BillingInvoiceDetailsComponent } from 'src/app/modules/billing/components/invoice-details/invoice-details.component';

@Component({
  selector: 'billing-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class BillingInvoiceComponent implements OnInit {
  @Input() invoice: BillingInvoice = new BillingInvoice();
  @Input() download: (invoice: BillingInvoice) => void = (): void => { };
  
  public balance: BillingBalance = new BillingBalance();

  constructor(
    private uiService: UiService,
    private billingAccountingService: BillingAccountingService
  ) {
    this.billingAccountingService.balanceData.subscribe((balance) => {
      this.balance = balance;
    });
  }

  ngOnInit(): void { }

  details(){
    this.uiService.modalbox({
      component: BillingInvoiceDetailsComponent,
      backdrop: true,
      data: { 
        invoice: this.invoice,
        download: this.download,
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Utils
import { RoutingGuard } from 'src/app/platform/functions/routing.guard';

// Views
import { WorkspacesComponent } from 'src/app/modules/workspaces/views/workspaces/workspaces.component';
import { WorkspaceDetailsComponent } from 'src/app/modules/workspaces/views/workspace-details/workspace-details.component';

const routes: Routes = [
  { path: '', redirectTo: '/workspaces/accounts', pathMatch: 'full' },
  { path: 'workspaces/accounts', component: WorkspacesComponent, canActivate: [RoutingGuard] },
  { path: 'workspaces/accounts/:id', component: WorkspaceDetailsComponent, canActivate: [RoutingGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkspacesRoutingModule { }

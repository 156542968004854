import { Injectable, Injector, Type } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

// Services
import { UiSidebox } from 'src/app/library/components/ui-sidebox/ui-sidebox.ref';

// Components
import { UiSideboxComponent } from 'src/app/library/components/ui-sidebox/ui-sidebox.component';

export interface UiSideboxOptions {
    backdrop?: boolean;
    backdropClass?: string;
    panelClass?: string;
    width?: number;
    position?: 'left'|'right';
    title?: string;
    data?: any;
    component: any;
}

const DEFAULT_CONFIG: UiSideboxOptions = {
    backdrop: true,
    backdropClass: 'ui-backdrop-modal',
    panelClass: 'ui-overlay-modal',
    width: 350,
    position: 'right',
    title: '',
    data: {},
    component: ''
};

@Injectable({
    providedIn: 'root'
})
export class UiSideboxService {
    private overlayRef: OverlayRef | undefined;

    constructor(
        private overlay: Overlay,
        private injector: Injector
    ) { }

    open<R = any, T = any>(options: UiSideboxOptions): UiSidebox<R> {
        this.overlayRef = this.overlay.create(this._config({ ...DEFAULT_CONFIG, ...options }));

        const dataSet: any = { options: { ...DEFAULT_CONFIG, ...options }, data: options.data };

        const uiSideboxRef = new UiSidebox<R, T>(this.overlayRef, options.component, dataSet);
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: UiSidebox,
                    useValue: uiSideboxRef
                }
            ]
        });

        this.overlayRef.attach(new ComponentPortal(UiSideboxComponent, null, injector));

        return uiSideboxRef;
    }

    private _config(config: UiSideboxOptions): OverlayConfig {
        const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

        const overlayConfig = new OverlayConfig({
            hasBackdrop: config.backdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }
}

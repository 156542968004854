import { Component, Input, OnInit } from '@angular/core';

// Model
import { User } from 'src/app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UsersService } from 'src/app/modules/user/services/users.service';

@Component({
  selector: 'user-record',
  templateUrl: './user-record.component.html',
  styleUrls: ['./user-record.component.scss']
})
export class UserRecordComponent implements OnInit {
  @Input() user = new User();

  constructor(
    private uiService: UiService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void { }

  verify(){

  }
  
  paswordReset(){
    return new Promise((resolve, reject) => { 
      this.uiService.alert({
        title: 'Blanquear contraseña',
        message: 'Si continúa, la contraseña del usuario será modificada.',
        buttons: [
            { text: 'Cancelar', role: 'cancel', click: () => { reject(''); } },
            { text: 'Continuar', color: 'primary', click: () => {
                this.uiService.loader(true);

                this.usersService.passwordReset(this.user.id).then((success: HttpResponseData) => {
                  this.uiService.loader(false);
                  this.uiService.dialog('Nueva contraseña', `
                    <p>` + success.message + `</p>
                    <p><strong>Clave:</strong> ` + success.result + `</p>
                  `);
                }).catch((error: HttpResponseData) => {
                  this.uiService.loader(false);
                  this.uiService.snackbar(error.message);
                });             
            } }
          ]
      });
    });
  }

  workspaces(){
    const workspaces: Array<string> = [];

    this.user.workspaces.forEach((workspace) => {
      workspaces.push(workspace.name);
    });
    
    this.uiService.dialog('Espacios de trabajo', `
      <ul>
        <li>` + workspaces.join('</li><li>') + `</li>
      </ul>
    `);
  }
}

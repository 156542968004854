import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { LibraryModule } from 'src/app/library/library.module';
import { UserModule } from 'src/app/modules/user/user.module';
import { BillingModule } from 'src/app/modules/billing/billing.module';
import { IntegratorRoutingModule } from 'src/app/modules/integrator/integrator-routing.module';

// Services
import { IntegratorIntegrationsService } from 'src/app/modules/integrator/services/integrations.service';

// Components
import { TransferRecordComponent } from 'src/app/modules/integrator/components/transfer-record/transfer-record.component';
import { IntegrationRecordComponent } from 'src/app/modules/integrator/components/integration-record/integration-record.component';
import { IntegrationRunFormComponent } from 'src/app/modules/integrator/components/integration-run-form/integration-run-form.component';

// Views
import { IntegrationComponent } from '@app/modules/integrator/views/integration/integration.component';
import { IntegrationsComponent } from '@app/modules/integrator/views/integrations/integrations.component';

@NgModule({
  declarations: [
    TransferRecordComponent,
    IntegrationRecordComponent,
    IntegrationRunFormComponent,

    IntegrationComponent,
    IntegrationsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LibraryModule,
    UserModule,
    BillingModule,
    IntegratorRoutingModule
  ],
  exports: [ ],
  providers: [
    IntegratorIntegrationsService,
  ]
})
export class IntegratorModule { }

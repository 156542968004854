<ui-card>
    <div class="ui-content-el">
        <div class="ui-data-el">
            <span class="ui-name-el">{{workspace.name}}</span>
        </div>
    </div>

    <div class="ui-actions-el">
        <ui-button color="transparent" (click)="details(workspace)">
            <ui-icon name="input" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>
    </div>
</ui-card>
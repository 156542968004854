import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { AuthComponent } from 'src/app/modules/auth/views/auth/auth.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full', data: { public: true, whitoutInterfase: true }},
  { path: 'auth/login', component: AuthComponent, data: { public: true, whitoutInterfase: true }},
  { path: 'auth/recover', component: AuthComponent, data: { public: true, whitoutInterfase: true }},
  { path: 'auth/restore', component: AuthComponent, data: { public: true, whitoutInterfase: true }},
  { path: 'auth/workspaces', component: AuthComponent, data: { public: true, whitoutInterfase: true }},
  { path: 'auth/activation', component: AuthComponent, data: { public: true, whitoutInterfase: true }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }

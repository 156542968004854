
<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">Cambiar plan</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card class="ui-form">
        <div class="ui-card-content">
            <p>Estos son los planes disponibles para realizar un cambio en tu cuenta <span>{{account.domain}}</span>.</p>
            <p>Podes ver más información sobre el servicio en <a href="https://hostbi.clousis.com" targe="_blank">https://hostbi.clousis.com</a></p>
            
            <form [formGroup]="formData" class="ui-form ui-account-plan-change"> 
                <div class="ui-form-row">
                    <mat-radio-group formControlName="plan">
                        <div class="el-plan" *ngFor="let plan of plansData">
                            <mat-radio-button [value]="plan">
                                <div class="el-plan-content">
                                    <p><strong>{{plan.name}}</strong> $&nbsp;{{plan.price | number: '1.2-2'}} / {{plan.periodName}}</p>
                                    <small><strong>{{plan.bandwidth}} Mb</strong> de transferencia · <strong>{{plan.storage}} Mb</strong> de almacenamiento </small>
                                </div>
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button (click)="close()">Cancelar</ui-button>
            <ui-button color="primary" [disabled]="!formData.valid" (click)="continue()">Cambiar plan</ui-button>       
        </div>
    </ui-card>
</ui-content>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { Module } from 'src/app/platform/models/module';
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';
import { Integration } from 'src/app/modules/integrator/models/integration';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { IntegratorIntegrationsService } from 'src/app/modules/integrator/services/integrations.service';

@Component({
  selector: 'app-integrator-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  public moduleData: Module | undefined;

  public integrationsData: Array<Integration> = [];
  public integrationsPagination: PaginationData = new PaginationData(0);
  public integrationsFetch = this.integrationsList.bind(this);
  public integrationsLoading = false;

  public errors = {
    load: {
      icon: 'move_down',
      title: 'Cargando integraciones',
      message: 'Estamos buscando tus integraciones.',
      loading: true,
    },
    empty: {
      icon: 'move_down',
      title: 'Sin integraciones',
      message: 'Aun no dispones de ninguna integración.',
    },
    error: {
      icon: 'move_down',
      title: 'Integraciones',
      message: 'No se pudo cargar el listado de integraciones. Intentar nuevamente.',
    }
  };

  constructor(
    private uiService: UiService,
    private integrationsService: IntegratorIntegrationsService,
  ) {}

  ngOnInit(): void {
    this.integrationsList();
  }

  integrationsList(page = 1): void{
    this.integrationsLoading = true;
    this.uiService.screenLoader(this.errors.load);
    
    this.integrationsService.getAll(page, this.integrationsPagination.pageSize).then((success: HttpResponseData) => {
      this.integrationsLoading = false;

      if (success.result.records.length > 0) {
        this.integrationsData = success.result.records;
        this.integrationsPagination.totalUpdate(success.result.recordsTotal);
        this.integrationsPagination.pageUpdate(page);
      } else {
        this.uiService.screenLoader(this.errors.empty);
      }
    }).catch(() => {
      this.integrationsLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }
}

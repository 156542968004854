import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Utils
import { RoutingGuard } from 'src/app/platform/functions/routing.guard';

// Views
import { IntegrationComponent } from '@app/modules/integrator/views/integration/integration.component';
import { IntegrationsComponent } from '@app/modules/integrator/views/integrations/integrations.component';

const routes: Routes = [
  { path: '', redirectTo: '/integrations/dashboard', pathMatch: 'full' },
  { path: 'integrations/dashboard', component: IntegrationsComponent, canActivate: [RoutingGuard] },
  { path: 'integrations/:id', component: IntegrationComponent, canActivate: [RoutingGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegratorRoutingModule { }

import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

export const screenTransitions = trigger('screenTransitions', [
    transition('* => slideUp', [
        style({ 
            position: 'relative'
        }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%'
            })
        ], { optional: true }),
        query(':enter', [
            style({ 
                transform: 'translateY(25%)',
                opacity: 0
            })
        ], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(':leave', [
                animate('150ms ease-out', style({ 
                    opacity: 0
                }))
            ], { optional: true }),
            query(':enter', [
                animate('150ms ease-out', style({
                    transform: 'translateY(0%)',
                    opacity: 1
                }))
            ], { optional: true }),
            query('@*', animateChild(), { optional: true })
        ]),
    ]),
    transition('* => slideDown', [
        style({ 
            position: 'relative'
        }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%'
            })
        ], { optional: true }),
        query(':enter', [
            style({ 
                transform: 'translateY(-25%)',
                opacity: 0
            })
        ], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(':leave', [
                animate('150ms ease-out', style({ 
                    opacity: 0
                }))
            ], { optional: true }),
            query(':enter', [
                animate('150ms ease-out', style({
                    transform: 'translateY(0%)',
                    opacity: 1
                }))
            ], { optional: true }),
            query('@*', animateChild(), { optional: true })
        ]),
    ]),
    transition('* => slideRight', [
        style({ 
            position: 'relative'
        }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%'
            })
        ], { optional: true }),
        query(':enter', [
            style({ 
                transform: 'translateX(25%)',
                opacity: 0
            })
        ], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(':leave', [
                animate('150ms ease-out', style({ 
                    opacity: 0
                }))
            ], { optional: true }),
            query(':enter', [
                animate('150ms ease-out', style({
                    transform: 'translateX(0%)',
                    opacity: 1
                }))
            ], { optional: true }),
            query('@*', animateChild(), { optional: true })
        ]),
    ]),
    transition('* => slideLeft', [
        style({ 
            position: 'relative'
        }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%'
            })
        ], { optional: true }),
        query(':enter', [
            style({ 
                transform: 'translateX(-25%)',
                opacity: 0
            })
        ], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(':leave', [
                animate('150ms ease-out', style({ 
                    opacity: 0
                }))
            ], { optional: true }),
            query(':enter', [
                animate('150ms ease-out', style({
                    transform: 'translateX(0%)',
                    opacity: 1
                }))
            ], { optional: true }),
            query('@*', animateChild(), { optional: true })
        ]),
    ]),
    transition('* <=> *', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%'
            })
        ], { optional: true }),
        query(':enter', [ style({  opacity: 0 }) ], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(':leave', [ animate('150ms ease-out', style({ opacity: 0 })) ], { optional: true }),
            query(':enter', [ animate('150ms ease-out', style({ opacity: 1 })) ], { optional: true }),
            query('@*', animateChild(), { optional: true })
        ]),
    ]),
]);
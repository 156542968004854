
<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">Solicitar baja de la cuenta</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card class="ui-form">
        <div class="ui-card-content">
            <form [formGroup]="formData">
                <ui-field class="ui-field" label="Motivo" [validate]="formValidates.motive">
                    <select class="ui-input" formControlName="motive">
                        <option *ngFor="let item of motivesData" [value]="item.name">{{item.name}}</option>
                    </select>
                </ui-field>

                <ui-field class="ui-field" label="¿Recomendarias nuestro servicio?" [validate]="formValidates.recommendation">
                    <select class="ui-input" formControlName="recommendation">
                        <option *ngFor="let item of recommendationsData" [value]="item.name">{{item.name}}</option>
                    </select>
                </ui-field>
                        
                <ui-field class="ui-field" label="Recomendaciones adicionales" [validate]="formValidates.note">
                    <textarea class="ui-input" formControlName="note"></textarea>
                </ui-field>
            </form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" [disabled]="!formData.valid" (click)="delete()">Solicitar baja</ui-button>    
        </div>
    </ui-card>
</ui-content>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';


// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { formValidate, formValidateInit, validateEmail } from 'src/app/library/utils/form-validators';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {
  @Output() screen: EventEmitter<string> = new EventEmitter();
  
  public params: any;

  public formData: UntypedFormGroup;
  public formLoading = false;
  public formValidates: any;
  public formInputsErrors: object = {
    email: {
      invalid:  'El email es inválido.',
    },     
    passwordNew: {
      invalid:  'La contraseña debe tener 8 o más dígitos.',
      notSame: 'Las contraseñas no son similares'
    },
    passwordRepeat: {
      notSame: 'Las contraseñas no son similares'
    },
  };

  constructor(
    private uiService: UiService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
  ) { 
    this.formData = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required, validateEmail()]),
      passwordNew: new UntypedFormControl('', [Validators.required, this.validatePasswordLength()]),
      passwordRepeat: new UntypedFormControl('', [Validators.required, this.validatePasswordLength(), this.validatePassword()]),
    });

    this. formValidates = formValidateInit(this.formData);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  screenChange(screen: string){
    this.screen.emit(screen);
  }

  restore(){
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    

    if (this.formData.valid) {
      this.formLoading = true;

      this.userService.passwordRestore(
        this.params.token,
        this.formData.value.email,
        this.formData.value.passwordNew
      ).then((success: HttpResponseData) => {   
        this.formData.reset();
        this.formLoading = false;
        this.uiService.snackbar(success.message);
        
        this.screen.emit('login');
      }).catch((error: HttpResponseData) => {
        this.formLoading = false;
        this.uiService.snackbar(error.message);
      });
    }
  }

  private validatePasswordLength(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validationResult: ValidationErrors | null = (control.value !== null && control.value.length >= 8) ? null : { invalid: true };

      if (typeof validationResult === 'undefined' || control.value == '') {
        validationResult = null;
      }

      return validationResult;
    };
  }
  
  private validatePassword(): ValidatorFn {
    return (input: AbstractControl): ValidationErrors | null => {
      if (!input.root || !input.parent) {
        return null;
      }

      const root = input.root as FormGroup;

      if (root.get('passwordNew')?.value !== input.value) {
        return { notSame: true };
      }

      return null;
    };
  }
}

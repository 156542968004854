import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-empty',
  templateUrl: './ui-empty.component.html',
  styleUrls: ['./ui-empty.component.scss']
})
export class UiEmptyComponent implements OnInit {
  @Input() icon = '';

  constructor() { }

  ngOnInit(): void { }
}

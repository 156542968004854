import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';

// Models
import { DataRecords, HttpResponseData, HttpResponseError } from 'src/app/library/models/utils';
import { BillingOrder, BillingOrderDetail } from 'src/app/modules/billing/models/orders';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { ApiService } from 'src/app/library/services/api.service';

// Components
import { BillingOrdersComponent } from 'src/app/modules/billing/views/orders/orders.component';

@Injectable()
export class BillingOrdersService {
    constructor(
        private uiService: UiService,
        private apiService: ApiService,
    ) {}

    getUnpaids(): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'module/accounting/v1/workspace/orders/?status=unpaid').toPromise().then((success: any) => {
                resolve(this.apiService.successDataPrepare(success.data.message, success.data.details.records_total));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    get(id: number, workspace?: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'module/accounting/v1/' + (workspace ?? 'workspace') + '/orders/' + id).toPromise().then((success: any) => {
                resolve(this.apiService.successDataPrepare(success.data.message, new BillingOrder(success.data.order)));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error.data));
            });
        });
    }

    getAll(page = 1, pageSize = 10, filters?: { status?: string; }, workspace: string|null = 'workspace'): Promise<HttpResponseData> {
        const params: string = this.apiService.paramsBuild(this.apiService.paramsFilter(filters));

        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/accounting/v1/' + (workspace === null ? '' : workspace + '/') + 'orders/?' + 'page=' + page + '&page_size=' + pageSize + ((params !== '') ? '&' + params : '')).toPromise().then((success: any) => {
                const data: DataRecords = new DataRecords(success.data.details);

                if(data.recordsTotal > 0){
                    success.data.results.forEach((item: any) => {
                        data.records.push(new BillingOrder(item));
                    });
                }

                resolve(this.apiService.successDataPrepare(success.data.message, data));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    paymentLink(id: number, returnUrl: string): Promise<HttpResponseData> { 
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'module/accounting/v1/workspace/orders/' + id + '/payment/link/?return_url=' + returnUrl).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data.link));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    requestsExecute(id: number, workspace?: string): Promise<HttpResponseData> { 
        return new Promise((resolve, reject) => {
            this.apiService.request('post', 'module/accounting/v1/' + (workspace ?? 'workspace') + '/orders/' + id + '/execute').toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data.link));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    new(params: any): Promise<any> {
        return new Promise((resolve) => { 
            const modalbox = this.uiService.modalbox({
                component: BillingOrdersComponent,
                panelClass: 'ui-order-modal',
                data: params,
            });
  
            modalbox.afterClosed.subscribe((data: any) => {
                resolve(data.data);
            });
        });
    }

    create(workspace: string, data: {
        type: 'order'|'renewal';
        subtotal: number;
        expires_on?: Date|string;
        notify?: boolean;
        return_url?: string;
        details: Array<BillingOrderDetail>;
      }): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            if(typeof data.expires_on != 'undefined'){
                data.expires_on = moment(data.expires_on).format('YYYY-MM-DD');
            }

            this.apiService.request(
                'post',
                'module/accounting/v1/' + workspace + '/orders',
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if(success.status){
                  resolve(this.apiService.successDataPrepare(success.data.message, new BillingOrder(success.data.order)));
                } else {
                  reject(this.apiService.errorDataPrepare(success.data.message, new HttpResponseError(success.data.errors)));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data.errors)));
            });
        });
      }
}

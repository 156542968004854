import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Utils
import { User } from 'src/app/modules/user/models/user';
import { isUndefined } from 'src/app/library/utils/functions';
import { HttpResponseData } from 'src/app/library/models/utils';

// Models
import { UserWorkspace } from 'src/app/modules/user/models/workspaces';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-workspaces',
  templateUrl: './workspaces.component.html',
  styleUrls: ['./workspaces.component.scss']
})
export class WorkspacesComponent implements OnInit {
  @Input() user: User = new User();
  @Output() userChange: EventEmitter<User> = new EventEmitter();
  @Output() screen: EventEmitter<string> = new EventEmitter();
  
  public workspacesData: Array<UserWorkspace> = [];
  
  public formLoading: { [key:string]: boolean } = { first: false };
  
  constructor(
    private uiService: UiService,
    private authService: AuthService,
    private userService: UserService,
  ) {
    if(!this.user.isSet() || !this.user.verified){
      this.screen.emit('login');
    }

    if(isUndefined(this.user.workspaces) || this.user.workspaces.length <= 0){
      this.screen.emit('login');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void { }

  screenChange(screen: string){
    this.screen.emit(screen);
  }

  set(id: string): void {
    this.formLoading[id] = true;

    this.userService.workspaceSet(id, false).then((success: HttpResponseData) => {   
      this.formLoading[id] = false;

      this.user.workspace = this.authService.session.value.workspace;

      this.userChange.emit(this.user);
      this.screen.emit('finish');
    }).catch((error: HttpResponseData) => {
      this.formLoading[id] = false;
  
      this.uiService.snackbar(error.message);
    });
  }

  invitationRejected(workspace: UserWorkspace): void {
    this.uiService.alert({
      title: 'Rechazar invitación',
      message: '¿Estás seguro de querer rechazar esta invitación?',
      width: 300,
      buttons: [
        { text: 'Cancelar', role: 'cancel',  },
        { text: 'Rechazar', role: 'accept', click: () => {            
            this.formLoading[workspace.id] = true;

            this.userService.workspaceInvitation(workspace.id, 'rejected').then((success: HttpResponseData) => {   
              this.formLoading[workspace.id] = false;              
              this.userService.dataSetCustom('workspaces', this.user.workspacesRemove(workspace.id));
            }).catch((error: HttpResponseData) => {
              this.formLoading[workspace.id] = false;          
              this.uiService.snackbar(error.message);
            });

          }
        }
      ]
    });
  }

  invitationAcepted(workspace: UserWorkspace): void {
    this.formLoading[workspace.id] = true;

    this.userService.workspaceInvitation(workspace.id, 'acepted').then((success: HttpResponseData) => {   
      this.formLoading[workspace.id] = false;      
      workspace.status = 'acepted';
    }).catch((error: HttpResponseData) => {
      this.formLoading[workspace.id] = false;  
      this.uiService.snackbar(error.message);
    });
  }

  logout(): void {
    this.formLoading['first'] = true;
    
    this.authService.logout().then((success: HttpResponseData) => {   
      this.formLoading['first'] = false;

      this.screen.emit('login');
    }).catch((error: HttpResponseData) => {
      this.formLoading['first'] = false;
      this.uiService.snackbar(error.message);
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-stat-progress',
  templateUrl: './ui-stat-progress.component.html',
  styleUrls: ['./ui-stat-progress.component.scss']
})
export class UiStatProgressComponent implements OnInit {
  @Input() name = '';
  @Input() value = 0;
  @Input() total = 0;
  @Input() unity = '';
  @Input() percent = 0;
  @Input() color: string|'primary'|'success'|'alert'|'error' = 'primary';

  constructor() { }

  ngOnInit(): void { }

}

import moment from "moment";

// Models
import { camelcase } from "src/app/library/utils/functions";

export class Integration {
    id = 0;
    name = '';
    job = '';
    fields: Array<IntegrationField> = [];
    origin = new IntegrationClient();
    destination = new IntegrationClient();
    creatredAt: Date = moment().toDate();
    updatedAt: Date = moment().toDate();

    constructor(parameters?: any) {
        if(parameters !== undefined){
            for (const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }

    private filter(key: string, value: any) {
        if(['origin', 'destination'].includes(key)){
            value = new IntegrationClient(value);
        } else if(key === 'fields'){
            const _value: Array<IntegrationField> = [];

            Object.keys(value).forEach((key) => {
                _value.push(new IntegrationField(key, value[key]));
            });

            value = _value;
        }

        return value;
    }
}

class IntegrationClient{
    id = 0;
    name = '';
    type = '';
    typeName = '';

    constructor(parameters?: any) {
        if(parameters !== undefined){
            for (const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = value;
                }
            }
        }
    }
}

class IntegrationField{
    constructor(
        public origin = '',
        public destination = '',
    ) { }
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-icon',
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.scss']
})
export class UiIconComponent implements OnInit {
  @Input() name: string = '';
  @Input() type: string = 'outlined';

  constructor() { }

  ngOnInit(): void { }

}

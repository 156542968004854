import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
  transform(value: any, format = 'DD/MM/YYYY HH:mm'): string {
    return moment(value).format(format);
  }
}
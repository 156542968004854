import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { LibraryModule } from 'src/app/library/library.module';

// Services
import { GlobalsService } from 'src/app/modules/globals/services/globals.service';

// Components
import { DashboardComponent } from 'src/app/modules/globals/views/dashboard/dashboard.component';
import { ErrorComponent } from 'src/app/modules/globals/views/error/error.component';
import { NewsModalComponent } from 'src/app/modules/globals/views/news-modal/news-modal.component';

@NgModule({
  declarations: [ 
    DashboardComponent,
    ErrorComponent,
    NewsModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LibraryModule
  ],
  providers: [
    GlobalsService,
  ]
})
export class GlobalsModule { }

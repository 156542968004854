import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: number): string {
    const minutes = Math.floor(value / 60); // Convertir segundos a minutos

    if (minutes >= 10080) {
      const weeks = Math.floor(minutes / 10080);
      return `${weeks} semana${weeks > 1 ? 's' : ''}`;
    }

    if (minutes >= 1440) {
      const days = Math.floor(minutes / 1440);
      return `${days} día${days > 1 ? 's' : ''}`;
    }

    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      return `${hours} hora${hours > 1 ? 's' : ''}`;
    }

    if (value < 60) {
      return `${value} segundo${value > 1 ? 's' : ''}`;
    }

    return `${minutes} minuto${minutes > 1 ? 's' : ''}`;
  }
}
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Chart, ChartConfiguration, ChartData, ChartType } from 'chart.js';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiMonitoringService } from 'src/app/modules/hostbi/services/hostbi-monitoring.service';

// Components
import { HostbiMonitoringLiveTraffic, HostbiMonitoring } from "../../models/hostbi-monitoring";

@Component({
  selector: 'app-hostbi-monitoring-details',
  templateUrl: './monitoring-details.component.html',
  styleUrls: ['./monitoring-details.component.scss']
})
export class HostbiMonitoringDetailsComponent implements OnInit, AfterViewInit {
  public id = 0;
  public monitoring = new HostbiMonitoring();
  public loadData = false;
  
  public livetrafficData: Array<HostbiMonitoringLiveTraffic> = [];
  public livetrafficLoading = false;

  public errors = {
    load: {
      title: 'Cargando datos',
      message: 'Estamos cargando la información de la cuenta.',
      icon: 'browse_activity',
      loading: true,
    },
    error: {
      title: 'Cuenta de monitoreo',
      message: 'No es posible obtener los datos en este momento. Estamos trabajando para solucionar el inconveniente.',
      icon: 'browse_activity',
    },
    invalid: {
      title: 'Cuenta de monitoreo',
      message: 'Al aprecer, no se accedió correctamente a los detalles. Podes volver al listado de cuentas e ingresar nuevamente.',
      icon: 'report_problem',
    }
  };

  public chartType: ChartType = 'line';
  public chartData: ChartData<'line'> = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Bloqueos totales',
        fill: true, // Rellena el área bajo la línea
        tension: 0.4, // Suaviza la curva de la línea
        borderColor: '#0050db',
        backgroundColor: 'rgba(0, 80, 219, 0.2)',
        pointBackgroundColor: '#0050db',
      },
      {
        data: [],
        label: 'Complejos',
        fill: false,
        borderColor: '#8196BB',
        tension: 0.4,
        backgroundColor: 'rgba(0, 71, 196, 0.2)',
        pointBackgroundColor: '#8196BB',
      },
      {
        data: [],
        label: 'Fuerza bruta',
        fill: false,
        borderColor: '#8196BB',
        tension: 0.4,
        backgroundColor: 'rgba(0, 71, 196, 0.2)',
        pointBackgroundColor: '#8196BB',
      }
    ]
  };
  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0)',
        },
        ticks: {
          count: 7,
          color: '#8196BB'
        },
        title: {
          color: '#8196BB'
        }
      },
      y: {
        position: 'right',
        min: 0,
        grid: {
          color: '#CDDBF2',
          lineWidth: 1,
        },
        ticks: {
          count: 5,
          color: '#8196BB'
        },
        border: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          borderRadius: 0,
          color: '#8196BB', // Color de la tipografía
          font: {
            size: 13, // Tamaño de la fuente
            weight: '400' // Grosor de la fuente
          },
          boxHeight: 6, // Altura del círculo
          boxWidth: 6, // Ancho del círculo
          usePointStyle: true, // Usar el estilo de punto en lugar de cuadro
          padding: 25 // Espacio entre las leyendas
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    elements: {
      line: {
        tension: 0.4 // Suaviza la línea
      },
      point: {
        radius: 3,
        borderColor: '#fff',
        borderWidth: 1
      }
    }
  };

  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,
    private hostbiMonitoringService: HostbiMonitoringService,
  ) {
    this.id = Number(this.route.snapshot.paramMap.get('id') ?? 0);
  }

  ngOnInit(): void {
    this.uiService.screenLoader(this.errors.load);

    if(this.id > 0){
      const initData = [];
  
      initData.push(this.hostbiMonitoringService.whitWorkspace().get(this.id));
      initData.push(this.hostbiMonitoringService.whitWorkspace().livetraffic(this.id));
      
      Promise.all(initData).then(values => { 
        this.loadData = true;

        this.monitoring = values[0].result;
        this.livetrafficData = values[1].result;

        console.log('monitoring', this.monitoring);
        console.log('livetraffic', this.livetrafficData);

        setTimeout(() => {
          this.chartSetGradientBackground();
        }, 600);
      }).catch(() => {
        this.uiService.loader(false);
        this.uiService.screenLoader(this.errors.error);
      });
    } else {
      this.uiService.screenLoader(this.errors.invalid);
    }
  }

  ngAfterViewInit() { }

  // Live Traffic

  livetrafficFetch(){
    this.livetrafficLoading = true;

    this.hostbiMonitoringService.whitWorkspace().livetraffic(this.id).then((success: HttpResponseData) => {        
      this.livetrafficLoading = false;
      this.livetrafficData = success.result;
    }).catch(() => {
      this.livetrafficLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }

  // Actions

  ipActivity(action: 'lock'|'unlock'): void {
    this.uiService.loader(true);

    this.hostbiMonitoringService.whitWorkspace().ipActivity(action, this.id).then((success: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Ip desbloqueada', 'IP desbloqueada correctamente.', 'success');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Error', error.message, 'error');
    });
  }

  // Chart

  chartSetGradientBackground() {
    const ctx = (document.getElementsByTagName('canvas')[0] as HTMLCanvasElement).getContext('2d');

    if (ctx) {
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);

      gradient.addColorStop(0, 'rgba(0, 80, 219, 0.2)');
      gradient.addColorStop(1, 'rgba(0, 80, 219, 0.0)');

      this.chartData.labels = this.monitoring.stats.labels;
      this.chartData.datasets[0].data = this.monitoring.stats.total;
      this.chartData.datasets[0].backgroundColor = gradient;
      this.chartData.datasets[1].data = this.monitoring.stats.complex;
      this.chartData.datasets[2].data = this.monitoring.stats.bruteforce;

      const chart = Chart.getChart('myChart');

      if (chart) {
        chart.update();
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

// Utils
import { appName } from 'src/app/platform/functions/platform';

// Models
import { User } from 'src/app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';
import { AuthSession } from 'src/app/modules/auth/models/session';
import { CryptoJSAesEncrypt } from 'src/app/library/utils/functions';
import { UserWorkspace } from 'src/app/modules/user/models/workspaces';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { PushService } from '@app/library/services/push.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public session: BehaviorSubject<AuthSession> = new BehaviorSubject<AuthSession>({user: new User(), workspace: new UserWorkspace(), userSet: true});
  
  constructor(
    private apiService: ApiService,
    private pushService: PushService,
  ) {
    // In each HTTP request the status of the session is verified, if it is false it is closed

    this.apiService.sessionValidation.subscribe((status) => {
      if(status === false){
        this.sessionSet({user: new User(), workspace: new UserWorkspace(), userSet: true});
      }
    });
  }  

  /**
   * Verify that there is a session started
   */
  sessionVerification(): Promise<boolean> {
    return new Promise((resolve) => {            
      const data: AuthSession = {user: new User(), workspace: new UserWorkspace(), userSet: true};
      
      this.sessionCheck(appName).then((success: HttpResponseData) => {
        if(success.result.user !== false){
          data.user = new User(success.result.user);
        }

        if(success.result.workspace !== false){
          data.workspace = new UserWorkspace(success.result.workspace)
        }
        
        this.sessionSet(data);
        
        if(data.user.isSet() && data.workspace.isSet()){
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch(() => {
        this.sessionSet(data);

        resolve(false);
      });
    });
  }

  /**
   * Generate the session value
   */
  sessionSet(session: AuthSession, userSet = true): void {
    session.userSet = userSet;

    this.session.next(session);
  }

  /**
   * Gets the value of the session
   */
  sessionGet(): AuthSession {
    return this.session.value;
  }

  /**
   * Check that the session and workspace are set
   */
  sessionValidate(): boolean {
    return this.session.value.user.isSet() && this.session.value.workspace.isSet();
  }

  /**
   * Check that the backend session is established
   */
  sessionCheck(app = ''): Promise<HttpResponseData> {
    return new Promise((resolve, reject) => {
      
      this.apiService.request('get', 'v1/user/session' + ((app != '') ? '?app=' + app : '')).toPromise().then((success: any) => {
        const session: AuthSession = {
          user: success.data.user,
          workspace: success.data.workspace,
          userSet: false
        };

        resolve(this.apiService.successDataPrepare(success.data.message, session));
      }, (error: any) => {
        reject(this.apiService.errorDataPrepare(error.data.message, error));
      });
    });
  }

  logout(userSet = true): Promise<HttpResponseData> {
    return new Promise((resolve, reject) => { 
      this.apiService.request('post', 'v1/user/logout').toPromise().then((success: any) => {
        if (success.status) {
          this.sessionSet({user: new User(), workspace: new UserWorkspace(), userSet});
          this.pushService.removeUser();

          resolve(this.apiService.successDataPrepare(success.data.message, success.data));
        } else {
          reject(this.apiService.errorDataPrepare(success.data.message, success.data));
        }
      }, (error: any) => {
        reject(this.apiService.errorDataPrepare(error.data.message, error.data));
      });
    });
  }

  login(email: string, password: string, userSet = true): Promise<HttpResponseData> {
    return new Promise((resolve, reject) => {            
      this.apiService.request('get', 'v1/xsrf').toPromise().then(() => {
        
        this.apiService.request(
          'post',
          'v1/user/login',
          { email, password: CryptoJSAesEncrypt(password) },
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        ).toPromise().then((success: any) => {
          if (success.status) {
            const user = new User(success.data.user);
            
            if(success.data.workspace !== false){
              user.workspace = new UserWorkspace(success.data.workspace);
            }

            const session = { user, workspace: user.workspace, userSet };

            this.sessionSet(session, session.userSet);
            this.pushService.setUser(user.id, user.name);
                    
            resolve(this.apiService.successDataPrepare(success.data.message, session));
          } else {
            reject(this.apiService.errorDataPrepare(success.data.message, success.data));
          }
        }, (error: any) => {
          reject(this.apiService.errorDataPrepare(error.data.message, error));
        });

      }, (error: any) => {
        reject(this.apiService.errorDataPrepare(error.data.message, error));
      });
      
    });
  }
}

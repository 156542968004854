// Utils
import { camelcase } from 'src/app/library/utils/functions';
import { CommonsLocation } from 'src/app/library/models/commons';

export class BillingData {
    name = '';
    taxAgent = '';
    taxEmitter = '';
    taxType = '';
    taxNumber = '';
    taxCondition = '';
    address = '';
    location: CommonsLocation = new CommonsLocation();
    paymentGateway = '';
    paymentSubscription = '';
    paymentSubscriptionAmount = '';
    currency = '';

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }

    private filter(key: string, value: any){
        if(key === 'location'){
            return new CommonsLocation(value);
        } else if(key === 'paymentGateway'){
            const gateways: any = {
                mercadopago: 'Mercado Pago',
                stripe: 'Stripe',
                paypal: 'PayPal',
            };
            
            return gateways[value];
        }

        return value;
    }

    isSet(): boolean {
        return this.name !== '' && this.taxType !== '' && this.taxNumber !== '' && this.taxCondition !== '' && this.address !== '' && this.location.cityId > 0;
    }
}

export class BillingDataUpdate {
    message: string;
    data: BillingData;

    constructor(message = '', data = new BillingData()) {
        this.message = message;
        this.data = data;
    }
}

export class BillingBalance {
    ordersAmount = 0;
    renewalsAmount = 0;
    balance = 0;
	debitStatus = false;
	services = 0;
	servicesAmount = 0;
	charges = 0;
	chargesAmount = 0;
	credits = 0;
	creditsAmount = 0;

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = value;
                }
            }
        }
    }
}

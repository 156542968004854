<ui-card>
    <div class="ui-content-el">
        <ui-status-box [color]="(!monitor.firewall.status ? 'alert' : 'accent')">
            <ui-icon [name]="(!monitor.firewall.status ? 'report_problem' : 'check_circle')" type="outlined"></ui-icon>
        </ui-status-box>

        <div class="ui-data-el">
            <span class="ui-name-el">{{monitor.name}} {{monitor.domain}}</span>
            <span class="ui-info-el">{{monitor.hasAccount ? 'El monitoreo está asociado a la cuenta de alojamiento ' + monitor.account.domain : 'Este monitoreo no está asociado a ninguna cuenta de alojamiento.'}}</span>
        </div>
    </div>

    <div class="ui-actions-el">
        <ui-button color="transparent" (click)="preview()">
            <ui-icon name="input" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>
    </div>
</ui-card>
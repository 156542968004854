import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { HostbiServer } from 'src/app/modules/hostbi/models/hostbi-server';

// Services
import { ApiService } from 'src/app/library/services/api.service';

@Injectable()
export class HostbiServersService {
    public accountsListIsUpdate = new BehaviorSubject<boolean>(false);

    constructor(
        private apiService: ApiService,
    ) {}

    getAll(page = 1, pageSize = 10): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/manage/servers?page=' + page + '&pageSize=' + pageSize).toPromise().then((success: any) => {
                if (success.status) {
                    const serversData: Array<HostbiServer> = [];

                    success.data.results.forEach((value: any) => {
                        serversData.push(new HostbiServer(value));
                    });

                    resolve(this.apiService.successDataPrepare(success.data.message, serversData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    get(id: number): Promise<any> {
        const promise = new Promise((resolve, reject) => {
           
            this.apiService.request('get', 'module/hostbi/v1/manage/servers/' + id).toPromise().then((success: any) => {
                if (success.status) {
                    const serverData = new HostbiServer(success.data.server);

                    resolve(this.apiService.successDataPrepare(success.data.message, serverData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });

        return promise;
    }

    create(name: string, host: string, ipv4: string, ipv6: string, dns:Array<string>, user: string, password: string): Promise<any> {
        const data = {
            name,
            host,
            ipv4,
            ipv6,
            dns,
            user,
            password
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.request('post', 'module/hostbi/v1/manage/servers/', data).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });

        return promise;
    }

    update(id: number, name: string, host: string, ipv4: string, ipv6: string, dns:Array<string>, user: string, password: string): Promise<any> {
        const data = {
            name,
            host,
            ipv4,
            ipv6,
            dns,
            user,
            password
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.request('put', 'module/hostbi/v1/manage/servers/' + id, data).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });

        return promise;
    }

    delete(id: number): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            this.apiService.request('delete', 'module/hostbi/v1/manage/servers/' + id).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });

        return promise;
    }

    login(id: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/manage/servers/' + id + '/login').toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}

import moment from "moment";

export interface SupportPostsFilters { 
    page?: number, 
    per_page?: number, 
    search?: string, 
    category?: number,
    orderby?: string, 
    order?: string, 
}

export class SupportPost {
    id = 0;
    date: Date = moment().toDate();
    slug = '';
    status = '';
    linkExternal = '';
    linkSelf = '';
    title = '';
    content = '';
    excerpt = '';

    constructor(parameters?: any) {
        if(typeof parameters != 'undefined' && parameters !== null){
            this.id = parameters.id;
            this.date = moment(parameters.date).toDate();
            this.slug = parameters.slug;
            this.status = parameters.status;
            this.linkExternal = parameters.link;
            this.linkSelf = parameters._links.self;
            this.title = parameters.title.rendered;
            this.content = parameters.content.rendered;
            this.excerpt = parameters.excerpt.rendered;
        }
    }  
}

export class SupportCategory {
    id = 0;
    name = '';
    slug = '';
    icon = '';
    count = 0;
    taxonomy  = '';   
    description = '';
    linkExternal = '';
    linkSelf = '';

    constructor(parameters?: any) {
        if(typeof parameters != 'undefined' && parameters !== null){
            this.id = parameters.id;
            this.name = parameters.name;
            this.slug = parameters.slug;
            this.icon = parameters.meta.icon[0] ?? 'bookmark';
            this.count = parameters.count;
            this.taxonomy = parameters.taxonomy;  
            this.description = parameters.description;
            this.linkExternal = parameters.link;
            this.linkSelf = parameters._links.self.href;
        }
    }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Utils
import { formValidate, formValidateInit } from 'src/app/library/utils/form-validators';
import { UiModalbox } from 'src/app/library/components/ui-modalbox';

// Models
import { DataMeta, HttpResponseData } from 'src/app/library/models/utils';
import { User } from 'src/app/modules/user/models/user';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss']
})
export class SupportFormComponent implements OnInit {
  public userData: User = new User();

  public areasData: Array<DataMeta> = [
    new DataMeta('soporte', 'Soporte técnico'),
    new DataMeta('administracion', 'Administración'),
    new DataMeta('comercial','Comercial'),
  ];

  public reasonsData: {[key:string]: Array<string>} = {
    'soporte': [
      'Inconveniente para enviar y recibir correos',
      'Cuenta suspendida',
      'Datos de accesos a correo',
      'Sitio fuera de linea',
      'Modificaciones en sitio web',
      'Ayuda con error en sitio web',
      'Otro',
    ],
    'administracion': [
      'Cambio de datos de facturación',
      'Realizar un deposito en cuenta',
      'Detalle de servicios',
      'Otro',
    ],
    'comercial': [
      'Adquirir una nueva cuenta',
      'Adquieir un servicio',
      'Detalles de servicios',
      'Otro',
    ],
  };

  public reasonsDataSelect: Array<string> = this.reasonsData['soporte'];
  
  public formData: FormGroup = new FormGroup({});
  public formValidates: any;
  public formInputsErrors: object = {
    area: { required: 'Seleccioná el área' },
    reason: { required: 'Seleccioná un motivo' },
    subject: { required: 'Ingresá un asunto' },
    firstname: { required: 'Ingresá tu nombre' },
    email: { required: 'Ingresá una dirección de correo' },
    message: { },
  };

  constructor(
    private uiModalbox: UiModalbox,
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private usersService: UserService,
  ) {
    this.usersService.userData.subscribe((value: User) => {
      this.userData = value;

      this.formData = this.formBuilder.group({
        area: new FormControl('', [Validators.required]),
        reason: new FormControl('', [Validators.required]),
        subject: new FormControl('', [Validators.required]),
        firstname: new FormControl(this.userData.firstname, [Validators.required]),
        email: new FormControl(this.userData.email, [Validators.required]),
        message: new FormControl('', [Validators.required]),
      });

      this.formValidates = formValidateInit(this.formData);
    });
  }

  ngOnInit(): void { }

  close(){
    this.uiModalbox.close();
  }

  areaChange(): void {
    this.reasonsDataSelect = this.reasonsData[this.formData.value.area];
  }

  onSend(): void {
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    
    
    if (this.formData.valid) {
      this.uiService.loader(true);

      this.usersService.support({
        area: this.formData.get('area')?.value,
        reason: this.formData.get('reason')?.value,
        subject: this.formData.get('subject')?.value,
        firstname: this.formData.get('firstname')?.value,
        email: this.formData.get('email')?.value,
        message: this.formData.get('message')?.value
      }).then((result: HttpResponseData) => {
        if (result.status) {
          this.close();

          this.uiService.loader(false);
          this.uiService.dialog('Ticket emitido', 'Tus ticket se emitió correctamente. A la brevedad un agente respondrá tu consulta', 'success');
        } else {
          this.uiService.loader(false);
          this.uiService.dialog('Emitir ticket', result.message, 'error');
        }
      }, () => {
        this.uiService.loader(false);
        this.uiService.dialog('Emitir ticket', 'No es posible emitir un ticket en este momento. Estamos trabajando para solucionar el inconveniente.', 'error');
      });
    }
  }
}

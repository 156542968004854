<ui-card>
    <div class="ui-status-el" [ngClass]="status" matTooltip="{{title}}: {{message}}">
        <ui-icon *ngIf="status === 'correct'" name="mark_email_read" type="outlined"></ui-icon>
        <ui-icon *ngIf="status === 'alert'" name="mark_email_read" type="outlined"></ui-icon>
        <ui-icon *ngIf="status === 'error'" name="mail_lock" type="outlined"></ui-icon>
    </div>
    
    <ui-stat-progress [name]="email.email" [value]="email.usage" [total]="email.total" [percent]="email.usagePercent" [unity]="email.unity" [color]="((status === 'correct') ? 'primary' : status)"></ui-stat-progress>

    <button (click)="configData()" matTooltip="Datos de configuración">
        <ui-icon name="settings" type="outlined"></ui-icon>
    </button>

    <button (click)="login()" matTooltip="Webmail">
        <ui-icon name="input" type="outlined"></ui-icon>
    </button>
</ui-card>
import { Location } from '@angular/common'
import { NavigationEnd, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import isValidDomain from 'is-valid-domain';
import * as CryptoJS from 'crypto-js';
import { Moment } from 'moment';
import * as moment from 'moment';


export function ObjectCompact(input: { [key: string]: any }, parentKey?: string): { [key: string]: any } {
  let result: { [key: string]: any } = {};
  
  for (const key in input) {
    // eslint-disable-next-line no-prototype-builtins
    if (input.hasOwnProperty(key)) {
        const value = input[key];
        const currentKey = parentKey ? `${parentKey}[${key}]` : key;

        if (Array.isArray(value)) {
          value.forEach((v, i) => {
            result = {...result, ...ObjectCompact(value[i], currentKey+`[${i}]`)}
          });         
        } else if (typeof value === 'object') {
          result = {...result, ...ObjectCompact(value, currentKey)}
        } else {
          result[currentKey] = value;
        }
    }
  }

  return result;
}

/**
 * Check if the given string is similar to the path
 */
export function isPath(path: string): boolean {
    let location = window.location.pathname;
    
    if(!environment.production){
      location = window.location.hash.replace('#', '');
    }

    if (location === path || location.split('?')[0] === path) {
        return true;
    }

    return false;
}

export function getSubdomain(): string {
    const { hostname } = new URL(window.location.href);
    
    if(hostname !== 'localhost'){
      const [subdomain] = hostname.split('.');

      return subdomain
    }

    return '';
}

export function isUndefined(variable: any): boolean{
  return typeof variable === 'undefined';
}

export function isNull(variable: any): boolean{
  return variable === null || variable === 'null';
}

export function isDefined(variable: any): boolean{
  return !isUndefined(variable) && !isNull(variable);
}

export function isEmpty(variable: any): boolean{
  return isUndefined(variable) || isNull(variable) || variable === '';
}

export function isEmail(email: string): boolean{
  const validate = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  return validate.test(email);
}

export function isCUIT(cuit: string): any {
  if (cuit === null || cuit === '') {
      return 'Por favor, ingrese un CUIT/DNI válido';
  }
  if (cuit.length !== 8 && cuit.length !== 11) {
      return 'Por favor, ingrese un CUIT/DNI válido';
  }
  if (cuit.length === 8) {
      return true;
  }

  const mult = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  let total = 0;

  for (let i = 0; i < mult.length; i++) {
      total += parseInt(cuit[i]) * mult[i];
  }

  const resto = total % 11;
  const calculado = resto === 0 ? 0 : resto === 1 ? 9 : 11 - resto;
  const digito = parseInt(cuit[10]);

  if (calculado === digito) {
      return true;
  } else {
      return 'El CUIT/DNI ingresado no es válido.';
  }
}

export function isCBU(cbu: string): any {
  try{
      cbu = cbu.replace('-', '').replace('/', '').replace('(', '').replace(')', '');

      if (cbu.length !== 22 || cbu === '0000000000000000000000'){
          return 'Por favor, ingrese el CBU correctamente';
      }

      const ponderador = '97139713971397139713971397139713';
      let i: any;
      let nDigito: any;
      let nPond: any;
      let bloque1: any;
      let bloque2: any;
      let nTotal = 0;

      bloque1 = '0' + cbu.substring(0, 7);

      for (let it = 0; it <= 7; it++) {
          nDigito = bloque1.charAt(it);
          nPond = ponderador.charAt(it);
          nTotal = nTotal + (nPond * nDigito) - ((Math.floor(nPond * nDigito / 10)) * 10);

          if (isNaN(nTotal)) {
              return 'Por favor, ingrese el CBU correctamente';
             
          }
      }

      i = 0;

      while (((Math.floor((nTotal + i) / 10)) * 10) !== (nTotal + i)) {
          i = i + 1;
      }

      if (Number(cbu.substring(7, 8)) !== i) {
          return 'Por favor, ingrese un CBU válido';
      }

      nTotal = 0;
      bloque2 = '000' + cbu.substring(8, 21);

      for (i = 0; i <= 15; i++) {
          nDigito = bloque2.charAt(i);
          nPond = ponderador.charAt(i);
          nTotal = nTotal + (nPond * nDigito) - ((Math.floor(nPond * nDigito / 10)) * 10);
      }

      i = 0;

      while (((Math.floor((nTotal + i) / 10)) * 10) !== (nTotal + i)) {
          i = i + 1;
      }

      if (Number(cbu.substring(21, 22)) !== i) {
          return 'Por favor, ingrese un CBU válido';
      }

      return true;
  }
  catch (ex){
      return 'Por favor, ingrese un CBU válido';
  }
}

export function isPhone(telefono: string): boolean {
  // return RegExp(/^[1-9]{1}[0-9]{9}$/).test(telefono); // 10 numeros sin permitir 0 inicial
  return RegExp(/^[1-9]{1}[0-9]{7}([0-9]+)?$/).test(telefono); // Entre 8 y mas numeros sin permitir 0 inicial
  // return RegExp(/^[0-9]{10}([0-9]{1})?$/).test(telefono); // Entre 10 y 11 numeros permitiendo 0 inicial
}

export function isCardNumber(numero: string): boolean {
  numero = String(numero);

  if (numero.length <= 0 && numero.length < 12) {
      return false;
  }

  // Elimina los espacios en blanco
  numero = numero.replace(/\s/g, '');

  // Inicio algoritmo Luhn
  // 1. Elimina el último dígito
  const ultimoDigito = Number(numero[numero.length - 1]);

  // 2. Invierte el numero
  const numeroInvertido: number[] = numero
      .slice(0, numero.length - 1)
      .split('')
      .reverse()
      .map(x => Number(x));

  // 3. + 4. Multiplica por 2 los numeros en posición par
  // Resta 9 si el digito es mayor a 9
  for (let i = 0; i <= numeroInvertido.length - 1; i += 2){
      numeroInvertido[i] = numeroInvertido[i] * 2;
      if (numeroInvertido[i] > 9) {
          numeroInvertido[i] = numeroInvertido[i] - 9;
      }
  }

  // 5. Suma los digitos obtenidos en el paso 4.
  let sumaDigitos = 0;
  sumaDigitos = numeroInvertido.reduce((valorPrevio, valorActual) => (valorPrevio + valorActual), 0);

  // 6. Calcula el módulo sobre 10 de la suma del paso 5 y el último dígito.
  // Si el resultado es 0 entonces es un numero de tarjeta válido
  return ((sumaDigitos + ultimoDigito) % 10 === 0);
}

export function dateConvertTimezone(date: Date, timezone: string): string {
    const newDate = date.toLocaleString('en-US', {timeZone: timezone});

    return newDate;
}

/*export function dateFormat(date: string | Moment, format: string, originFormat: string = ''): string {
    if (date !== '' && date !== null && typeof date !== 'undefined') {
        if (originFormat !== '') {
            return moment(date, originFormat).format(format);
        }

        return moment(date).format(format);
    }

    return '';
}*/

export function objectToString(data: any): string {
    const parameters: Record<string, any> = {};

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            if (data[key] !== null && typeof data[key] !== 'undefined' && data[key] !== '') {
                parameters[key] = data[key];
            }
        }
    }

    if (Object.keys(parameters).length > 0) {
        return '?' + Object.entries(parameters).map(([key, val]) => `${key}=${val}`).join('&');
    }

    return '';
}

export function getAllUrlParams(url: string): object {
  // get query string from url (optional) or window
  
  const queryString = url.split('?')[1];
  const obj: Record<string, any> = {};

  if (queryString) {
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=');
     
      // set parameter name and value (use 'true' if empty)
      const paramName = a[0];
      let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      // paramName = paramName.toLowerCase();

      //if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.replace('%20', ' ');

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {

        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '');        
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)![1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string'){
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

export function capitalize(str: string, lower = true): string {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

export function isDomain(domain: string): boolean{
    return (isValidDomain(domain, {
        subdomain: false, 
        wildcard: false,
        allowUnicode: false,
        topLevel: false
    })) ? true : false;
    
    return RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/).test(domain);
}

export function isSubdomain(domain: string, wildcard = false): boolean{
    return isValidDomain(domain, {
        subdomain: true, 
        wildcard: wildcard,
        allowUnicode: false,
        topLevel: false
    });
}

export function isIPv4(value: string): boolean{
  return /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm.test(value);
}

export function isIPv6(value: string): boolean{
  return /^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm.test(value);
}

export function camelcase(string: string): string {
  return string.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
}

export function CryptoJSAesEncrypt(text: string): string {
	const salt = CryptoJS.lib.WordArray.random(256);
	const iv = CryptoJS.lib.WordArray.random(16);
	
	const key = CryptoJS.PBKDF2(environment.encryptKey, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999 });
	const encrypted = CryptoJS.AES.encrypt(text, key, {iv: iv});
	
	const data = {
		ciphertext : CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
		salt : CryptoJS.enc.Hex.stringify(salt),
		iv : CryptoJS.enc.Hex.stringify(iv)    
	}
	
	//return text;
	return JSON.stringify(data);
}

export function ipDecode(encodedIp: string): string {
  const byteArray = Array.from(encodedIp).map(char => char.charCodeAt(0));

  if (byteArray.length === 4) {
    return byteArray.join('.');
  } else if (byteArray.length === 16) {
    return byteArray
      .map((byte, index) => byte.toString(16).padStart(2, '0'))  // Convertir cada byte a hexadecimal de 2 dígitos
      .join('')                                                  // Unir todos los bytes en una cadena
      .replace(/(.{4})/g, '$1:')                                 // Insertar ":" cada 4 dígitos
      .slice(0, -1);                                             // Quitar el último ":"
  } else {
    console.log('Formato de IP desconocido: ' + encodedIp);
  }

  return encodedIp;
}

export function isUUID(value: string): boolean {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(value);
}

/*
(function(){
    const oldLog = console.log;
    const oldWarn = console.warn;
    const oldInfo = console.info;
    const oldError = console.error;
    const oldClear = console.clear

    console.log = function (...args) {
        //window.logs.push(['log:',arguments]);
        oldLog.apply(console, args);
    };

    console.warn = function (...args) {
        //window.logs.push(['warn:',arguments]);
        oldWarn.apply(console, args);
    };

    console.info = function (...args) {
        //window.logs.push(['info:',arguments]);
        oldInfo.apply(console, args);
    };

    console.error = function (...args) {
        //window.logs.push(['error:',arguments]);
        oldError.apply(console, args);
    };

    console.clear = function () {
       //window.logs = [];
       oldClear();
    };

})();
*/
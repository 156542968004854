import moment from "moment";

// Models
import { CommonsCurrency } from "src/app/library/models/commons";
import { camelcase } from "src/app/library/utils/functions";
import { User } from "src/app/modules/user/models/user";

export class Workspace {
    id = '';
    name = '';
    partner: User = new User();
    currency: CommonsCurrency = new CommonsCurrency();
    creatredAt: Date = moment().toDate();
    updatedAt: Date = moment().toDate();
    deletedAt: Date = moment().toDate();
    users: Array<WorkspaceUser> = [];   

    constructor(parameters?: any) {
        if(parameters !== undefined){
            for (const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }

    private filter(key: string, value: any) {
        if (key === 'partner') {
            return new User(value);
        } else if(key === 'currency') {
            return new CommonsCurrency(value);
        } else if(['creatredAt', 'updatedAt', 'deletedAt'].includes(key)) {
            return moment(value).toDate();
        } else if(key === 'users') {
            const record: Array<WorkspaceUser> = [];

            if(value.length > 0){
                value.forEach((item: any) => {
                    record.push(new WorkspaceUser(item));
                });
            }

            return record;
        }

        return value;
    }
}

export class WorkspaceUser extends User {
    role: 'owner'|'admin'|'user' = 'user';

    constructor(parameters?: any) {
        super(parameters);
        
        if(parameters !== undefined){
            this.role = parameters.role;
        }
    }

    get roleName(): string{
        const names = {
            owner: 'Dueño',
            admin: 'Administrador',
            user: 'Usuario',
        };

        return names[this.role];
    }
}
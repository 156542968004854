<ui-card>
    <div class="ui-data-el">
        <div class="ui-type-el">
            <span class="ui-title-el">{{movement.title}}</span>
            <span class="ui-legend-el"><ui-badge>{{movement.typeName}}</ui-badge> {{movement.description}}</span>
        </div>

        <div class="ui-movement-el">
            <span class="ui-title-el" [ngClass]="{'entry' : movement.isConfirmed && movement.isEntry,'egress' : movement.isConfirmed && movement.isEgress}">
                <ng-container *ngIf="movement.isEgress">- </ng-container>$&nbsp;{{movement.ammount | number : '1.2-2'}}
            </span>
            <span class="ui-legend-el">{{movement.isPending ? movement.statusName : ''}}</span>
        </div>
    </div>

    <div class="ui-balance-el">
        <ng-container *ngIf="movement.isConfirmed">
            <span class="ui-title-el" [ngClass]="{'negative': movement.balance < 0}">$&nbsp;{{movement.balance | number : '1.2-2'}}</span>
            <span class="ui-legend-el">{{movement.date | date: 'dd/MM/YYYY'}}</span>
        </ng-container>

        <ng-container *ngIf="movement.isPending">
            <ui-button (click)="pay(movement)" matTooltip="Pagar">
                <ui-icon name="attach_money" type="outlined" slot="icon-only"></ui-icon>
            </ui-button>

            <ui-button (click)="cancel(movement)" *ngIf="movement.isRemovable" matTooltip="Cancelar">
                <ui-icon name="close" type="outlined" slot="icon-only"></ui-icon>
            </ui-button>
        </ng-container>
    </div>
</ui-card>
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { DataRecords, HttpResponseData } from 'src/app/library/models/utils';
import { BillingInvoice } from 'src/app/modules/billing/models/invoices';

// Services
import { ApiService } from 'src/app/library/services/api.service';

@Injectable()
export class BillingInvoicesService {
    constructor(
        private apiService: ApiService,
    ) {}

    getAll(page = 1, pageSize = 10, workspace?: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'module/accounting/v1/' + (workspace ?? 'workspace') + '/invoices/?' + 'page=' + page + '&page_size=' + pageSize).toPromise().then((success: any) => {
                const data: DataRecords = new DataRecords(success.data.details);

                if(data.recordsTotal > 0){
                    success.data.results.forEach((item: any) => {
                        data.records.push(new BillingInvoice(item));
                    });
                }

                resolve(this.apiService.successDataPrepare(success.data.message, data));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    download(id: number, workspace?: string): Promise<HttpResponseData> { 
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'module/accounting/v1/' + (workspace ?? 'workspace') + '/invoices/' + id + '/download/').toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data.link));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}

export class HostbiStatus {
    status = '';
    reason = '';
    message = '';

    constructor(parameters?: any) {
        if(typeof parameters !== 'undefined'){
            this.status = parameters.status;
            this.reason = parameters.statusReason;
            this.message = parameters.statusMessage;
        }
    }
}

import { Component, Input, OnInit } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { HostbiServer } from 'src/app/modules/hostbi/models/hostbi-server';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiServersService } from 'src/app/modules/hostbi/services/hostbi-servers.service';

@Component({
  selector: 'hostbi-server-record',
  templateUrl: './server-record.component.html',
  styleUrls: ['./server-record.component.scss']
})
export class HostbiServerRecordComponent implements OnInit {
  @Input() server = new HostbiServer();

  constructor(
    private uiService: UiService,
    private hostbiServersService: HostbiServersService
  ) { }

  ngOnInit(): void { }

  login(): void {
    this.uiService.loader(true);

    this.hostbiServersService.login(this.server.id).then((success: HttpResponseData) => {
      this.uiService.loader(false);
      window.open(success.result.data.url, '_blank');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Error', error.message, 'error');
    });
  }

  availability(): void {
    let list = '';

    Object.keys(this.server.availability).forEach((key: any) => {
      const value = Number(this.server.availability[key]);

      list = list + '<li style="color:' + (value < 5 ? '#cc0000' : '') + ';"><strong>' + key + '</strong> ' + value + '</li>';
    });

    this.uiService.dialog('Disponibilidad', `<p> <ul>` + list + `</ul> </p>`);
  }
}

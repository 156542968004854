import { Component, Input, OnInit } from '@angular/core';

// Models
import { SupportCategory } from 'src/app/modules/user/models/support';

@Component({
  selector: 'support-category',
  templateUrl: './support-category.component.html',
  styleUrls: ['./support-category.component.scss']
})
export class SupportCategoryComponent implements OnInit {
  @Input() category = new SupportCategory();

  constructor() { }

  ngOnInit(): void { }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { HostbiPlan } from 'src/app/modules/hostbi/models/hostbi-plan';
import { HostbiService } from 'src/app/modules/hostbi/models/hostbi-service';

// Services
import { ApiService } from 'src/app/library/services/api.service';


@Injectable()
export class HostbiServicesService {
    public accountsListIsUpdate = new BehaviorSubject<boolean>(false);

    constructor(
        private apiService: ApiService,
    ) {}

    getAll(serverId: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/manage/server/' + serverId + '/services/').toPromise().then((success: any) => {
                if (success.status) {
                    const servicesData: Array<HostbiService> = [];

                    success.data.results.forEach((value: any) => {
                        servicesData.push(new HostbiService(value));
                    });

                    resolve(this.apiService.successDataPrepare(success.data.message, servicesData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    get(serverId: number, id: number): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/manage/server/' + serverId + '/service/' + id).toPromise().then((success: any) => {
                if (success.status) {
                    const planData = new HostbiPlan(success.plan);

                    resolve(this.apiService.successDataPrepare(success.data.message, planData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });

        return promise;
    }

    create(serverId: number, name: string, price: number, action: string, actionParameters?: object): Promise<any> {
        const data = {
            name,
            price,
            action,
            actionParameters
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.request('post', 'module/hostbi/v1/manage/server/' + serverId + '/service/', data).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });

        return promise;
    }

    update(serverId: number, id: number, name: string, price: number, action: string, actionParameters?: object): Promise<any> {
        const data = {
            name,
            price,
            action,
            actionParameters
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.request('put', 'module/hostbi/v1/manage/server/' + serverId + '/service/' + id, data).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });

        return promise;
    }

    delete(serverId: number, id: number): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            this.apiService.request('delete', 'module/hostbi/v1/manage/server/' + serverId + '/service/' + id, {}).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });

        return promise;
    }

    plans(type: Array<'hosting'|'app'|'vps'|'dedicated'|'domain'>, currency: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'public/module/hostbi/v1/services/plans?model=' + type + '&currency=' + currency).toPromise().then((success: any) => {
                if (success.status) {
                    const servicesData: Array<HostbiService> = [];

                    success.data.results.forEach((value: any) => {
                        servicesData.push(new HostbiService(value));
                    });

                    resolve(this.apiService.successDataPrepare(success.data.message, servicesData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}

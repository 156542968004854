import { Type } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export interface UiAlertCloseEvent<R> {
  type: 'backdropClick' | 'close';
  data: R;
}

export interface UiAlertClickEvent {
  alert: UiAlertRef;
  data?: any;
}

// R = Response Data Type, T = Data passed to Modal Type
export class UiAlertRef<R = any, T = any> {
  data: any = {};
  afterClosed = new Subject<UiAlertCloseEvent<R>>();

  constructor(
    public overlay: OverlayRef,
    public config: T
  ) {
    overlay.backdropClick().subscribe(() => this._close('backdropClick'));
  }

  close(data?: any): void {
    this._close('close', data);
  }

  private _close(type: 'backdropClick' | 'close', data?: any): void {
    this.overlay.dispose();

    this.afterClosed.next({type, data});
    this.afterClosed.complete();
  }
}

import { Moment } from 'moment';
import * as moment from 'moment';

// Utils
import { camelcase } from 'src/app/library/utils/functions';

export class BillingMovement {
    id = 0;
    title = '';
    description = '';
    type = '';    
    status = 'pending'
    entry = 0;
    egress = 0;
    balance = 0;
    date: Date = moment().toDate();

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = value;
                }
            }
        }
    }

    get typeName(): string {
        const index: Record<string, any> = {
            payment: 'Pago',
            collection: 'Cobro',
            withdrawal: 'Retiro',
            credit: 'Crédito',
            deposit: 'Depósito',
        }
        
        return index[this.type];
    }   
    
    get statusName(): string {
        const index: Record<string, any> = {
            confirmed: 'Confirmado',
            pending: 'Pendiente',
        }
        
        return index[this.status];
    }

    get isEgress(): boolean {
        return this.entry <= 0 && this.egress > 0;
    }

    get isEntry(): boolean {
        return this.entry > 0 && this.egress <= 0;
    }
    
    get isConfirmed(): boolean {
        return this.status === 'confirmed';
    }

    get isPending(): boolean {
        return this.status === 'pending';
    }
    
    get isRemovable(): boolean {
        return this.type === 'deposit';
    }

    get ammount(): number {
        return this.isEgress ? this.egress : this.entry;
    }
}

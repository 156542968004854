import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Utils
import { platformUrl } from 'src/app/platform/functions/platform';
import { UiModalbox } from 'src/app/library/components/ui-modalbox';
import { FormControlIntegrate, formValidate, formValidateInit } from 'src/app/library/utils/form-validators';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { Workspace } from 'src/app/modules/workspaces/models/workspace';
import { BillingOrderDetail } from 'src/app/modules/billing/models/orders';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { BillingOrdersService } from 'src/app/modules/billing/services/orders.service';

@Component({
  selector: 'billing-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class BillingOrderFormComponent implements OnInit {
  @Input() workspace: Workspace = new Workspace();

  public form = new FormControlIntegrate({
    type: { required: '' },
    expiresOn: { required: '' },
    notify: { required: '' },
    item: { required: '' },
    quantity: { required: '' },
    amount: { required: '' },
  });
 
  constructor(
    private uiService: UiService,
    private uiModalbox: UiModalbox,
    private formBuilder: FormBuilder,
    private billingOrdersService: BillingOrdersService,
  ) {
    this.form.data = this.formBuilder.group({
      type: new FormControl('', [Validators.required]),
      expiresOn: new FormControl('', [Validators.required]),
      notify: new FormControl(false, [Validators.required]),
      details: this.formBuilder.array([]),
    });

    this.detailCreate();
  }

  ngOnInit(): void { }

  close(){
    this.uiModalbox.close();
  }

  get detailsTotal(): number{
    return this.form.data.value.details.reduce((a: number, b: any) => a + (b.price * b.quantity), 0);
  }

  get detailsControls(): Array<AbstractControl> {
    return (this.form.data.get('details') as FormArray).controls;
  }

  detailCreate(){
    const detailsControl = <FormArray> this.form.data.controls['details'];

    detailsControl.push(this.formBuilder.group({
      item: ['', [Validators.required]],
      quantity: [1, [Validators.required, Validators.min(1)]],
      price: [0, [Validators.required, Validators.min(1)]]
    }));

    this.form.init();
  }

  detailDelete(index: number){
    const detailsControl = <FormArray> this.form.data.controls['details'];

    detailsControl.removeAt(index);
  }

  generate(){
    this.form.validate();

    if (this.form.data.valid) {
      this.uiService.loader(true);

      const details: Array<BillingOrderDetail> = [];

      this.form.data.value.details.forEach((detail: any) => {
        details.push(new BillingOrderDetail({
          item: detail.item,
          unity: 'un',
          quantity: detail.quantity,
          price: detail.price,
          amount: detail.price * detail.quantity,
        }));
      });

      this.billingOrdersService.create(this.workspace.id, {
        type: this.form.data.value.type,
        subtotal: this.detailsTotal,
        expires_on: this.form.data.value.expiresOn,
        notify: this.form.data.value.notify,
        return_url: platformUrl(),
        details,
      }).then((success: HttpResponseData) => {
        this.uiService.loader(false);

        if(success.status){
          this.uiModalbox.close({status: true});
          this.uiService.dialog('Generar orden', 'Orden generada correctamente', 'success');
        } else {
          this.uiService.dialog('Generar orden', success.message);
        }
      }).catch((error: HttpResponseData) => {
        this.uiService.loader(false);
        this.uiService.dialog('Generar orden', error.message, 'error');
      });
    }
  }
}
